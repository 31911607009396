<div *ngIf="fullPageLoader" class="loading">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="forms">
  <div class="forms__content">
    <div class="forms__content_image"></div>
    <div class="forms__content_form">
      <div class="container">
        <div class="login" *ngIf="forgotPassword; else forgotPassTemplateName">
          <h1 class="form-heading">{{'LOGIN_PAGE.TITLE' | translate}}</h1>
          <form (ngSubmit)="onSubmit()" [formGroup]="validationform">

            <!-- <div class="form-group">
              <label class="form-label" for="validationCustom01">Email Address</label>
              <img src="" alt="">
              <input  required type="text" class="form-input" id="validationCustom01" formControlName="email"
                placeholder="Enter Email Address" [ngClass]="{'is-valid': submit && form.email.errors}">
              <div *ngIf="submit && form.email.errors" class="valid-feedback">
                <span *ngIf="form.email.errors.required">Looks good!</span>
              </div>
            </div> -->

            <div class="form-group login-form-margin">
              <ngb-alert *ngIf="showError" type="danger" (closed)="close()">{{ errorMessage }}</ngb-alert>
              <mat-form-field class="example-full-width">
                <mat-label>{{'LOGIN_PAGE.EMAIL_ADDRESS' | translate}}</mat-label>
                <input matInput class="form-input" formControlName="email">
                <mat-error *ngIf="validationform.controls.email.invalid">{{'LOGIN_PAGE.VALID_EMAIL' | translate}}</mat-error>
              </mat-form-field>
            </div><!-- form-group -->

            <div class="form-group login-form-margin">
              <mat-form-field class="example-full-width">
                <mat-label>{{'LOGIN_PAGE.PASSWORD' | translate}}</mat-label>
                <input matInput type="password" minlength="8" #x class="form-input" formControlName="password">
                <div class="show-password" (click)="x.type=x.type=='password'?'text':'password'">{{'LOGIN_PAGE.SHOW' | translate}}</div>
                <mat-error *ngIf="validationform.controls.password.invalid">{{'LOGIN_PAGE.VALID_PASS' | translate}}</mat-error>
              </mat-form-field>
              <div class="parent-link">
                <a (click)="forgotPassword = !forgotPassword">{{'LOGIN_PAGE.FORGOT_PASSWORD' | translate}}</a>
              </div>
            </div><!-- form-group -->




            <button class="btn btn-primary login-btn" type="submit" [disabled]='validationform.invalid || isLogin'>
              <p *ngIf="!isLogin">{{'LOGIN_PAGE.SIGN_IN' | translate}}</p> <img *ngIf="!isLogin" src="../assets/images/right-arrow.png" alt="">
              <div class="loading-wrapper" *ngIf="isLogin">
                <div class="spinner-border">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </button>
          </form>
          <p class="bottom-login">{{'LOGIN_PAGE.ACCOUNT' | translate}}</p>
        </div>
        <!--login-->

        <ng-template #forgotPassTemplateName>
          <div class="login">
            <h1 class="form-heading">{{'LOGIN_PAGE.FORGOT_PASSWORD' | translate}}</h1>
            <form (ngSubmit)="resetPassword()" [formGroup]="forgotPassform">

              <!-- <div class="form-group">
              <label class="form-label" for="validationCustom01">Email Address</label>
              <img src="" alt="">
              <input  required type="text" class="form-input" id="validationCustom01" formControlName="email"
                placeholder="Enter Email Address" [ngClass]="{'is-valid': submit && form.email.errors}">
              <div *ngIf="submit && form.email.errors" class="valid-feedback">
                <span *ngIf="form.email.errors.required">Looks good!</span>
              </div>
            </div> -->

              <div class="form-group login-form-margin">
                <ngb-alert *ngIf="showResetAlert" type="success" (closed)="showResetAlert = false">{{ resetPasswordAlert
                  }}</ngb-alert>
                <mat-form-field class="example-full-width">
                  <mat-label>{{'LOGIN_PAGE.EMAIL_ADDRESS' | translate}}</mat-label>
                  <input matInput class="form-input" formControlName="email">
                  <mat-error *ngIf="forgotPassform.controls.email.invalid">Enter valid email</mat-error>
                </mat-form-field>
                <div class="parent-link">
                  <a (click)="forgotPassword = !forgotPassword">{{'FORGOT_PASS.RETURN' | translate}}</a>
                </div>
              </div><!-- form-group -->


              <div class="loading-wrapper" *ngIf="isLogin">
                <div class="spinner-border">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>


              <button [disabled]="forgotPassform.invalid" class="btn btn-primary" type="submit">
                {{'FORGOT_PASS.BUTTON' | translate}}
              </button>
            </form>
          </div>
        </ng-template>


        <div class="register-redirect">
          <div class="teacher-register">
            <img routerLink="/tutor/register" src="../assets/images/teacher-register.png" alt="">
            <p>{{'LOGIN.TUTOR_REGISTER' | translate}}</p>
          </div>

          <div class="student-register">
            <img routerLink="/student/register" src="../assets/images/student-register.png" alt="">
            <p>{{'LOGIN.STUDENT_REGISTER' | translate}}</p>
          </div>
        </div>
      </div><!-- container -->
    </div>
    <!--forms__content_form"-->
  </div><!-- forms__content -->
</div><!-- forms -->
