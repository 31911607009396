import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';

import { CarouselModule } from 'ngx-owl-carousel-o';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

import { MatSelectCountryModule } from "@angular-material-extensions/select-country";

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { TutorRegisterComponent } from './tutor-register/tutor-register.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { StudentModule } from './student/student.module';
import { CommonModule, DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { AuthInterceptor } from './core/_helpers/auth.interceptor';
import { AngularIbanModule } from 'angular-iban';


import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgxPhotoEditorModule} from "ngx-photo-editor";
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DemoComponent } from './home/demo/demo.component';
import { GlobalErrorService } from './core/_services/errors/global-error.service';
import { AboutComponent } from './home/about/about.component';
import { TutorComponent } from './home/tutor/tutor.component';

import { NgxStripeModule } from 'ngx-stripe';
import { GroupTutoringComponent } from './home/group-tutoring/group-tutoring.component';
import { DetailsGroupTutoringComponent } from './home/group-tutoring/details-group-tutoring/details-group-tutoring.component';




// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TutorRegisterComponent,
    StudentRegisterComponent,
    VerifyEmailComponent,
    ImpressumComponent,
    DemoComponent,
    AboutComponent,
    TutorComponent,
    GroupTutoringComponent,
    DetailsGroupTutoringComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatSliderModule,
    StudentModule,
    MatDatepickerModule,
    NgbModule,
    HttpClientModule,
    NgxSliderModule,
    Ng2TelInputModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTooltipModule,
    MatIconModule,
    NgxPhotoEditorModule,
    FontAwesomeModule,
    CrystalLightboxModule,
    AngularIbanModule,
    NgxStripeModule.forRoot('pk_test_51ITYvyLrdToeAfziriIQSMfwfAODA2LJvt3hPCyMlXlZrK8hgOCtzciBFxSSgEF7G6DiIoXa5R6NY409FyjVxtLt00uZlgus8T'),
    MatSelectCountryModule.forRoot('en'),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    //CountdownTimerModule.forRoot()
  ],
  exports: [MatFormFieldModule, MatInputModule, MatAutocompleteModule, TranslateModule],
  providers: [
    MatAutocompleteModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    HttpClient,
    {provide: ErrorHandler, useClass: GlobalErrorService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
