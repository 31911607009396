import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/_services/auth.service';
import { RegisterFormsService } from '../core/_services/register-forms.service';
import { passValidator, dateValidator, requiredFileType, phoneValidator } from '../core/_shared/validator';
import { ChangeDetectorRef } from '@angular/core';
import { CroppedEvent } from 'ngx-photo-editor';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ValidatorService } from 'angular-iban';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
declare var $: any;



@Component({
  selector: 'app-tutor-register',
  templateUrl: './tutor-register.component.html',
  styleUrls: ['./tutor-register.component.scss'],
  providers: [DatePipe]
})
export class TutorRegisterComponent implements OnInit, AfterViewChecked {
  @ViewChildren("selectSubject") subjectSelects: QueryList<ElementRef<HTMLSelectElement>>;


  arrLang = {
    en: {
      timeslot: "Change Timeslots",
    },
    de:{
      timeslot: "Zeitfenster ändern",
    }
  }

  fullPageLoader = false;

  isSelected: boolean = false;
  imgURL;

  times;

  getValue = [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    },

  ];

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  educations;
  educationsDepth;
  subjects;
  tutoringStyle;
  lessons;
  tutoringPlaces;

  submitRegister = true;

  object3 = {};
  fourthObject = [];

  wrapTime = [{
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }]


  verifyEmail = '';
  verifyIban = '';
  verifyZip = false;

  // CV uploaded name
  cvName = '';

  lang;
  percentage;

  get educationFormArray() {
    return (this.secondFormGroup.get('education') as FormArray).controls;
  }


  addEducaitonForm() {
    this.educationFormArray.push(this.formBuilder.group({
      id: ['', Validators.required],
      education_id: ['', Validators.required],
    }))

    console.log(this.educationFormArray)
  }

  removeEducationForm(index, subject) {
    this.educationFormArray.splice(index, 1)
    this.updateValidation(event);
    this.selectedSubjects.delete(subject);
  }

  public reactiveForm: FormGroup;

  public ibanReactive: FormControl;

  constructor(private formBuilder: FormBuilder,
    private registerFormService: RegisterFormsService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private dateAdapter: DateAdapter<Date>) {

    this.lang = localStorage.getItem('lang') || 'de';
    this.dateAdapter.setLocale(this.lang);

    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }


    // this.registerFormService.getNrLessonsPercentage().subscribe(data => {
    //   console.log('percentage',data)
    //   this.percentage = data;
    // })


    // handling lists data from server
    this.registerFormService.getEducation().subscribe(res => {
      this.educations = res.data;
      console.log('ed', this.educations)
    });

    this.registerFormService.getSubjects().subscribe(res => {
      this.subjects = res.data;
    });

    this.registerFormService.getTutoringStyle().subscribe(res => {
      this.tutoringStyle = res.data;
    });

    this.registerFormService.getLessons().subscribe(res => {
      this.lessons = res.data;
      this.percentage = res.data;
    });
    this.registerFormService.geTutoringPlaces().subscribe(res => {
      this.tutoringPlaces = res.data;

    });
    this.registerFormService.getTimes().subscribe(res => {
      this.times = res.data;
      console.log(this.times)
    });
  }





  ngOnInit() {

    this.ibanReactive = new FormControl(
      null,
      [
        ValidatorService.validateIban
      ]
    );

    // this.reactiveForm = this.formBuilder.group({

    // });


    this.firstFormGroup = this.formBuilder.group({
      gender: ['', Validators.required],
      firstname: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      surname: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      //description: ["", Validators.required],
      date: ['', [Validators.required, dateValidator]],
      address: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      zipCode: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      city: ["", [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ["", [Validators.required, phoneValidator]],
      verifyPassword: ['', [Validators.required, passValidator]],
      password: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      bio: [""],
      image: [''],
      ibanReactive: this.ibanReactive,
      //ibanReactive: ["", Validators.requiredValidatorService.validateIban],
      //image: ['', [Validators.required, requiredFileType(["jpg"])]],
    });

    this.secondFormGroup = this.formBuilder.group({

      //new fields changed
      lastDegre: ['', Validators.required],
      direction: [''],
      direction_id: [''],
      school: [''],
      working: [''],
      //file: ['', [Validators.required, requiredFileType(["pdf", "docx"])]],
      //file: ["", Validators.required],

      education: this.formBuilder.array([
        this.formBuilder.group({
          id: ['', Validators.required],
          education_id: ['', Validators.required]
        })
      ]),


      // tutoringStyle: ['', Validators.required],
      // lessons: ['', Validators.required],
      // tutoringPlaces: ['', Validators.required],
      //tutoringTime: ['', Validators.required],


    });

    this.thirdFormGroup = this.formBuilder.group({

      //new fields changed
      tutoringExperience: [''],
      tutoringPlaces: ['', Validators.required],
      tutoringStyle: ['', Validators.required],
      tutoringPlatform: [''],
      skills: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],


      //subject: ['', Validators.required],
      //educa: ['', Validators.required],
      //educaLevel: ['', Validators.required],
      //tutoredStudents: ['', Validators.required],
      //image: ['', [Validators.required, requiredFileType(["jpg"])]],
      //file: ['', [Validators.required, requiredFileType(["pdf", "docx"])]],
    });

    this.fourthFormGroup = this.formBuilder.group({
      lessons_price: new FormArray([])
    })

  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();

  }

  // onTidioChatApiReady() {
  //   // Code after chat loaded
  //   if(this.router.url == '/tutor/register'){
  //     window['tidioChatApi'].hide();
  //   }
  // }


  // Check for valid email
  emailExists = false;
  returnEmail() {
    this.emailExists = true;
    this.registerFormService.checkEmail(this.firstFormGroup.value.email).subscribe(res => {
      if (res != null) {
        this.verifyEmail = res.msg;
        this.emailExists = false;
      } else {
        this.verifyEmail = '';
        this.emailExists = false;
      }
    })
  }


  ibanCheck = false;
  ibanError = false;
  returnIban() {
    this.ibanCheck = true;
    this.registerFormService.checkIban(this.firstFormGroup.value.ibanReactive).subscribe(res => {
      console.log(res);
      if (res != 1) {
        this.verifyIban = res[0];
        this.ibanCheck = false;
        this.ibanError = true;
      } else {
        this.verifyIban = '';
        this.ibanCheck = false;
        this.ibanError = false;
      }
    })
  }

  zipCheck = false;
  returnZip() {
    this.zipCheck = true;
    this.registerFormService.checkZip(this.firstFormGroup.value.zipCode).subscribe(res => {
      console.log(res);
      if (res == 1) {
        this.verifyZip = false;
        this.zipCheck = false;
      } else {
        this.zipCheck = false;
        this.verifyZip = true;
      }
    }, (err) => {
      this.verifyZip = true;
      this.zipCheck = false;
    })
  }

  genderChose = 'male';
  choseGender(e) {
    console.log(e)
    this.genderChose = e.value;
  }




  onChange(e) {
    // console.log(e)
    // for(var i = 1; i <= this.educations.length; i++){
    //   console.log(this.educations[e.id])
    // }
    // if(e.value == this.secondFormGroup.controls.educa.value){
    //   this.educationsDepth = this.educations.education_depth;
    // }
    const enableForm = this.thirdFormGroup.controls.educaLevel.enable();
    const educationValue = this.thirdFormGroup.controls.educa.value;
    if (educationValue == "5") {
      this.educationsDepth = this.educations[4].education_depth;
      enableForm
    } else if (educationValue == "6") {
      this.educationsDepth = this.educations[5].education_depth;
      enableForm
    } else if (educationValue == "7") {
      this.educationsDepth = this.educations[6].education_depth;
      enableForm
    } else if (educationValue == "8") {
      this.educationsDepth = this.educations[7].education_depth;
      enableForm
    } else if (educationValue == "9") {
      this.educationsDepth = this.educations[8].education_depth;
      enableForm
    }
    if (educationValue == "1" ||
      educationValue == "2" ||
      educationValue == "3" ||
      educationValue == "4") {
      this.thirdFormGroup.controls.educaLevel.disable();
    }


    // if(this.fourthFormGroup.controls.tutorAge.value == 'no'){
    //   this.fourthFormGroup.controls.tutorRange.disable();
    // }else{
    //   this.fourthFormGroup.controls.tutorRange.enable();
    // }

    // if(this.fourthFormGroup.controls.askLocation.value == 'no'){
    //   this.fourthFormGroup.controls.location.disable();
    // }else{
    //   this.fourthFormGroup.controls.location.enable();
    // }

  }


  imageChangedEvent: any;
  base64: any;
  file: File = null;
  cvFile: File = null;

  fileChangeEvent(event: any) {
    console.log('event', event)
    this.imageChangedEvent = event;

  }

  imageSizePass = true;
  imageSizeMb;
  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;

    console.log('image croped', event, event.file.size)
    if (event.file.size < 2000000) {
      this.imageSizePass = true;
      this.file = <File>event.file;
      this.firstFormGroup.get('image').setValue(this.file);
    } else {
      this.imageSizeMb = (event.file.size / (1024 * 1024)).toFixed(2)
      this.imageSizePass = false;
    }


  }

  addFile(event) {
    console.log(event)
    this.cvName = event.srcElement.files[0].name;
    this.cvFile = event.srcElement.files[0];
    // const file = event.target.files[0];
    // this.secondFormGroup.get('file').setValue(file);

    // this.secondFormGroup.updateValueAndValidity();
  }


  // show input image to DOM
  // preview(files) {
  //   const image = files[0];
  //   this.firstFormGroup.get('image').setValue(image);

  //   if (files.length === 0) {
  //     return;
  //   }

  //   const mimeType = files[0].type;

  //   if (mimeType.match(/image\/*/) == null) {
  //     return;
  //   }

  //   const reader = new FileReader();
  //   reader.readAsDataURL(files[0]);
  //   reader.onload = (e) => {
  //     this.imgURL = reader.result;
  //   }
  // }

  deleteImage() {
    this.firstFormGroup.get('image').setValue('');
    this.base64 = null;
  }

  onceClick() {
    $('.times').html(this.arrLang[this.lang].timeslot)
  }

  selectAll(e) {

    //var day = e.target.innerText.toLowerCase()
    var day = e.target.defaultValue.toLowerCase();
    $(`input[name="${day}"]`).prop('checked', true);
    $(`input[name="${day}"]`).parent().addClass('colorTime');

    let ele = document.getElementsByName(day)

    if (e.target.checked === true) {
      this.getValue[0][day] = []
      for (let i = 0; i < ele.length; i++) {
        console.log(ele[i])
        this.getValue[0][day].push({
          id: ele[i].attributes[4].value,
          time: ele[i].nextSibling.textContent,
          day: ele[i].id.replace(/[0-9]/g, '')
        })
      }
      e.target.parentNode.classList.add('colorTime');

    } else {

      this.getValue[0][day] = []
      $(`input[name="${day}"]`).prop('checked', false);
      $(`input[name="${day}"]`).parent().removeClass('colorTime');
      e.target.parentNode.classList.remove('colorTime');

    }

    this.wrapTime[0][day] = this.reduceArray(this.getValue[0][day].slice().sort((a, b) => a.id - b.id));
  }

  changeSelection(e) {
    //console.log(e.target.id == 'monday'+e.target.defaultValue)

    if (e.target.checked === true && e.target.id == 'monday' + e.target.defaultValue) {

      this.getValue[0].monday.push({
        id: parseInt(e.target.defaultValue),
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');


    } else if (e.target.checked === true && e.target.id === 'tuesday' + e.target.defaultValue) {

      this.getValue[0].tuesday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'wednesday' + e.target.defaultValue) {

      this.getValue[0].wednesday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'thursday' + e.target.defaultValue) {

      this.getValue[0].thursday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'friday' + e.target.defaultValue) {

      this.getValue[0].friday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'saturday' + e.target.defaultValue) {

      this.getValue[0].saturday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'sunday' + e.target.defaultValue) {

      this.getValue[0].sunday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else {
      e.target.parentNode.classList.remove('colorTime');

      if (e.target.id == 'monday' + e.target.defaultValue) {
        var index = this.getValue[0].monday.findIndex(el => el.id === e.target.defaultValue)

        this.getValue[0].monday.splice(index, 1);
      } else if (e.target.id == 'tuesday' + e.target.defaultValue) {
        var index = this.getValue[0].tuesday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].tuesday.splice(index, 1);
      } else if (e.target.id == 'wednesday' + e.target.defaultValue) {
        var index = this.getValue[0].wednesday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].wednesday.splice(index, 1);
      }
      else if (e.target.id == 'thursday' + e.target.defaultValue) {
        var index = this.getValue[0].thursday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].thursday.splice(index, 1);
      } else if (e.target.id == 'friday' + e.target.defaultValue) {
        var index = this.getValue[0].friday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].friday.splice(index, 1);
      } else if (e.target.id == 'saturday' + e.target.defaultValue) {
        console.log(e.target.id)
        var index = this.getValue[0].saturday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].saturday.splice(index, 1);
      } else if (e.target.id == 'sunday' + e.target.defaultValue) {
        var index = this.getValue[0].sunday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].sunday.splice(index, 1);
      }

    }
    // if (e.target.checked === true && e.target.id == dayID) {
    //   this.getValue.push({
    //     id: e.target.defaultValue,
    //     day: e.target.id
    //   });
    //   e.target.parentNode.classList.add('colorTime');


    // } else {
    //   e.target.parentNode.classList.remove('colorTime');

    //   if (e.target.id == dayID) {
    //     console.log(dayID)
    //     const index = this.getValue.findIndex(item => item.day === e.target.id);
    //     this.getValue.splice(index, 1);
    //     console.log(index)
    //   }
    //   console.log(this.getValue)
    // }






    // var self = this;
    // if (e.target.checked === true && e.target.id === 'monday') {
    //   this.getValue.weeks[0].monday.push(e.target.defaultValue);
    //   e.target.parentNode.classList.add('colorTime');
    // } else if (e.target.checked === true && e.target.id === 'tuesday') {
    //   this.getValue.weeks[1].tuesday.push(e.target.defaultValue);
    //   e.target.parentNode.classList.add('colorTime');
    // }
    // else if (e.target.checked === true && e.target.id === 'wednesday') {
    //   //this.getValue.weeks[2].wednesday += e.target.defaultValue;
    //   this.getValue.weeks[2].wednesday.push(e.target.defaultValue);
    //   e.target.parentNode.classList.add('colorTime');
    // }
    // else if (e.target.checked === true && e.target.id === 'thursday') {
    //   this.getValue.weeks[3].thursday.push(e.target.defaultValue);
    //   e.target.parentNode.classList.add('colorTime');
    // }
    // else if (e.target.checked === true && e.target.id === 'friday') {
    //   this.getValue.weeks[4].friday.push(e.target.defaultValue);
    //   e.target.parentNode.classList.add('colorTime');
    // } else {
    //   e.target.parentNode.classList.remove('colorTime');

    //   if (e.target.id === 'monday') {
    //     let index = self.getValue.weeks[0].monday.indexOf(e.target.defaultValue);

    //     self.getValue.weeks[0].monday.splice(index, 1);
    //   } else if (e.target.id == 'tuesday') {
    //     let index = self.getValue.weeks[1].tuesday.indexOf(e.target.defaultValue);
    //     self.getValue.weeks[1].tuesday.splice(index, 1);
    //   }
    //   else if (e.target.id == 'wednesday') {
    //     let index = self.getValue.weeks[2].wednesday.indexOf(e.target.defaultValue);
    //     self.getValue.weeks[2].wednesday.splice(index, 1);
    //   }
    //   else if (e.target.id == 'thursday') {
    //     let index = self.getValue.weeks[3].thursday.indexOf(e.target.defaultValue);
    //     self.getValue.weeks[3].thursday.splice(index, 1);
    //   }
    //   else if (e.target.id == 'friday') {
    //     let index = self.getValue.weeks[4].friday.indexOf(e.target.defaultValue);
    //     self.getValue.weeks[4].friday.splice(index, 1);
    //   }
    // }

    this.wrapTime[0].monday = this.reduceArray(this.getValue[0].monday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].tuesday = this.reduceArray(this.getValue[0].tuesday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].wednesday = this.reduceArray(this.getValue[0].wednesday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].thursday = this.reduceArray(this.getValue[0].thursday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].friday = this.reduceArray(this.getValue[0].friday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].saturday = this.reduceArray(this.getValue[0].saturday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].sunday = this.reduceArray(this.getValue[0].sunday.slice().sort((a, b) => a.id - b.id));

  }

  reduceArray(arr) {
    return arr.reduce((acc, current) => {
      const lastSubArray = acc[acc.length - 1];

      if (!lastSubArray || parseInt(lastSubArray[lastSubArray.length - 1].id) !== parseInt(current.id) - 1) {
        acc.push([]);
      }

      acc[acc.length - 1].push(current);

      return acc;

    }, []);
  }

  deleteTime(e) {
    const weekArr = document.querySelectorAll(".testTime");

    weekArr.forEach((val, i) => {
      var selectedTime = e.target.parentNode.textContent.trim();
      var valTime = val.textContent;
      if (selectedTime == valTime.trim()) {
        $(val).has('#' + e.target.id).children().prop('checked', false);
        $(val).has('#' + e.target.id).removeClass('colorTime')
      }
    })

    var self = this;
    console.log(e)
    if (e.target.id == 'monday' + e.target.id.slice(-1)) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].monday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].monday.splice(index, 1);
    } else if (e.target.id == 'tuesday' + e.target.id.slice(-1)) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].tuesday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].tuesday.splice(index, 1);
    } else if (e.target.id == 'wednesday' + e.target.id.slice(-1)) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].wednesday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].wednesday.splice(index, 1);
    } else if (e.target.id == 'thursday' + e.target.id.slice(-1)) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].thursday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].thursday.splice(index, 1);
    } else if (e.target.id == 'friday' + e.target.id.slice(-1)) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].friday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].friday.splice(index, 1);
    }
  }


  tutoringOnline = false;
  tutoringPlace(e) {
    if (e.value == "4") {
      this.tutoringOnline = true;
    } else {
      this.tutoringOnline = false;
    }
  }

  tabsArray = [];
  tabsArrayHTML = [];
  get f() { return this.fourthFormGroup.controls; }
  get l() { return this.f.lessons_price as FormArray; }

  secondFormBtn() {
    this.tabsArray = []
    this.tabsArrayHTML = [];
    this.fourthFormGroup.controls.lessons_price['controls'] = []
    this.educationFormArray.forEach(el => {

      this.l.push(this.formBuilder.group({
        subject_id: '',
        lessons_0: ['', Validators.required],
        lessons_1: ['', Validators.required],
        lessons_2: ['', Validators.required],
        lessons_3: ['', Validators.required],
        lessons_4: ['', Validators.required],
      }));
      this.tabsArray.push(el.value.id)
      this.tabsArrayHTML.push(el.value.id.replace(/[0-9]/g, ''))
      console.log('loop', this.tabsArray)
    })

    console.log('l', this.l)

  }

  changeStepper(e) {
    this.secondFormBtn();
    this.updateValidation(e);
  }


  selectedSubjects = new Set<string>();
  updateValidation(e) {

    this.secondFormGroup.get('education').updateValueAndValidity();
    this.fourthFormGroup.get('lessons_price').updateValueAndValidity();

    // for(let i = 0; i < this.disa.length; i++){
    //   if(e.value.charAt(0) == this.disa[i].id){
    //     this.disa[i].disabled = true;
    //   }
    // }

    this.selectedSubjects.clear();
    this.subjectSelects.forEach(ls => {
      const selectedVal = ls['_value'];
      console.log(selectedVal.replace(/[0-9]/g, ''))
      if (selectedVal) this.selectedSubjects.add(selectedVal);
    });


  }

  isSelectedSubject(sub: string) {
    return this.selectedSubjects.has(sub);
  }



  //function variables
  degreDepth;
  showDirection = true;
  filteredEducation = [];
  lastDegreOnChange(e) {
    console.log(this.secondFormGroup)
    this.filteredEducation = [];
    for (let i = parseInt(e.value) - 1; i >= 0; i--) {

      this.filteredEducation.push(this.educations[i])
    }
    this.secondFormGroup['controls'].direction.enable();
    if (e.value == "1" || e.value == "2" || e.value == "4") {
      this.secondFormGroup['controls'].direction.disable();
      // } else if (e.value == "4" || e.value == "9" || e.value == "11") {
      //   let val = e.value;
      //   this.showDirection = false;
      //   if (val == '4') {
      //     this.degreDepth = this.educations[3].education_depth;
      //   } else if (val == '9') {
      //     this.degreDepth = this.educations[8].education_depth;
      //   } else if (val == 11) {
      //     this.degreDepth = this.educations[10].education_depth;
      //   }

    } else {
      this.showDirection = true;
    }


  }

  updateDate() {
    console.log('test')
    this.firstFormGroup.get('date').updateValueAndValidity();
    console.log(this.firstFormGroup)
  }

  getNumber(e) {
    console.log('number', e)
    this.firstFormGroup.get('phone').setValue(e)
  }

  numberError = true;
  hasError(e) {
    console.log(e)
    this.numberError = e;
  }

  telInputObject(obj) {
    console.log('obj', obj)
  }


  storePrices = {
    prices: []
  }



  submit() {

    this.fullPageLoader = true;

    let subjects = {
      elements: []
    }

    this.fourthObject = [];
    this.storePrices = {
      prices: []
    }

    this.secondFormGroup.value.education.forEach(element => {
      subjects.elements.push({
        id: element.id.replace(/[^0-9]/g, ''),
        education_id: element.education_id
      })
    });

    let objectLength = Object.keys(this.fourthFormGroup.value.lessons_price[0]).length
    console.log(objectLength)
    this.fourthFormGroup.value.lessons_price.forEach((element, index) => {
      for (let i = 0; i < objectLength; i++) {
        if (i == 0) {
          this.fourthObject.push({
            subject_id: this.tabsArray[index].replace(/\D/g, ""),
            id: i + 1,
            price: element.lessons_0,

          })
        } else if (i == 1) {
          this.fourthObject.push({
            subject_id: this.tabsArray[index].replace(/\D/g, ""),
            id: i + 1,
            price: element.lessons_1,

          })
        } else if (i == 2) {
          this.fourthObject.push({
            subject_id: this.tabsArray[index].replace(/\D/g, ""),
            id: i + 1,
            price: element.lessons_2,

          })
        } else if (i == 3) {
          this.fourthObject.push({
            subject_id: this.tabsArray[index].replace(/\D/g, ""),
            id: i + 1,
            price: element.lessons_3,

          })
        } else if (i == 4) {
          this.fourthObject.push({
            subject_id: this.tabsArray[index].replace(/\D/g, ""),
            id: i + 1,
            price: element.lessons_4,

          })
        }

      }
    });
    this.storePrices.prices.push(this.fourthObject);

    console.log('this obj', this.fourthObject)


    const docFile = new FormData();



    const body = this.firstFormGroup.value;
    body.date = this.datePipe.transform(body.date, 'dd/MM/yyyy');




    this.object3 = {
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
      ...this.thirdFormGroup.value,
      ...this.storePrices,
      ...this.getValue,
      ...subjects,

    };

    // const imageFile = new FormData();
    // console.log('image', this.file)
    // imageFile.append('image', this.file, this.file.name)
    //this.object3['img'] = imageFile;

    if (this.file) {
      const imageFile = new FormData();
      console.log('image', this.file)
      imageFile.append('image', this.file, this.file.name)
      this.object3['img'] = imageFile;

    }

    // if(this.cvFile){
    //   console.log('cv',this.file)
    //   docFile.append('doc' , this.cvFile, this.cvName)
    //   this.object3['cvFile'] = docFile;
    // }


    console.log('final object', this.object3)


    // this.registerFormService.checkIbanZip(this.firstFormGroup.value.ibanReactive, this.firstFormGroup.value.zipCode).subscribe(res =>{
    //   console.log('iban', res)
    //   if(res == 1){
    //     this.authService.tutorRegister(this.object3).subscribe(
    //       data => {
    //         console.log(data);
    //         this.authService.uploadTeacherImage(data.user.user_type_id, this.file).subscribe(resImg => {
    //           console.log('image res', resImg)
    //           this.submitRegister = false;
    //           this.fullPageLoader = false;
    //         }, (err)=>{
    //           console.log('img err',err)
    //           Swal.fire({
    //             icon: 'error',
    //             title: "Warnung",
    //             text: err.error.message
    //           })
    //           this.fullPageLoader = false;
    //         })
    //       },
    //       (err) => {
    //         console.log('register', err)
    //         Swal.fire({
    //           icon: 'error',
    //           title: "Warnung",
    //           text: err.error.message
    //         })
    //         this.fullPageLoader = false;
    //       }
    //     );
    //   }else{
    //     this.fullPageLoader = false;
    //     Swal.fire({
    //       icon: 'error',
    //       title: "Warnung",
    //       text: 'Bitte überprüfe das Format deiner Postleitzahl und deiner IBAN-Nummer'
    //     })

    //   }

    // }, (ibanErr) =>{
    //   console.log('iban err', ibanErr)
    //   Swal.fire({
    //     icon: 'error',
    //     title: "Warnung",
    //     text: ibanErr.error.message
    //   })
    //   this.fullPageLoader = false;
    // })

    this.authService.tutorRegister(this.object3).subscribe(
      data => {
        console.log(data);

        if (this.file) {
          this.authService.uploadTeacherImage(data.user.user_type_id, this.file).subscribe(resImg => {
            console.log('image res', resImg)
            this.submitRegister = false;
            this.fullPageLoader = false;
          }, (err) => {
            console.log('img err', err)
            Swal.fire({
              icon: 'error',
              title: "Warnung",
              text: err.error.message
            })
            this.submitRegister = false;
            this.fullPageLoader = false;
          })
        } else {
          this.submitRegister = false;
          this.fullPageLoader = false;
        }


      },
      (err) => {
        console.log('register', err)
        Swal.fire({
          icon: 'error',
          title: "Warnung",
          text: err.error.message
        })
        this.fullPageLoader = false;
      }
    );
  }




}
