
<div class="proposal">
  <div class="container">
    <div class="main-body">
      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card" style="height: 100%;">
            <div class="card-body image-box">

              <div class="d-flex flex-column align-items-center text-center">
                <img *ngIf="this.tutor?.teacher_image_path != ''" [src]="this.tutor?.teacher_image_path" alt="" class="rounded-circle"
                  width="200" height="200">
                <img *ngIf="this.tutor?.teacher_image_path == ''" width="200" height="200"
                  src="../../../assets/images/{{this.tutor?.teacher.gender}}.jpg" alt="">
                <div class="mt-3">
                  <h4> {{this.tutor?.teacher.name}} {{this.tutor?.teacher.surname}}</h4>
                  <ngb-rating [rate]="this.tutor?.rating" [max]="5" [readonly]="true" class="stars-color">rating</ngb-rating>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="personalInfo"></fa-icon>
            <h1 class="main-title">
              Personal Information
            </h1>
          </div>

          <div class="card mb-3">

            <div class="card-body">

              <div class="row">

                <div class="col-sm-3">
                  <h6 class="mb-0">Age</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.teacher.age}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Education</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.teacher.edu_finished}}
                </div>
              </div>
              <hr>
              <!-- <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Subject</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.education.current_work}}
                </div>
              </div> -->

              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Status</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  <p *ngIf="tutor?.status == 'confirmed'"> {{'OVERVIEW.MODALS.MATCHED_TUTORS.CONFIRMED' | translate}}</p>
                  <p *ngIf="tutor?.status == 'pending'"> {{'OVERVIEW.MODALS.MATCHED_TUTORS.PENDING' | translate}}</p>
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Experience as a tutor</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.teacher.experience}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Number of students</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.teacher.all_students}}
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Subject</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.subject}}
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Distance</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.distance}} km
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Tutoring Place</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.tutoring_place}}
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-3">
                  <h6 class="mb-0">Tutorig Style</h6>
                </div>
                <div class="col-sm-9 text-secondary">
                  {{this.tutor?.tutoring_style}}
                </div>
              </div>
              <hr>


            </div>
          </div>



        </div>
        <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="bio"></fa-icon>
            <h1 class="main-title">
              Description of the tutor
            </h1>
          </div>
          <div class="card mb-3">
            <div class="card-body card-body-edit">
              <div class="col-sm-9 text-secondary">
                {{this.tutor?.teacher.skills}}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="calendarIcon"></fa-icon>
            <h1 class="main-title">
              Matches of the available Time
            </h1>
          </div>
          <div class="color-info">
            <p> <span class="colors blue"></span> Tutor Timeslot</p>
            <p> <span class="colors green"></span>Matched Timeslot</p>
            <p> <span class="colors yellow"></span>Student Timeslot</p>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <div class="grid-container">
                <div class="week">
                  <div class="day">MONDAY</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="monday{{t.id}}"
                      name="monday" value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">TUESDAY</div>
                  <div *ngFor="let t of times" class="time" [ngClass]="{'green': t.id == '6'}"><input type="checkbox" id="tuesday{{t.id}}"
                      name="tuesday" value="{{t.id}}">{{t.time}}
                  </div>

                </div>
                <div class="week">
                  <div class="day">WEDNESDAY</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="wednesday{{t.id}}"
                      name="wednesday" value="{{t.id}}">{{t.time}}
                  </div>

                </div>
                <div class="week">
                  <div class="day">THURSDAY</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="thursday{{t.id}}"
                      name="thursday" value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">FRIDAY</div>
                  <div *ngFor="let t of times" class="time" [ngClass]="{'green': t.id == '4'}"><input type="checkbox" id="friday{{t.id}}"
                      name="friday" value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">SATURDAY</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="saturday{{t.id}}"
                      name="saturday" value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">SUNDAY</div>

                  <div *ngFor="let t of times" class="time" [ngClass]="{'yellow': t.id == '4'}"><input type="checkbox" id="sunday{{t.id}}"
                      name="sunday" value="{{t.id}}">{{t.time}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
