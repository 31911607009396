import { AfterViewChecked, AfterViewInit, Component, OnInit, ChangeDetectorRef, ViewChild, TemplateRef } from '@angular/core';
import * as $ from 'jquery';
import { map } from 'rxjs/operators';
import { NgbModal, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';

import { StudentService } from 'src/app/core/_services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { dateValidatorTimeslot } from '../../core/_shared/validator';
import { DateAdapter } from '@angular/material/core';
import Swal from 'sweetalert2';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';


@Component({
  selector: 'app-engagements',
  templateUrl: './engagements.component.html',
  styleUrls: ['./engagements.component.scss'],
  providers: [DatePipe]
})


export class EngagementsComponent implements OnInit, AfterViewChecked {
  @ViewChild('intro', { read: TemplateRef }) private introModal: TemplateRef<any>;

  personalInfo = faInfoCircle;

  historical_engagements;
  current_engagements;

  isFetchingCurrent = true;
  isFetchingHistorical = true;

  current_page = 1;
  historical_page = 1;

  currentpageSize = 4;
  historicaltpageSize = 4;

  collectionSize;

  current_engagements_range;
  historical_engagements_range;

  current_filter_object = {}
  historical_filter_object = {}

  currentFormGroup;
  historicalFormGroup;
  currentModalFormGroup;
  rescheduleForm;
  raportForm;

  current_subject_list;
  current_teacher_list;

  historical_subject_list;
  historical_teacher_list;

  rescheduledLessons;
  teacherReschId;
  teacherReschDate;
  proposedId;

  //modal stored times
  reschTimes = []; //get
  reschTimesObject = {
    time: []
  }; //send

  availableTimeslot = false;

  raportObject = {};

  toggleClassIdCurrent;
  toggleClassIdHistorical;

  lang;

  constructor(
    private studentService: StudentService,
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private registerFormService: RegisterFormsService,
    private dateAdapter: DateAdapter<Date>) {

    this.lang = localStorage.getItem('lang') || 'de';
    this.dateAdapter.setLocale(this.lang);

    // this.studentService.getIntro('engagements').subscribe(data => {
    //   console.log('intro', data)
    //   if (data == '1') {
    //     this.modalService.open(this.introModal)
    //   } else {
    //     return
    //   }
    // })



    this.studentService.getHistoricalEngagements().subscribe(data => {
      this.historical_engagements = data.data;
      this.isFetchingHistorical = false;
    })

    this.studentService.getCurrentEngagements().subscribe(data => {
      console.log(data.data)
      this.current_engagements = data.data;
      this.isFetchingCurrent = false;
    })

    this.studentService.engagementsDropList().subscribe(data => {
      this.current_subject_list = data['data'].subjects;
      this.current_teacher_list = data['data'].teachers;

      this.historical_subject_list = data['data'].subjects;
      this.historical_teacher_list = data['data'].teachers;
    })



  }


  ngOnInit() {
    this.currentFormGroup = this.formBuilder.group({
      currentStartDate: ['', Validators.required],
      currentEndDate: ['', Validators.required],
    });

    this.currentModalFormGroup = this.formBuilder.group({
      appointmentDate: ['', Validators.required],

    });

    this.historicalFormGroup = this.formBuilder.group({
      historicalStartDate: ['', Validators.required],
      historicalEndDate: ['', Validators.required],
    });

    this.rescheduleForm = this.formBuilder.group({
      date: ['', [Validators.required, dateValidatorTimeslot]],
      comment: ['', Validators.required]
    })

    this.raportForm = this.formBuilder.group({
      type: ['', Validators.required],
      description: ['', Validators.required],
      refund: ['', Validators.required],
      collab: ['', Validators.required],
      iban: ['', Validators.required],
    })

  }


  retriveCurrentEngagements() {
    this.studentService.getCurrentEngagements().subscribe(data => {
      this.current_engagements = data.data;
      this.isFetchingCurrent = false;
      console.log(data)
    })
  }

  retriveHistoricalEngagements() {
    this.studentService.getHistoricalEngagements().subscribe(data => {
      this.historical_engagements = data.data;
      this.isFetchingHistorical = false;
    })
  }

  retrieveEngagementsList() {
    this.studentService.engagementsDropList().subscribe(data => {
      this.current_subject_list = data['data'].subjects;
      this.current_teacher_list = data['data'].teachers;

      this.historical_subject_list = data['data'].subjects;
      this.historical_teacher_list = data['data'].teachers;
    })
  }

  refreshCurrentEngagements() {
    if (this.current_engagements) {
      this.current_engagements_range = this.current_engagements
        .map((el, i) => ({ id: i + 1, ...el }))
        .slice((this.current_page - 1) * this.currentpageSize, (this.current_page - 1) * this.currentpageSize + this.currentpageSize);
    }
  }

  refreshHistoricalEngagements() {
    if (this.historical_engagements) {
      this.historical_engagements_range = this.historical_engagements
        .map((el, i) => ({ id: i + 1, ...el }))
        .slice((this.historical_page - 1) * this.historicaltpageSize, (this.historical_page - 1) * this.historicaltpageSize + this.historicaltpageSize);
    }

  }



  ngAfterViewChecked() {
    // $('.border-table').on('click', function () {
    //   //console.log($(this).find('td'))
    //   $(this).find('td').not(':first').removeClass('mobile-table')
    // });
    this.refreshCurrentEngagements();
    this.refreshHistoricalEngagements();
    this.cdref.detectChanges();

  }






  clearCurrentFilters() {
    this.current_teacher_list = undefined;
    this.current_subject_list = undefined;
    this.retriveCurrentEngagements()
    this.retrieveEngagementsList();
  }

  clearHistoricalFilters() {
    this.retrieveEngagementsList();
    this.historical_teacher_list = undefined;
    this.historical_subject_list = undefined;
    this.retriveHistoricalEngagements()

  }



  currentDateRange() {
    const start_date = this.datePipe.transform(this.currentFormGroup.value.currentStartDate, 'yyyy-MM-dd')
    const end_date = this.datePipe.transform(this.currentFormGroup.value.currentEndDate, 'yyyy-MM-dd')

    if (start_date && end_date) {
      this.current_filter_object['from_date'] = start_date;
      this.current_filter_object['to_date'] = end_date;
    }

    this.studentService.currentEngagementsFilter(this.current_filter_object).subscribe(data => {
      this.current_engagements = data['data'];
      this.refreshCurrentEngagements()
    });
  }
  currentFilter(e) {
    console.log(e.source._id)

    if (e.value == "all_subjects") {
      delete this.current_filter_object['subject']
    }else if (e.source._id == 'subject') {
      this.current_filter_object[e.source._id] = e.value;
    }
    if (e.source._id.toString() == 'teacher' &&  e.value == 'all_teachers') {
      console.log('true')
      delete this.current_filter_object['teacher']
    }  else if (e.source._id == 'teacher') {
      this.current_filter_object[e.source._id] = e.value;
    }

    //console.log(e, this.current_filter_object)
    this.currentDateRange();

    this.studentService.currentEngagementsFilter(this.current_filter_object).subscribe(data => {
      this.current_engagements = data['data'];
      this.refreshCurrentEngagements()
    });

    console.log(this.current_filter_object)

  }
  searchByCurrentDate() {
    this.currentDateRange();
  }



  historicalDateRange() {
    const start_date = this.datePipe.transform(this.historicalFormGroup.value.historicalStartDate, 'yyyy-MM-dd')
    const end_date = this.datePipe.transform(this.historicalFormGroup.value.historicalEndDate, 'yyyy-MM-dd')

    if (start_date && end_date) {
      this.historical_filter_object['from_date'] = start_date;
      this.historical_filter_object['to_date'] = end_date;
    }

    this.studentService.historicalEngagementsFilter(this.historical_filter_object).subscribe(data => {
      this.historical_engagements = data['data'];
      this.refreshHistoricalEngagements()
    });
  }

  historicalFilter(e) {

    //console.log(e.value == "undefined")
    if (e.value == "all_subjects") {
      delete this.historical_filter_object['subject']
    } else if (e.source._id == 'subject') {
      this.historical_filter_object[e.source._id] = e.value;
    }

    if (e.source._id.toString() == 'teacher' &&  e.value == 'all_teachers') {
      delete this.historical_filter_object['teacher']
    } else if (e.source._id == 'teacher') {
      this.historical_filter_object[e.source._id] = e.value;
    }

    console.log(this.historical_filter_object)

    this.historicalDateRange();

    console.log(this.historical_filter_object)

    this.studentService.historicalEngagementsFilter(this.historical_filter_object).subscribe(data => {
      console.log(data)
      this.historical_engagements = data['data'];
      this.refreshHistoricalEngagements()
    });

  }

  rescheduleHistoricalModal(historicalModal, tutor) {
    console.log(tutor)
    this.raportObject = {
      student_id: tutor.student_id,
      teacher_id: tutor.teacher_id,
      subject_id: tutor.subject_id,
      student_teacher: tutor.student_teacher_ids,
    }
    this.modalService.open(historicalModal, { windowClass: 'dark-modal' }).dismissed.subscribe(res => {
      this.raportObject = {}
      this.raportForm.reset();
    })

  }


  searchByHistoricalDate() {
    this.historicalDateRange();
  }


  ///rescheduled lesson modal (current engagements)
  rescheduleCurrentModal(currentModal, tutor) {
    console.log(tutor)
    this.teacherReschId = tutor.user_engagements_id;
    this.modalService.open(currentModal, { windowClass: 'dark-modal' }).dismissed.subscribe(res => {
      this.rescheduleForm.reset();
      this.reschTimes = [];
      this.reschTimesObject = {
        time: []
      };
      this.availableTimeslot = false;
    })
  }

  saveRescheduleLesson(id) {
    this.studentService.rescheduleLesson(id, this.reschTimesObject, this.teacherReschDate, this.rescheduleForm).subscribe(res => {
      console.log('res')
      Swal.fire({
        title: 'Reschedule sent successfully!',
        text: `By clicking on "Reschedule", you propose the selected appointment to the teacher.
        The teacher then has the option of accepting the rescheduling or suggesting a new appointment.
        In case of a new proposal, you will be informed about the new proposal in the "Dashboard".`,
        icon: 'success',
        confirmButtonText: 'OK'
      })
      this.retriveCurrentEngagements();
      this.modalService.dismissAll()
    }, (error) => {

      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    })

    this.modalService.dismissAll();
  }




  //search by date for free times
  reschDate(e) {
    this.availableTimeslot = true;
    this.teacherReschDate = '';
    this.reschTimes = [];
    let date = this.datePipe.transform(e.value, 'yyyy-MM-dd')
    this.teacherReschDate = date;
    this.studentService.getReschTime(date, this.teacherReschId).subscribe(data => {
      console.log('times sub', data)
      this.reschTimes.push(data['data'])
      console.log('times', this.reschTimes)
    })
  }


  selectTime(e) {
    if (e.target.checked === true) {
      e.target.parentNode.classList.add('colorTime');
      this.reschTimesObject['time'].push({
        time_id: e.target.defaultValue,
        selected: true,
      })
      this.reschTimes[0].forEach((el, getIndex) => {
        if (parseInt(e.target.defaultValue) != el.id) {
          el.selected = "true";
        }
      })

    } else {
      e.target.parentNode.classList.remove('colorTime');
      var index = this.reschTimesObject['time'].findIndex(el => el.time_id === e.target.defaultValue)
      this.reschTimesObject['time'].splice(index, 1);
      this.reschTimes[0].forEach((el, getIndex) => {
        if (parseInt(e.target.defaultValue) != el.id) {
          el.selected = "false";
        }
      })
    }
  }


  giveFeedback() {
    this.raportObject['type'] = this.raportForm.value.type;
    this.raportObject['description'] = this.raportForm.value.description;
    this.raportObject['refund'] = this.raportForm.value.refund;
    this.raportObject['collab'] = this.raportForm.value.collab;

    console.log(this.raportObject)
    this.studentService.giveRaport(this.raportObject).subscribe(res => {
      Swal.fire({
        title: 'Success!',
        text: 'Thank you for your report',
        icon: 'success',
        confirmButtonText: 'OK'
      })
      this.retriveHistoricalEngagements();
    }, (err) => {
      console.log(err)
      return Swal.fire({
        icon: 'error',
        title: 'Please try again',
        text: 'Something went wrong!',
      })
    })
    this.modalService.dismissAll();
  }


  toggleClass(id, name) {
    console.log(name)
    if (name == 'current') {
      this.toggleClassIdCurrent = id;
    } else if (name == 'historical') {
      this.toggleClassIdHistorical = id;
    }
  }

  ibanCheck = false;
  errorIban = false;
  verifyIban = '';
  returnIban() {
    console.log(this.raportForm.value.iban)
    this.ibanCheck = true;
    this.registerFormService.checkIban(this.raportForm.value.iban).subscribe(res => {
      console.log(res);
      if (res != 1) {
        this.verifyIban = res[0];
        this.errorIban = true;
        this.ibanCheck = false;
      } else {
        this.verifyIban = '';
        this.errorIban = false;
        this.ibanCheck = false;
      }
    })
  }


  askIban = false;
  refundRequest(e) {
    console.log(e)
    if (e.value == 'yes') {
      this.askIban = true;
    }
    else {
      this.askIban = false;
    }
  }

  removeIntroModal() {
    this.studentService.updateIntro('engagements', false).subscribe(res => {
      console.log(res)
      this.modalService.dismissAll();
    })
  }

}
