import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/_services/auth.service';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';
import { StudentService } from 'src/app/core/_services/student.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit, AfterViewChecked {

  page = 1;
  pageSize = 4;

  payments;
  payments_range;

  studentInfo;

  cardForm: FormGroup;
  ibanForm: FormGroup;

  verifyIban = '';
  checkFullname = false;
  websiteFee;

  constructor(private studentService: StudentService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private registerFormService: RegisterFormsService) {
    this.studentService.studentPayments().subscribe(data =>{
      console.log('inv' ,data)
      this.payments = data['data'];
    })


    this.authService.getUser().subscribe(data =>{
      console.log('info', data)
      this.studentInfo = data['data'];



      this.cardForm.setValue({
        fullname: this.studentInfo.user.name,
        address: this.studentInfo.user.address,
        city: this.studentInfo.user.city,
        zip: this.studentInfo.user.zip,
        iban: this.studentInfo.user.iban_number,
      });
    })


    this.studentService.getWebsiteFee().subscribe(data => {
      console.log('fees',data)
      this.websiteFee = data;
    })

  }


  ngOnInit(): void {

    this.ibanForm = this.formBuilder.group({
      iban: ["", Validators.required],
    })

    this.cardForm = this.formBuilder.group({
      fullname: ["", Validators.required],
      address: ["", Validators.required],
      city: ["", Validators.required],
      zip: ["", Validators.required],
      iban: ["", Validators.required],
    });
  }

  ngAfterViewChecked(){
    this.refreshPayments();
    this.cdr.detectChanges();
  }

  changeInfo(){
    this.registerFormService.checkCard(this.cardForm.value.fullname).subscribe(res => {
      console.log(res)
      if(res != 1){
        this.checkFullname = true;
      }else{
        this.checkFullname = false;
        this.studentService.cardInfo(this.cardForm).subscribe(res => {
          console.log(res)
          window.location.reload();
        })
      }

    })
  }


  refreshPayments() {
    if(this.payments){
      this.payments_range = this.payments
      .map((el, i) => ({id: i + 1, ...el}))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    }

  }

  ibanCheck = false;
  errorIban = false;
  returnIban() {
    this.ibanCheck = true;
    this.registerFormService.checkIban(this.cardForm.value.iban).subscribe(res => {
      console.log(res);
      if (res != 1) {
        this.verifyIban = res[0];
        this.errorIban = true;
        this.ibanCheck = false;
      } else {
        this.verifyIban = '';
        this.errorIban = false;
        this.ibanCheck = false;
      }
    })
  }


  zipCheck = false;
  verifyZip = false;
  returnZip(){
    this.zipCheck = true;
    this.registerFormService.checkZip(this.cardForm.value.zip).subscribe(res =>{
      console.log(res);
      if(res == 1){
        this.verifyZip = false;
        this.zipCheck = false;
      }else{
        this.zipCheck = false;
        this.verifyZip = true;
      }
    }, (err) =>{
      this.verifyZip = true;
      this.zipCheck = false;
    })
  }

  changeIban(){
    this.studentService.cardInfo(this.ibanForm).subscribe(res => {
      console.log(res)
      window.location.reload();
    })
  }

}
