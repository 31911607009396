<header class="header" [ngClass]="status ? 'header-show' : 'danger'">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#"><img src="../../assets/images/logo-educa.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item">
            <a data-toggle="collapse" data-target=".navbar-collapse.show" class="nav-link"
              routerLink="/teacher/messenger" [routerLinkActive]="['active']">
              <img src="../../assets/images/conversation.png" alt="">
              {{'NAVIGATION.MESSENGER' | translate}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/calendar" [routerLinkActive]="['active']">
              <img src="../../assets/images/calendar.png" alt="">
              {{'NAVIGATION.CALENDAR' | translate}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/dashboard" [routerLinkActive]="['active']">
              <img src="../../assets/images/overview-dashboard.png" alt="">
              {{'NAVIGATION.OVERVIEW' | translate}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/engagements" [routerLinkActive]="['active']">
              <img src="../../assets/images/engagements.png" alt="">
              {{'NAVIGATION.ENGAGEMENTS' | translate}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/payments" [routerLinkActive]="['active']">
              <img src="../../assets/images/wallet.png" alt="">
              {{'NAVIGATION.INVOICES' | translate}}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/students" [routerLinkActive]="['active']">
              <img src="../../assets/images/tutors.png" alt="">
              {{'NAVIGATION.TUTORING' | translate}}
            </a>
          </li>
        </ul>



        <div class="btn-sign dropdown notification">
          <a class="nav-link dropdown-toggle profile-name" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="seenAllNotifications()">

            <fa-icon [icon]="faBell">

            </fa-icon>
            <span matBadge="{{notifications?.unread_notification}}" matBadgeSize="small" matBadgeOverlap="true" matBadgeColor="accent"></span>
          </a>
          <div class="dropdown-menu dropdown-notify" aria-labelledby="navbarDropdown">

            <div class="notify-header">
              <h6 class="general-heading">Notifications</h6>
            </div>
            <perfect-scrollbar style="height: 230px; min-width: 320px">
              <div class="notify-item" *ngFor="let notify of this.notifications?.notifications">
                <img src="../../../assets/images/male.jpg" alt="">
                <div class="notify-text">
                  <h1 class="general-heading">{{notify.details.title | titlecase}}</h1>
                  <p class="general-paragraph">{{notify.details.description}}</p>
                  <p class="general-paragraph notify-time"><span>
                      <fa-icon [icon]="faClock"></fa-icon> {{this.timeElapsed(notify.created_at)}}
                    </span></p>
                </div>
              </div>
              <hr>
            </perfect-scrollbar>

          </div>
        </div>

        <div class="flags">
          <div class="dropdown d-inline-block" ngbDropdown>

            <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
              <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16"> <span
                class="ml-1">{{countryName}}</span>
              <img *ngIf="flagvalue === undefined" src="{{valueset}}" height="16">
              <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <!-- item-->
              <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{'active': cookieValue === item.lang}">
                <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
                  class="align-middle">{{item.text}}</span>
              </a>
              <!-- item-->
            </div>
          </div>

        </div>

        <div class="btn-sign dropdown">
          <a class="nav-link dropdown-toggle profile-name" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

            <img class="initial" *ngIf="this.user?.user.image_path" [src]="this.user?.user.image_path" alt="">
            <div *ngIf="this.user?.user.image_path == ''" class="initial">{{user?.user.name | slice:0:1}}
              {{user?.user.surname | slice:0:1}}</div>
            {{user?.user.name}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/profile"><img src="../../assets/images/account-settings.png"
                alt="">{{'NAVIGATION.ACCOUNT' | translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show"
              routerLink="/teacher/contact"><img src="../../assets/images/wallet.png" alt="">{{'NAVIGATION.CONTACT' |
              translate}}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logOut()"><img src="../../assets/images/sign-out.png"
                alt="">{{'NAVIGATION.SIGN_OUT' | translate}}</a>
          </div>
        </div>


      </div>
    </nav>
  </div>
  <!--container-fluid-->
</header>
<router-outlet></router-outlet>
