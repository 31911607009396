import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/${LANGUAGE}`

const EDUCATION_API = `${API}/education`;
const SUBJECT_API = `${API}/subjects`;
const TUTORING_STYLE_API = `${API}/tutoring-style`;
const TIMES_API = `${API}/times`;
const LESSONS_API = `${API}/nr-lessons`;
const TORING_PLACES_API = `${API}/tutoring-places`;
const CHECK_EMAIL = `${API}/check-email`;
const CHECK_IBAN = `${API}/check-iban`;
const CHECK_ZIP = `${API}/check-zip`;
const CHECK_CARD = `${API}/check-card`;
const CHECK_IBAN_ZIP = `${API}/check-iban-zip`;
const GET_PERCENTAGE = `${API}/get-percentage`;
const NR_LESSONS = `${API}/get-percentage`;

@Injectable({
  providedIn: 'root'
})

export class RegisterFormsService {

  constructor(private http: HttpClient) {

  }


  languageRequest() {
    let headers = new HttpHeaders();
    headers = headers.set('cookie', 'test-cookie');

    // return this.http.get('https://dev.api.educagrow.com/public/api/setlocale/en',{observe:'response'}).subscribe(res =>{
    //   console.log(res.headers)
    // })
  }
  //Educations list
  getEducation(): Observable<any> {
    return this.http.get(EDUCATION_API);
  }

  //Subjects list
  getSubjects(): Observable<any> {
    return this.http.get(SUBJECT_API);
  }

  //Tutoring Style list
  getTutoringStyle(): Observable<any> {
    return this.http.get(TUTORING_STYLE_API);
  }

  //Time list
  getTimes(): Observable<any> {
    return this.http.get(TIMES_API);
  }

  //Lessons Style list
  getLessons(): Observable<any> {
    return this.http.get(LESSONS_API);
  }

  //Tutoring places list
  geTutoringPlaces(): Observable<any> {
    return this.http.get(TORING_PLACES_API);
  }

  checkEmail(email): Observable<any>{
    return this.http.get(`${CHECK_EMAIL}?email=${email}`)
  }

  checkIban(iban): Observable<any>{
    return this.http.get(`${CHECK_IBAN}?iban_number=${iban}`)
  }

  checkZip(zip): Observable<any>{
    return this.http.get(`${CHECK_ZIP}?zip=${zip}`)
  }

  checkCard(card): Observable<any> {
    return this.http.get(`${CHECK_CARD}?card_holder=${card}`)
  }


  checkIbanZip(iban, zip): Observable<any>{
    let validIban = iban.replace(/\s+/g, '');
    return this.http.get(`${CHECK_IBAN_ZIP}?iban_number=${validIban}&zip=${zip}`)
  }

  getPercentage(){
    return this.http.get(`${GET_PERCENTAGE}`);
  }

  // getNrLessonsPercentage(){
  //   return this.http.get(`${NR_LESSONS}`)
  // }
}
