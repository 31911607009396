import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/_services/auth.service';
import { TeacherService } from 'src/app/core/_services/teacher.service';
import Swal from 'sweetalert2';
import {
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  arrLang = {
    en: {
      success: "Success",
      text: 'Thank you for your question. We will contact you as soon as possible and answer your question',
      wrong: "Something went wrong!"
    },
    de: {
      success: "Erfolg",
      text: 'Besten Dank für deine Anfrage. Wir werden dich sobald wie möglich Kontaktieren mit einer Antwort auf deine Frage',
      wrong: "Etwas ist schief gelaufen!"
    }
  }

  contactForm: FormGroup;

  user;
  currentDate;

  fullPageLoader = false;

  sendMessage = false;
  liveChat = false;

  lang;

  constructor(
    private formBuilder: FormBuilder,
    private autheService: AuthService,
    private router: Router,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document,
    private teacherService: TeacherService) {

    console.log(window)



    // if (window['Tawk_API']) {
    //   window['Tawk_API'] = window['Tawk_API'] || {};
    //   //window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    //   window['$_Tawk'].ready(this.onTawkApiReady.bind(this));
    // } else {
    //   //document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    //   document.addEventListener("onLoad", this.onTawkApiReady.bind(this));
    // }



    this.lang = localStorage.getItem('lang') || 'de';

    this.autheService.getUser().subscribe(data => {
      this.user = data.data;
      console.log(this.user)
    })

    this.currentDate = new Date();
    console.log(this.currentDate)
  }

  ngOnInit(): void {

    this.contactForm = this.formBuilder.group({
      topic: ['', Validators.required],
      description: ['', Validators.required]
    });

    const s = this._renderer.createElement('script');
    s.text = `var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/${'61adf95a80b2296cfdd03c17/1fm7pc2vc'}/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
    this._renderer.appendChild(this._document.body, s)`;

  }


  sendQuestion() {
    this.fullPageLoader = true;
    this.teacherService.questions(this.contactForm, this.user.id).subscribe(res => {
      this.fullPageLoader = false;
      Swal.fire({
        title: this.arrLang[this.lang].success,
        text: this.arrLang[this.lang].text,
        icon: 'success',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['teacher/dashboard']);
        }
      })

    }, (err) => {
      return Swal.fire({
        icon: 'error',
        title: 'Ups!',
        text: this.arrLang[this.lang].wrong,
      })
    })
  }

  startMessage() {
    this.sendMessage = !this.sendMessage;
  }




}
