<section class="engagements">
  <div class="container-fluid">
    <div class="engagements__table">
      <div class="engagements__table-header">
        <div>
          <h1 class="main-title">{{'ENGAGEMENTS.CURRENT' | translate}}</h1>
          <p class="general-paragraph">{{'ENGAGEMENTS.CURRENT_PARAGRAPH_STUDENT' | translate}}</p>
        </div>
        <div class="filters">
          <form class="form-filter" [formGroup]="currentFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>{{'ENGAGEMENTS.DATE_RANGE' | translate}}</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="currentStartDate">
                <input matEndDate placeholder="End date" (dateChange)="searchByCurrentDate()"
                  formControlName="currentEndDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </form>

          <!-- <button (click)="searchByCurrentDate()" class="form-input btn-filter">Search by Date </button> -->
        </div>
        <!--filters-->
      </div>

      <!--engagements__table--header-->
      <div *ngIf="isFetchingCurrent; else current_data">
        <div class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ng-template #current_data>
        <div class="engagements__table-filters">
          <mat-form-field appearance="fill">
            <mat-label class="label-custom">{{'ENGAGEMENTS.SEARCH_SUBJECT' | translate}}</mat-label>
            <mat-select id='subject' (selectionChange)="currentFilter($event)">
              <mat-option value="all_subjects">
                {{'ENGAGEMENTS.ALL_SUBJECTS' | translate}}
              </mat-option>
              <mat-option selected value="{{subject.id}}" *ngFor="let subject of current_subject_list">
                {{subject.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label class="label-custom">{{'ENGAGEMENTS.SEARCH_TEACHER' | translate}}</mat-label>
            <mat-select id='teacher' (selectionChange)="currentFilter($event)">
              <mat-option value="all_teachers">
                {{'ENGAGEMENTS.ALL_TEACHERS' | translate}}
              </mat-option>
              <mat-option selected value="{{teacher.id}}" *ngFor="let teacher of current_teacher_list">
                {{teacher.full_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="btn btn-danger clear-filters" (click)="clearCurrentFilters()">{{'ENGAGEMENTS.CLEAR_FILTER' |
            translate}}</button>
        </div>
        <table class="table responsive">
          <thead>
            <tr>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.TEACHER' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.SUBJECT' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.NEXT_LESSON' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.LESSON_LEFT' | translate}}</th>
              <th class="table-title" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-table" [ngClass]="{'border-active': current_eng.rescheduling == 'active'}"
              *ngFor="let current_eng of current_engagements_range; let currId = index"
              (click)="toggleClass(currId, 'current')">
              <td row-header="Student" class="general-heading">
                <img *ngIf="current_eng.image_path != ''" [src]="current_eng.image_path">
                {{current_eng.full_name}}
              </td>
              <td row-header="Subject" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}">{{current_eng.subject}}</td>
              <td row-header="Next Lesson" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}">
                {{current_eng.date | date:'dd/MM/yyyy'}}<br>{{current_eng.time}}
                <span class="social">{{current_eng.tutoring_place}}</span>
              </td>
              <td row-header="Lessons Left" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}">{{current_eng.lesson_left}}</td>
              <td row-header="" class="inner-box-p reschedule"
                [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}"
                (click)="rescheduleCurrentModal(currentModal, current_eng)"
                *ngIf="current_eng.difference > 24  && current_eng.rescheduling == 'inactive'">
                {{'ENGAGEMENTS.RESCHEDULE_LESSON' | translate}}
              </td>
              <td row-header="" *ngIf="current_eng.difference <= 24 && current_eng.rescheduling == 'inactive'"
                class="inner-box-p" [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}">
                {{'ENGAGEMENTS.LESSON_24' | translate}}</td>

              <td row-header="" class="inner-box-p" [ngClass]="{'mobile-table': !(toggleClassIdCurrent == currId)}"
                *ngIf="current_eng.rescheduling == 'active'">
                {{'ENGAGEMENTS.RESCHEDULE_TO' | translate}} <br>{{current_eng.resch_date | date:"dd/MM/yyyy"}}</td>



              <ng-template #currentModal let-modal>
                <div class="modal-header">
                  <h1 class="main-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.MODAL_TITLE' | translate}}</h1>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body remove-padding-top" (clickOutside)="onClickedOutside()">

                  <div class="m-tutor-content">
                    <div class="m-tutor default-bottom-space">
                      <div class="m-tutor-info">
                        <p class="table-title" style="margin: 0;">{{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_I' |
                          translate}}
                          <b>{{current_eng.date | date:'dd/MM/yyyy'}}</b> {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_II' | translate}}
                          <b>{{current_eng.subject}}</b> {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_III' | translate}}
                          <b>{{current_eng.full_name}}</b>. {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_IV' | translate}}:
                        </p>
                        <ul style="list-style-type:none">
                          <li class="table-title"> - {{'OVERVIEW.MODALS.PLANNED_LESSONS.RULE_I' | translate}}
                          </li>
                          <li class="table-title"> - {{'OVERVIEW.MODALS.PLANNED_LESSONS.RULE_II' | translate}}
                          </li>
                        </ul>
                      </div>
                      <!--m-tutor-info-->
                    </div>
                    <!--m-tutor-->
                    <div class='row'>
                      <div class="col-md-5">
                        <div class="m-tutor-profile">
                          <img *ngIf="current_eng.image_path != ''" [src]="current_eng.image_path">
                          <h1 *ngIf="current_eng.image_path == ''">{{current_eng.name | slice:0:1}}.{{current_eng.surname | slice:0:1}}</h1>


                        </div>
                        <!--m-tutor-profile-->
                      </div>
                      <div class="col-md-7">
                        <div class="m-tutor-body">
                          <div class="m-tutor">
                            <fa-icon [icon]="personalInfo"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.INFORMATION' | translate}}:
                              </h1>

                              <table class="table">
                                <tbody>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.NAME' |
                                      translate}}:</th>
                                    <th scope="col">{{current_eng.full_name}}</th>
                                  </tr>

                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.SUBJECT' |
                                      translate}}:</th>
                                    <th scope="col">{{current_eng.subject}}</th>
                                  </tr>

                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.DATE' |
                                      translate}}:</th>
                                    <th scope="col">{{current_eng.date | date:'dd/MM/yyyy'}}</th>
                                  </tr>

                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.TIME' |
                                      translate}}:</th>
                                    <th scope="col">{{current_eng.time}}</th>
                                  </tr>

                                </tbody>
                              </table>

                            </div>
                            <!--m-tutor-info-->
                          </div>
                          <!--m-tutor-->


                        </div>
                      </div>
                    </div><!-- row -->

                  </div>
                  <!--m-tutor-content-->

                  <div class="m-tutor-content">

                    <!--m-tutor-profile-->
                    <div class="m-tutor-body">
                      <form [formGroup]="rescheduleForm">


                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title date-label-space">{{'OVERVIEW.MODALS.PLANNED_LESSONS.SUGGEST_DATE' |
                              translate}}:</p>
                            <mat-form-field appearance="fill">
                              <mat-label>{{'OVERVIEW.MODALS.PLANNED_LESSONS.CHOOSE_DATE' | translate}}</mat-label>
                              <input (dateChange)="reschDate($event)" class="input-date" matInput formControlName='date'
                                [matDatepicker]="picker">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <mat-error *ngIf="rescheduleForm.invalid">
                                {{'OVERVIEW.MODALS.PLANNED_LESSONS.TOMORROW_DATE' | translate}}.</mat-error>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders" *ngIf="availableTimeslot">
                          <div class="m-tutor-info">
                            <p class="table-title">Please select an available timeslot:</p>
                            <div class="desired-time">
                              <div class="time testTime" [ngClass]="{'disable-time': time.selected == 'true'}"
                                *ngFor="let time of reschTimes[0]">
                                <input type="checkbox" [disabled]="time.selected == 'true'" value="{{time.id}}"
                                  (change)="selectTime($event)">
                                {{time.time}}

                              </div>
                              <p *ngIf="reschTimes[0]?.length == 0" class="m-tutor-paragraph">
                                {{'OVERVIEW.MODALS.PLANNED_LESSONS.NO_TIME_STUDENT' | translate}}</p>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title date-label-space">{{'OVERVIEW.MODALS.PLANNED_LESSONS.STUDENT_MESSAGE' | translate}}</p>
                            <mat-form-field class="example-full-width">
                              <mat-label>{{'OVERVIEW.MODALS.PLANNED_LESSONS.WRITE_HERE' | translate}}</mat-label>
                              <textarea matInput class="form-input text-area-style"
                                formControlName="comment"></textarea>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                      </form>
                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary m-btn-book" [disabled]="rescheduleForm.invalid"
                    (click)="saveRescheduleLesson(current_eng.id)">{{'OVERVIEW.MODALS.PLANNED_LESSONS.RESCHEDULE' |
                    translate}}</button>
                </div>
              </ng-template>
            </tr>

          </tbody>
        </table>
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="current_engagements.length" [(page)]="current_page"
            [pageSize]="currentpageSize" (pageChange)="refreshCurrentEngagements()">
          </ngb-pagination>

          <select class="custom-select form-input" style="width: auto" [(ngModel)]="currentpageSize"
            (ngModelChange)="refreshCurrentEngagements()">
            <option [ngValue]="4">4 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
            <option [ngValue]="6">6 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
            <option [ngValue]="8">8 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
          </select>
        </div>
      </ng-template>
    </div>
    <!--engagements__table-->

    <div class="engagements__table">
      <div class="engagements__table-header">
        <div>
          <h1 class="main-title">{{'ENGAGEMENTS.HISTORICAL' | translate}}</h1>
          <p class="general-paragraph">{{'ENGAGEMENTS.PAST_ENGAGEMENTS' | translate}}</p>
        </div>
        <div class="filters">
          <!-- <button class="form-input btn-filter">Filtering <img src="../../assets/images/Filter.png" alt=""> </button> -->
          <form class="form-filter" [formGroup]="historicalFormGroup">
            <mat-form-field appearance="fill">
              <mat-label>{{'ENGAGEMENTS.DATE_RANGE' | translate}}</mat-label>
              <mat-date-range-input [rangePicker]="picker1">
                <input matStartDate placeholder="Start date" formControlName="historicalStartDate">
                <input matEndDate placeholder="End date" (dateChange)="searchByHistoricalDate()"
                  formControlName="historicalEndDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-date-range-picker #picker1></mat-date-range-picker>
            </mat-form-field>
          </form>
          <!-- <button (click)="searchByHistoricalDate()" class="form-input btn-filter">Search by Date </button> -->
        </div>
        <!--filters-->
      </div>
      <!--engagements__table--header-->
      <div *ngIf="isFetchingHistorical; else historical_data">
        <div class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <ng-template #historical_data>
        <div class="engagements__table-filters">
          <mat-form-field appearance="fill">
            <mat-label class="label-custom">{{'ENGAGEMENTS.SEARCH_SUBJECT' | translate}}</mat-label>
            <mat-select id='subject' (selectionChange)="historicalFilter($event)">
              <mat-option value="all_subjects">
                {{'ENGAGEMENTS.ALL_SUBJECTS' | translate}}
              </mat-option>
              <mat-option selected value="{{subject.id}}" *ngFor="let subject of historical_subject_list">
                {{subject.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label class="label-custom">{{'ENGAGEMENTS.SEARCH_TEACHER' | translate}}</mat-label>
            <mat-select id='teacher' (selectionChange)="historicalFilter($event)">
              <mat-option value="all_teachers">
                {{'ENGAGEMENTS.ALL_TEACHERS' | translate}}
              </mat-option>
              <mat-option selected value="{{teacher.id}}" *ngFor="let teacher of historical_teacher_list">
                {{teacher.full_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button class="btn btn-danger clear-filters" (click)="clearHistoricalFilters()">{{'ENGAGEMENTS.CLEAR_FILTER' |
            translate}}</button>
        </div>
        <table class="table responsive">
          <thead>
            <tr>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.TEACHER' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.SUBJECT' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.TIME' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.DATE' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.LESSONS_NR' | translate}}</th>
              <th class="table-title" scope="col">{{'ENGAGEMENTS.PRICE' | translate}}</th>
              <th class="table-title" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-table" *ngFor="let historical_eng of historical_engagements_range; let histId = index"
              (click)="toggleClass(histId, 'historical')">
              <td row-header="Student" class="general-heading">
                <img *ngIf="historical_eng.image_path != ''" [src]="historical_eng.image_path">
                {{historical_eng.full_name}}
              </td>
              <td row-header="Subject" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}">{{historical_eng.subject}}</td>
              <td row-header="Time" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}">{{historical_eng.time}}</td>
              <td row-header="Date" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}">{{historical_eng.date}}</td>
              <td row-header="Number of Lessons" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}">{{historical_eng.nr_of_lesson}}</td>
              <td row-header="Price" class="inner-box-p"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}">{{historical_eng.price_per_lesson}}
                CHF</td>
              <td row-header="" class="inner-box-p reschedule"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}"
                *ngIf="!historical_eng.report_exists && historical_eng.difference <= 48"
                (click)="rescheduleHistoricalModal(historicalModal, historical_eng)">{{'ENGAGEMENTS.RAPORT' |
                translate}}</td>
              <td row-header="" class="inner-box-p reschedule"
                [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}" *ngIf="historical_eng.report_exists">
                {{'ENGAGEMENTS.VIEW_RAPORT' | translate}}</td>
              <td row-header="" class="inner-box-p" [ngClass]="{'mobile-table': !(toggleClassIdHistorical == histId)}"
                *ngIf="!historical_eng.report_exists && historical_eng.difference > 48">{{'ENGAGEMENTS.RAPORT_48' |
                translate}}</td>

              <ng-template #historicalModal let-modal>

                <div class="modal-header">
                  <h1 class="main-title">Report Form</h1>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">

                    <div class="m-tutor-body">



                      <div class="m-tutor remove-borders">
                        <img *ngIf="historical_eng.image_path != ''" [src]="historical_eng.image_path">
                        <h1 *ngIf="historical_eng.image_path == ''">{{historical_eng.name}}</h1>
                        <div class="teacher-name">
                          <h1 class="general-heading">{{historical_eng.full_name}}</h1>
                          <p class="inner-box-p">{{historical_eng.subject}}</p>
                        </div>
                      </div>
                      <!--m-tutor-->

                      <div class="m-tutor remove-borders">
                        <div class="m-tutor-info row">
                          <div class="col-md-6">
                            <table class="table">
                              <tbody>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">Price per lesson:</th>
                                  <th scope="col">{{historical_eng.price_per_lesson}}</th>
                                </tr>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">Time:</th>
                                  <th scope="col">{{historical_eng.time}}</th>
                                </tr>

                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>


                      <form [formGroup]="raportForm">
                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <mat-form-field appearance="fill">
                              <mat-label>Subject</mat-label>
                              <mat-select formControlName="type">
                                <mat-option value='aggresive'>
                                  Aggresive
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <mat-form-field class="example-full-width">
                              <mat-label>Description</mat-label>
                              <textarea matInput formControlName="description"
                                class="form-input text-area-style"></textarea>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <div class="form-group">
                              <h4 class="form-label">Wills du dein für diese lektion zurückerstattet haben?</h4>
                              <mat-radio-group aria-label="Select an option" formControlName='refund'
                                (change)="refundRequest($event)">
                                <mat-radio-button value="yes">Ja, ich will mein geld zurück!</mat-radio-button>
                                <mat-radio-button value="no">Nein</mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders" *ngIf="askIban">
                          <div class="m-tutor-info">
                            <div class="form-group">
                              <mat-form-field class="example-full-width">
                                <mat-label>IBAN</mat-label>
                                <input matInput class="form-input" formControlName='iban' (change)="returnIban()">
                                <div class="edited-error" *ngIf="verifyIban != ''">
                                  {{verifyIban}}
                                </div>
                                <div class="loading-wrapper custom-loader" *ngIf="ibanCheck">
                                  <div class="spinner-border">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                  <p>Checking IBAN</p>
                                </div>
                              </mat-form-field>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>



                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <div class="form-group">
                              <h4 class="form-label">Willst du die zusammenarbeit mit disem lehrer unterbrechen?</h4>
                              <mat-radio-group aria-label="Select an option" formControlName='collab'>
                                <mat-radio-button value="yes">Ja</mat-radio-button>
                                <mat-radio-button value="no">Nein</mat-radio-button>
                              </mat-radio-group>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                      </form>
                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <div class="teacher-proposal-modal-footer">

                    <button class="btn btn-success" [disabled]="raportForm.invalid" (click)="giveFeedback()">Send
                      Raport</button>
                  </div>
                </div>
              </ng-template>

            </tr>

          </tbody>
        </table>
        <div class="d-flex justify-content-between p-2">
          <ngb-pagination [collectionSize]="historical_engagements.length" [(page)]="historical_page"
            [pageSize]="historicaltpageSize" (pageChange)="refreshHistoricalEngagements()">
          </ngb-pagination>

          <select class="custom-select form-input" style="width: auto" [(ngModel)]="historicaltpageSize"
            (ngModelChange)="refreshHistoricalEngagements()">
            <option [ngValue]="4">4 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
            <option [ngValue]="6">6 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
            <option [ngValue]="8">8 {{'ENGAGEMENTS.PAGE_ITEMS' | translate}}</option>
          </select>
        </div>
      </ng-template>

    </div>
    <!--engagements__table-->
  </div>
  <!--container-fluid-->
</section>
<ng-template #intro let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Steps</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-horizontal-stepper #stepperAlert>
      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <p class="m-tutor-paragraph">
            {{'ALERT.ENGAGEMENTS.STAGE_I.I' | translate}}
          </p>
          <p class="m-tutor-paragraph">
            {{'ALERT.ENGAGEMENTS.STAGE_I.II' | translate}}
          </p>
          <p class="m-tutor-paragraph">
            {{'ALERT.ENGAGEMENTS.STAGE_I.III' | translate}}
          </p>
          <img class="smaller-img" src="../../../assets/images/engagements_one.jpg" alt="">
        </div>
        <div class="form-buttons-alert">
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert remove-alert" (click)="removeIntroModal()">Don't show it again</button>
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert" (click)="modal.dismiss('Cross click')">Done</button>
        </div>
      </mat-step>

    </mat-horizontal-stepper>
  </div>

</ng-template>
