<header class="header-01 sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mobile-wrapper">
          <nav class="navbar navbar-expand-lg">
            <!-- logo Start-->
            <a class="navbar-brand" href="index.html">
              <img src="../../assets/images/logo-educa.png" alt="">
              <img class="sticky-logo" src="../assets/images/logo-educa.png" alt="">
            </a>
            <!-- logo End-->

            <!-- Moblie Btn Start -->
            <button class="navbar-toggler" type="button">
              <!-- <i class="fal fa-bars"></i> -->
              <fa-icon [icon]="faBars"></fa-icon>
            </button>
            <!-- Moblie Btn End -->

            <!-- Nav Menu Start -->
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="menu-item-has-children">
                  <a routerLink="/">Home</a>

                </li>
                <li class="menu-item-has-children">
                  <a routerLink="/group-tutoring">Group Tutoring</a>
                </li>
                <li class="menu-item-has-children">
                  <a (click)="becomeStudent()" routerLink="/">{{'LABELS.BECOME_STUDENT' | translate}}</a>
                </li>
                <!-- <li class="menu-item-has-children">
                <a>{{'HOMEPAGE.NAV.GROUP_TUTORING' | translate}}</a>
              </li> -->
                <li>
                  <a routerLink='/about'>{{'HOMEPAGE.NAV.ABOUT_US' | translate}}</a>
                </li>
                <li class="nav-item flag-item">

                  <div class="dropdown d-inline-block" ngbDropdown>
                    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                      <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16"> <span
                        class="ml-1">{{countryName}}</span>
                      <img *ngIf="flagvalue === undefined" src="{{valueset}}" height="16">
                      <span *ngIf="flagvalue === undefined" class="ml-1"></span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right flag-menu" ngbDropdownMenu>

                      <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                        (click)="setLanguage(item.text, item.lang, item.flag)"
                        [ngClass]="{'active': cookieValue === item.lang}">
                        <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
                          class="align-middle">{{item.text}}</span>
                      </a>

                    </div>
                  </div>
                </li>

                <!-- Join Btn -->
                <a routerLink="/login" class="join-btn">{{'LOGIN_PAGE.SIGN_IN' | translate}}</a>
                <!-- Join Btn -->
              </ul>
            </div>
            <!-- Nav Menu End -->


          </nav>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="wrap-home">
  <!-- <section class="register">
      <div class="overlay"></div>
      <div class="register__container row">
        <div class="main-text">
          <h1>{{'HOMEPAGE.JUMBOTRON.DESCRIPTION' | translate}}</h1>
        </div>
        <div class="col-md-6 column" style="background-image: url(../assets/images/student_side.jpg);">
          <div class="register-button">
            <a routerLink="/student/register">{{'HOMEPAGE.JUMBOTRON.STUDENT_REGISTER' | translate}}</a>
          </div>
          <div class="register-button mobile-view">
            <a routerLink="/tutor/register">{{'HOMEPAGE.JUMBOTRON.TUTOR_REGISTER' | translate}}</a>
          </div>
        </div>
        <div class="col-md-6 column" style="background-image: url(../assets/images/teacher_side.jpg);">
          <div class="register-button">
            <a routerLink="/tutor/register">{{'HOMEPAGE.JUMBOTRON.TUTOR_REGISTER' | translate}}</a>
          </div>

        </div>
      </div>

    </section> -->

  <section class="hero-banner-1" style="background-image: url(../../assets/images/banner.png);">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-6">

          <div class="hero-content">
            <h2>{{'HOMEPAGE.JUMBOTRON.TUTOR_SUCCESS' | translate}}</h2>
            <p>
              {{'HOMEPAGE.JUMBOTRON.TUTOR_REGISTER_INFO' | translate}}
            </p>
            <a routerLink="/tutor/register" class="bisylms-btn">{{'HOMEPAGE.JUMBOTRON.TUTOR_REGISTER' | translate}}</a>
          </div>
        </div>
        <div class="col-lg-7 col-md-6">
          <div class="banner-thumb">
            <img src="assets/images/tutor-2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Banner End -->

  <!-- <section class="works" id="howitworks">
      <div class="container">
        <div class="section-title">
          <h1>{{"HOMEPAGE.DEMO.MAIN_TITLE" | translate}}</h1>
        </div>

        <div class="works__steps">

          <div class="step-one home-steps-padding">
            <h1 class="steps-header">
              <span>{{"HOMEPAGE.DEMO.FOR_STUDENT.TITLE" | translate}}</span>
            </h1>
            <div class="steps-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    <h6 class="general-heading"><span> 1.</span>{{"HOMEPAGE.DEMO.FOR_STUDENT.RULE_I" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_STUDENT.PARA_I" | translate}}.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faHandshake"></fa-icon>
                    <h6 class="general-heading"><span> 2.</span>{{"HOMEPAGE.DEMO.FOR_STUDENT.RULE_II" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_STUDENT.PARA_II" | translate}}.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faBoxOpen"></fa-icon>
                    <h6 class="general-heading"><span> 3.</span>{{"HOMEPAGE.DEMO.FOR_STUDENT.RULE_III" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_STUDENT.PARA_III" | translate}}.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faChalkboardTeacher"></fa-icon>
                    <h6 class="general-heading"><span> 4.</span>{{"HOMEPAGE.DEMO.FOR_STUDENT.RULE_IV" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_STUDENT.PARA_IV" | translate}}.</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="demo">
              <p class="demo-paragraph">{{"HOMEPAGE.DEMO.DEMO_TITLE" | translate}}</p>
              <div class="demo__filters row">
                <div class="col-md-9">
                  <div class="box-filters row">
                    <div class="col-md-4">
                      <div class="group-filter">
                        <mat-form-field appearance="fill">
                          <mat-label>{{"HOMEPAGE.DEMO.DEMO_SUBJECT" | translate}}</mat-label>
                          <mat-select id="tutoring_style" (selectionChange)="searchBySubject($event)">
                            <mat-option value="{{subject.id}}" *ngFor="let subject of subjects">
                              {{subject.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="group-filter">
                        <mat-form-field appearance="fill">
                          <mat-label>{{"HOMEPAGE.DEMO.DEMO_PLACE" | translate}}</mat-label>
                          <mat-select id="tutoring_style" (selectionChange)="searchByStyle($event)">
                            <mat-option value="{{place.id}}" *ngFor="let place of tutoringPlace">
                              {{place.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="group-filter">
                        <mat-form-field appearance="fill">
                          <mat-label>{{"HOMEPAGE.DEMO.DEMO_DAYS" | translate}}</mat-label>
                          <mat-select multiple (selectionChange)="searchByday($event)">
                            <mat-option value="monday">{{'WEEK_DAYS.MONDAY' | translate}}</mat-option>
                            <mat-option value="tuesday">{{'WEEK_DAYS.TUESDAY' | translate}}</mat-option>
                            <mat-option value="wednesday">{{'WEEK_DAYS.WEDNESDAY' | translate}}</mat-option>
                            <mat-option value="thursday">{{'WEEK_DAYS.THURSDAY' | translate}}</mat-option>
                            <mat-option value="friday">{{'WEEK_DAYS.FRIDAY' | translate}}</mat-option>
                            <mat-option value="saturday">{{'WEEK_DAYS.SATURDAY' | translate}}</mat-option>
                            <mat-option value="sunday">{{'WEEK_DAYS.SUNDAY' | translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-md-4">

                    </div>


                  </div>
                </div>
                <div class="col-md-3">
                  <div class="group-filter">
                    <button class="btn-filter" (click)="tutorFilter()">{{"HOMEPAGE.DEMO.DEMO_SEARCH" |
                      translate}}</button>
                  </div>
                </div>
              </div>

              <div class="demo__boxes">
                <div *ngIf="showDemoBoxes" class="parent-spinner">
                  <div class="spinner-border">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div class="demo__boxes_box" *ngFor="let tutor of tutors">

                  <div class="demo__boxes_box--info">
                    <div class="box-img">
                      <img *ngIf="tutor.image_path!= ''" [src]="tutor.image_path" alt="">
                      <img *ngIf="tutor.image_path.length == 0" src="../assets/images/{{tutor.gender}}.jpg" alt="">
                    </div>
                    <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                    <h1 class="general-heading">{{tutor.name | slice:0:1}} . {{tutor.surname | slice:0:1}}</h1>
                    <p class="inner-box-p text-center">{{tutor.education.department}} {{'TUTORS.AT' | translate}}
                      {{tutor.education.school}}</p>
                  </div>

                  <div class="demo__boxes_box--price">
                    <p class="table-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_LESSON' | translate}}</p>
                    <div *ngFor="let price of tutor.subjects">
                      <p class="table-title" *ngIf="price.name == tutor.chosen_subject">
                        {{price.pivot_teacher.prices[0].price}} CHF</p>
                    </div>

                  </div>


                  <button class="tutor-book" (click)="sendToDemo(tutor)"> {{'HOMEPAGE.DEMO.MORE_INFO' |
                    translate}}</button>

                </div>
              </div>




              <div class="demo__button" *ngIf="tutors.length >= 1">
                <a routerLink="/tutor/register">{{'TUTORS.SEARCH_MORE' | translate}}</a>
              </div>
            </div>
          </div>



        </div>



      </div>
    </section> -->

  <section class="popular-course-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <!-- <h2 class="sec-title"><span>Explore</span> Our Popular Courses</h2> -->
        </div>
      </div>

      <div class="row mt-120">
        <div class="col-lg-7 col-md-6 steps-img">
          <div class="ab-thumb">
            <img src="assets/images/tutor-1.png" alt="">
          </div>
        </div>
        <div class="col-lg-5 col-md-6">
          <div class="ab-content">
            <h3>{{"HOMEPAGE.DEMO.MAIN_TITLE" | translate}}:</h3>
            <owl-carousel-o [options]="howItWorksOptions">
              <ng-template carouselSlide>
                <p class="mid-item">1. {{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_I" | translate}}</p>
                <p>
                  {{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_I" | translate}}
                </p>
              </ng-template>
              <ng-template carouselSlide>
                <p class="mid-item">2. {{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_II" | translate}}</p>
                <p>
                  {{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_II" | translate}}
                </p>
              </ng-template>
              <ng-template carouselSlide>
                <p class="mid-item">3. {{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_III" | translate}}</p>
                <p>
                  {{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_III" | translate}}
                </p>
                <a class="bisylms-btn" routerLink="/student/register">{{"HOMEPAGE.JUMBOTRON.TUTOR_REGISTER" |
                  translate}}</a>
              </ng-template>

            </owl-carousel-o>

          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Course End -->

  <!-- <section class="works-teacher">
      <div class="container">


        <div class="works__steps">
          <div class="step-two home-spteps-padding">
            <h1 class="steps-header">
              <span>{{"HOMEPAGE.DEMO.FOR_TUTOR.TITLE" | translate}} </span>
            </h1>
            <div class="steps-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    <h6 class="general-heading"><span> 1.</span> {{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_I" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_I" | translate}}.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faHandshake"></fa-icon>
                    <h6 class="general-heading"><span> 2.</span>{{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_II" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_II" | translate}}.</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faChalkboardTeacher"></fa-icon>
                    <h6 class="general-heading"><span> 3.</span>{{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_III" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_III" | translate}}</p>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="step-content">
                    <fa-icon [icon]="faCreditCard"></fa-icon>
                    <h6 class="general-heading"><span> 4.</span>{{"HOMEPAGE.DEMO.FOR_TUTOR.RULE_IV" | translate}}</h6>
                    <p>{{"HOMEPAGE.DEMO.FOR_TUTOR.PARA_IV" | translate}}.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section> -->




  <!-- <section class="offer popular-course-section" id="offer">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <h2 class="sec-title"><span>Why</span>EducaGrow?</h2>
          </div>
        </div>
        <div class="offer__content row">
          <p class="sec-paragraph">Everything that is needed for an ideal teaching experience can be found on EducaGrow. We support you with the following elements for your learning success:</p>
          <div class="col-md-6">
            <div class="offer__content_student">
              <ul style="list-style-type: circle">

                <li>
                  <fa-icon [icon]="faCircle"></fa-icon>
                  {{"HOMEPAGE.ADVANTAGES.STUDENT.ONE" | translate}}
                </li>
                <li>
                  <fa-icon [icon]="faCircle"></fa-icon>
                  {{"HOMEPAGE.ADVANTAGES.STUDENT.TWO" | translate}}
                </li>
                <li>
                  <fa-icon [icon]="faCircle"></fa-icon>
                  {{"HOMEPAGE.ADVANTAGES.STUDENT.THREE" | translate}}
                </li>


              </ul>
            </div>
          </div>
          <div class="col-md-6">
            <ul style="list-style-type: circle">
              <li>
                <fa-icon [icon]="faCircle"></fa-icon>
                {{"HOMEPAGE.ADVANTAGES.STUDENT.FOUR" | translate}}
              </li>
              <li>
                <fa-icon [icon]="faCircle"></fa-icon>
                {{"HOMEPAGE.ADVANTAGES.STUDENT.FIVE" | translate}}
              </li>
              <li>
                <fa-icon [icon]="faCircle"></fa-icon>
                {{"HOMEPAGE.ADVANTAGES.STUDENT.SIX.I" | translate}} <span class="arrow"> &rarr; </span>
                {{"HOMEPAGE.ADVANTAGES.STUDENT.SIX.II" | translate}}
              </li>
            </ul>

          </div>

        </div>
      </div>
    </section> -->

  <section class="offer popular-course-section">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h2 class="sec-title">{{"HOMEPAGE.ADVANTAGES.MAIN_TITLE" | translate}} {{"HOMEPAGE.ADVANTAGES.TITLE_SPAN" |
            translate}}</h2>
        </div>
      </div>
      <p class="sec-paragraph">{{"HOMEPAGE.ADVANTAGES.TUTOR_PARAGRAPH" | translate}}</p>
      <div class="row">
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faHandHoldingUsd"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_I" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_I" | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faCalendarAlt"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_II" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_II" | translate}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faCalendarTimes"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_III" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_III" | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faChalkboardTeacher"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_IV" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_IV" | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faCreditCard"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_V" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_V" | translate}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="step">
            <fa-icon [icon]="faUserShield"></fa-icon>
            <div class="content-wrapper">
              <h4>{{"HOMEPAGE.ADVANTAGES.TUTOR.TITLE_VI" | translate}}</h4>
              <p>{{"HOMEPAGE.ADVANTAGES.TUTOR.PARA_VI" | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>



  <!-- <section class="testimonials" id="testimonials">
      <div class="container">
        <div class="section-title">
          <h1>{{"HOMEPAGE.REVIEWS.TITLE" | translate}}</h1>
        </div>
        <div class="testimonials__carousel">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="box">
                <div class="box__header">
                  <div class="box-img">
                    <img src="../assets/images/Almedina.jpg" alt="">
                    <div class="name">
                      <h6>Almedina</h6>
                      <p>Zürich</p>
                      <p>25 Jahre</p>
                      <ngb-rating [rate]="5" [max]="5" [readonly]="true" class="stars-color">rating</ngb-rating>
                    </div>

                  </div>

                </div>
                <div class="box__content">
                  <p class="review-content">“Dank EducaGrow.com ist es mir möglich unkompliziert die Unterrichtsstunden
                    mit meinen Schülern zu organisieren. Zudem erhalte ich fortlaufend Anfragen von Schülern, was mir eine
                    Menge Aufwand erspart.
                    Sehr empfehlenswert!
                    ”</p>
                </div>
              </div>
            </ng-template>


          </owl-carousel-o>
        </div>
      </div>
    </section> -->

  <!-- Testimonial Start -->
  <section class="testimonial-section" style="background-image: url(../assets/images/testimonial-bg.png);">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <h2 class="sec-title"><span>{{"HOMEPAGE.REVIEWS.TITLE" | translate}}</span> {{"HOMEPAGE.REVIEWS.TITLE_SPAN"
            | translate}}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="testimonial-slider">

            <owl-carousel-o [options]="customOptions">
              <ng-template carouselSlide *ngFor="let feedback of feedbackArray">
                <div class="testimonial-item">
                  <div class="testi-author">
                    <!-- <img src="../assets/images/Almedina.jpg" alt=""> -->
                    <h5>{{feedback.name}} {{feedback.surname}}</h5>
                    <span>{{feedback.address}}</span>
                  </div>
                  <p>
                    {{feedback.description}}
                  </p>
                </div>
              </ng-template>
            </owl-carousel-o>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">


          <div class="feedback-box">
            <img src="../../assets/images/ellipse.png" (click)="feedbackModal(feedback)" alt="">
            <h2 class="sec-title">{{"HOMEPAGE.REVIEWS.FEEDBACK" | translate}} </h2>
          </div>

        </div>
      </div>
    </div>
  </section>
  <!-- Testimonial End -->

  <!-- <section class="about">
      <div class="container">
        <div class="section-title">
          <h1>{{"HOMEPAGE.NAV.ABOUT_US" | translate}}</h1>

          <p>Wir sind zwei Brüder, welche im Verlauf ihres Bachelor- und Masterstudiums an der ETH respektive an der HSG,
            unzählige Nachhilfeschüler auf ihrer schulischen Karriere erfolgreich begleitet und unterstützt haben.</p>

          <p>Wir sind fest davon überzeugt, dass Nachhilfe ein unersetzliches und effektives Instrument zur Steigerung der
            schulischen Leistung ist. Dabei spielt der zwischenmenschliche Kontakt eine entscheidende Rolle.</p>

          <p> Daher haben wir es uns zum Ziel gemacht ein zeitgemässe und effiziente Lösung zur Vernetzung von
            qualifizierten
            Tutoren und bedürftigen Nachhilfeschülern zu erstellen. Das Resultat davon ist unsere Plattform EducaGrow, die
            erste Schweizer Nachhilfeplattform, welche mittels eines Algorithmus bedürfnisgerechte und massgeschneiderte
            Lösungen anbietet.</p>
        </div>
        <owl-carousel-o [options]="aboutUs">
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row custom-row">

                <div class="col-md-5">
                  <h1>“An investment in knowledge pays the best interest.”</h1>
                  <p>Benjamin Franklin</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Franklin.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“Education is the most powerful weapon which you can use to change the world.”</h1>
                  <p>Nelson Mandela</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Mandela.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“Anyone who stops learning is old, whether at twenty or eighty. Anyone who keeps learning stays
                    young.
                    The greatest thing in life is to keep your mind young.”</h1>
                  <p>Henry Ford</p>

                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Ford.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“Education breeds confidence. Confidence breeds hope. Hope breeds peace.”</h1>
                  <h1>“The man who says he can, and the man who says he can’t are both correct.”</h1>
                  <p>Confucius</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Confucius.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“The roots of education are bitter, but the fruit is sweet.”</h1>
                  <h1>“We are what we repeatedly do. Excellence, then, is not an act, but a habit.”</h1>
                  <p>Aristoteles</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Aristoteles.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“A child without education, is like a bird without wings.”</h1>
                  <p>Tibetan Proverb</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Tibet.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“It is through good education that all the good in the world arises.”</h1>
                  <p>Immanuel Kant</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Kant.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“Education is the passport to the future, for tomorrow belongs to those who prepare for it today.”
                  </h1>
                  <p>Malcom X</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Malcom.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1>“The greatest investment a young person can make is in their own education, in their own mind.
                    Because
                    money comes and goes. Relationships come and go. But what you learn once stays with you forever.”</h1>
                  <p>Warren Buffett</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Buffett.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1> “Education is not the learning of facts, but the training of the mind to think.”</h1>
                  <p>Albert Einstein</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Einstein.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1> “Knowledge has power. It controls access to opportunity and advancement.” </h1>
                  <p>Peter Drucker</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Drucker.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="about__content">
              <div class="row">

                <div class="col-md-5">
                  <h1> “Learn continually – there’s always “one more thing” to learn!”</h1>
                  <p>Steve Jobs</p>
                </div>
                <div class="col-md-7">
                  <img src="../assets/images/Jobs.jpg" alt="">
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>

      </div>
    </section> -->

  <section class="questions">
    <div class="container">
      <div class="section-title">
        <h1>{{'HOMEPAGE.QA.MAIN_TITLE' | translate}}</h1>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat<br> maecenas curabitur dolor molestie eu
          pellentesque.</p> -->

      </div><!-- section__title -->

      <mat-accordion>


        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'HOMEPAGE.QA.Q_FOUR' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{'HOMEPAGE.QA.A_FOUR' | translate}}</p>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'HOMEPAGE.QA.Q_THREE' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{'HOMEPAGE.QA.A_THREE' | translate}}
          </p>
        </mat-expansion-panel>



        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'HOMEPAGE.QA.Q_FIVE' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>{{'HOMEPAGE.QA.A_FIVE' | translate}}</p>
        </mat-expansion-panel>




      </mat-accordion>


    </div><!-- container -->
  </section>
  <!-- <section class="subscribe">
    <div class="container">
      <div class="subscribe__content row">
        <div class="col-md-6">
          <div class="subscribe__content_text">
            <h1>{{"HOMEPAGE.SUBSCRIBE.TITLE" | translate}}</h1>

          </div>

        </div>
        <div class="col-md-6 flex-center">
          <div class="subscribe__content_btn">
            <button class="btn">{{"HOMEPAGE.SUBSCRIBE.BTN" | translate}}</button>
          </div>

        </div>
      </div>
    </div>
  </section> -->
</div>

<section class="contact-section">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="contact--info-area">
          <h3>{{'HOMEPAGE.FORM.TITLE' | translate}}</h3>
          <p>
            {{'HOMEPAGE.FORM.DESC' | translate}}
          </p>
          <div class="single-info">
            <h5> {{'HOMEPAGE.FORM.HEADQUARTER' | translate}}</h5>
            <p>
              <fa-icon [icon]="faHome"></fa-icon>
              Bleichistrasse 5a , <br> 9404 Rorschacherberg,<br> St. Gallen
            </p>
          </div>
          <div class="single-info">
            <h5>{{'HOMEPAGE.FORM.PHONE' | translate}}</h5>
            <p>
              <fa-icon [icon]="faPhoneAlt"></fa-icon>
              +41 76 332 76 53
            </p>
          </div>
          <div class="single-info">
            <h5>Support</h5>
            <p>
              <fa-icon [icon]="faEnvelope"></fa-icon>
              info@educagrow.com
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="contact-form">
          <h4>{{'HOMEPAGE.FORM.FORM_TITLE' | translate}}</h4>
          <form class="row" [formGroup]="QAFormGroup" (ngSubmit)="sendEmail()">
            <div class="col-md-6">
              <input type="text" name="f-name" formControlName="name"
                placeholder="{{'HOMEPAGE.FORM.NAME' | translate}}">
            </div>
            <div class="col-md-6">
              <input type="text" name="l-name" formControlName="surname"
                placeholder="{{'HOMEPAGE.FORM.LASTNAME' | translate}}">
            </div>
            <div class="col-md-6">
              <input type="email" name="email" formControlName="email"
                placeholder="{{'HOMEPAGE.FORM.EMAIL' | translate}}">
            </div>
            <div class="col-md-6">
              <input type="number" name="phone" formControlName="phone"
                placeholder="{{'HOMEPAGE.FORM.PHONE' | translate}}">
            </div>
            <div class="col-md-12">
              <input type="text" name="suject" formControlName="subject"
                placeholder="{{'HOMEPAGE.FORM.SUBJECT' | translate}}">
            </div>
            <div class="col-md-12">
              <textarea name="message" formControlName="description"
                placeholder="{{'HOMEPAGE.FORM.HELP' | translate}}"></textarea>
            </div>
            <div class="col-md-6">

            </div>
            <div class="col-md-6 text-right">
              <button type="submit" [disabled]="this.QAFormGroup.invalid ||sendQA">
                <p *ngIf="!sendQA">{{'HOMEPAGE.FORM.SEND' | translate}}</p>
                <div class="loading-wrapper" *ngIf="sendQA">
                  <div class="spinner-border">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Contact End -->

<footer class="footer">
  <div class="container">
    <div class="footer__content row">
      <div class="col-md-4 footer-flex">
        <div class="footer__content_logo">
          <a href=""><img src="../assets/images/logo-educa.png"></a>

        </div>
        <p class="copy">EducaGrow &copy; {{currentYear}}</p>
      </div>
      <!--col-md-6-->
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" routerLink='/impressum'>Impressum</li>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" (click)="contactModal(contactInfo)">Kontakt</li>
            <ng-template #contactInfo let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Contact Info</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="general-heading">E-mail: <a href="mailto:info@educagrow.com">info@educagrow.com</a></p>
                <p class="general-heading contact-margin">Phone: <a href="tel:0763327653">0763327653</a></p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Done</button>
              </div>
            </ng-template>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>

      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title">Verbesserungsvorschläge</li>

          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
    </div>
    <!--footer__content-->
  </div>
  <!--container-->
</footer>

<ng-template #feedback let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{"HOMEPAGE.REVIEWS.FEEDBACK" | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="feedbackForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" class="form-input" placeholder="Name" formControlName='name'>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <input type="text" class="form-input" placeholder="Surname" formControlName='surname'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input type="text" class="form-input" placeholder="City" formControlName='city'>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <input type="email" class="form-input" placeholder="Email" formControlName='email'>
          </div>
        </div>

      </div>
      <div class="form-group">
        <textarea class="form-input" placeholder="Leave a feedback" formControlName='description'
          style="height: 100px; padding-top: 10px;"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="sendFeedback()">{{"HOMEPAGE.REVIEWS.FEEDBACK" | translate}}</button>
  </div>
</ng-template>
