<section class="payments">
  <div class="container">
    <div class="contact__title">
      <h1 class="main-title">{{'PAYMENTS.PAYMENTS' | translate}}</h1>
    </div>
    <div class="account">
      <div class="account__header">
        <h1 class="general-heading">{{'PAYMENTS.PAYMENTS_INFO' | translate}}</h1>
      </div>
      <!--account-header-->
      <div class="account__body">

        <form [formGroup]="cardForm">

          <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Fullname
                </mat-panel-title>
                <mat-panel-description>
                  {{studentInfo?.user.card_holder || studentInfo?.user.user.name}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group margin-input">
                    <mat-form-field class="example-full-width">
                      <mat-label>FullName</mat-label>
                      <input matInput class="form-input" formControlName="fullname">
                      <div class="edited-error" *ngIf="checkFullname">
                        please enter fullname
                      </div>

                    </mat-form-field>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->

              </div>
              <!--row-->

            </mat-expansion-panel>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'PAYMENTS.ADDRESS' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{studentInfo?.user.address}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group margin-input">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'PAYMENTS.ADDRESS' | translate}}</mat-label>
                      <input matInput class="form-input" formControlName="address">
                    </mat-form-field>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->

              </div>
              <!--row-->

            </mat-expansion-panel>

          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'PAYMENTS.CITY' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{studentInfo?.user.city}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group margin-input">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'PAYMENTS.CITY' | translate}}</mat-label>
                      <input matInput class="form-input" formControlName="city">
                    </mat-form-field>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->
              </div>
              <!--row-->
            </mat-expansion-panel>

          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'PAYMENTS.ZIP' | translate}}
                </mat-panel-title>
                <mat-panel-description>
                  {{studentInfo?.user.zip}}
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div class="row ">
                <div class="col-md-4">
                  <div class="form-group margin-input">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'PAYMENTS.ZIP' | translate}}</mat-label>
                      <input matInput class="form-input" formControlName="zip" (change)="returnZip()">
                      <div class="edited-error" *ngIf="verifyZip">
                        please add valid code
                      </div>
                      <div class="loading-wrapper custom-loader" *ngIf="zipCheck">
                        <div class="spinner-border">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p>Checking ZIP</p>
                      </div>
                    </mat-form-field>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->
              </div>
              <!--row-->
            </mat-expansion-panel>

          </mat-accordion>

          <mat-accordion>

            <mat-expansion-panel expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  IBAN number
                </mat-panel-title>
                <mat-panel-description>
                  {{studentInfo?.user.iban_number}}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group margin-input">
                    <mat-form-field class="example-full-width">
                      <mat-label>IBAN</mat-label>
                      <input matInput class="form-input" formControlName='iban' (change)="returnIban()">
                      <div class="edited-error" *ngIf="verifyIban != ''">
                        {{verifyIban}}
                      </div>
                      <div class="loading-wrapper custom-loader" *ngIf="ibanCheck">
                        <div class="spinner-border">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <p>Checking IBAN</p>
                      </div>
                    </mat-form-field>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->

              </div>
              <!--row-->
            </mat-expansion-panel>

          </mat-accordion>
        </form>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-md-6 d-flex justify-content-end">
        <div class="form-buttons margin-input">
          <button class="btn btn-primary btn-submit" [disabled]="cardForm.invalid || verifyZip || errorIban" (click)="changeInfo()" mat-button>
            Save Changes
          </button>
        </div>
      </div>
    </div>
    <!--row-->



    <div class="invoices">
      <div class="row">

        <div class="col-md-12">
          <div class="invoices__data">
            <h1 class="main-title">{{'PAYMENTS.PAST_INVOICES' | translate}}</h1>
            <table class="table table-hover table-price">
              <thead class="table-light">
                <tr>
                  <th scope="col">{{'PAYMENTS.SUBJECT' | translate}}</th>
                  <th scope="col">{{'PAYMENTS.TEACHER' | translate}}</th>
                  <th scope="col">{{'PAYMENTS.COSTS' | translate}}</th>
                  <th scope="col">{{'PAYMENTS.PDF' | translate}}</th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let payment of payments_range">
                  <td>{{payment.subject}}</td>
                  <td>{{payment.teacher}}</td>
                  <td>{{payment.total_price}}CHF</td>
                  <td>
                    <a href="{{payment.pdf_file}}" target="blank">file</a>
                  </td>
                </tr>


              </tbody>
            </table>
            <div class="d-flex justify-content-between p-2">
              <ngb-pagination [collectionSize]="payments?.length" [(page)]="page" [pageSize]="pageSize"
                (pageChange)="refreshPayments()">
              </ngb-pagination>

              <select class="custom-select" style="width: auto" [(ngModel)]="pageSize"
                (ngModelChange)="refreshPayments()">
                <option [ngValue]="2">2 {{'PAYMENTS.PAGE_ITEM' | translate}}</option>
                <option [ngValue]="4">4 {{'PAYMENTS.PAGE_ITEM' | translate}}</option>
                <option [ngValue]="6">6 {{'PAYMENTS.PAGE_ITEM' | translate}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!--container-->
</section>
