import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/${LANGUAGE}`

const MATCHED_TUTORS_API = `${API}/matched-tutors`;
const PERMANENT_FILTER = `${API}/student-filter`;
const PROPOSALS = `${API}/proposals`;
const HISTORICAL_ENGAGEMENTS = `${API}/engagements/historical`;
const CURRENT_ENGAGEMENTS = `${API}/engagements/current`;
const ENGAGEMENTS_DROPLIST = `${API}/engagements`;
const RESCHEDULE_LESSON = `${API}/engagements/current`;
const TEACHERS = `${API}/overview/my-teachers`;
const GOALS = `${API}/overview/goals`;
const CALENDAR = `${API}/calendar`;
const TORING_PLACES_API = `${API}/tutoring-places`;
const SUBJECT_API = `${API}/subjects`;
const TUTORING_STYLE_API = `${API}/tutoring-style`;
const CALENDAR_DATA = `${API}/calendar/data`;
const SEND_GOAL = `${API}/calendar/goals`;
const APPOINTMENT = `${API}/calendar/appointment`;
const TEMPORARY_CALENDAR = `${API}/calendar/temporary`;
const TIMES_API = `${API}/times`;
const TIME_AVAILABILITY = `${API}/availability`;
const ADD_SUBJECT = `${API}/student/add-subject`;
const GET_NEW_SUBJECTS = `${API}/student/get-subjects`
const REMOVE_SUBJECT = `${API}/student/delete-subject`;
const RESCHEDULED_LESSONS = `${API}/rescheduled-lessons`;
const RESCH_TIMES = `${API}/get-times`;
const SHOW_EXTEND_LESSONS = `${API}/extend-lessons`
const MATCHED_TUTORS_SEEN = `${API}/matched-tutors/proposals`;
const GIVE_FEEDBACK = `${API}/rating`;
const GIVE_REPORT = `${API}/reports`;
const UPDATE_PROFILE = `${API}/account-settings`;
const UPDATE_PASSWORD = `${API}/change-password`;
const UPDATE_MEDIA = `${API}/update-media`;
const WALLET = `${API}/overview/wallet`;
const STUDENT_SUBJECT = `${API}/overview/student-subjects`;
const STUDENT_PAYMENTS = `${API}/student-payments`;
const CARD_INFO = `${API}/student/card-info`;
const QUESTIONS = `${API}/questions`;
const CHATS = `${API}/chats`;
const DELETE_MEDIA = `${API}/delete-media`;
const AUTH_SUBJECTS = `${API}/auth-subjects`;
const INTRO = `${API}/intro`;
const MATCHED_TIMES = `${API}/matched-times`;
const NOTIFICATIONS = `${API}/notifications`;
const SEEN = `${API}/seen`;
const MARK_ALL_SEEN = `${API}/mark-all-seen`;
const QA_FORUM = `${API}/qa-forum`;
const DEACTIVATE_ACCOUNT = `${API}/deactivate-account`;
const WEBSITE_FEE = `${API}/website-fee`;
const NR_LESSONS = `${API}/nr-lessons`;



@Injectable({
  providedIn: 'root'
})
export class StudentService {



  constructor(private http: HttpClient) { }


  getIntro(tab): Observable<any>{
    return this.http.get(`${INTRO}?tab_name=${tab}`)
  }

  updateIntro(name, state): Observable<any>{
    return this.http.put(`${INTRO}`,{
      tab_name: name,
      status: state,
    })
  }

  //Time list
  getTimes(): Observable<any> {
    return this.http.get(TIMES_API);
  }

  getTimeAvailability(): Observable<any> {
    return this.http.get(TIME_AVAILABILITY)
  }

  saveTimeAvailability(editedTime): Observable<any> {
    return this.http.post(`${TIME_AVAILABILITY}`, editedTime)
  }

  getGoals(): Observable<any> {
    return this.http.get(`${GOALS}`)
  }

  getMatchedTutors(): Observable<any> {
    return this.http.get(MATCHED_TUTORS_API);
  }

  getMatchedTutor(id): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_API}/${id}`);
  }

  getMatchedTutorStatus(proposalId, subject_id): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_API}/${proposalId}?subject=${subject_id}`);
  }

  getTeachers(): Observable<any> {
    return this.http.get(`${TEACHERS}`);
  }

  getLessonsByCategory(): Observable<any> {
    return this.http.get('https://dev.api.educagrow.com/public/api/overview/lessons-by-category');
  }

  getTutor(tutor): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_API}/${tutor}`);
  }

  getRescheduledLessons(): Observable<any> {
    return this.http.get(RESCHEDULED_LESSONS);
  }

  // sendSubjectId(tutor): Observable<any> {
  //   return this.http.get(`${MATCHED_TUTORS_API}/${tutor.id}?subject_id=${tutor.subjects[0].id}`)
  // }

  sendSubjectId(tutor, subjectId): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_API}/${tutor.id}?subject_id=${subjectId}`)
  }

  sendSubjectIdBooked(tutor, subjectId): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_API}/${tutor}?subject_id=${subjectId}`)
  }



  bookTeacher(teacher): Observable<any> {
    return this.http.post(`${MATCHED_TUTORS_API}/${teacher.user.user_type_id}`, {
      token: teacher.id,
      teacher_email: teacher.user.email,
      //teacher_id: teacher.teacher_id,
      teacher_id: teacher.user.user_type_id,
      total_price: teacher.total_price,
      //start_date: teacher.date,
      start_date: teacher.starting_date,
      lessons_booked: teacher.value,
      lesson_price: teacher.price_lesson,
      subject_id: teacher.subject_id,
      place_of_tutoring_id: teacher.tutoring.tutoring_place_id,
      times: teacher.sortedByDay,
      nr_of_lessons_id: teacher.nrOfLessons,
      // lessons_booked: teacher.value,
      // lesson_price: teacher.price_lesson,
      // total_price: teacher.total_price,
      // subject_id: teacher.subjects[0].id,
      // "place_of_tutoring_id": 1,
      // times: teacher.times,

    }, { responseType: 'text' })
  }

  generalfilters(params): Observable<any> {

    return this.http.get(`${MATCHED_TUTORS_API}`, { params: params })
  }

  // permanentFilter(): Observable<any> {
  //   return this.http.post(`${PERMANENT_FILTER}`, {
  //     "day": "monday",
  //     "time": 1
  //   })
  // }

  deletePermanentFilter(): Observable<any> {
    return this.http.delete(PERMANENT_FILTER)
  }

  /// this was old proposal
  // proposals(proposalData, startingDate, nrOfLessonsId, nrOfLessons): Observable<any> {
  //   console.log('test proposal',proposalData, startingDate)
  //   return this.http.post(`${PROPOSALS}`, {
  //     teacher_name: proposalData.teacher_name,
  //     teacher_id: proposalData.teacher_id,
  //     teacher_email: proposalData.teacher_email,
  //     subject_id: proposalData.subject_id,
  //     start_date: startingDate,
  //     days: proposalData.days,
  //     nr_lessons: nrOfLessonsId,
  //     lessons_nr: nrOfLessons
  //   })
  // }

  proposals(teacher_id, subject_id, message): Observable<any> {
    return this.http.post(`${PROPOSALS}`, {
      teacher_id: teacher_id,
      subject_id: subject_id,
      message: message
    })
  }

  getProposals(): Observable<any> {
    return this.http.get(`${PROPOSALS}`)
  }

  getProposal(id): Observable<any>{
    return this.http.get(`${PROPOSALS}/${id}`)
  }

  getHistoricalEngagements(): Observable<any> {
    return this.http.get(`${HISTORICAL_ENGAGEMENTS}`)
  }

  getCurrentEngagements(): Observable<any> {
    return this.http.get(`${CURRENT_ENGAGEMENTS}`)
  }

  currentEngagementsFilter(params) {
    return this.http.get(`${CURRENT_ENGAGEMENTS}`, { params: params, })
  }
  historicalEngagementsFilter(params) {
    return this.http.get(`${HISTORICAL_ENGAGEMENTS}`, { params: params })
  }

  engagementsDropList() {
    return this.http.get(`${ENGAGEMENTS_DROPLIST}`)
  }

  getEvents(): Observable<any> {
    return this.http.get(`${CALENDAR}`)
  }

  //Subjects list
  getSubjects(): Observable<any> {
    return this.http.get(SUBJECT_API);
  }

  //Tutoring Style list
  getTutoringStyle(): Observable<any> {
    return this.http.get(TUTORING_STYLE_API);
  }

  //Tutoring places list
  geTutoringPlaces(): Observable<any> {
    return this.http.get(TORING_PLACES_API);
  }

  getCalendarData(): Observable<any> {
    return this.http.get(CALENDAR_DATA);
  }

  sendGoal(goal): Observable<any> {
    return this.http.post(`${SEND_GOAL}`, {
      title: goal.title,
      time: goal.time,
      description: goal.description,
      teacher: goal.teacher,
      subject: goal.subject,
      tutoring_place: goal.tutoring_place
    })
  }

  removeGoal(id): Observable<any> {
    return this.http.delete(`${SEND_GOAL}/${id}`)
  }

  calendarAppointment(): Observable<any> {

    return this.http.get(`${APPOINTMENT}`)
  }

  calendarAppointmentT(appointmentData): Observable<any> {
    return this.http.get(`${APPOINTMENT}?teacher=${appointmentData.teacher_id}`)
  }

  calendarAppointmentD(appointmentData): Observable<any> {
    return this.http.get(`${APPOINTMENT}?teacher=${appointmentData.teacher_id}&date=${appointmentData.date}`)
  }

  sendAppointmentCalendar(appProposal): Observable<any> {
    console.log(appProposal)
    return this.http.post(`${APPOINTMENT}`, {
      teacher_id: appProposal.teacher_id,
      teacher_email: appProposal.email,
      subject_id: appProposal.subject_id,
      date: appProposal.date,
      tutoring_place_id: appProposal.tutoring_place,
      times: appProposal.times
    });
  }

  //dashboard. Add new subject
  addNewSubject(subject) {
    return this.http.post(`${ADD_SUBJECT}`, {
      subject: subject.subject
    })
  }

  //dashboard. Add new subject + second step
  addNewSubjectFinished(subjectForm) {
    return this.http.post(`${ADD_SUBJECT}`, {
      subject: {
        subject_id: subjectForm.subject,
        education_id: subjectForm.degree,
        describe: subjectForm.describe,
        nr_of_lessons_id: subjectForm.nr_lessons,
        periodicity: subjectForm.periodicity
      },
      preferences: {
        tutoring_style_id: subjectForm.tutoring_style_id,
        tutoring_place_id: subjectForm.tutoring_place_id
      }
    })
  }

  getNewSubjects() {
    return this.http.get(`${GET_NEW_SUBJECTS}`)
  }
  removeSubject(id) {
    return this.http.delete(`${REMOVE_SUBJECT}?subject_id=${id}`)
  }




  getReschTime(date, teacherReschId) {
    return this.http.get(`${RESCH_TIMES}?date=${date}&teacher_id=${teacherReschId}`)
  }


  // reschedule from engagements
  rescheduleLesson(id, time, date, comment) {
    console.log(time)
    return this.http.post(`${RESCHEDULE_LESSON}/${id}`, {
      resch_date: date,
      time_id: time.time[0].time_id,
      comment: comment.value.comment
    })
  }

  // reschedule from dashboard
  newReschAppoint(rescheduledObject, time) {
    console.log('back', rescheduledObject, time)
    return this.http.put(`${RESCHEDULED_LESSONS}/${rescheduledObject.created_by}/${rescheduledObject.proposedId}`, {
      status: "pending",
      date: rescheduledObject.teacherReschDate,
      time_id: time.time[0].time_id,
      comment: rescheduledObject.comment
    })
  }

  // accept or cancel the reschedule
  updateReschLesson(lesson, status) {
    return this.http.put<any>(`${RESCHEDULED_LESSONS}/${lesson.created_by}/${lesson.id}`, {
      status: status
    })
  }

  //get all extended lessons
  showExtendLessons() {
    return this.http.get(`${SHOW_EXTEND_LESSONS}`);
  }

  //extend lesson request
  extendLesson(teacher, lesson_nr) {
    return this.http.post(`${SHOW_EXTEND_LESSONS}`, {
      teacher_id: teacher.teacher_id,
      teacher_email: teacher.teacher_email,
      subject_id: teacher.subject_id,
      lessons_nr: lesson_nr
    })
  }

  bookExtendLesson(totalPrice, id, token): Observable<any> {
    console.log('back', totalPrice, id, token)
    return this.http.post(`${SHOW_EXTEND_LESSONS}/${id}`, {
      token: token.id,
      total_price: totalPrice,
    })
  }

  getTemporaryCalendar(): Observable<any> {
    return this.http.get(`${TEMPORARY_CALENDAR}`)
  }

  matchedTutorsSeen(): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_SEEN}`);
  }

  matchedTutorsSeenFilter(filterValue): Observable<any> {
    return this.http.get(`${MATCHED_TUTORS_SEEN}?status=${filterValue}`);
  }

  giveFeedback(feedbackObject) {
    return this.http.post(`${GIVE_FEEDBACK}`, {
      student_id: feedbackObject.student_id,
      teacher_id: feedbackObject.teacher_id,
      subject_id: feedbackObject.subject_id,
      rating: feedbackObject.rating,
      comment: feedbackObject.comment,
    })
  }

  giveRaport(raportObject) {
    return this.http.post(`${GIVE_REPORT}`, {
      student_id: raportObject.student_id,
      teacher_id: raportObject.teacher_id,
      subject_id: raportObject.subject_id,
      student_teacher: raportObject.student_teacher,
      type: raportObject.type,
      description: raportObject.description,
      refund: raportObject.refund,
      stop_collab: raportObject.collab
    })
  }

  updateProfile(profile){
    return this.http.put(`${UPDATE_PROFILE}`,{
      name: profile.value.firstname,
      surname: profile.value.surname,
      phone: profile.value.phone,
      gender: profile.value.gender,
      birthdate: profile.value.date,
      address: profile.value.streetName,
      zip: profile.value.zipCode,
      city: profile.value.location,
      tutoring_place_id: profile.value.tutoringPlace,
      tutoring_style_id: profile.value.tutoringStyle,
      bio: profile.value.bio,
    })
  }

  deleteMedia(){
    return this.http.delete(DELETE_MEDIA);
  }

  updatePassword(pass){
    return this.http.put(`${UPDATE_PASSWORD}`,{
      current_password: pass.value.oldPass,
      new_password: pass.value.newPass,
      new_confirm_password: pass.value.confPass
    })
  }

  updateMedia(image){
    return this.http.post(`${UPDATE_MEDIA}`,image)
  }

  studentSubject() {
    return this.http.get(`${STUDENT_SUBJECT}`);
  }

  wallet(subjectId){
    return this.http.get(`${WALLET}?subject=${subjectId}`)
  }

  studentPayments(){
    return this.http.get(`${STUDENT_PAYMENTS}`);
  }

  cardInfo(formValue) {
    return this.http.put(`${CARD_INFO}`, {
      iban_number: formValue.value.iban,
      card_holder: formValue.value.fullname,
      address: formValue.value.address,
      zip: formValue.value.zip,
      city: formValue.value.city
    });
  }

  questions(contactForm, user){
    return this.http.post(`${QUESTIONS}`, {
      user_id: user,
      title: contactForm.value.topic,
      description: contactForm.value.description
    })
  }


  getChats(){
    return this.http.get(CHATS);
  }

  userChat(id){
    return this.http.get(`${CHATS}/${id}`)
  }


  sendChat(id, sms, file){

    const fd = new FormData();
    fd.append('body', sms)

    if(file){
      fd.append('file', file, file.name)
    }

    return this.http.post(`${CHATS}/${id}`,fd)
  }

  lastSeen(id){
    return this.http.put(`${CHATS}/${id}`,{
    })
  }

  getAuthSubjects(){
    return this.http.get(AUTH_SUBJECTS)
  }

  getMatchedTimes(studentId, teacherId){
    return this.http.get(`${MATCHED_TIMES}?student=${studentId}&teacher=${teacherId}`)
  }

  getNotifications(){
    return this.http.get(`${NOTIFICATIONS}`);
  }

  getSeenNotification(notificationId){
    return this.http.post(`${SEEN}`,{
      id: notificationId
    });
  }

  getMarkAllSeen(){
    return this.http.post(`${MARK_ALL_SEEN}`,{
    });
  }

  sendQA(QAForm){
    return this.http.post(`${QA_FORUM}`, {
      name: QAForm.value.name,
      surname: QAForm.value.surname,
      email: QAForm.value.email,
      subject: QAForm.value.subject,
      description: QAForm.value.description,
      phone: QAForm.value.phone,
    })
  }

  getQA(){
    return this.http.get(QA_FORUM)
  }


  deactivateAccount(){
    return this.http.post(`${DEACTIVATE_ACCOUNT}`,{})
  }

  getWebsiteFee(){
    return this.http.get(WEBSITE_FEE)
  }



}
