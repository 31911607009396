

<div class="impresum">

  <div class="container">
    <div class="info">
      <h6>Handelsregistereintrag:</h6>
      <p>EducaGrow GmbH</p>
      <p>Bleichistrasse 5a</p>
      <p>9404 Rorschacherberg</p>
      <p>Schweiz</p>
    </div>

    <div class="info">
      <h6>Kontakt:</h6>
      <p>Telefon: 0763327653</p>
      <p>E-Mail: info@educagrow.com</p>
    </div>

    <div class="info">
      <h6>Geschäftsleitung:</h6>
      <p>Nektar Mustafa</p>
    </div>
  </div>
  <div class="back-btn" id="questions">
    <p routerLink='/'>{{'IMPRESSUM.BTN' | translate}}</p>
  </div>
</div>
