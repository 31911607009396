import { Component, ErrorHandler, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AuthService } from '../core/_services/auth.service';
import { TokenStorageService } from '../core/_services/token-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  arrLang = {
    en: {
      emailCheck: "Check your email to reset the password",
      incorrect_alert: "Incorrect email or password"
    },
    de:{
      emailCheck: "Überprüfe Deine E-Mails um Dein Passwort zurückzusetzten",
      incorrect_alert: "Ungültige E-Mail und Passwort"
    }
  }


  roles;

  errorMessage: String;
  resetPasswordAlert: String;

  validationform: FormGroup; // bootstrap validation form
  forgotPassform: FormGroup;

  isLogin: boolean = false;

  forgotPassword = true;
  emailVerified = true;

  // Form submition
  submit: boolean;
  showError: boolean = false;
  showResetAlert: boolean = false;


  PAT_EMAIL = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.][a-zA-Z]{2,4}$";

  isLoggedIn = false;
  fullPageLoader = false;

  lang;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router) {
    this.isLoggedIn = !!this.tokenStorage.getToken();
    this.lang = localStorage.getItem('lang')  || 'de';

    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }


    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.fullPageLoader = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.fullPageLoader = false;
          break;
        }
        default: {
          break;
        }
      }
    });


  }


  ngOnInit(): void {

    if (this.isLoggedIn) {
      this.router.navigate([`/${this.tokenStorage.getUser()}/dashboard`]);
    } else {
      this.router.navigate(['/login']);
    }

    if (this.tokenStorage.getToken()) {
      this.roles = this.tokenStorage.getUser().roles;
    }

    this.validationform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.forgotPassform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // onTidioChatApiReady() {
  //   // Code after chat loaded
  //   if(this.router.url == '/login'){
  //     window['tidioChatApi'].hide();
  //   }
  // }

  get form() {
    return this.validationform.controls;

  }

  close() {
    this.showError = false;
  }
  onSubmit(): void {
    this.isLogin = true;

    this.authService.login(this.validationform.value).subscribe(
      dataUser => {

        console.log('user login', dataUser)

        // console.log('user login', data)

        this.tokenStorage.saveToken(dataUser.access_token);
        this.tokenStorage.saveUser(dataUser.user);

        this.authService.afterLogin().subscribe(data => {
          this.isLogin = false;
          console.log('after login ', data);
          if (data == 1) {
            if (dataUser.user.user_type == 'student') {
              this.router.navigate(['/student/tutors']);

            } if (dataUser.user.user_type == 'teacher') {
              this.router.navigate(['/teacher/dashboard'])
            }
            if (dataUser.user.user_type == 'admin') {
              this.router.navigate(['/admin/dashboard'])
              //window['tidioChatApi'].hide();
            }
          } else {
            this.router.navigate(['/verify'])
            this.isLogin = false;
          }
        })


      }, (error) => {
        this.showError = true;
        this.isLogin = false;
        console.log(error)
        setTimeout(() => {
          this.showError = false;
        }, 5000)

        this.errorMessage = this.arrLang[this.lang].incorrect_alert;
      }
    );

  }

  resetPassword() {
    this.isLogin = false;
    this.showResetAlert = true;
    this.resetPasswordAlert = '';
    this.authService.resetPassword(this.forgotPassform.value.email).subscribe(res => {
      console.log(res)
      this.resetPasswordAlert = this.arrLang[this.lang].emailCheck;
      this.isLogin = false;
      this.showResetAlert = true;

    }, (err) => {
      this.resetPasswordAlert = 'Something went wrong, please try again';
      this.isLogin = false;
      this.showResetAlert = true;
    })
  }


}
