<section class="profile">
  <div *ngIf="fullPageLoader" class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="container">
    <div class="student__title">
      <h1 class="main-title">{{'PROFILE.ACCOUNT_SETTINGS' | translate}}</h1>
    </div>
    <div class="profile__content">

      <div class="row photo-wrap">
        <div class="col-md-4 ">
          <form [formGroup]="thirdFormGroup">
            <div class="form-group align-items-center">
              <p class="form-label">{{'PROFILE.PROFILE_PIC' | translate}}</p>
              <div *ngIf="imageLoader" class="spinner-border text-info">
                <span class="sr-only">Loading...</span>
              </div>
              <!-- <div *ngIf="this.user?.user.image_media.length == 0 && imageLoader">
                <img [src]="base64" alt="">
              </div> -->
              <div class="profile-pic" *ngIf="this.user?.user.image_media.length != 0">
                <img [src]="this.user?.user.image_path" alt="">
              </div>
              <div class="profile-pic" *ngIf="this.user?.user.image_media.length == 0">
                <img src="../../../assets/images/{{gender}}.jpg" alt="">
              </div>
            </div>
            <div class="form-group align-items-center">
              <label for="image-upload" class="custom-file-upload">{{'PROFILE.UPLOAD_PIC' | translate}}
              </label>
              <input id="image-upload" #file accept="image/*" type="file" (change)="fileChangeEvent($event)" />

              <p class="form-label" (click)="deleteImage()" style="color: #FB6565;">{{'PROFILE.REMOVE_PIC' | translate}}
              </p>
            </div><!-- form-group -->
            <ngx-photo-editor [imageChanedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)"
              [aspectRatio]="4/3" [scalable]="false" [viewMode]="1" [modalCentered]='true' [resizeToWidth]="500">
            </ngx-photo-editor>
          </form>
        </div>
        <!--col-md-4-->
      </div>
      <!--row-->

      <div class="account">
        <div class="account__header">
          <h1 class="general-heading">{{'PROFILE.PERSONAL_INFO' | translate}}</h1>
        </div>
        <!--account-header-->
        <div class="account__body">
          <form [formGroup]="firstFormGroup">
            <mat-accordion>

              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.NAME' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.name}} {{this.user?.user.surname}}
                  </mat-panel-description>

                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.SURNAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="firstname">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.SURNAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="surname">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->
              </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.PHONE' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.phone}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="example-full-width country-code">
                        <mat-label>{{'PROFILE.PHONE' | translate}}</mat-label>
                        <input ng2TelInput [ng2TelInputOptions]="{initialCountry: 'ch', 'onlyCountries': ['ch', 'de']}"
                          (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)" matInput
                          (intlTelInputObject)="telInputObject($event)" class="form-input" formControlName="phone">
                        <div class="edited-error" *ngIf="!numberError">
                          {{'VALIDATIONS.PHONE' | translate}}
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->

                </div>
                <!--row-->
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.GENDER' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.gender}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label class="label-custom">{{'PROFILE.GENDER' | translate}}</mat-label>
                        <mat-select formControlName="gender">
                          <mat-option selected value="male">
                            {{'LABELS.MALE' | translate}}
                          </mat-option>
                          <mat-option value="female">
                            {{'LABELS.FEMALE' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->

                </div>
                <!--row-->
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.BIRTHDATE' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>

                    {{this.user?.user.birthdate | date:'dd/MM/yyyy'}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
              </mat-expansion-panel>
            </mat-accordion>

          </form>
        </div>
      </div>
      <!--account-->
      <div class="row justify-content-end">
        <div class="col-md-6 d-flex justify-content-end">
          <div class="form-buttons margin-input">
            <button class="btn btn-primary btn-submit" [disabled]="firstFormGroup.invalid || !numberError" mat-button
              (click)="submitProfile()">
              {{'PROFILE.SAVE' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!--row-->


      <div class="account">
        <div class="account__header">
          <h1 class="general-heading">Login</h1>
        </div>
        <!--account-header-->
        <div class="account__body">
          <form [formGroup]="passFormGroup">
            <mat-accordion>
              <mat-expansion-panel disabled>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.EMAIL' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>

                    {{this.user?.email}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.EMAIL' | translate}}</mat-label>
                        <input matInput class="form-input">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>Confirm email address</mat-label>
                        <input matInput class="form-input">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->
                <div class="row justify-content-end">
                  <div class="col-md-6">
                    <div class="form-buttons margin-input">
                      <button class="btn btn-primary btn-submit" mat-button>
                        {{'PROFILE.SAVE' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
                <!--row-->
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>

              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.PASSWORD' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    *******
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.OLD_PASS' | translate}}</mat-label>
                        <input type="password" formControlName="oldPass" matInput class="form-input">
                        <span *ngIf="passError != ''">{{passError}}</span>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-4">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.NEW_PASS' | translate}}</mat-label>
                        <input type="password" minlength="8" formControlName="password" matInput class="form-input">
                        <mat-error *ngIf="passFormGroup.controls.password.invalid">{{'VALIDATIONS.PASSWORD' |
                          translate}}</mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-4">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.CONFIRM_PASS' | translate}}</mat-label>
                        <input type="password" formControlName="confPass" matInput class="form-input">
                        <mat-error *ngIf="passFormGroup.controls.confPass.invalid">{{'VALIDATIONS.RE_PASSWORD' |
                          translate}}
                        </mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->
                <div class="row justify-content-end">
                  <div class="col-md-6">
                    <div class="form-buttons margin-input">
                      <button class="btn btn-primary btn-submit" mat-button [disabled]="passFormGroup.invalid"
                        (click)="changePass()">
                        {{'PROFILE.SAVE' | translate}}
                      </button>
                    </div>
                  </div>
                </div>
                <!--row-->
              </mat-expansion-panel>

            </mat-accordion>
          </form>
        </div>
      </div>
      <!--account-->

      <div class="account">
        <div class="account__header">
          <h1 class="general-heading">{{'PROFILE.ADDRESS' | translate}}</h1>
        </div>
        <!--account-header-->
        <div class="account__body">
          <form [formGroup]="addressFormGroup">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.STREET' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>

                    {{this.user?.user.address}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.STREET' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="streetName">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>

              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.ZIP' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.zip}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.ZIP' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="zipCode">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>

              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'PROFILE.CITY' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.city}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'PROFILE.CITY' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="location">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>

              </mat-expansion-panel>
            </mat-accordion>
          </form>
        </div>
      </div>
      <!--account-->
      <div class="row justify-content-end">
        <div class="col-md-6">
          <div class="form-buttons margin-input">
            <button class="btn btn-primary btn-submit" [disabled]="addressFormGroup.invalid" mat-button
              (click)="submitAddress()">
              {{'PROFILE.SAVE' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!--row-->



      <div class="account">
        <div class="account__header">
          <h1 class="general-heading">{{'PROFILE.PREFERENCES' | translate}}</h1>
        </div>
        <!--account-header-->
        <div class="account__body">
          <form [formGroup]="preferencesFormGroup">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'TUTORS.TUTORING_PLACE' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.preferences.tutoring_place}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'TUTORS.TUTORING_PLACE' | translate}}</mat-label>
                        <mat-select formControlName="tutoringPlace">
                          <mat-option value='{{place.id}}' *ngFor='let place of tutoringPlace'>
                            {{place.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>

              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{'TUTORS.TUTORING_STYLE' | translate}}
                  </mat-panel-title>
                  <mat-panel-description>
                    {{this.user?.user.tutoring_style.name}}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group margin-input">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'TUTORS.TUTORING_STYLE' | translate}}</mat-label>
                        <mat-select formControlName="tutoringStyle">
                          <mat-option value='{{style.id}}' *ngFor='let style of tutoringStyle'
                            [disabled]='style.id == 2'>
                            {{style.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>

              </mat-expansion-panel>
            </mat-accordion>

          </form>
        </div>
      </div>

      <!--account-->
      <div class="row justify-content-end">
        <div class="col-md-6">
          <div class="form-buttons margin-input">
            <button class="btn btn-primary btn-submit" [disabled]="preferencesFormGroup.invalid" mat-button
              (click)="submitPreferences()">
              {{'PROFILE.SAVE' | translate}}
            </button>
          </div>
        </div>
      </div>
      <!--row-->




      <div class="account">
        <div class="account__header" style="margin-bottom: 20px;">
          <h1 class="general-heading">{{'PROFILE.PREFERENCES' | translate}}</h1>
        </div>
        <div class="spinner-border" role="status" *ngIf="timeLoader">
          <span class="sr-only">Loading...</span>
        </div>

        <div class="grid-container" [hidden]="timeLoader">

          <div class="week">
            <div class="day">{{'WEEK_DAYS.MONDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                type="checkbox" id="monday{{t.id}}" [disabled]="timeDisable" (change)="changeSelection($event)"
                name="monday" value="{{t.id}}">{{t.time}}
            </div>
          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.TUESDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="tuesday{{t.id}}" (change)="changeSelection($event)"
                name="tuesday" value="{{t.id}}">{{t.time}}
            </div>

          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.WEDNESDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="wednesday{{t.id}}" (change)="changeSelection($event)"
                name="wednesday" value="{{t.id}}">{{t.time}}
            </div>

          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.THURSDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="thursday{{t.id}}" (change)="changeSelection($event)"
                name="thursday" value="{{t.id}}">{{t.time}}
            </div>
          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.FRIDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="friday{{t.id}}" (change)="changeSelection($event)"
                name="friday" value="{{t.id}}">{{t.time}}
            </div>
          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.SATURDAY' | translate }}</div>
            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="saturday{{t.id}}" (change)="changeSelection($event)"
                name="saturday" value="{{t.id}}">{{t.time}}
            </div>
          </div>
          <div class="week">
            <div class="day">{{'WEEK_DAYS.SUNDAY' | translate }}</div>

            <div *ngFor="let t of times" class="time" [ngClass]="{'disable-timeslot': timeDisable}"><input
                [disabled]="timeDisable" type="checkbox" id="sunday{{t.id}}" (change)="changeSelection($event)"
                name="sunday" value="{{t.id}}">{{t.time}}
            </div>
          </div>
        </div>
      </div>



      <div class="account">
        <button (click)="saveAvailability()" class="custom-file-upload ">{{'PROFILE.SAVE_AVAILABILITY' | translate
          }}</button>
      </div>

      <div class="account">
        <div class="account__header">
          <h1 class="general-heading">{{'PROFILE.DEACTIVE_ACCOUNT' | translate }}</h1>
        </div>
        <!--account-header-->
        <div class="account__body">

          <mat-accordion>
            <mat-expansion-panel hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{'PROFILE.ACCOUNT_STATUS' | translate }}
                </mat-panel-title>
                <mat-panel-description>
                  {{'PROFILE.ACTIVE' | translate }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="row justify-content-end">
                <div class="col-md-12">
                  <div class="form-group margin-input">
                    <button type="button" class="btn btn-danger"
                      (click)="deactivateAccount()">{{'PROFILE.DEACTIVE_ACCOUNT' | translate }}</button>
                  </div><!-- form-group -->
                </div>
                <!--col-md-6-->
              </div>

            </mat-expansion-panel>
          </mat-accordion>



        </div>
      </div>

    </div>
    <!--profile__content-->
  </div>
  <!--container-->
</section>
