import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/${LANGUAGE}`
const REGIONS = `${API}/regions`;
const TABLE_REGIONS = `${API}/educagrow-info`;
const TUTORS_DEMO = `${API}/educagrow-filter`;
const FEEDBACK = `${API}/feedback`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};






@Injectable({
  providedIn: 'root'
})
export class HomeService {

  sharingData = {};

  // Observable string source
  private dataStringSource = new BehaviorSubject<any>(this.sharingData);;

  // Observable string stream
  dataString$ = this.dataStringSource.asObservable();



  constructor(private http: HttpClient) { }


  public saveData(value) {
    this.sharingData = value
    this.dataStringSource.next(this.sharingData);
  }

  getRegions(): Observable<any> {
    return this.http.get(`${REGIONS}`)
  }

  getTableRegions(zip): Observable<any> {
    return this.http.get(`${TABLE_REGIONS}?zip=${zip}`)
  }

  getTableSubjects(zip, subject): Observable<any> {
    return this.http.get(`${TABLE_REGIONS}?zip=${zip}&subject=${subject}`)
  }

  // searchTutorSubject(data): Observable<any> {
  //   return this.http.get(`${TUTORS_DEMO}?subject=${data.subject}&tutoring_place=${data.style}&day=${data.day}`)
  // }

  searchTutorSubject(data): Observable<any> {
    return this.http.get(`${TUTORS_DEMO}`, { params: { subject: data.subject.toString() } })
  }

  getSearchTutor(id) {
    return this.http.get(`${TUTORS_DEMO}/${id}`)
  }

  feedback(feedbackForm) {
    return this.http.post(`${FEEDBACK}`, {
      name: feedbackForm.value.name,
      surname: feedbackForm.value.surname,
      email: feedbackForm.value.email,
      address: feedbackForm.value.city,
      description: feedbackForm.value.description,
    })
  }

  getFeedback() {
    return this.http.get(FEEDBACK)
  }

  getClientIp() {
    return this.http.get<any>('https://geolocation-db.com/json/')
  }

}
