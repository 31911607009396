import { AfterViewInit, Component, ElementRef, OnChanges, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { AuthService } from '../core/_services/auth.service';
import { RegisterFormsService } from '../core/_services/register-forms.service';
import { Options } from '@angular-slider/ngx-slider';
import { passValidator, dateValidator, phoneValidator, noWhitespaceValidator } from '../core/_shared/validator';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { HomeService } from '../core/_services/home.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, of } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { ValidatorService } from 'angular-iban';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { time } from 'console';

@Component({
  selector: 'app-student-register',
  templateUrl: './student-register.component.html',
  styleUrls: ['./student-register.component.scss'],
  providers: [DatePipe]
})
export class StudentRegisterComponent implements OnInit, OnChanges {
  @ViewChildren("selectSubject") subjectSelects: QueryList<ElementRef<HTMLSelectElement>>;

  arrLang = {
    en: {
      timeslot: "Change Timeslots",
      error_title: "Warning",
      error_alert: "Something went wrong"
    },
    de: {
      timeslot: "Zeitfenster ändern",
      error_title: "Warnung",
      error_alert: "Etwas ist schief gelaufen"
    }
  }

  fullPageLoader = false;

  isSelected: boolean = false;
  isVirtualMeeting: boolean = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  counter = 0;

  invalidSubject = true;

  educations;
  educationsDepth = [];
  subjects;
  tutoringStyle;
  lessons;
  tutoringPlaces;

  storeSubjects = {
    subject: []
  }

  value: number = 40;
  highValue: number = 60;
  optionsRange: Options = {
    floor: 20,
    ceil: 65,
    disabled: true
  };

  submitRegister = true;


  object3 = {};

  times;



  get educationFormArray() {
    return (this.secondFormGroup.get('education') as FormArray).controls;
  }

  addEducaitonForm() {
    this.educationFormArray.push(this.formBuilder.group({
      id: ['', Validators.required],
      education_id: ['', Validators.required],
      describe: ['', Validators.required],
      nr_of_lessons: ['', Validators.required],
      periodicity: ['', Validators.required]
    }))
    // this.educationFormArray.push(this.formBuilder.group({
    //   id: ['', Validators.required],
    //   education_id: ['', Validators.required],
    //   education_depth_id: ['undefined', Validators.required],
    // }))

    // setTimeout(() => {

    //   this.educationFormArray.forEach((element, index) => {

    //     if (this.educationFormArray[index].value.id != "" &&
    //       this.educationFormArray[index].value.education_id != "" &&
    //       element['controls'].education_depth_id.disabled) {
    //       console.log('false')
    //       this.invalidSubject = false;

    //     } else {
    //       this.invalidSubject = true;
    //     }
    //   })

    // }, 100)
  }



  removeEducationForm(index, subject) {
    this.educationFormArray.splice(index, 1)
    this.updateValidation(event);
    this.selectedSubjects.delete(subject);
    // this.educationFormArray.splice(index, 1)
    // this.educationFormArray.forEach((element, index) => {
    //   if (this.educationFormArray[index].value.id != "" &&
    //     this.educationFormArray[index].value.education_id != "") {
    //     this.invalidSubject = false;
    //   }

    // })

    // setTimeout(() => {

    //   this.educationFormArray.forEach((element, index) => {

    //     if (this.educationFormArray[index].value.id != "" &&
    //       this.educationFormArray[index].value.education_id != "" &&
    //       element['controls'].education_depth_id.disabled) {
    //       console.log('false')
    //       this.invalidSubject = false;

    //     } else {
    //       this.invalidSubject = true;
    //     }
    //   })

    // }, 100)
  }



  getValue = [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    },

  ];


  wrapTime = [{
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }]


  weekDays = [
    {
      day: 'monday',
      day_id: 1

    },
    {
      day: 'tuesday',
      day_id: 2
    },
    {
      day: 'wednesday',
      day_id: 3
    },
    {
      day: 'thursday',
      day_id: 4
    },
    {
      day: 'friday',
      day_id: 5
    },
    {
      day: 'saturday',
      day_id: 6
    },
    {
      day: 'sunday',
      day_id: 7
    },
  ]


  verifyEmail = '';
  verifyIban = '';
  verifyZip = false;

  lang;

  public ibanReactive: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private registerFormService: RegisterFormsService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private homeService: HomeService,
    private http: HttpClient,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
  ) {

    this.lang = localStorage.getItem('lang') || 'de';
    this.dateAdapter.setLocale(this.lang);

    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }

    //this.registerFormService.languageRequest();



    // handling lists data from server
    this.registerFormService.getEducation().subscribe(res => {
      this.educations = res.data;

    });



    // forkJoin([this.registerFormService.languageRequest(), this.registerFormService.getEducation()]).subscribe(data =>{
    //   console.log(data.headers)
    // })

    this.registerFormService.getSubjects().subscribe(res => {
      this.subjects = res.data;


    });
    this.registerFormService.getTutoringStyle().subscribe(res => {
      this.tutoringStyle = res.data;

    });
    this.registerFormService.getLessons().subscribe(res => {
      this.lessons = res.data;

    });
    this.registerFormService.geTutoringPlaces().subscribe(res => {
      this.tutoringPlaces = res.data;

    });
    this.registerFormService.getTimes().subscribe(res => {
      this.times = res.data;
    });



  }

  ngOnChanges() {

  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();

  }

  testForm(){
    console.log(this.firstFormGroup)
  }

  ngOnInit() {

    // setTimeout(() => {
    //   window['tidioChatApi'].hide();
    // }, 1000)

    this.ibanReactive = new FormControl(
      null,
      [
        ValidatorService.validateIban
      ]
    );


    this.firstFormGroup = this.formBuilder.group({
      gender: ['', Validators.required],
      firstname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      surname: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      date: ['', [Validators.required, dateValidator]],
      address: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      zipCode: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      city: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, phoneValidator, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      password: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      verifyPassword: ['', [Validators.required, passValidator]],
      ibanReactive: this.ibanReactive,
      // iban: ['', Validators.required],
    });

    this.secondFormGroup = this.formBuilder.group({

      //new fields changed
      lastDegre: ['', Validators.required],
      direction: ['',],
      direction_id: [''],
      //working: ['', Validators.required],

      education: this.formBuilder.array([
        this.formBuilder.group({
          id: ['', Validators.required],
          education_id: ['', Validators.required],
          describe: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
          nr_of_lessons: ['', Validators.required],
          periodicity: ['', Validators.required]
        })
      ]),

      // subject: [''],
      // educa: [''],
      // educaLevel: [''],
      // education: this.formBuilder.array([
      //   this.formBuilder.group({
      //     id: ['', Validators.required],
      //     education_id: ['', Validators.required],
      //     education_depth_id: ['undefined', Validators.required],
      //   })
      // ])
    });

    this.thirdFormGroup = this.formBuilder.group({
      tutoringStyle: ['', Validators.required],
      lessons: [''],
      tutoringPlaces: ['', Validators.required],
    });

    this.fourthFormGroup = this.formBuilder.group({
      tutorAge: ['no'],
      tutorRange: [''],
      preferedGender: ['none'],
      askLocation: ['no'],
      askedLocation: [''],
      platform: [{ value: '', disabled: true }]
    })
  }

  // onTidioChatApiReady() {
  //   // Code after chat loaded
  //   if(this.router.url == '/student/register'){
  //     window['tidioChatApi'].hide();
  //   }
  // }

  // Check for valid email
  emailExists = false;
  returnEmail() {
    this.emailExists = true;
    this.registerFormService.checkEmail(this.firstFormGroup.value.email).subscribe(res => {
      if (res != null) {
        this.verifyEmail = res.msg;
        this.emailExists = false;
      } else {
        this.verifyEmail = '';
        this.emailExists = false;
      }
      console.log(res)
    })
  }

  ibanCheck = false;
  returnIban() {
    this.ibanCheck = true;
    this.registerFormService.checkIban(this.firstFormGroup.value.ibanReactive).subscribe(res => {
      console.log(res);
      if (res != null) {
        this.verifyIban = res[0];
        this.ibanCheck = false;
      } else {
        this.verifyIban = '';
        this.ibanCheck = false;
      }
    })
  }


  zipCheck = false;
  returnZip() {
    this.zipCheck = true;
    this.registerFormService.checkZip(this.firstFormGroup.value.zipCode).subscribe(res => {
      console.log(res);
      if (res == 1) {
        this.verifyZip = false;
        this.zipCheck = false;
      } else {
        this.zipCheck = false;
        this.verifyZip = true;
      }
    }, (err) => {
      this.verifyZip = true;
      this.zipCheck = false;
    })
  }

  getNumber(e) {
    console.log('number', e)
    this.firstFormGroup.get('phone').setValue(e)
  }

  numberError = true;
  hasError(e) {
    console.log(e)
    this.numberError = e;
  }


  onChange(e) {
    if (e.source.options.last._element.nativeElement.getAttribute('formNameId') == null) {
      return false;
    }

    else if (e.source.options.last._element.nativeElement.getAttribute('formNameId') !== '') {
      var attrId = e.source.options.last._element.nativeElement.getAttribute('formNameId');
      const enableForm = this.educationFormArray[attrId]['controls'].education_depth_id.enable();

      // const educationValue = this.secondFormGroup.controls.educa.value;

      if (e.value == "5") {
        this.educationsDepth[attrId] = this.educations[4].education_depth;
        enableForm

      } else if (e.value == "6") {
        this.educationsDepth[attrId] = this.educations[5].education_depth;
        enableForm
      } else if (e.value == "7") {
        this.educationsDepth[attrId] = this.educations[6].education_depth;
        enableForm
      } else if (e.value == "8") {
        this.educationsDepth[attrId] = this.educations[7].education_depth;
        enableForm
      } else if (e.value == "9") {
        this.educationsDepth[attrId] = this.educations[8].education_depth;
        enableForm
      }
      if (e.value == "1" ||
        e.value == "2" ||
        e.value == "3" ||
        e.value == "4") {
        this.educationFormArray[attrId]['controls'].education_depth_id.disable();

      }

      setTimeout(() => {

        this.educationFormArray.forEach((element, index) => {

          if (this.educationFormArray[index].value.id != "" &&
            this.educationFormArray[index].value.education_id != "" &&
            element['controls'].education_depth_id.disabled) {

            this.invalidSubject = false;

          } else {
            if (this.educationFormArray[index].value.education_depth_id != "") {

              this.invalidSubject = false;
            } if (this.educationFormArray[index].value.education_depth_id == "") {
              this.invalidSubject = true;

            }

          }
        })

      }, 100)
    }


    // this.educations.forEach((value, index) => {
    //     if(e.value == index + 1){
    //       console.log(value)
    //     }
    // })
    // this.educationsDepth.forEach((value, index) => {
    //   if(e.value == index){
    //     console.log(value)
    //   }
    // })



  }

  sliderValidation() {
    if (this.fourthFormGroup.controls.tutorAge.value == 'no') {
      this.optionsRange = Object.assign({}, this.optionsRange, { disabled: true });
    } else if (this.fourthFormGroup.controls.tutorAge.value == 'yes') {
      this.optionsRange = Object.assign({}, this.optionsRange, { disabled: false });
    }

    if (this.fourthFormGroup.controls.askLocation.value == 'no') {
      this.fourthFormGroup.controls.platform.disable();
      this.fourthFormGroup.controls.askedLocation.disable();
    } else if (this.fourthFormGroup.controls.askLocation.value == 'yes') {
      this.fourthFormGroup.controls.platform.enable();
      this.fourthFormGroup.controls.askedLocation.enable();
    }

    if (this.thirdFormGroup.controls.tutoringPlaces.value == "1" ||
      this.thirdFormGroup.controls.tutoringPlaces.value == "2" ||
      this.thirdFormGroup.controls.tutoringPlaces.value == "3") {
      this.isVirtualMeeting = true;
    } else if (this.thirdFormGroup.controls.tutoringPlaces.value == "4") {
      this.isVirtualMeeting = false;
      this.fourthFormGroup.controls.platform.enable();
    }
  }

  formatLabel(value: number) {
    if (value >= 20) {
      return '20+';
    }
    return value;
  }


  onceClick() {
    $('.times').html(this.arrLang[this.lang].timeslot)
  }


  result = false;

  selectAll(e) {

    //var day = e.target.innerText.toLowerCase()
    var day = e.target.defaultValue.toLowerCase();
    $(`input[name="${day}"]`).prop('checked', true);
    $(`input[name="${day}"]`).parent().addClass('colorTime');

    let ele = document.getElementsByName(day)

    if (e.target.checked === true) {
      this.getValue[0][day] = []
      for (let i = 0; i < ele.length; i++) {
       // console.log(ele[i])
        this.getValue[0][day].push({
          id: ele[i].attributes[4].value,
          time: ele[i].nextSibling.textContent,
          day: ele[i].id.replace(/[0-9]/g, '')
        })
      }
      e.target.parentNode.classList.add('colorTime');

    } else {

      this.getValue[0][day] = []
      $(`input[name="${day}"]`).prop('checked', false);
      $(`input[name="${day}"]`).parent().removeClass('colorTime');
      e.target.parentNode.classList.remove('colorTime');

    }

    this.wrapTime[0][day] = this.reduceArray(this.getValue[0][day].slice().sort((a, b) => a.id - b.id));

    this.checkTimeslot()


  }

  booleanValues = [];
  checkTimeslot() {
    let editArr
    this.weekDays.forEach(element => {

      // editArr = this.getValue.map(e => {
      //   if(e[element.day].length == 0){
      //     return true
      //   }
      //   else{
      //     return false
      //   }
      // })

      // this.getValue.forEach(e =>{
      //   if(e[element.day].length == 0){
      //     this.booleanValues.push(true)
      //   }
      //   else{
      //     this.booleanValues.push(true)
      //   }
      // })



    })

    console.log('chec', this.booleanValues)


  }


  // checkTime(){
  //   this.booleanValues.forEach(bool =>{
  //     if(bool.includes('exist')){
  //       return true
  //     }
  //     return true
  //   })
  //   return false;
  // }




  changeSelection(e) {
    //console.log(e.target.id == 'monday'+e.target.defaultValue)


    if (e.target.checked === true && e.target.id == 'monday' + e.target.defaultValue) {

      this.getValue[0].monday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');


    } else if (e.target.checked === true && e.target.id === 'tuesday' + e.target.defaultValue) {

      this.getValue[0].tuesday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'wednesday' + e.target.defaultValue) {

      this.getValue[0].wednesday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'thursday' + e.target.defaultValue) {

      this.getValue[0].thursday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'friday' + e.target.defaultValue) {

      this.getValue[0].friday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'saturday' + e.target.defaultValue) {

      this.getValue[0].saturday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'sunday' + e.target.defaultValue) {

      this.getValue[0].sunday.push({
        id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else {
      e.target.parentNode.classList.remove('colorTime');

      if (e.target.id == 'monday' + e.target.defaultValue) {
        var index = this.getValue[0].monday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].monday.splice(index, 1);
      } else if (e.target.id == 'tuesday' + e.target.defaultValue) {
        var index = this.getValue[0].tuesday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].tuesday.splice(index, 1);
      } else if (e.target.id == 'wednesday' + e.target.defaultValue) {
        var index = this.getValue[0].wednesday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].wednesday.splice(index, 1);
      }
      else if (e.target.id == 'thursday' + e.target.defaultValue) {
        var index = this.getValue[0].thursday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].thursday.splice(index, 1);
      } else if (e.target.id == 'friday' + e.target.defaultValue) {
        var index = this.getValue[0].friday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].friday.splice(index, 1);
      } else if (e.target.id == 'saturday' + e.target.defaultValue) {
        console.log(e.target.id)
        var index = this.getValue[0].saturday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].saturday.splice(index, 1);
      } else if (e.target.id == 'sunday' + e.target.defaultValue) {
        var index = this.getValue[0].sunday.findIndex(el => el.id === e.target.defaultValue)
        this.getValue[0].sunday.splice(index, 1);
      }

    }

    this.wrapTime[0].monday = this.reduceArray(this.getValue[0].monday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].tuesday = this.reduceArray(this.getValue[0].tuesday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].wednesday = this.reduceArray(this.getValue[0].wednesday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].thursday = this.reduceArray(this.getValue[0].thursday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].friday = this.reduceArray(this.getValue[0].friday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].saturday = this.reduceArray(this.getValue[0].saturday.slice().sort((a, b) => a.id - b.id));
    this.wrapTime[0].sunday = this.reduceArray(this.getValue[0].sunday.slice().sort((a, b) => a.id - b.id));

    console.log(this.wrapTime)

  }

  reduceArray(arr) {
    return arr.reduce((acc, current) => {
      const lastSubArray = acc[acc.length - 1];

      if (!lastSubArray || parseInt(lastSubArray[lastSubArray.length - 1].id) !== parseInt(current.id) - 1) {
        acc.push([]);
      }

      acc[acc.length - 1].push(current);

      return acc;

    }, []);
  }




  deleteTime(e) {
    const weekArr = document.querySelectorAll(".testTime");

    weekArr.forEach((val, i) => {
      var selectedTime = e.target.parentNode.textContent.trim();
      var valTime = val.textContent;
      if (selectedTime == valTime.trim()) {
        $(val).has('#' + e.target.id).children().prop('checked', false);
        $(val).has('#' + e.target.id).removeClass('colorTime')
      }
    })

    var self = this;

    if (e.target.id == 'monday' + e.target.id.match(/(\d+)/)[0]) {

      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].monday.findIndex(el => el.time.trim() == indexFormat)

      self.getValue[0].monday.splice(index, 1);
    } else if (e.target.id == 'tuesday' + e.target.id.match(/(\d+)/)[0]) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].tuesday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].tuesday.splice(index, 1);
    } else if (e.target.id == 'wednesday' + e.target.id.match(/(\d+)/)[0]) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].wednesday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].wednesday.splice(index, 1);
    } else if (e.target.id == 'thursday' + e.target.id.match(/(\d+)/)[0]) {

      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].thursday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].thursday.splice(index, 1);
    } else if (e.target.id == 'friday' + e.target.id.match(/(\d+)/)[0]) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].friday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].friday.splice(index, 1);
    } else if (e.target.id == 'saturday' + e.target.id.match(/(\d+)/)[0]) {
      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].saturday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].saturday.splice(index, 1);
    } else if (e.target.id == 'sunday' + e.target.id.match(/(\d+)/)[0]) {

      var indexFormat = e.target.parentNode.textContent.trim()
      var index = this.getValue[0].sunday.findIndex(el => el.time.trim() == indexFormat)
      self.getValue[0].sunday.splice(index, 1);
    }
  }

  selectedSubjects = new Set<string>();
  updateValidation(e) {

    this.secondFormGroup.get('education').updateValueAndValidity();

    this.selectedSubjects.clear();
    this.subjectSelects.forEach(ls => {
      const selectedVal = ls['_value'];
      if (selectedVal) this.selectedSubjects.add(selectedVal);
    });

  }

  isSelectedSubject(sub: string) {
    return this.selectedSubjects.has(sub);
  }

  //function variables
  degreDepth;
  showDirection = true;
  filteredEducation = [];
  lastDegreOnChange(e) {
    this.filteredEducation = [];
    for (let i = parseInt(e.value) - 1; i < this.educations.length; i++) {
      this.filteredEducation.push(this.educations[i])
      console.log(this.educations[i])
    }
    this.secondFormGroup['controls'].direction.enable();
    if (e.value == "1" || e.value == "2" || e.value == "3") {
      this.secondFormGroup['controls'].direction.disable();
      // } else if (e.value == "4" || e.value == "9" || e.value == "11") {
      //   let val = e.value;
      //   this.showDirection = false;
      //   if (val == '4') {
      //     this.degreDepth = this.educations[3].education_depth;
      //   } else if (val == '9') {
      //     this.degreDepth = this.educations[8].education_depth;
      //   } else if (val == 11) {
      //     this.degreDepth = this.educations[10].education_depth;
      //   }

    } else {
      this.showDirection = true;
    }


  }

  submit() {
    this.fullPageLoader = true;

    // this.educationFormArray.forEach(element => {
    //   this.storeSubjects.subject.push({
    //     id: element.value.id,
    //     education_id: element.value.education_id,
    //     education_depth_id: element.value.education_depth_id
    //   })
    // })

    const body = this.firstFormGroup.value;
    body.date = this.datePipe.transform(body.date, 'dd/mm/yyyy');

    let subjects = {
      elements: []
    }
    this.secondFormGroup.value.education.forEach(element => {
      subjects.elements.push({
        id: element.id.replace(/[^0-9]/g, ''),
        education_id: element.education_id,
        describe: element.describe,
        nr_of_lessons: element.nr_of_lessons,
        periodicity: element.periodicity
      })
    });




    this.object3 = {
      ...this.firstFormGroup.value,
      ...this.secondFormGroup.value,
      ...subjects,
      ...this.thirdFormGroup.value,
      ...this.fourthFormGroup.value,
      ...this.getValue,
      ...body,
      ...this.storeSubjects
    };
    console.log('final object', this.object3)

    this.authService.studentRegister(this.object3).subscribe(
      data => {
        this.submitRegister = false;
        this.fullPageLoader = false;
      },
      (err) => {
        console.log(err)
        Swal.fire({
          icon: 'error',
          title: this.arrLang[this.lang].error_title,
          text: this.arrLang[this.lang].error_alert
        })
        this.fullPageLoader = false;
      }

    );
  }
}
