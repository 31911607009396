import { Component, OnInit } from '@angular/core';
import { StudentService } from 'src/app/core/_services/student.service';

import { faInfoCircle,
  faClock,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faHome,
  faChevronLeft,
  faChevronRight,
  faEnvelopeOpenText,
  faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-proposal',
  templateUrl: './dashboard-proposal.component.html',
  styleUrls: ['./dashboard-proposal.component.scss']
})
export class DashboardProposalComponent implements OnInit {

  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign
  percentRate = faPercent
  overview = faEye;
  calendarIcon = faCalendarAlt;
  faHome = faHome;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faEnvelopeOpenText = faEnvelopeOpenText;

  tutor;
  times = [
    {
      id: 1,
      status: "active",
      time: "08:00-09:00"
    },
    {
      id: 2,
      status: "active",
      time: "09:00-10:00"
    },
    {
      id: 3,
      status: "active",
      time: "10:00-11:00"
    },
    {
      id: 4,
      status: "active",
      time: "11:00-12:00"
    },
    {
      id: 5,
      status: "active",
      time: "12:00-13:00"
    },
    {
      id: 6,
      status: "active",
      time: "13:00-14:00"
    },
    {
      id: 7,
      status: "active",
      time: "14:00-15:00"
    },
    {
      id: 8,
      status: "active",
      time: "15:00-16:00"
    },
    {
      id: 9,
      status: "active",
      time: "16:00-17:00"
    },
    {
      id: 10,
      status: "active",
      time: "17:00-18:00"
    },
    {
      id: 11,
      status: "active",
      time: "18:00-19:00"
    },
    {
      id: 12,
      status: "active",
      time: "19:00-20:00"
    },
    {
      id: 13,
      status: "active",
      time: "20:00-21:00"
    },
    {
      id: 14,
      status: "active",
      time: "21:00-22:00"
    }
  ]

  constructor(private studentService: StudentService, private route: ActivatedRoute) {
    const id = this.route.snapshot.paramMap.get('id');

    this.studentService.getProposal(id).subscribe(data => {
      console.log(data)
      this.tutor = data.data
    })
  }

  ngOnInit(): void {
  }

}
