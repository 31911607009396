import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../_services/token-storage.service';
import { AuthService } from '../_services/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  user_role;
  constructor(
    private router: Router,
    private tokenStorage: TokenStorageService,
    private authService: AuthService
  ) {
    this.authService.getUser().subscribe(user => {
      this.user_role = user.data.user_type;
    })
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.tokenStorage.getUser()) {
      this.router.navigate(['/login']); // choose either default route like here
      return false;
    }
    return this.authService.getUser().pipe(map(user => {

      if (route.data.roles[0] == user.data.user_type) return true;

      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }));

  }

}
