import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


const USER_KEY = '';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  role;
  TOKEN_KEY;

  private currentUserSubject;
  public currentUser;

  constructor() {

   }

  signOut(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(this.TOKEN_KEY);
    window.localStorage.setItem(this.TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public saveUser(user): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user.user_type));
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }

  public setUserRole(type): void{
    this.role = type;
  }

  public getUserRole(){
    return this.role;
  }

}
