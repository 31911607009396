import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
// import { environment } from '../../../environments/environment'


// const baseUrl = environment.apiBaseUrl;
// const API = `${baseUrl}/public/api`
const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/${LANGUAGE}`
const REGISTER_STUDENTS = `${API}/students`;
const REGISTER_TUTOR = `${API}/teachers`;
const LOGIN = `${API}/login`;
const USER = `${API}/user`;
const RESET_PASSWORD = `${API}/password/email`;
const AFTER_LOGIN = `https://dev.api.educagrow.com/public/api/after-login`;
const RESEND_EMAIL = `${API}/email/verify/resend`;
const UPLOAD_TEACHER_IMAGE = `${API}/upload-teacher-image`;


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsImage = {

  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) { }

  login(credentials): Observable<any> {
    return this.http.post(LOGIN, {
      email: credentials.email,
      password: credentials.password
    }, httpOptions);
  }

  afterLogin(): Observable<any> {
    return this.http.get(`${AFTER_LOGIN}`)
  }

  resendEmail() {
    return this.http.post(`${RESEND_EMAIL}`, {

    })
  }

  getUser(): Observable<any> {
    return this.http.get(USER)
  }

  getUserTest() {
    return this.http.get(USER, { observe: 'response' }).subscribe(res => {
      console.log(res)
    })
  }

  studentRegister(student): Observable<any> {

    return this.http.post(REGISTER_STUDENTS, {
      name: student.firstname,
      surname: student.surname,
      email: student.email,
      password: student.password,
      password_confirmation: student.verifyPassword,
      birthdate: student.date,
      gender: student.gender,
      address: student.address,
      zip: student.zipCode,
      city: student.city,
      education_id: student.lastDegre,
      department: student.direction,
      tutoring_style_id: student.tutoringStyle,
      //nr_of_lessons_id: student.lessons,
      tutoring_place_id: student.tutoringPlaces,
      //online_platform: student.platforme,
      //distance: student.askedLocation,
      //iban_number: student.ibanReactive,
      phone: student.phone,
      //age_preference: student.tutorAge,
      //age_range: student.tutorRange,
      //prefered_gender: student.preferedGender,
      //location_preference: student.askLocation,
      //location: student.askedLocation,
      //subjects: student.education,
      subjects: student.elements,
      // "subjects": [
      //   {
      //     "id": "1",
      //     "education_id": "3",
      //     "education_depth_id": "2"
      //   }
      // ],
      times: [student[0]]


    }, httpOptions);
  }

  tutorRegister(tutor): Observable<any> {
    console.log(tutor)
    // let ibanCode = tutor.ibanReactive.replace(/\s+/g, '');
    // console.log('iban', ibanCode)
    return this.http.post(REGISTER_TUTOR, {
      name: tutor.firstname,
      surname: tutor.surname,
      email: tutor.email,
      password: tutor.password,
      password_confirmation: tutor.verifyPassword,
      birthdate: tutor.date,
      gender: tutor.gender,
      address: tutor.address,
      zip: tutor.zipCode,
      city: tutor.city,
      education_id: tutor.lastDegre,
      education_depth_id: tutor.direction_id,
      tutoring_style_id: tutor.tutoringStyle,
      tutoring_place_id: tutor.tutoringPlaces,
      department: tutor.direction,
      school: tutor.school,
      //current_work: tutor.working,
      //experience: tutor.tutoringExperience,
      skills: tutor.skills,
      online_platform: tutor.tutoringPlatform,
      subjects: tutor.elements,
      //iban_number: ibanCode,
      //image: tutor.img[0] || '',
      //cv: tutor.cvFile,
      phone: tutor.phone,
      bio: tutor.bio,
      // "subjects": [
      //   {
      //     "id": 1,
      //     "education_id": 5,
      //     "education_depth_id": 2
      //   }
      // ],
      times: [
        tutor[0]
      ],
      prices: tutor.prices[0]

    });
  }

  uploadTeacherImage(teacherId, image) {
    const jsonFormData: any = new FormData();
    jsonFormData.append('image', image)
    jsonFormData.append('teacher_id', teacherId)

    console.log('form data',jsonFormData)
    return this.http.post(UPLOAD_TEACHER_IMAGE, jsonFormData)
  }




  resetPassword(email): Observable<any> {
    console.log(email)
    return this.http.post(`${RESET_PASSWORD}`, {
      email: email,
    })
  }


}

