<div *ngIf="fullPageLoader" class="loading">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="forms" *ngIf="submitRegister; else elseBlock">
  <div class="forms__content">
    <div class="forms__content_image">

    </div>
    <div class="forms__content_form">
      <div class="container">
        <div class="student-register">
          <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="changeStepper($event)">

            <mat-step [stepControl]="firstFormGroup">
              <h1 class="form-heading">{{'LABELS.BECOME_TUTOR' | translate}}</h1>
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.PERSONAL_INFORMATION' | translate}}</ng-template>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label class="label-custom">{{'LABELS.GENDER' | translate}} </mat-label>

                        <mat-select formControlName="gender" (selectionChange)="choseGender($event)">

                          <mat-option selected value="male">
                            {{'LABELS.MALE' | translate}}
                          </mat-option>
                          <mat-option value="female">
                            {{'LABELS.FEMALE' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!--form-group-->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.PRENAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="firstname">
                      </mat-form-field>
                      <!-- <label class="form-label">Prename</label>
                      <input required type="text" class="form-input" formControlName="firstName"
                        placeholder="Enter prename here"> -->
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.SURNAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="surname">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="date-custom" appearance="fill">
                        <mat-label>{{'LABELS.DATE' | translate}}</mat-label>
                        <input class="input-date" (click)="picker.open()" matInput formControlName="date"
                          [matDatepicker]="picker" placeholder="dd/mm/yyyy" readonly>
                        <mat-error *ngIf="firstFormGroup.controls.date.invalid">{{"LABELS.DATE_ERROR" | translate}}
                        </mat-error>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <!-- <div class="col-md-5">
                    <div class="form-group">

                      <mat-form-field class="date-custom">

                        <mat-label>IBAN</mat-label>
                        <input matInput class="form-input" formControlName="ibanReactive">

                        <div class="edited-error" *ngIf="verifyIban != ''">
                          {{verifyIban}}
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="ibanCheck">
                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>Checking IBAN</p>
                        </div>
                      </mat-form-field>

                    </div>
                  </div> -->
                  <!--col-md-5-->
                  <!-- <div class="col-md-1">
                    <mat-icon mat-raised-button matTooltip="A.No one is able to withdraw money from his bank account just by having the IBAN number, on the contrary, money could only be received
                    B. IBAN number will be used for the transaction of the lectures he or she gives
                    C. Find its own IBAN number on the debit bank card."
                      aria-label="Button that displays a tooltip when focused or hovered over" matBadge="15"
                      matBadgeColor="warn">info</mat-icon>
                  </div> -->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.ADDRESS' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="address">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-2">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.ZIP_CODE' | translate}}</mat-label>
                        <input matInput maxlength="5" class="form-input" formControlName="zipCode" (keyup)="returnZip()">
                        <div class="edited-error" *ngIf="verifyZip">
                          {{"LABELS.ZIP_ERROR" | translate}}
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="zipCheck">
                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>Checking ZIP</p>
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->

                  </div>
                  <!--col-md-2-->
                  <div class="col-md-4">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.CITY' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="city">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-4-->
                </div>
                <!--row-->

                <div class="row">

                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.EMAIL' | translate}}</mat-label>
                        <input matInput type="email" class="form-input" (change)="returnEmail()"
                          formControlName="email">
                        <div class="edited-error" *ngIf="verifyEmail != ''">
                          {{verifyEmail}}
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="emailExists">
                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>{{'LABELS.CHECKING_EMIAL' | translate}}</p>
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">

                      <mat-form-field class="example-full-width country-code">
                        <mat-label>{{'LABELS.PHONE' | translate}}</mat-label>

                        <input ng2TelInput [ng2TelInputOptions]="{initialCountry: 'ch', 'onlyCountries': ['ch', 'de']}"
                          (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)" matInput
                          (intlTelInputObject)="telInputObject($event)" class="form-input" formControlName="phone">
                        <div class="edited-error" *ngIf="!numberError">
                          {{'VALIDATIONS.PHONE' | translate}}
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.PASSWORD' | translate}}</mat-label>
                        <input matInput minlength="8" type="password" #x class="form-input" formControlName="password">
                        <div class="show-password" (click)="x.type=x.type=='password'?'text':'password'">
                          {{'LOGIN_PAGE.SHOW' | translate}}</div>
                        <mat-error *ngIf="firstFormGroup.controls.password.invalid">{{'VALIDATIONS.PASSWORD' |
                          translate}}</mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.RE_PASSWORD' | translate}}</mat-label>
                        <input matInput minlength="8" type="password" #y class="form-input"
                          formControlName="verifyPassword">
                        <div class="show-password" (click)="y.type=y.type=='password'?'text':'password'">
                          {{'LOGIN_PAGE.SHOW' | translate}}</div>
                        <mat-error *ngIf="firstFormGroup.controls.verifyPassword.invalid">{{'VALIDATIONS.RE_PASSWORD' |
                          translate}}
                        </mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <p class="form-label photo-text">{{'LABELS.PHOTO_INFO' | translate}}.</p>
                    <!-- <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.BIO' | translate}}</mat-label>
                        <textarea matInput class="form-input" formControlName="bio"
                          style="height: 160px; padding: 10px;"></textarea>
                      </mat-form-field>
                    </div> -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-2 d-flex align-items-center">
                    <!-- <p class="form-label">{{'LABELS.PROFILE' | translate}}</p> -->
                    <div class="profile-pic">
                      <img [src]="base64" alt="" *ngIf="base64 != null">
                      <img src="../../assets/images/{{genderChose}}.jpg" alt="" *ngIf="base64 == null">
                    </div>
                  </div>
                  <!--col-md-5-->
                  <div class="col-md-4">
                    <div class="form-group form-group-margin"
                      style="height: 100%; display: flex; flex-direction: column; justify-content: center;">
                      <!-- <h4 class="form-label">CV(PDF document)</h4> -->
                      <label for="image-upload" class="custom-file-upload">{{'LABELS.UPDATE_PROFILE' | translate}}
                      </label>
                      <input id="image-upload" accept="image/*" type="file" (change)="fileChangeEvent($event)" />
                      <mat-error *ngIf="firstFormGroup.controls.image.invalid">{{'LABELS.FILE_ERROR' | translate}}
                      </mat-error>
                      <mat-error *ngIf="!imageSizePass">File must less than 2MB. Your file is {{imageSizeMb}}MB
                      </mat-error>

                      <p class="form-label" (click)="deleteImage()" style="color: #FB6565;" *ngIf="base64">{{'VALIDATIONS.REMOVE_PHOTO' | translate}}</p>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-3-->
                  <!-- <div class="col-md-6"><input type="file" (change)="fileChangeEvent($event)" /></div> -->

                  <ngx-photo-editor [imageQuality]='60' [imageChanedEvent]="imageChangedEvent"
                    (imageCropped)="imageCropped($event)" [aspectRatio]="4/3" [scalable]="false" [viewMode]="1"
                    [modalCentered]='true' [resizeToWidth]="500">
                  </ngx-photo-editor>


                </div>
                <!--row-->

                <div class="row">

                </div>
                <!--row-->

                <div class="form-buttons first-btn">
                  <!-- <button
                    [disabled]="firstFormGroup.invalid || verifyEmail != '' || ibanError || !numberError || verifyZip || !imageSizePass"
                    mat-button matStepperNext class="right-btn-form">
                    <img class="left-rotate"
                      [ngClass]="{'disable-cursor' : firstFormGroup.invalid || verifyEmail != '' || ibanError || !numberError || verifyZip || !imageSizePass}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button> -->
                  <button [disabled]="firstFormGroup.invalid || verifyEmail != '' || !numberError || verifyZip" mat-button
                    matStepperNext class="right-btn-form">
                    <img class="left-rotate"
                      [ngClass]="{'disable-cursor' : firstFormGroup.invalid || verifyEmail != '' || !numberError || verifyZip }"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="secondFormGroup">
              <!-- <h1 class="form-heading">{{'LABELS.BECOME_TUTOR' | translate}}</h1> -->
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.EDUCATION' | translate}}</ng-template>

                <div class="row">
                  <div class="col-md-4">
                    <!-- <h4 class="form-label">What kind of tutoring style do you prefer?</h4> -->
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.DEGREE' | translate}}</mat-label>
                        <mat-select formControlName="lastDegre" (selectionChange)="lastDegreOnChange($event)">
                          <mat-option *ngFor="let edu of educations; let i = index" value="{{edu.id}}">
                            {{edu.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-4">
                    <!-- <h4 class="form-label">What kind of tutoring style do you prefer?</h4> -->
                    <div class="form-group" *ngIf="showDirection">
                      <mat-form-field [ngClass]='{"disabled-filed": secondFormGroup.controls.direction.disabled}'>
                        <mat-label>{{'LABELS.DIRECTION' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="direction">
                      </mat-form-field>

                    </div>
                    <div class="form-group" *ngIf="!showDirection">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.DIRECTION' | translate}}</mat-label>
                        <mat-select formControlName="direction_id">
                          <mat-option *ngFor="let nestedDegree of degreDepth" value="{{nestedDegree.id}}">
                            {{nestedDegree.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--col-md-6-->

                  <div class="col-md-4">
                    <!-- <h4 class="form-label">What kind of tutoring style do you prefer?</h4> -->
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.SCHOOL' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="school">
                      </mat-form-field>
                    </div>
                  </div>
                  <!--col-md-6-->

                  <!-- <div class="col-md-6">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.WORKING' | translate}}</mat-label>
                        <input matInput class="form-input">
                      </mat-form-field>
                    </div>
                  </div> -->

                  <div class="col-md-6">
                    <!-- <div class="form-group">
                      <label for="file-upload" class="custom-file-upload">{{cvName || 'LABELS.CV' | translate}}
                      </label>
                      <input accept=".pdf,.docx" id="file-upload" (change)="addFile($event)" type="file" formControlName="file" />
                      <mat-error *ngIf="secondFormGroup.controls.file.invalid">{{'LABELS.FILE_TYPE' | translate}}</mat-error>
                    </div> -->
                    <!-- form-group -->
                  </div>
                  <!--col-md-6-->

                  <!-- <div class="col-md-12">
                    <h4 class="form-label">At what time slots are you available to give tutoring?</h4>
                    <button type="button" class="btn btn-primary times" (click)='onceClick()' data-toggle="modal" data-target="#exampleModal">
                      Select timeslots
                    </button>
                  </div> -->

                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-12">
                    <p class="form-label">
                      {{'LABELS.EDUCATION_INFO' | translate}}
                    </p>
                  </div>
                </div>

                <div class="break-form" formArrayName="education"
                  *ngFor="let education of educationFormArray; let i = index">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-6">

                      </div>
                      <!--col-md-6-->
                      <div class="col-md-6">
                        <div class="education-form remove-form" *ngIf="educationFormArray.length > 1"
                          (click)="removeEducationForm(i, selectSubject.value)">{{'LABELS.REMOVE_EDUCATION' |
                          translate}} <img src="../../assets/images/deleteform.png" alt=""> </div>
                      </div>
                    </div>
                    <!--row-->



                    <div class="row">

                      <div class="col-md-6">
                        <!-- <h4 class="form-label">For which school subjects would you like tutoring?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.SUBJECT' | translate}}</mat-label>
                            <mat-select #selectSubject formControlName="id"
                              (selectionChange)="updateValidation($event)">
                              <ng-container *ngFor="let sub of subjects">
                                <mat-option value="{{sub.id}}{{sub.name}}"
                                  *ngIf="selectSubject.value === sub.id + sub.name || !isSelectedSubject(sub.id + sub.name)">
                                  {{sub.name}}
                                </mat-option>
                              </ng-container>

                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                      <div class="col-md-6">
                        <!-- <h4 class="form-label">What level of education or school are you at?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.LEVEL' | translate}}</mat-label>
                            <mat-select formControlName="education_id" (selectionChange)="updateValidation()">
                              <mat-option *ngFor="let edu of filteredEducation; let i = index" class="test"
                                value="{{edu.id}}">
                                {{edu.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                    </div>
                    <!--row-->

                  </div>

                  <div class="row">
                    <div class="col-md-6">
                    </div>

                  </div>

                </div>
                <div class="education-form add-form" (click)="addEducaitonForm()">{{'LABELS.ADD_EDUCATION' | translate}}
                  <img src="../../assets/images/add-from.png" alt="">
                </div>

                <div class="form-buttons">
                  <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png" alt="">
                  </button>

                  <button [disabled]="secondFormGroup.invalid" mat-button matStepperNext (click)="secondFormBtn()">
                    <img class="left-rotate" [ngClass]="{'disable-cursor' : secondFormGroup.invalid}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="thirdFormGroup">
              <form [formGroup]="thirdFormGroup">
                <!-- <h1 class="form-heading">{{'LABELS.BECOME_TUTOR' | translate}}</h1> -->
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.INTERESTED' | translate}}</ng-template>

                <!-- <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>Which school subjects can you tutor?</mat-label>
                        <mat-select formControlName="subject">
                          <mat-option *ngFor="let sub of subjects" value="{{sub.name}}">
                            {{sub.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div> -->



                <!-- <div class="row">
                  <div class="col-md-6">

                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>What level of education can you tutor?</mat-label>
                        <mat-select (selectionChange)="onChange($event)" formControlName="educa">
                          <mat-option *ngFor="let edu of educations; let i = index" class="test" value="{{edu.id}}">
                            {{edu.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>Berufslehre subject</mat-label>
                        <mat-select formControlName="educaLevel">
                          <mat-option *ngFor="let eduDepth of educationsDepth" value="{{eduDepth.id}}">
                            {{eduDepth.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div> -->

                <div class="row">
                  <!-- <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.EXPERIENCE' | translate}}</mat-label>
                        <mat-select formControlName="tutoringExperience">
                          <mat-option value="1">
                            {{'LABELS.YEARS.ONE' | translate}}
                          </mat-option>
                          <mat-option value="2">
                            {{'LABELS.YEARS.TWO' | translate}}
                          </mat-option>
                          <mat-option value="3">
                            {{'LABELS.YEARS.THREE' | translate}}
                          </mat-option>
                          <mat-option value="4">
                            {{'LABELS.YEARS.FOUR' | translate}}
                          </mat-option>
                          <mat-option value="5+">
                            {{'LABELS.YEARS.MORE' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div> -->

                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.TUTORING_HOURS' | translate}}</mat-label>
                        <mat-select formControlName="tutoringPlaces" (selectionChange)="tutoringPlace($event)">
                          <mat-option *ngFor="let place of tutoringPlaces" value="{{place.id}}">
                            {{place.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>


                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.STYLE' | translate}}</mat-label>
                        <mat-select formControlName="tutoringStyle">
                          <mat-option *ngFor="let tuto of tutoringStyle" value="{{tuto.id}}" [disabled]='tuto.id == 2'>
                            {{tuto.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-md-6" *ngIf="tutoringOnline">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.PLATFORM' | translate}}</mat-label>
                        <mat-select formControlName="tutoringPlatform">
                          <mat-option value="google">
                            Google
                          </mat-option>
                          <mat-option value="zoom">
                            Zoom
                          </mat-option>
                          <mat-option value="MS Teams">
                            MS Teams
                          </mat-option>
                          <mat-option value="skype">
                            Skype
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.SKILLS' | translate}}</mat-label>
                        <textarea matInput class="form-input" formControlName="skills"
                          style="height: 160px; padding: 10px;"></textarea>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->

                  <div class="col-md-12">
                    <h4 class="form-label">{{'LABELS.TIMESLOT_INFO_TEACHER' | translate}}</h4>
                    <button type="button" class="btn btn-primary times" (click)='onceClick()' data-toggle="modal"
                      data-target="#exampleModal">
                      {{'LABELS.SELECT_TIMESLOT' | translate}}
                    </button>
                  </div>
                  <!--col-md-12-->
                </div>
                <!-- row -->

                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{'LABELS.TIME_INTERVAL' | translate}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="grid-container">
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="monday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.MONDAY' | translate}}</div>
                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="monday{{t.id}}" name="monday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="tuesday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.TUESDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="tuesday{{t.id}}" name="tuesday" value="{{t.id}}">{{t.time}}
                            </div>

                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="wednesday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.WEDNESDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="wednesday{{t.id}}" name="wednesday" value="{{t.id}}">{{t.time}}
                            </div>

                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="thursday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.THURSDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="thursday{{t.id}}" name="thursday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="friday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.FRIDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="friday{{t.id}}" name="friday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="saturday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.SATURDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="saturday{{t.id}}" name="saturday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="sunday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.SUNDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="sunday{{t.id}}" name="sunday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-time" data-dismiss="modal">{{'LABELS.DONE' |
                          translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

                <table class="timeslot-table">
                  <tr [hidden]='wrapTime[0].monday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.MON' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].monday">
                          <div *ngFor="let t of time;let last = last; let first = first">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].tuesday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.TUE' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].tuesday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].wednesday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.WED' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].wednesday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].thursday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.THU' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].thursday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].friday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.FRI' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].friday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].saturday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.SAT' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].saturday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].sunday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.SUN' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].sunday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{ t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="day-name">Sun</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime" *ngFor="let timeSlot of getValue[0].sunday">
                          {{timeSlot.time}}
                          <img id="sunday{{timeSlot.id}}" (click)="deleteTime($event)"
                            src="../../assets/images/close.png" alt="">
                        </div>
                      </div>
                    </td>
                  </tr> -->
                </table>

                <!-- <div class="row">
                  <div class="col-md-6">

                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>How many students have you tutored until now?</mat-label>
                        <mat-select formControlName="tutoredStudents">
                          <mat-option value="1">
                            1
                          </mat-option>
                          <mat-option value="2">
                            2
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">

                      <label for="file-upload" class="custom-file-upload">CV(PDF document)
                      </label>
                      <input id="file-upload" (change)="addFile($event)" type="file" formControlName="file" />
                      <mat-error *ngIf="thirdFormGroup.controls.file.invalid">File must be pdf or docx</mat-error>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-md-2">
                    <p class="form-label">Profile picture</p>
                    <div class="profile-pic">
                      <img [src]="imgURL">
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">

                      <label for="image-upload" class="custom-file-upload">Upload profile picture
                      </label>
                      <input id="image-upload"  #file accept="image/*" type="file" (change)="preview(file.files)" />
                      <mat-error *ngIf="thirdFormGroup.controls.image.invalid">File must be jpg or png</mat-error>
                      <p class="form-label" (click)="deleteImage()" style="color: #FB6565;">Remove profile picture</p>
                    </div>
                  </div>

                </div> -->

                <div class="form-buttons">
                  <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png"
                      alt=""></button>

                  <button
                  [disabled]="
                  thirdFormGroup.invalid ||
                  !(wrapTime[0].monday.length != 0 ||
                    wrapTime[0].tuesday.length != 0 ||
                    wrapTime[0].wednesday.length != 0 ||
                    wrapTime[0].thursday.length != 0 ||
                    wrapTime[0].friday.length != 0 ||
                    wrapTime[0].saturday.length != 0 ||
                    wrapTime[0].sunday.length != 0)" mat-button matStepperNext>
                    <img class="left-rotate" [ngClass]="{'disable-cursor' :
                    thirdFormGroup.invalid ||
                    !(wrapTime[0].monday.length != 0 ||
                      wrapTime[0].tuesday.length != 0 ||
                      wrapTime[0].wednesday.length != 0 ||
                      wrapTime[0].thursday.length != 0 ||
                      wrapTime[0].friday.length != 0 ||
                      wrapTime[0].saturday.length != 0 ||
                      wrapTime[0].sunday.length != 0)}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button>
                </div>
              </form>
            </mat-step>


            <mat-step [stepControl]="fourthFormGroup">


              <form [formGroup]="fourthFormGroup">
                <!-- <h1 class="form-heading">{{'TUTOR_REGISTER_FOURTH.TITLE' | translate}}</h1> -->
                <ul>
                  <li>{{'REGISTER_TUTOR_PAGE.FIRST_POINT' | translate}}</li>
                  <li>{{'REGISTER_TUTOR_PAGE.SECOND_POINT' | translate}}</li>
                  <li>{{'REGISTER_TUTOR_PAGE.THIRD_POINT' | translate}}</li>
                  <li>{{'REGISTER_TUTOR_PAGE.FOURTH_POINT' | translate}}</li>
                </ul>
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.PREFERENCES' | translate}}</ng-template>

                <mat-tab-group>
                  <mat-tab *ngFor="let tabSubject of this.tabsArrayHTML; let i = index" label="{{tabSubject}}">
                    <div class="list-group list-group-flush">
                      <div class="list-group-item">
                        <div [formGroup]="l.controls[i]" class="form-row">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="eco">
                                <h1>{{'REGISTER_TUTOR_PAGE.BRUTTO' | translate}}</h1>
                                <h1>{{'REGISTER_TUTOR_PAGE.NETTO' | translate}}</h1>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group form-group-margin">
                                <!-- <label class="form-label">Enter Pricing for 1-9 lessons</label>
                                <img class="image-input" src="../assets/images/swiss-franc.png" alt="">
                                <input required type="text" class="form-input" formControlName="lessons_1_9" placeholder="Enter Price for one lesson"> -->
                                <mat-form-field class="example-full-width">
                                  <mat-label class="fourth_group-label">{{'LABELS.ENTER_PRICE' | translate}} 1-9
                                    {{'LABELS.FOR_LESSONS' | translate}}</mat-label>
                                  <input matInput class="form-input fourth_group-input" type="number" pattern="^[0-9]*$"
                                    formControlName="lessons_0" #first>
                                  <div class="nr-percentage"> -{{this.percentage[0]?.percentage}}%</div>
                                  <div class="neto">{{first.value - (first.value / 100) * this.percentage[0]?.percentage | number : '1.2-2'}} CHF
                                  </div>
                                </mat-form-field>

                                <input matInput class="form-input" type="number" pattern="^[0-9]*$"
                                  formControlName="subject_id" style="display: none;">

                                <div></div>
                              </div><!-- form-group -->
                            </div>
                            <!--col-md-7-->
                            <div class="col-md-12">
                              <div class="form-group form-group-margin">
                                <mat-form-field class="example-full-width">
                                  <mat-label>{{'LABELS.ENTER_PRICE' | translate}} 10-14 {{'LABELS.FOR_LESSONS' |
                                    translate}}</mat-label>

                                  <input matInput class="form-input fourth_group-input" type="number" pattern="^[0-9]*$"
                                    formControlName="lessons_1" #second>
                                  <div class="nr-percentage">-{{this.percentage[1]?.percentage}}%</div>
                                  <div class="neto">{{second.value - (second.value / 100) * this.percentage[0]?.percentage | number : '1.2-2'}}
                                    CHF</div>
                                </mat-form-field>
                                <input matInput class="form-input" type="number" pattern="^[0-9]*$"
                                  formControlName="subject_id" style="display: none;">
                              </div><!-- form-group -->
                            </div>
                            <!--col-md-7-->
                            <div class="col-md-12">
                              <div class="form-group form-group-margin">
                                <mat-form-field class="example-full-width">
                                  <mat-label>{{'LABELS.ENTER_PRICE' | translate}} 15-19 {{'LABELS.FOR_LESSONS' |
                                    translate}}</mat-label>

                                  <input matInput class="form-input fourth_group-input" type="number" pattern="^[0-9]*$"
                                    formControlName="lessons_2" #third>
                                  <div class="nr-percentage">-{{this.percentage[2]?.percentage}}%</div>
                                  <div class="neto">{{third.value - (third.value / 100) * this.percentage[2]?.percentage | number : '1.2-2'}} CHF
                                  </div>
                                </mat-form-field>
                                <input matInput class="form-input" type="number" pattern="^[0-9]*$"
                                  formControlName="subject_id" style="display: none;">
                              </div><!-- form-group -->
                            </div>
                            <!--col-md-7-->
                            <div class="col-md-12">
                              <div class="form-group form-group-margin">
                                <mat-form-field class="example-full-width">
                                  <mat-label>{{'LABELS.ENTER_PRICE' | translate}} 20-24 {{'LABELS.FOR_LESSONS' |
                                    translate}}</mat-label>

                                  <input matInput class="form-input fourth_group-input" type="number" pattern="^[0-9]*$"
                                    formControlName="lessons_3" #fourth>
                                  <div class="nr-percentage">-{{this.percentage[3]?.percentage}}%</div>
                                  <div class="neto">{{fourth.value - (fourth.value / 100) * this.percentage[3]?.percentage | number : '1.2-2'}}
                                    CHF</div>
                                </mat-form-field>
                                <input matInput class="form-input" type="number" pattern="^[0-9]*$"
                                  formControlName="subject_id" style="display: none;">
                              </div><!-- form-group -->
                            </div>
                            <!--col-md-7-->
                            <div class="col-md-12">
                              <div class="form-group form-group-margin">
                                <mat-form-field class="example-full-width">
                                  <mat-label>{{'LABELS.ENTER_PRICE' | translate}} 25 or more {{'LABELS.FOR_LESSONS' |
                                    translate}}</mat-label>

                                  <input matInput class="form-input fourth_group-input" type="number" pattern="^[0-9]*$"
                                    formControlName="lessons_4" #fifth>
                                  <div class="nr-percentage">-{{this.percentage[4]?.percentage}}%</div>
                                  <div class="neto">{{fifth.value - (fifth.value / 100) * this.percentage[4]?.percentage | number : '1.2-2'}} CHF
                                  </div>
                                </mat-form-field>
                                <input matInput class="form-input" type="number" pattern="^[0-9]*$"
                                  formControlName="subject_id" style="display: none;">
                              </div><!-- form-group -->
                            </div>
                            <!--col-md-7-->
                          </div>
                          <!--row-->
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>



              </form>

              <div class="form-buttons">
                <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png"
                    alt=""></button>

                <button class="btn btn-primary btn-submit" [disabled]="fourthFormGroup.invalid" mat-button
                  (click)="submit()">
                  {{'LABELS.SUBMIT' | translate}}
                </button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>

        </div>
        <!--login-->
      </div><!-- container -->
    </div>
  </div><!-- forms__content -->
</div><!-- forms -->
<ng-template #elseBlock>
  <div class="container">
    <div class="after-register">
      <img src="../assets/images/save.png" alt="">
      <h1>{{'REGISTRATION.TITLE' | translate}}</h1>
      <p>{{'REGISTRATION.PARAGRAPH_TUTOR' | translate}}</p>
      <a href="#">{{'REGISTRATION.LINK' | translate}}</a>
    </div>
  </div>
  <!-- <div class="bottom-text">
    <p>Didn’t receive an email? Click <a href="#">here</a> to re-send the email.</p>
  </div> -->
</ng-template>
