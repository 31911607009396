import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/core/_services/auth.service';
import { HomeService } from 'src/app/core/_services/home.service';
import { StudentService } from 'src/app/core/_services/student.service';
import {
  faInfoCircle,
  faClock,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faHome,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faEnvelopeOpenText
} from '@fortawesome/free-solid-svg-icons';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})


export class DemoComponent implements OnInit {

  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign
  percentRate = faPercent
  overview = faEye;
  calendarIcon = faCalendarAlt;
  faHome = faHome;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faEnvelopeOpenText = faEnvelopeOpenText;

  sub

  tutor;

  bookingFormGroup;

  times = [
    {
      id: 1,
      status: "active",
      time: "08:00-09:00"
    },
    {
      id: 2,
      status: "active",
      time: "09:00-10:00"
    },
    {
      id: 3,
      status: "active",
      time: "10:00-11:00"
    },
    {
      id: 4,
      status: "active",
      time: "11:00-12:00"
    },
    {
      id: 5,
      status: "active",
      time: "12:00-13:00"
    },
    {
      id: 6,
      status: "active",
      time: "13:00-14:00"
    },
    {
      id: 7,
      status: "active",
      time: "14:00-15:00"
    },
    {
      id: 8,
      status: "active",
      time: "15:00-16:00"
    },
    {
      id: 9,
      status: "active",
      time: "16:00-17:00"
    },
    {
      id: 10,
      status: "active",
      time: "17:00-18:00"
    },
    {
      id: 11,
      status: "active",
      time: "18:00-19:00"
    },
    {
      id: 12,
      status: "active",
      time: "19:00-20:00"
    },
    {
      id: 13,
      status: "active",
      time: "20:00-21:00"
    },
    {
      id: 14,
      status: "active",
      time: "21:00-22:00"
    }
  ]

  periodicityData = {
    periodicityTime: [],
    sortedByDay: []
  };

  subjectId;

  constructor(private studentService: StudentService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    //private datePipe: DatePipe,
    private homeService: HomeService,
    private authService: AuthService) {
    const id = this.route.snapshot.paramMap.get('id');

    this.route.queryParamMap.subscribe(data => {
      this.subjectId = data['params'].subject_id;
    })

    this.homeService.getSearchTutor(id).subscribe(data => {
      console.log('new', data)
      this.tutor = data['data'];

      this.tutor.times.forEach(element => {
        if (element.pivot_teacher.day == 'monday') {

          if (element.pivot_teacher.availability == 'active') {
            $(`#monday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#monday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }

        }
        else if (element.pivot_teacher.day == 'tuesday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#tuesday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#tuesday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }
        else if (element.pivot_teacher.day == 'wednesday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#wednesday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#wednesday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }
        else if (element.pivot_teacher.day == 'thursday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#thursday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#thursday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }
        else if (element.pivot_teacher.day == 'friday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#friday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#friday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }
        else if (element.pivot_teacher.day == 'saturday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#saturday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#saturday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }
        else if (element.pivot_teacher.day == 'sunday') {
          if (element.pivot_teacher.availability == 'active') {
            $(`#sunday${element.pivot_teacher.time_id}`).prop('checked', true);
            $(`#sunday${element.pivot_teacher.time_id}`).parent().addClass('colorTime');
          }
        }

      });

    })

  }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      console.log('data', data)
    })

    this.bookingFormGroup = this.formBuilder.group({
      starting_date: ['', Validators.required],
      starting_day: ['', Validators.required]
    });
  }








  getNextDay(dayName) {


    // The current day
    let date = new Date();
    let now = date.getDay();
    let optionsFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    // Days of the week
    let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    // The index for the day you want
    let day = days.indexOf(dayName.toLowerCase());

    // Find the difference between the current day and the one you want
    // If it's the same day as today (or a negative number), jump to the next week
    let diff = day - now;
    diff = diff < 2 ? 7 + diff : diff;

    // Get the timestamp for the desired day
    let nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);


    // Get the next day
    return new Date(nextDayTimestamp).toLocaleDateString("en-US");




  }

  nextDates = [];
  getNextDates(e) {
    console.log(e)
    let choosedDate = new Date(this.getNextDay(e.value));
    let daySum = 0;
    for (let i = 0; i < 7; i++) {
      this.nextDates[i] = (new Date(choosedDate.valueOf() + daySum * 24 * 60 * 60 * 1000))
      daySum += 7;
    }

    console.log(this.nextDates)
  }

}
