import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-group-tutoring',
  templateUrl: './group-tutoring.component.html',
  styleUrls: ['./group-tutoring.component.scss']
})
export class GroupTutoringComponent implements OnInit, AfterViewInit {

  faBars = faBars;

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;

  currentYear;

  listLang = [
    { text: '', flag: '../assets/images/flags/us.jpg', lang: 'en' },
    { text: '', flag: '../assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: '../assets/images/flags/italy.jpg', lang: 'it' },
  ]

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {

    this.currentYear = new Date().getFullYear();

    this.cookieValue = localStorage.getItem('lang') || 'de';
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  ngAfterViewInit(){

    $(window).on('scroll', function () {

      if ($(window).scrollTop() > 40) {
        $(".sticky").addClass('fix-header animated fadeInDown');
        if ($(window).width() > 991) {
          $(".navbar-nav li a").css('color', '#000')
        }
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')

      } else {
        $(".sticky").removeClass('fix-header animated fadeInDown');
        $(".navbar-nav li a").css('color', '#000')
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')
      }

    });

    if ($(window).width() < 991) {
      console.log('called')
      $('.navbar-toggler').on('click', function (e) {

        e.stopPropagation();
        $('.navbar-collapse').stop(true, true).slideToggle();
        e.preventDefault();
      });

      $('.navbar-nav li.menu-item-has-children').each(function () {
        var $this = $(this);
        $this.append('<span class="submenu-toggler"><i class="fal fa-plus"></i></span>');
      });

      $('.navbar-nav li.menu-item-has-children span.submenu-toggler').on('click', function () {
        var $this = $(this);

        if ($(this).hasClass('active-span')) {
          $('i', $this).removeClass('fa-minus').addClass('fa-plus');
        } else {
          $('i', $this).addClass('fa-minus').removeClass('fa-plus');
        }

        //$(this).prev('ul.sub-menu, .mega-menu').stop(true, true).slideToggle();
        $(this).toggleClass('active-span');
      });
    };

  }


  becomeStudent(){
    window.localStorage.setItem('route', '/')
  }
  becomeTutor(){
    localStorage.setItem('route', 'tutor')
  }


  contactModal(contactInfo) {
    this.modalService.open(contactInfo, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

    }, (reason) => {

    });
  }

}
