import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/_services/auth.service';
import { TokenStorageService } from './core/_services/token-storage.service';
import { HomeService } from './core/_services/home.service';
import { RegisterFormsService } from './core/_services/register-forms.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class AppComponent implements OnInit {
  title = 'educaweb-test';
  isLoggedIn = false;

  //public languages: string[] = ['en', 'de', 'it'];

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private registerFormService: RegisterFormsService,
    private authService: AuthService,
    private window: Window
  ) {

    console.log(window)

    window['Tawk_API'] = window['Tawk_API'] || {};

    if(window['Tawk_API']){
      window['Tawk_API'].onBeforeLoad = function () {
        //place your code here
        console.log('window.Tawk_API.isChatHidden()', window['Tawk_API'].isChatHidden())
        window['Tawk_API'].hideWidget();
      };

    }


    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }

    // let browserLang;
    // this.translateService.addLangs(this.languages);
    // if (localStorage.getItem('lang')) {
    //   browserLang = localStorage.getItem('lang');
    // } else {
    //   browserLang = translateService.getBrowserLang();
    // }
    // translateService.use(browserLang.match(/en|de|it/) ? browserLang : 'en');

    // this.registerFormService.languageRequest().subscribe(data =>{
    //   console.log(data)
    // })

    // this.authService.afterLogin().subscribe(data => {

    //   console.log('after login ', data);
    //   if (data == null) {
    //     this.router.navigate(['/verify'])
    //   }
    // })


  }


  ngOnInit(): void {

    this.translateService.setDefaultLang('de')
    this.translateService.use(localStorage.getItem('lang') || 'de')

    // this.isLoggedIn = !!this.tokenStorage.getToken();
    // if(this.isLoggedIn){
    //   this.router.navigate([`/${this.tokenStorage.getUser().user_type}/dashboard`]);
    // }else{
    //   this.router.navigate(['/']);
    // }
  }

  // onTidioChatApiReady() {
  //   // Code after chat loaded
  //   if (this.router.url == '/') {
  //     window['tidioChatApi'].hide();
  //   }
  // }


}


