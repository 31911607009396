import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/core/_services/admin/admin.service';
import { AuthService } from 'src/app/core/_services/auth.service';
import { TokenStorageService } from 'src/app/core/_services/token-storage.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponentAdmin implements OnInit, AfterContentInit {
  status: boolean = false;

  user;
  admins;

  isAdmin = false;
  isManager = false;
  isEditor = false;

  constructor(private tokenStorage: TokenStorageService,
    private router: Router, private authService: AuthService,
    private adminService: AdminService) {

    this.authService.getUser().subscribe(data => {
      console.log('user login',data)
      this.user = data.data;
    })




  }

  ngOnInit(): void {


  }

  ngAfterContentInit(){
    this.adminService.getAdmins().subscribe(data => {
      console.log('admins',data)
      this.admins = data;
      this.admins.forEach(admin => {
        if(admin.user_type_id == this.user.user_type_id){
          admin.roles.forEach(role => {
            if(role.id == 1){
              this.isAdmin = true;

            }else if(role.id == 2){

              this.isManager = true;

            }else if(role.id == 3){

              this.isEditor = true;
            }
          });
        }
      });
    })

  }

  headerToggle() {
    this.status = !this.status;
  }

  sendToPayoutes() {
    this.router.navigate([`admin/payouts/`], { queryParams: { id: this.user.id } })

  }


  logOut() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login'])
  }




}
