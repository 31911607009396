import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/${LANGUAGE}`


const PROPOSALS = `${API}/proposals`;
const STUDENT = `${API}/students`;
const HISTORICAL_ENGAGEMENTS = `${API}/engagements/historical`;
const CURRENT_ENGAGEMENTS = `${API}/engagements/current`;
const GOALS = `${API}/overview/goals`;
const STUDENTS = `${API}/overview/my-students`;
const ENGAGEMENTS_DROPLIST = `${API}/engagements`;
const RESCHEDULE_LESSON = `${API}/engagements/current`;
const SEND_GOAL = `${API}/calendar/goals`;
const CALENDAR = `${API}/calendar`;
const APPOINTMENT = `${API}/calendar/appointment`;
const CALENDAR_DATA = `${API}/calendar/data`;
const RESCHEDULED_LESSONS = `${API}/rescheduled-lessons`;
const RESCH_TIMES = `${API}/get-times`;
const TIME_AVAILABILITY = `${API}/availability`;
const TIMES_API = `${API}/times`;
const SHOW_EXTEND_LESSONS = `${API}/extend-lessons`
//const NEW_RESCH = `${API}/rescheduled-lessons/student`;
const UPDATE_PROFILE = `${API}/account-settings`;
const UPDATE_PASSWORD = `${API}/change-password`;
const UPDATE_MEDIA = `${API}/update-media`;
const EARNINGS_PER_WEEK = `${API}/overview/earnings-per-week`;
const EARNINGS_ALL_TIME = `${API}/overview/earnings-all-time`;
const TEACHER_SUBJECT = `${API}/overview/teacher-subjects`;
const AVERAGE_PRICE_LESSON = `${API}/overview/average-price-lesson`;
const TEACHER_SUBJECT_PRICES = `${API}/overview/teacher-subject-prices`
const CHANGE_PRICES = `${API}/overview/change-prices`;
const WALLET = `${API}/overview/wallet`;
const LESSONS_BY_CATEGORY = `${API}/overview/lessons-by-category`;
const GET_SUBJECTS = `${API}/teacher/get-subjects`;
const AUTH_SUBJECTS = `${API}/auth-subjects`;
const ADD_SUBJECT = `${API}/teacher/add-subject`;
const REMOVE_SUBJECT = `${API}/teacher/delete-subject`;
const INVOICES = `${API}/teacher-invoices`;
const CREATE_INVOICE = `${API}/create-pdf`;
const UPCOMING_INVOICES = `${API}/teacher-upcomming-invoice`;
const CARD_INFO = `${API}/teacher/card-info`;
const QUESTIONS = `${API}/questions`;
const CHATS = `${API}/chats`;
const DELETE_MEDIA = `${API}/delete-media`;
const MATCHED_STUDENTS = `${API}/matched-students`;
const SEND_POKE = `${API}/send-poke`;
const MATCHED_TIMES = `${API}/matched-times`;
const NOTIFICATIONS = `${API}/notifications`;
const SEEN = `${API}/seen`;
const MARK_ALL_SEEN = `${API}/mark-all-seen`;
const DEACTIVATE_ACCOUNT = `${API}/deactivate-account`;
const GET_PERCENTAGE = `${API}/get-percentage`;


@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(private http: HttpClient) { }

  getGoals(): Observable<any> {
    return this.http.get(`${GOALS}`)
  }

  getStudentInfo(id): Observable<any> {
    return this.http.get(`${STUDENT}/${id}`)
  }

  //Time list
  getTimes(): Observable<any> {
    return this.http.get(TIMES_API);
  }

  getTimeAvailability(): Observable<any> {
    return this.http.get(TIME_AVAILABILITY)
  }

  saveTimeAvailability(editedTime): Observable<any> {
    console.log('time edited',editedTime)
    return this.http.post(`${TIME_AVAILABILITY}`, editedTime)
  }

  getEvents(): Observable<any> {
    return this.http.get(`${CALENDAR}`)
  }

  getCalendarData(): Observable<any> {
    return this.http.get(CALENDAR_DATA);
  }

  getRescheduledLessons(): Observable<any> {
    return this.http.get(RESCHEDULED_LESSONS);
  }
  removeGoal(id): Observable<any> {
    return this.http.delete(`${SEND_GOAL}/${id}`)
  }

  calendarAppointment(): Observable<any> {

    return this.http.get(`${APPOINTMENT}`)
  }

  calendarAppointmentT(appointmentData): Observable<any> {
    return this.http.get(`${APPOINTMENT}?teacher=${appointmentData.teacher_id}`)
  }

  calendarAppointmentD(appointmentData): Observable<any> {
    return this.http.get(`${APPOINTMENT}?teacher=${appointmentData.teacher_id}&date=${appointmentData.date}`)
  }

  sendGoal(goal): Observable<any> {
    return this.http.post(`${SEND_GOAL}`, {
      title: goal.title,
      time: goal.time,
      description: goal.description,
      teacher: goal.teacher,
      subject: goal.subject,
      tutoring_place: goal.tutoring_place
    })
  }

  sendAppointmentCalendar(appProposal): Observable<any> {
    console.log(appProposal)
    return this.http.post(`${APPOINTMENT}`, {
      teacher_id: appProposal.teacher_id,
      teacher_email: appProposal.email,
      subject_id: appProposal.subject_id,
      date: appProposal.date,
      tutoring_place_id: appProposal.tutoring_place,
      times: appProposal.times
    });
  }

  getStudents(): Observable<any> {
    return this.http.get(`${STUDENTS}`)
  }

  getStudent(studentId): Observable<any> {
    return this.http.get(`${STUDENTS}/${studentId}`)
  }

  getLessonsByCategory(): Observable<any> {
    return this.http.get(`${LESSONS_BY_CATEGORY}`);
  }

  getProposals(): Observable<any> {
    return this.http.get(`${PROPOSALS}`)
  }

  getProposal(id): Observable<any>{
    return this.http.get(`${PROPOSALS}/${id}`)
  }

  updateProposal(proposal, status, timeslot) {
    return this.http.put<any>(`${PROPOSALS}/${proposal.id}`, {
      status: status,
      days: timeslot,
      student_email: proposal.student.email
    })
  }

  proposalMessage(proposal, message){
    return this.http.post<any>(`${PROPOSALS}/${proposal.id}`, {
      message: message
    })
  }

  initTeacherProposal(student_id, subject_id, timeslot){
    return this.http.post(`${PROPOSALS}`, {
      student_id: student_id,
      subject_id: subject_id,
      days: timeslot,
      message: 'init proposal from teacher'
    })
  }

  deleteMedia(){
    return this.http.delete(DELETE_MEDIA);
  }


  getHistoricalEngagements(): Observable<any> {
    return this.http.get(`${HISTORICAL_ENGAGEMENTS}`)
  }

  getCurrentEngagements(): Observable<any> {
    return this.http.get(`${CURRENT_ENGAGEMENTS}`)
  }

  currentEngagementsFilter(params) {
    return this.http.get(`${CURRENT_ENGAGEMENTS}`, { params: params })
  }
  historicalEngagementsFilter(params) {
    return this.http.get(`${CURRENT_ENGAGEMENTS}`, { params: params })
  }

  engagementsDropList() {
    return this.http.get(`${ENGAGEMENTS_DROPLIST}`)
  }

  getReschTime(date, teacherReschId) {
    return this.http.get(`${RESCH_TIMES}?date=${date}&teacher_id=${teacherReschId}`)
  }

  // reschedule from engagements
  rescheduleLesson(id, time, date, comment) {
    console.log(id, time, date, comment)
    return this.http.post(`${RESCHEDULE_LESSON}/${id}`, {
      resch_date: date,
      time_id: time.time[0].time_id,
      comment: comment.value.comment
    })
  }


  // reschedule from dashboard
  newReschAppoint(rescheduledObject, time) {
    console.log('back', rescheduledObject, time)
    return this.http.put(`${RESCHEDULED_LESSONS}/${rescheduledObject.created_by}/${rescheduledObject.proposedId}`, {
      status: "pending",
      date: rescheduledObject.teacherReschDate,
      time_id: time.time[0].time_id,
      comment: rescheduledObject.comment
    })
  }

  // accept or cancel the reschedule
  updateReschLesson(lesson, status) {
    return this.http.put<any>(`${RESCHEDULED_LESSONS}/${lesson.created_by}/${lesson.id}`, {
      status: status
    })
  }

  //get all extended lessons
  showExtendLessons() {
    return this.http.get(`${SHOW_EXTEND_LESSONS}`);
  }

  //accept or cancel extended lessons
  extendedLesson(id, status) {
    return this.http.put(`${SHOW_EXTEND_LESSONS}/${id}`, {
      status: status
    })
  }



  updateProfile(profile) {

    return this.http.put(`${UPDATE_PROFILE}`, {
      name: profile.value.firstname,
      surname: profile.value.surname,
      phone: profile.value.phone,
      gender: profile.value.gender,
      birthdate: profile.value.date,
      address: profile.value.streetName,
      zip: profile.value.zipCode,
      city: profile.value.location,
      tutoring_place_id: profile.value.tutoringPlace,
      tutoring_style_id: profile.value.tutoringStyle,
      skills: profile.value.bio,
    })
  }

  getProfile() {
    return this.http.get(`${UPDATE_PROFILE}`)
  }

  updatePassword(pass) {
    return this.http.put(`${UPDATE_PASSWORD}`, {
      current_password: pass.value.oldPass,
      new_password: pass.value.newPass,
      new_confirm_password: pass.value.confPass
    })
  }
  updateMedia(image) {
    return this.http.post(`${UPDATE_MEDIA}`, image)
  }

  teacherSubject() {
    return this.http.get(`${TEACHER_SUBJECT}`);
  }

  earningsPerWeek(subjectId) {
    return this.http.get(`${EARNINGS_PER_WEEK}?subject=${subjectId}`)
  }

  earningsAllTime(subjectId) {
    return this.http.get(`${EARNINGS_ALL_TIME}?subject=${subjectId}`)
  }

  averagePriceLesson(subjectId, lesson_nr) {
    return this.http.get(`${AVERAGE_PRICE_LESSON}?subject=${subjectId}&lessons_nr=${lesson_nr}`)
  }

  teacherSubjectPrices(subjectId) {
    return this.http.get(`${TEACHER_SUBJECT_PRICES}?subject=${subjectId}`)
  }

  changePrices(prices) {
    return this.http.put(`${CHANGE_PRICES}`, {
      subject: prices.subjectId,
      prices: [
        {
          id: prices.prices[0].id,
          price: prices.prices[0].price
        },
        {
          id: prices.prices[1].id,
          price: prices.prices[1].price
        },
        {
          id: prices.prices[2].id,
          price: prices.prices[2].price
        },
        {
          id: prices.prices[3].id,
          price: prices.prices[3].price
        },
        {
          id: prices.prices[4].id,
          price: prices.prices[4].price
        }
      ]
    })
  }

  wallet(subjectId) {
    return this.http.get(`${WALLET}?subject=${subjectId}`)
  }

  getSubjects() {
    return this.http.get(`${GET_SUBJECTS}`);
  }

  authSubjectsList() {
    return this.http.get(`${AUTH_SUBJECTS}`)
  }

  addSubjects(subjectForm) {
    return this.http.post(`${ADD_SUBJECT}`, {
      subject: subjectForm.value.subject,
      education: subjectForm.value.education,
      prices: [
        {
          nr_of_lessons_id: 1,
          price: subjectForm.value.lesson_1
        },
        {
          nr_of_lessons_id: 2,
          price: subjectForm.value.lesson_2
        },
        {
          nr_of_lessons_id: 3,
          price: subjectForm.value.lesson_3
        },
        {
          nr_of_lessons_id: 4,
          price: subjectForm.value.lesson_4
        },
        {
          nr_of_lessons_id: 5,
          price: subjectForm.value.lesson_5
        }
      ]
    })
  }

  removeSubject(id) {
    return this.http.delete(`${REMOVE_SUBJECT}?subject_id=${id}`)
  }

  teacherInvoices() {
    return this.http.get(`${INVOICES}`);
  }

  createInvoice(teacherId) {
    return this.http.get(`${CREATE_INVOICE}`, { responseType: 'json' })
  }

  upcomingInvoices() {
    return this.http.get(`${UPCOMING_INVOICES}`);
  }

  cardInfo(formValue) {

    let ibanCode = formValue.value.iban.replace(/\s+/g, '');

    return this.http.put(`${CARD_INFO}`, {
      iban_number: ibanCode,
      card_holder: formValue.value.name,
      address: formValue.value.address,
      zip: formValue.value.zip,
      city: formValue.value.city
    });
  }

  questions(contactForm, user){
    return this.http.post(`${QUESTIONS}`, {
      user_id: user,
      title: contactForm.value.topic,
      description: contactForm.value.description
    })
  }


  getChats(){
    return this.http.get(CHATS);
  }

  userChat(id){
    return this.http.get(`${CHATS}/${id}`)
  }


  sendChat(id, sms, file){
    const fd = new FormData();
    fd.append('body', sms)

    if(file){
      fd.append('file', file, file.name)
    }


    return this.http.post(`${CHATS}/${id}`,fd)
  }

  matchedStudents(): Observable<any>{
    return this.http.get(MATCHED_STUDENTS);
  }

  // matchedStudent(id): Observable<any>{
  //   return this.http.get(`${MATCHED_STUDENTS}/${id}`);
  // }

  subjectFilterPoke(id, pokeValue): Observable<any>{
    console.log(id, pokeValue)
    let subjectProp = '';

    if(id != ''){
      subjectProp =  `subject=${id}`
    } else {

    }


    return this.http.get(`${MATCHED_STUDENTS}?${subjectProp}&poke=${pokeValue}`)
  }

  sendPoke(student_id, subject_id){
    return this.http.post(`${SEND_POKE}`, {
      student_id: student_id,
      subject: subject_id
    })
  }

  getMatchedTimes(studentId, teacherId){
    return this.http.get(`${MATCHED_TIMES}?student=${studentId}&teacher=${teacherId}`)
  }

  getNotifications(){
    return this.http.get(`${NOTIFICATIONS}`);
  }

  getSeenNotification(notificationId){
    return this.http.post(`${SEEN}`,{
      id: notificationId
    });
  }

  getMarkAllSeen(){
    return this.http.post(`${MARK_ALL_SEEN}`,{
    });
  }

  deactivateAccount(){
    return this.http.post(`${DEACTIVATE_ACCOUNT}`,{})
  }

  getPercentage(){
    return this.http.get(`${GET_PERCENTAGE}`);
  }
}
