import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { DashboardComponent } from './student/dashboard/dashboard.component';
import { NavbarComponent } from './student/navbar/navbar.component';
import { NavbarComponentTutor } from './tutor/navbar/navbar.component';
import { TutorRegisterComponent } from './tutor-register/tutor-register.component';
import { NavbarComponentAdmin } from './admin/navbar/navbar.component';
import { AuthGuard } from './core/guards/auth.guard';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DemoComponent } from './home/demo/demo.component';
import { AboutComponent } from './home/about/about.component';
import { TutorComponent } from './home/tutor/tutor.component';
import { GroupTutoringComponent } from './home/group-tutoring/group-tutoring.component';
import { DetailsGroupTutoringComponent } from './home/group-tutoring/details-group-tutoring/details-group-tutoring.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'demo/:id',
    component: DemoComponent, // child route component that the router renders
  },
  {
    path: 'impressum',
    component: ImpressumComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'tutor',
    component: TutorComponent
  },
  {
    path: 'group-tutoring',
    component: GroupTutoringComponent
  },
  {
    path: 'details-group-tutoring',
    component: DetailsGroupTutoringComponent
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'verify',
    component: VerifyEmailComponent,
  },
  {
    path: 'student/register',
    component: StudentRegisterComponent,
  },
  {
    path: 'tutor/register',
    component: TutorRegisterComponent,
  },
  // {
  //   path: 'student/dashboard',
  //   component: DashboardComponent,
  // },
  // tslint:disable-next-line: max-line-length
  { path: 'student', component: NavbarComponent, loadChildren: () => import('./student/student.module').then(m => m.StudentModule), canActivate: [AuthGuard], data: { roles: ['student']} },
  { path: 'teacher', component: NavbarComponentTutor, loadChildren: () => import('./tutor/tutor.module').then(m => m.TutorModule), canActivate: [AuthGuard], data: { roles: ['teacher']} },
  { path: 'admin', component: NavbarComponentAdmin, loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),  canActivate: [AuthGuard], data: { roles: ['admin']} },
  {
    path: '**', redirectTo: '/', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
