import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentService } from 'src/app/core/_services/student.service';
import { ChatMessage, ChatUser } from './chat.model';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NgbModal, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { chatData, chatMessagesData } from './data';
import { throttleTime } from 'rxjs/operators';
import {
  faCommentDots,
  faEnvelope,
  faImages,
  faPaperclip,
  faUser,
  faChevronLeft,
  faTimes,
  faUserAlt,
  faGlobe,
  faPlay,
  faPaperPlane
} from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/core/_services/auth.service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})
export class MessengerComponent implements OnInit {
  @ViewChild('chatPS') chatPS: PerfectScrollbarComponent;
  @ViewChild('intro', { read: TemplateRef }) private introModal: TemplateRef<any>;


  chatMessagesData;
  chatData: ChatUser[];

  formData: FormGroup;

  // Form submit
  chatSubmit: boolean;

  username: string;
  usermessage: string;
  status: string;
  image: string;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  toggleClassList;

  faEnvelope = faEnvelope;
  faPaperclip = faPaperclip;
  faImages = faImages;
  faCommentDots = faCommentDots;
  faUser = faUser;
  faChevronLeft = faChevronLeft;
  faTimes = faTimes;
  faUserAlt = faUserAlt;
  faGlobe = faGlobe;
  faPlay = faPlay;
  faPaperPlane = faPaperPlane;



  navClass = false;

  displayUsers = true;
  displayMessage = false;

  desktop = false;

  accountSettings = true;
  showMessage = false;
  navInfo = true;

  howItWorksOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;	'],
    margin: 20,
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4,
      }
    },
    nav: false
  }

  user;

  constructor(public formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private authService : AuthService,
    private studentService: StudentService) {

    this.authService.getUser().subscribe(data => {
      console.log('user',data)
      this.user = data.data;
    })


    this.studentService.getChats().subscribe(data => {
      console.log('chats', data)
      this.chatData = data['data']
      this.displayChat(this.chatData[0].id)
    })
  }

  ngOnInit(): void {

    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    this._fethchData();
    // this.username = 'Frank Vickery';
    // this.status = 'online';

    if (window.screen.width > 992) {
      this.displayUsers = true;
    } else {
      this.desktop = false;
    }

    $(".nav-link").on('click', function () {
      $('.side-menu li a').removeClass('active')
      $(this).addClass('active');

    });


  }


  private _fethchData() {
    // this.chatData = chatData;
    // this.chatMessagesData = chatMessagesData;

  }


  showUsers() {
    this.displayUsers = true;
    //this.displayMessage = false;
  }

  // showMessage(){
  //   //this.displayMessage = true;
  //   this.displayUsers = false;
  //   // if (window.screen.width < 992) {
  //   //   this.displayUsers = false;
  //   // }
  // }



  // chatUsername(name, image, status, sms) {
  //   this.username = name;
  //   this.image = image;
  //   this.usermessage = sms;
  //   this.chatMessagesData = [];
  //   const currentDate = new Date();
  //   this.status = status;

  //   this.chatMessagesData.push({
  //     image: this.image,
  //     name: this.username,
  //     message: this.usermessage,
  //     time: currentDate.getHours() + ':' + currentDate.getMinutes(),
  //     status: this.status
  //   });
  // }


  lastId
  chatUser(id) {
    // if(this.lastId == id){
    //   return false;
    // }else{
    // }
    // this.lastId = id;

    this.toggleClassList = id;


    this.studentService.userChat(id).subscribe(data => {
      console.log('chatMessagesData',data);
      this.chatMessagesData = data['data'];
      this.username = this.chatMessagesData['teacher'].name;

      setTimeout(() => {
        this.chatPS.directiveRef.scrollToBottom(-100, 500)
        this.cdr.detectChanges()
        // this.studentService.lastSeen(id).subscribe(data => {
        //   console.log('put', data);
        // })
      }, 100);
    })
  }

  file
  uploadImage(e){
    console.log(e)
    this.file = e.target.files[0]
  }

  sendMessage(id) {
    console.log(id, this.formData.get('message').value)
    this.studentService.sendChat(id, this.formData.get('message').value, this.file).subscribe(data => {
      console.log(data)
      this.chatUser(id);
      this.chatPS.directiveRef.scrollToBottom(-100, 500)
      this.cdr.detectChanges()
      this.formData.reset();
    })

  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  closeSettings() {
    this.accountSettings = !this.accountSettings;
  }

  idSelected;
  displayChat(id) {
    this.showMessage = true;
    this.idSelected = id;
    this.studentService.userChat(id).subscribe(data => {
      console.log('chatMessagesData',data);
      this.chatMessagesData = data['data'];
      this.username = this.chatMessagesData['teacher'].name;

      setTimeout(() => {
        this.chatPS.directiveRef.scrollToBottom(-100, 500)
        this.cdr.detectChanges()
        // this.studentService.lastSeen(id).subscribe(data => {
        //   console.log('put', data);
        // })
      }, 100);
    })

  }
  hideChat() {
    this.showMessage = false;
  }

  tabVal = 'users'
  navMenu(val) {
    this.tabVal = val;
  }


  /**
   * Save the message in chat
   */

  // messageSave() {
  //   const message = this.formData.get('message').value;
  //   const currentDate = new Date();
  //   if (this.formData.valid && message) {
  //     // Message Push in Chat
  //     this.chatMessagesData.push({
  //       align: 'right',
  //       name: 'Albin Gara',
  //       message,
  //       time: currentDate.getHours() + ':' + currentDate.getMinutes()
  //     });

  //     // Set Form Data Reset
  //     this.formData = this.formBuilder.group({
  //       message: null
  //     });
  //   }

  //   this.chatSubmit = true;
  // }
}
