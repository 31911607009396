<section class="wrap">
  <div *ngIf="fullPageLoader" class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="main-title">Overview</h1> -->
    <div class="dashboard">
      <div class="box-template borders first-grid">
        <h1 class="general-heading">{{'OVERVIEW.UPCOMING_LESSONS.MAIN_TITLE' | translate}}</h1>
        <p class="general-paragraph">{{'OVERVIEW.UPCOMING_LESSONS.PARAGRAPH' | translate}}</p>
        <div class="spinner-border" role="status" *ngIf="upcomingLessonsLoader">
          <span class="sr-only">Loading...</span>
        </div>
        <div *ngIf="current_engagements?.length == 0">
          <p class="general-paragraph">Empty
          </p>
        </div>
        <div class="inner-box borders" [ngClass]="{'border-active': up_lesson.rescheduling == 'active'}"
          *ngFor="let up_lesson of current_engagements | slice:0:4">


          <div class="inner-info" *ngIf="up_lesson.rescheduling == 'active'">
            <h1 class="general-heading">{{'OVERVIEW.UPCOMING_LESSONS.RESCHEDULE' | translate}} {{up_lesson.subject}}
            </h1>

          </div>
          <!--inner-info-->

          <div class="inner-info" *ngIf="up_lesson.rescheduling == 'inactive'">
            <h1 class="general-heading">{{up_lesson.subject}} Lesson</h1>
            <p class="inner-box-p">{{up_lesson.full_name}}</p>
            <p class="inner-box-p blue">{{up_lesson.tutoring_place}}</p>
          </div>
          <!--inner-info-->

          <div class="inner-schedule">
            <p class="inner-box-p hour">{{up_lesson.time}} <br>{{up_lesson.date | date:'dd/MM/yyyy'}}</p>

            <p class="inner-box-p reschedule" *ngIf="up_lesson.rescheduling == 'inactive'"
              (click)="rescheduleCurrentModal(currentModal, up_lesson)">{{'OVERVIEW.UPCOMING_LESSONS.RESCHEDULE' |
              translate}}</p>
          </div>

          <ng-template #currentModal let-modal>
            <div class="modal-header">
              <h1 class="main-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.MODAL_TITLE' | translate}}</h1>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body remove-padding-top" (clickOutside)="onClickedOutside()">

              <div class="m-tutor-content">
                <div class="m-tutor default-bottom-space">
                  <div class="m-tutor-info">
                    <p class="table-title" style="margin: 0;">{{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_I' |
                      translate}}
                      <b>{{up_lesson.date | date: 'dd/MM/yyyy'}}</b> {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_II' |
                      translate}}
                      <b>{{up_lesson.subject}}</b> {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_III' | translate}}
                      <b>{{up_lesson.full_name}}</b>. {{'OVERVIEW.MODALS.PLANNED_LESSONS.TEXT_IV' | translate}}:
                    </p>
                    <ul style="list-style-type:none">
                      <li class="table-title"> - {{'OVERVIEW.MODALS.PLANNED_LESSONS.RULE_I' | translate}}
                      </li>
                      <li class="table-title"> - {{'OVERVIEW.MODALS.PLANNED_LESSONS.RULE_II' | translate}}
                      </li>
                      <li class="table-title"> - {{'OVERVIEW.MODALS.PLANNED_LESSONS.RULE_III' | translate}}
                      </li>
                    </ul>
                  </div>
                  <!--m-tutor-info-->
                </div>
                <!--m-tutor-->
                <div class='row'>
                  <div class="col-md-5">
                    <div class="m-tutor-profile">
                      <img *ngIf="up_lesson.image_path != ''" [src]="up_lesson.image_path">
                      <h1 *ngIf="up_lesson.image_path == ''">{{up_lesson.name}}</h1>

                    </div>
                    <!--m-tutor-profile-->
                  </div>
                  <div class="col-md-7">
                    <div class="m-tutor-body">
                      <div class="m-tutor">
                        <fa-icon [icon]="personalInfo"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.INFORMATION' | translate}}:
                          </h1>

                          <table class="table">
                            <tbody>
                              <tr class="m-tutor-paragraph">
                                <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.NAME' |
                                  translate}}:</th>
                                <th scope="col">{{up_lesson.full_name}}</th>
                              </tr>

                              <tr class="m-tutor-paragraph">
                                <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.SUBJECT' |
                                  translate}}:</th>
                                <th scope="col">{{up_lesson.subject}}</th>
                              </tr>

                              <tr class="m-tutor-paragraph">
                                <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.DATE' |
                                  translate}}:</th>
                                <th scope="col">{{up_lesson.date | date:'dd/MM/yyyy'}}</th>
                              </tr>

                              <tr class="m-tutor-paragraph">
                                <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.PLANNED_LESSONS.TIME' |
                                  translate}}:</th>
                                <th scope="col">{{up_lesson.time}}</th>
                              </tr>

                            </tbody>
                          </table>

                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->


                    </div>
                  </div>
                </div><!-- row -->

              </div>
              <!--m-tutor-content-->

              <div class="m-tutor-content">

                <!--m-tutor-profile-->
                <div class="m-tutor-body">
                  <form [formGroup]="rescheduleForm">


                    <div class="m-tutor remove-borders">
                      <div class="m-tutor-info">
                        <p class="table-title date-label-space">{{'OVERVIEW.MODALS.PLANNED_LESSONS.SUGGEST_DATE' |
                          translate}}:</p>
                        <mat-form-field appearance="fill">
                          <mat-label>{{'OVERVIEW.MODALS.PLANNED_LESSONS.CHOOSE_DATE' | translate}}</mat-label>
                          <input (dateChange)="reschDate($event)" class="input-date" matInput formControlName='date'
                            [matDatepicker]="picker">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          <mat-error *ngIf="rescheduleForm.invalid">
                            {{'OVERVIEW.MODALS.PLANNED_LESSONS.TOMORROW_DATE' | translate}}.</mat-error>
                        </mat-form-field>
                      </div>
                      <!--m-tutor-info-->
                    </div>

                    <div class="m-tutor remove-borders" *ngIf="availableTimeslot">
                      <div class="m-tutor-info">
                        <p class="table-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.AVAILABLE_TIME' | translate}}:</p>
                        <div *ngIf="loadTimeslot" class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                        <div class="desired-time">
                          <div class="time testTime" *ngFor="let time of reschTimes[0]"
                            [ngClass]="{'disable-time': time.selected == 'true'}">
                            <input type="checkbox" value="{{time.id}}" [disabled]="time.selected == 'true'"
                              value="{{time.id}}" (change)="selectTime($event)">
                            {{time.time}}
                          </div>
                          <p *ngIf="reschTimes[0]?.length == 0" class="m-tutor-paragraph">
                            {{'OVERVIEW.MODALS.PLANNED_LESSONS.NO_TIME_STUDENT' | translate}}</p>
                        </div>
                      </div>
                      <!--m-tutor-info-->
                    </div>

                    <div class="m-tutor remove-borders">
                      <div class="m-tutor-info">
                        <p class="table-title date-label-space">{{'OVERVIEW.MODALS.PLANNED_LESSONS.STUDENT_MESSAGE'
                          | translate}}</p>
                        <mat-form-field class="example-full-width">
                          <mat-label>{{'OVERVIEW.MODALS.PLANNED_LESSONS.WRITE_HERE' | translate}}</mat-label>
                          <textarea matInput class="form-input text-area-style" formControlName="comment"></textarea>
                        </mat-form-field>
                      </div>
                      <!--m-tutor-info-->
                    </div>

                  </form>
                </div>
                <!--m-tutor-body-->
              </div>
              <!--m-tutor-content-->
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary m-btn-book" [disabled]="rescheduleForm.invalid"
                (click)="saveRescheduleUpcomingLesson(up_lesson.id)">{{'OVERVIEW.MODALS.PLANNED_LESSONS.RESCHEDULE'
                | translate}}</button>
            </div>
          </ng-template>
        </div>
        <!--inner-box-->
        <!-- <mat-tab-group>
          <mat-tab label="{{'OVERVIEW.UPCOMING_LESSONS.PLANED_LESSONS' | translate}}">

          </mat-tab>
          <mat-tab label="{{'OVERVIEW.UPCOMING_LESSONS.GOALS' | translate}}">

            <div class="spinner-border" role="status" *ngIf="goalsLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="inner-box borders" [ngClass]="{'border-goal': goal.validity}" *ngFor="let goal of myGoals">
              <div class="inner-info">
                <h1 class="general-heading">{{goal.title}}</h1>
              </div>

              <div class="inner-schedule">
                <p class="inner-box-p hour">{{goal.start.slice(-5)}} — {{goal.end.slice(-5)}}<br>
                  {{goal.start.slice(0, 10) | date:'dd/MM/yyyy'}}</p>
              </div>
              <fa-icon class="remove-goal" (click)="deleteGoal(goal)" [icon]="goalDelete"></fa-icon>
            </div>

          </mat-tab>
        </mat-tab-group> -->
      </div>
      <!--box-template-->

      <div class="box-template borders second-grid box-template-background">
        <div class="lessons" [ngClass]="{'leesons-added': newDataSubjects?.length != 0}">
          <img src="../../assets/images/ellipse.png" alt="" data-toggle="modal" (click)="newSubject(newSubjectModal)">
          <h1 class="general-heading">{{'OVERVIEW.SUBJECT.MAIN_TITLE' | translate}}</h1>
          <p class="general-paragraph">{{'OVERVIEW.SUBJECT.PARAGRAPH_STUDENT' | translate}}
          </p>
        </div>
        <!--lesson-->
        <!-- <div class="inner-box borders odd-box" *ngIf="newDataSubjects?.length != 0">
          <div class="plus-wrapper" (click)="newSubject(newSubjectModal)">
            <fa-icon class="remove-goal" [icon]="plus"></fa-icon>
          </div>
        </div> -->

        <div class="spinner-border" role="status" *ngIf="subjectLoader">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="inner-box borders" *ngFor="let subject of newDataSubjects">
          <div class="teacher-info">

            <div class="teacher-name">
              <h1 class="general-heading">{{subject.name}}</h1>
            </div>

          </div>
          <fa-icon *ngIf="!subject.engagement" class="remove-goal" (click)="deleteSubject(subject.id)"
            [icon]="goalDelete"></fa-icon>

        </div>



        <ng-template #newSubjectModal let-modal>
          <div class="modal-header">
            <h1 class="main-title">{{'OVERVIEW.SUBJECT.MAIN_TITLE' | translate}}</h1>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" (clickOutside)="onClickedOutside()" style="padding-top: 0!important;">

            <p class="form-label custom-label-subject" style="margin-bottom: 30px">{{'OVERVIEW.SUBJECT.MODAL.INTRO_TEXT'
              | translate}}
            </p>
            <form [formGroup]="addSubjectFormGroup">

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'LABELS.SUBJECT' | translate}}</mat-label>
                      <mat-select formControlName="subject">
                        <mat-option value="{{subject.id}}" *ngFor="let subject of subjects">
                          {{subject.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--col-md-6-->
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>Min. {{'LABELS.TEACHER_DEGREE' | translate}}</mat-label>
                      <mat-select formControlName="degree">
                        <mat-option value="{{education.id}}" *ngFor="let education of educations">
                          {{education.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--col-md-6-->
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.DESC_GOALS' | translate}}</mat-label>
                        <textarea matInput class="form-input text-area-style" formControlName="describe"></textarea>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                </div>
                <!--col-md-12-->

                <div class="col-md-6">
                  <div class="form-group form-group-margin">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'STUDENTS.NR_LESSONS' | translate}}</mat-label>
                      <mat-select formControlName="nr_lessons">
                        <mat-option *ngFor="let less of nr_of_lessons" value="{{less.id}}">
                          {{less.nr_lessons.split(' ')[0]}} {{'LABELS.LESSONS_NR' | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--col-md-6-->


                <div class="col-md-6">
                  <div class="form-group form-group-margin">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'LABELS.PERIODICITY' | translate}}</mat-label>
                      <mat-select formControlName="periodicity">
                        <mat-option value="weekly">
                          {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                        </mat-option>
                        <mat-option value="biweekly">
                          {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                        </mat-option>
                        <mat-option value="monthly">
                          {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>




                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <h4 class="form-label">Do you want to take over saved tutor preferences?</h4>
                    <div class="group-filter">
                      <div class="rating">
                        <input id="rating" formControlName="preferences" value='yes' (change)="ratingChange($event)"
                          type="checkbox">Yes
                      </div>
                      <div class="rating">
                        <input id="rating" formControlName="preferences" value='no' (change)="ratingChange($event)"
                          type="checkbox">No
                      </div>

                    </div>
                  </div>
                </div> -->

                <!-- <div class="col-md-12">
                  <div class="form-group">
                    <h4 class="form-label">{{'LABELS.SAVE_PREFERENCES' | translate}}</h4>
                    <mat-radio-group aria-label="Select an option" (change)="ratingChange($event)"
                      formControlName="preferences">
                      <mat-radio-button value="yes">{{'LABELS.YES' | translate}}</mat-radio-button>
                      <mat-radio-button value="no">{{'LABELS.NO' | translate}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div> -->
                <!--col-md-12-->
              </div>
              <!--row-->

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <h4 class="form-label custom-label-subject">{{'REGISTER_TUTOR_PAGE.LESSON_DETAILS' | translate}}:
                    </h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'LABELS.STYLE' | translate}}</mat-label>
                      <mat-select formControlName="tutoring_style_id" ngModel="{{userTutoringPlace}}">
                        <mat-option value="{{style.id}}" *ngFor="let style of tutoringStyle" [disabled]='style.id == 2'>
                          {{style.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!--col-md-6-->


                <div class="col-md-6">
                  <div class="form-group">
                    <mat-form-field appearance="fill">
                      <mat-label>{{'LABELS.PLACE' | translate}}</mat-label>
                      <mat-select formControlName="tutoring_place_id" ngModel="{{userTutoringPlace}}"
                        (selectionChange)="lessonsModal($event)">
                        <mat-option *ngFor="let place of tutoringPlaces" value="{{place.id}}">
                          {{place.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="form-buttons">
                <button data-dismiss="modal" class="btn btn-primary btn-submit lesson-modal-btn" mat-button
                  style="width: 100%;" [disabled]="addSubjectFormGroup.invalid" (click)="addFullSubject()">
                  {{'LABELS.FINISH' | translate}}
                </button>
              </div>

              <!-- <div class="form-buttons">
                <button mat-button matStepperNext *ngIf="ratingValue == 'no'"
                  class="btn btn-primary lesson-modal-btn" style="width: 100%;"
                  [disabled]='addSubjectFormGroup.invalid'>{{'LABELS.PREECED' | translate}}</button>

                <button *ngIf="ratingValue == 'yes'" class="btn btn-primary lesson-modal-btn"
                  (click)="addNewSubject()" style="width: 100%;"
                  [disabled]='addSubjectFormGroup.invalid'>{{'LABELS.PREECED' | translate}}</button>
              </div> -->
            </form>
          </div>
        </ng-template>
      </div>
      <!--box-template-->

      <div class="box-template third-grid borders grid-chart">
        <div class="wallet-top">
          <div class="wallet-title">
            <h1 class="general-heading">{{'OVERVIEW.WALLET.MY_WALLET' | translate}}</h1>
            <p class="general-paragraph">{{'OVERVIEW.WALLET.WALLET_PARAGRAPH' | translate}}</p>
          </div>
          <!--wallet-title-->
          <mat-form-field appearance="fill">
            <mat-label class="label-custom">{{'OVERVIEW.MODALS.MATCHED_TUTORS.SUBJECT' | translate}}</mat-label>
            <mat-select value='all' (selectionChange)="getWalletSubject($event)">
              <mat-option selected value='all'>
                {{'OVERVIEW.WALLET.ALL' | translate}}
              </mat-option>
              <mat-option *ngFor="let subject of studentSubjects" value="{{subject.id}}">
                {{subject.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--wallet-top-->
        <div class="round-chart">
          <apx-chart [series]="chartCircleOptions.series" [chart]="chartCircleOptions.chart"
            [plotOptions]="chartCircleOptions.plotOptions" [labels]="chartCircleOptions.labels"
            [stroke]="chartCircleOptions.stroke"></apx-chart>
          <p class="general-paragraph">{{'OVERVIEW.WALLET.WALLET_SPENDINGS.I' | translate}} {{walletData?.money_left}}
            {{'OVERVIEW.WALLET.WALLET_SPENDINGS.II' | translate}} {{walletData?.money_deposited}}
            {{'OVERVIEW.WALLET.WALLET_SPENDINGS.III' | translate}}</p>
        </div>
        <!--round-chart-->
        <!-- <div class="bar-chart">
          <h1 class="general-heading">{{'OVERVIEW.WALLET.LESSONS_TITLE' | translate}}</h1>
          <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [dataLabels]="chartOptions.dataLabels"
            [plotOptions]="chartOptions.plotOptions" [yaxis]="chartOptions.yaxis" [xaxis]="chartOptions.xaxis"
            [legend]="chartOptions.legend" [colors]="chartOptions.colors" [grid]="chartOptions.grid"></apx-chart>

          <div class="chart-info">
            <h1 class="general-heading">{{'OVERVIEW.WALLET.TOTAL_LESSONS' | translate}} 19</h1>
            <ul>
              <li class="general-paragraph">1/19 Geometry Lesson</li>
              <li class="general-paragraph">1/19 Geometry Lesson</li>
              <li class="general-paragraph">1/19 Geometry Lesson</li>
              <li class="general-paragraph">1/19 Geometry Lesson</li>
            </ul>
          </div>
        </div> -->
        <!--bar-chart-->
      </div>
      <!--box-template-->

      <div class="box-template borders fourth-grid">
        <div class="wallet-top">
          <div class="wallet-title">
            <h1 class="general-heading">{{'OVERVIEW.PROPOSALS.MAIN_TITLE' | translate}}</h1>
            <p class="general-paragraph">{{'OVERVIEW.PROPOSALS.PARAGRAPH' | translate}}</p>
          </div>
          <!--wallet-title-->

        </div>

        <!-- <div *ngIf="isFetchingProposals; else myProposals">
          <div class="spinner-border">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->

        <mat-tab-group>
          <mat-tab *ngIf="false" label="{{'OVERVIEW.PROPOSALS.TUTOR' | translate}}">
            <div class="spinner-border" role="status" *ngIf="calendarProposalsLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <!-- (click)="proposalGetModal(modalProposal, proposal)" -->

            <div class="inner-box borders" routerLink="proposal/{{proposal.id}}" [ngClass]="proposal.status"
              *ngFor="let proposal of allProposals">


              <div class="teacher-info">
                <img *ngIf="proposal.teacher_image_path != ''" [src]="proposal.teacher_image_path" alt="">
                <img *ngIf="proposal.teacher_image_path == ''"
                  src="../../../assets/images/{{proposal.teacher.gender}}.jpg" alt="">
                <div class="teacher-name">
                  <h1 class="general-heading">{{proposal.teacher.name}}</h1>
                  <p class="inner-box-p">{{proposal.subject}}</p>
                </div>
              </div>

              <div class="inner-schedule">
                <p class="inner-box-p hour">Satus
                </p>
                <p class="inner-box-p hour" *ngIf="proposal.status == 'confirmed'">
                  {{'OVERVIEW.MODALS.MATCHED_TUTORS.CONFIRMED' | translate}}
                </p>
                <p class="inner-box-p hour" *ngIf="proposal.status == 'pending'">
                  {{'OVERVIEW.MODALS.MATCHED_TUTORS.PENDING' | translate}}</p>
              </div>

              <ng-template #modalProposal let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">
                    <div class='row'>
                      <div class="col-md-5">
                        <div class="m-tutor-profile">
                          <!-- <img *ngIf="proposal.image_path != ''" [src]="proposal.image_path"  lightbox
                            [fullImage]="{path: '../../assets/images/avatar-1.jpg'}" /> -->

                          <img *ngIf="proposal.teacher_image_path != ''" [src]="proposal.teacher_image_path">
                          <img *ngIf="proposal.teacher_image_path == ''"
                            src="../../../assets/images/{{proposal.teacher.gender}}.jpg" alt="">
                        </div>
                        <!--m-tutor-profile-->
                      </div>
                      <div class="col-md-7">
                        <div class="m-tutor-body">
                          <div class="m-tutor">
                            <fa-icon [icon]="personalInfo"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.PERSONAL_INFORMATION' |
                                translate}}</h1>
                              <table class="table">
                                <tbody>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.NAME' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.teacher.name}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.AGE' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.teacher.age}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EDUCATION'
                                      | translate}}:</th>
                                    <th scope="col">{{proposal.teacher.edu_finished}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.SUBJECT' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.subject}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.STATUS' |
                                      translate}}:</th>
                                    <th scope="col" *ngIf="proposal.status == 'confirmed'">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.CONFIRMED' | translate}} </th>
                                    <th scope="col" *ngIf="proposal.status == 'pending'">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.PENDING' | translate}} </th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EXPERIENCE'
                                      | translate}}:</th>
                                    <th scope="col"><b>{{proposal.teacher.experience}}</b> {{'HOMEPAGE.DEMO.YEARS' |
                                      translate}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.STUDENTS_NR' | translate}}:</th>
                                    <th scope="col"><b>{{proposal.teacher.all_students}} </b> {{'HOMEPAGE.DEMO.STUDENT'
                                      | translate}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.DISTANCE' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.distance}} km</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_PLACE' | translate}}:</th>
                                    <th scope="col">{{proposal.tutoring_place}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_STYLE' | translate}}:</th>
                                    <th scope="col">{{proposal.tutoring_style}}</th>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                            <!-- <div class="m-tutor-info">
                              <h1 class="m-tutor-title">Personal Information</h1>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">Name:</span>
                                {{proposal.teacher.name | slice:0:1}} . {{proposal.teacher.surname |
                                slice:0:1}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Age:
                                </span>
                                {{proposal.teacher.age}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Age:
                                </span>
                                {{proposal.subject}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Phd in:
                                </span>
                                {{proposal.subject}}
                              </p>


                              <p class="m-tutor-paragraph"><span class="paragraph-line">Education:
                                </span>
                                {{proposal.teacher.school}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Status:
                                </span>
                                {{proposal.status}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Experience as tutor:
                                </span>
                                {{proposal.teacher.experience}} years
                              </p>


                              <p class="m-tutor-paragraph"><span class="paragraph-line">Number of students on EducaGrow:
                                </span>
                                <b>{{proposal.teacher.all_students}} </b> students
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Last time online:
                                </span>
                                <b>{{proposal.teacher.active_students}} </b> students
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">Distance:</span>
                                {{proposal.distance}} km</p>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">Tutoring Place:</span>
                                {{proposal.tutoring_place}}</p>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">Turing style:</span>
                                {{proposal.tutoring_style}}</p>
                              <p class="m-tutor-paragraph" *ngFor="let timeDetails of proposal.details"><span
                                  class="paragraph-line">{{timeDetails.day}}</span> - {{timeDetails.time}}</p>

                            </div> -->
                            <!--m-tutor-info-->
                          </div>
                          <!--m-tutor-->
                        </div>
                      </div>
                    </div><!-- row -->

                  </div>
                  <!--m-tutor-content-->

                  <div class="m-tutor-content">

                    <div class="m-tutor-body">

                      <!-- <div class="m-tutor">
                        <fa-icon [icon]="otherInfo"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">Other informations</h1>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Distance:</span>
                            {{proposal.distance}} km</p>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Tutoring Place:</span>
                            {{proposal.tutoring_place}}</p>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Turing style:</span>
                            {{proposal.tutoring_style}}</p>
                          <p class="m-tutor-paragraph" *ngFor="let timeDetails of proposal.details"><span
                              class="paragraph-line">{{timeDetails.day}}</span> - {{timeDetails.time}}</p>
                        </div>

                      </div> -->
                      <!--m-tutor-->


                      <div class="m-tutor">
                        <fa-icon [icon]="faUserTie"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.SKILLS' | translate}}</h1>
                          <p class="m-tutor-paragraph">
                            {{proposal.teacher.skills}}
                          </p>
                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->



                      <div class="m-tutor">
                        <fa-icon [icon]="faCalendarAlt"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.CALENDAR_INFORMATION' |
                            translate}}</h1>
                          <div class="calendar-box-wrap">
                            <p class="m-tutor-paragraph proposal-box-calendar"
                              *ngFor="let timeDetails of proposal.details">
                              <span class="paragraph-line">{{timeDetails.day | titlecase}}</span>
                              <span>{{timeDetails.time}} </span>
                              <span>{{timeDetails.periodicity}}</span>
                            </p>
                          </div>
                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->


                      <div class="m-tutor">
                        <fa-icon [icon]="lessonsInfo"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.LESSONS_INFORMATION' | translate}}
                          </h1>
                          <p class="m-tutor-paragraph">{{'OVERVIEW.MODALS.MATCHED_TUTORS.LESSONS_TEXT_STUDENT.I' |
                            translate}}
                            <b>{{proposal.lessons_per_week}}</b>
                            {{'OVERVIEW.MODALS.MATCHED_TUTORS.LESSONS_TEXT_STUDENT.II' | translate}}
                            <b>{{proposal.lessons_nr}} Lessons</b>
                            {{'OVERVIEW.MODALS.MATCHED_TUTORS.LESSONS_TEXT_STUDENT.III' | translate}}.
                          </p>

                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->



                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary m-btn-book"
                    (click)="modal.dismiss()">{{'OVERVIEW.MODALS.MATCHED_TUTORS.DONE' | translate}}</button>
                </div>
              </ng-template>

            </div>
            <!--inner-box-->

          </mat-tab>

          <mat-tab label="{{'OVERVIEW.PROPOSALS.CALENDAR' | translate}}">
            <div class="spinner-border" role="status" *ngIf="calendarProposalsLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="calendarProposals?.length == 0">
              <p class="general-paragraph">Empty</p>
            </div>
            <div class="inner-box borders" (click)="proposalGetModal(modalProposal, proposal)"
              [ngClass]="proposal.status" *ngFor="let proposal of calendarProposals">
              <div class="teacher-info">
                <img *ngIf="proposal.teacher_image_path != ''" [src]="proposal.teacher_image_path" alt="">
                <img *ngIf="proposal.teacher_image_path == ''"
                  src="../../../assets/images/{{proposal.teacher.gender}}.jpg" alt="">
                <div class="teacher-name">
                  <h1 class="general-heading">{{proposal.teacher.name}}</h1>
                  <p class="inner-box-p">{{proposal.subject.name}}</p>
                </div>
              </div>

              <div class="inner-schedule">
                <p class="inner-box-p hour">Satus
                </p>
                <p class="inner-box-p hour" *ngIf="proposal.status == 'confirmed'">
                  {{'OVERVIEW.MODALS.MATCHED_TUTORS.CONFIRMED' | translate}}
                </p>
                <p class="inner-box-p hour" *ngIf="proposal.status == 'pending'">
                  {{'OVERVIEW.MODALS.MATCHED_TUTORS.PENDING' | translate}}</p>
              </div>

              <ng-template #modalProposal let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">
                    <div class='row'>
                      <div class="col-md-5">
                        <div class="m-tutor-profile">
                          <!-- <img *ngIf="proposal.image_path != ''" [src]="proposal.image_path"  lightbox
                            [fullImage]="{path: '../../assets/images/avatar-1.jpg'}" /> -->
                          <img *ngIf="proposal.teacher_image_path != ''" [src]="proposal.teacher_image_path" alt="">
                          <img *ngIf="proposal.teacher_image_path == ''"
                            src="../../../assets/images/{{proposal.teacher.gender}}.jpg" alt="">

                        </div>
                        <!--m-tutor-profile-->
                      </div>
                      <div class="col-md-7">
                        <div class="m-tutor-body">

                          <div class="m-tutor">
                            <fa-icon [icon]="personalInfo"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.PERSONAL_INFORMATION' |
                                translate}}</h1>
                              <table class="table">
                                <tbody>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.NAME' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.teacher.name}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.AGE' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.teacher.age}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EDUCATION'
                                      | translate}}:</th>
                                    <th scope="col">{{proposal.teacher.edu_finished}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.SUBJECT' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.subject}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.STATUS' |
                                      translate}}:</th>
                                    <th scope="col" *ngIf="proposal.status == 'confirmed'">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.CONFIRMED' | translate}} </th>
                                    <th scope="col" *ngIf="proposal.status == 'pending'">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.PENDING' | translate}} </th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EXPERIENCE'
                                      | translate}}:</th>
                                    <th scope="col"><b>{{proposal.teacher.experience}}</b> {{'HOMEPAGE.DEMO.YEARS' |
                                      translate}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.STUDENTS_NR' | translate}}:</th>
                                    <th scope="col"><b>{{proposal.teacher.all_students}} </b> {{'HOMEPAGE.DEMO.STUDENT'
                                      | translate}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.DISTANCE' |
                                      translate}}:</th>
                                    <th scope="col">{{proposal.distance}} km</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_PLACE' | translate}}:</th>
                                    <th scope="col">{{proposal.tutoring_place}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">
                                      {{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_STYLE' | translate}}:</th>
                                    <th scope="col">{{proposal.tutoring_style}}</th>
                                  </tr>

                                </tbody>
                              </table>
                            </div>
                            <!-- <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.PERSONAL_INFORMATION' | translate}}</h1>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.NAME' | translate}}:</span>
                                {{proposal.teacher.name}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.AGE' | translate}}:
                                </span>
                                {{proposal.teacher.age}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EDUCATION' | translate}}:
                                </span>
                                {{proposal.teacher.edu_finished}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.STATUS' | translate}}:
                                </span>
                                {{proposal.status}}
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.EXPERIENCE' | translate}}:
                                </span>
                                {{proposal.teacher.experience}} years
                              </p>


                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.STUDENTS_NR' | translate}}:
                                </span>
                                <b>{{proposal.teacher.number_of_students}}</b> students
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.ONLINE' | translate}}:
                                </span>
                                <b>{{proposal.teacher.active_students}}</b> students
                              </p>

                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.DISTANCE' | translate}}:</span>
                                {{proposal.distance}} km</p>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_PLACE' | translate}}:</span>
                                {{proposal.tutoring_place}}</p>
                              <p class="m-tutor-paragraph"><span class="paragraph-line">{{'OVERVIEW.MODALS.MATCHED_TUTORS.TUTORING_STYLE' | translate}}:</span>
                                {{proposal.tutoring_style}}</p>


                            </div> -->
                          </div>
                          <!--m-tutor-->
                        </div>
                      </div>
                    </div><!-- row -->

                  </div>
                  <!--m-tutor-content-->

                  <div class="m-tutor-content">

                    <div class="m-tutor-body">

                      <!-- <div class="m-tutor">
                        <fa-icon [icon]="otherInfo"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">Other informations</h1>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Distance:</span>
                            {{proposal.distance}} km</p>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Tutoring Place:</span>
                            {{proposal.tutoring_place}}</p>
                          <p class="m-tutor-paragraph"><span class="paragraph-line">Turing style:</span>
                            {{proposal.tutoring_style}}</p>
                          <p class="m-tutor-paragraph" *ngFor="let timeDetails of proposal.details"><span
                              class="paragraph-line">{{timeDetails.day}}</span> - {{timeDetails.time}}</p>
                        </div>

                      </div> -->
                      <!--m-tutor-->


                      <div class="m-tutor">
                        <fa-icon [icon]="faUserTie"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.SKILLS' | translate}}</h1>
                          <p class="m-tutor-paragraph">
                            {{proposal.teacher.skills}}
                          </p>
                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->



                      <div class="m-tutor">
                        <fa-icon [icon]="faCalendarAlt"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">Calendar informations</h1>
                          <p class="m-tutor-paragraph" *ngFor="let timeDetails of proposal.details"><span
                              class="paragraph-line">{{timeDetails.day | titlecase}}</span> - {{timeDetails.time}} -
                            {{timeDetails.periodicity | titlecase}}</p>
                        </div>
                        <!--m-tutor-info-->
                      </div>
                      <!--m-tutor-->


                      <!-- <div class="m-tutor">
                        <fa-icon [icon]="lessonsInfo"></fa-icon>
                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.MATCHED_TUTORS.CALENDAR_INFORMATION' | translate}}</h1>
                          <p class="m-tutor-paragraph">With this teacher you will hold
                            <b>{{proposal.lessons_per_week}}</b> hours per week, which amounts in
                            <b>{{proposal.price_per_week}}</b> CHF per week.
                          </p>
                          <p class="m-tutor-paragraph">The student has in mind to hold
                            <b>{{proposal.nr_of_lessons}}</b>.
                          </p>
                        </div>

                      </div> -->
                      <!--m-tutor-->



                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary m-btn-book" (click)="modal.dismiss()">Done</button>
                </div>
              </ng-template>

            </div>
            <!--inner-box-->
          </mat-tab>

          <mat-tab label="{{'OVERVIEW.PROPOSALS.RESCHEDULE' | translate}}">
            <p class="general-paragraph">
              {{'OVERVIEW.PROPOSALS.EXTENDED_BOX.EXTENDED_PARAGRAPH' | translate}}"
            </p>

            <div class="spinner-border" role="status" *ngIf="rescheduleProposalsLoader">
              <span class="sr-only">Loading...</span>
            </div>

            <div *ngIf="rescheduledLessons?.length == 0">
              <p class="general-paragraph">Empty</p>
            </div>
            <div class="inner-box borders box-shadow" (click)="rescheduledModal(reschModal, lesson)" [ngClass]="{
              'sent-pending': lesson.status == 'pending' && lesson.last_updated == 'student',
              'pending': lesson.status == 'pending',
              'confirmed': lesson.status == 'confirmed',
              'canceled': lesson.status == 'canceled'
            }" *ngFor="let lesson of rescheduledLessons" [hidden]="lesson.status == 'confirmed'">
              <div class="teacher-info">
                <img *ngIf="lesson.teacher_image_path != ''" [src]="lesson.teacher_image_path" alt="">
                <img *ngIf="lesson.teacher_image_path == ''" src="../../../assets/images/{{lesson.teacher.gender}}.jpg"
                  alt="">
                <div class="teacher-name">
                  <h1 class="general-heading">{{lesson.teacher.name}} {{lesson.teacher.surname}}</h1>
                  <p class="inner-box-p">{{lesson.status}}</p>
                  <p class="inner-box-p">{{lesson.subject_id}}</p>
                </div>
              </div>
              <!--teacher-info-->

              <div class="inner-schedule proposal-buttons">
                <p class="inner-box-p hour">
                  {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.PROPOSED_TIME' | translate}}:
                  <br>{{lesson.resch_date}}
                  <br>{{lesson.time}}
                </p>
              </div>


              <!-- <p class="inner-box-p reschedule" (click)="exetendLessontModal(extendLesson)">Extend Lessons</p> -->

              <!-- <div class="inner-schedule proposal-buttons" *ngIf="lesson.status == 'new_appoint'">
                <fa-icon class="accept-proposal" (click)="acceptReschLesson(lesson)" [icon]="proposalAccept">
                </fa-icon>
                <fa-icon class="remove-proposal" (click)="declinetReschLesson(lesson)" [icon]="porposalDecline">
                </fa-icon>
              </div> -->

              <!-- <div class="inner-schedule" *ngIf="lesson.status == 'new_appoint'">
                <p class="inner-box-p hour">Reschedule sent</p>
              </div> -->

              <div class="inner-schedule proposal-buttons"
                *ngIf="lesson.status == 'pending' && lesson.last_updated == 'student'">
                <p class="inner-box-p hour">{{'OVERVIEW.PROPOSALS.EXTENDED_BOX.RESCHEDULE_SENT' | translate}}</p>
              </div>

              <div class="inner-schedule proposal-buttons" *ngIf="lesson.status == 'confirmed'">
                <p class="inner-box-p hour">Rescheduled</p>
              </div>

              <div class="inner-schedule proposal-buttons" *ngIf="lesson.status == 'canceled'">
                <p class="inner-box-p hour">{{'OVERVIEW.PROPOSALS.EXTENDED_BOX.RESCHEDULE_CANCELED' | translate}}</p>
              </div>

              <div class="inner-schedule" *ngIf="lesson.status == 'pending' && lesson.last_updated == 'teacher'">
                <p class="inner-box-p hour">{{'OVERVIEW.PROPOSALS.EXTENDED_BOX.ACCEPT_RESCHEDULE' | translate}}</p>
              </div>


              <ng-template #reschModal let-modal>
                <div class="modal-header">
                  <h1 class="main-title">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.MODAL_TITLE' | translate}}</h1>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body remove-padding-top" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">

                    <!--m-tutor-profile-->
                    <div class="m-tutor-body">
                      <form [formGroup]="rescheduleForm"
                        *ngIf="lesson.status != 'confirmed' && lesson.status != 'canceled' && lesson.status == 'pending' && lesson.last_updated == 'teacher'">
                        <div class="m-tutor" *ngIf="lesson.status == 'pending' && lesson.last_updated == 'teacher'">
                          <div class="m-tutor-info" *ngIf="lesson.difference > 10">
                            <p class="table-title default-bottom-space">
                              {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.DESC.I' | translate}} {{lesson.teacher.name}}
                              {{lesson.teacher.surname}} {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.DESC.II' | translate}}
                              {{lesson.subject_id}}.
                              {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.DESC.III' | translate}}
                            </p>
                            <div class="rescheduled__times">
                              <div class="rescheduled__date">
                                <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.ORIGINAL_DATE' |
                                  translate}}</p>
                                <p class="rescheduled__date-d">{{lesson.old_reschedule_date}}</p>
                                <p class="rescheduled__date-t">{{lesson.old_reschedule_time}}</p>
                              </div>

                              <div class="rescheduled__date">
                                <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.TEACHER_PROPOSAL'
                                  | translate}}</p>
                                <p class="rescheduled__date-d">{{lesson.lesson_date}}</p>
                                <p class="rescheduled__date-t">{{lesson.lesson_time}}</p>
                              </div>

                              <div class="rescheduled__date">
                                <fa-icon [icon]="faArrowRight"></fa-icon>
                              </div>

                              <div class="rescheduled__date">
                                <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.NEW_PORPOSAL' |
                                  translate}}</p>
                                <p class="rescheduled__date-d">{{lesson.resch_date}}</p>
                                <p class="rescheduled__date-t">{{lesson.time}}</p>
                              </div>
                            </div>

                            <div class="teacher-proposal-modal-footer">
                              <button class="btn btn-success"
                                (click)="acceptReschLesson(lesson)">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.ACCEPT' |
                                translate}}</button>
                              <!-- <button class="btn btn-danger" (click)="declinetReschLesson(lesson)">Decline</button> -->
                            </div>

                          </div>
                          <!--m-tutor-info-->

                          <div class="m-tutor-info" *ngIf="lesson.difference <= 10">
                            <p class="table-title">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.DELAY' | translate}}…</p>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor choose-date-p">
                          <p class="table-title">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.NEW_DATE_STUDENT' |
                            translate}}:</p>
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title date-label-space">
                              {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.SUGGEST_DATE' | translate}}:</p>
                            <mat-form-field appearance="fill">
                              <mat-label>{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.CHOOSE_DATE' | translate}}</mat-label>
                              <input (dateChange)="reschDate($event)" class="input-date" matInput
                                [matDatepicker]="picker" formControlName='date'>
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                              <mat-error *ngIf="rescheduleForm.invalid">
                                {{'OVERVIEW.MODALS.PLANNED_LESSONS.TOMORROW_DATE' | translate}}.</mat-error>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders" *ngIf="availableTimeslot">
                          <div class="m-tutor-info">
                            <p class="table-title">{{'OVERVIEW.MODALS.PLANNED_LESSONS.AVAILABLE_TIME' | translate}}:</p>
                            <div *ngIf="loadTimeslot" class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            <div class="desired-time">
                              <div class="time testTime" *ngFor="let time of reschTimes[0]"
                                [ngClass]="{'disable-time': time.selected == 'true'}">
                                <input type="checkbox" value="{{time.id}}" [disabled]="time.selected == 'true'"
                                  value="{{time.id}}" (change)="selectTime($event)">
                                {{time.time}}
                              </div>
                              <p *ngIf="reschTimes[0]?.length == 0" class="m-tutor-paragraph">
                                {{'OVERVIEW.MODALS.PLANNED_LESSONS.NO_TIME_STUDENT' | translate}}</p>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.TEACHERS_COMMENT' |
                              translate}}</p>
                            <p class="m-tutor-paragraph">{{lesson.comment}}</p>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <mat-form-field class="example-full-width">
                              <mat-label>{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.STUDENT_MESSAGE' | translate}}:
                              </mat-label>
                              <textarea matInput class="form-input text-area-style"
                                formControlName="comment"></textarea>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor ">
                          <div class="m-tutor-info">

                            <div class="important-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.IMPORTANT_INFORMATION' |
                                translate}}</h1>

                              <p class="m-tutor-paragraph">
                                {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.IMPORTANT_TEXT_STUDENT' | translate}}
                              </p>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>
                        <!--m-tutor-->

                      </form>

                      <div class="m-tutor" *ngIf="lesson.status == 'confirmed'">
                        <div class="m-tutor-info">
                          <p class="table-title">{{lesson.resch_date}},
                            {{lesson.time.time_start}}-{{lesson.time.time_end}}</p>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                      <div class="m-tutor" *ngIf="lesson.status == 'pending' && lesson.last_updated == 'student'">
                        <div class="m-tutor-info">
                          <p class="table-title default-bottom-space">
                            {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.RESCHEDULE_INFO.I' | translate}}
                            <b>{{lesson.resch_date}}</b> {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.RESCHEDULE_INFO.II' |
                            translate}} <b>{{lesson.time}}</b>
                            {{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.RESCHEDULE_INFO.III' | translate}}
                            <b>{{lesson.teacher.name}} {{lesson.teacher.surname}}</b>
                            in <b>{{lesson.subject_id}}</b>.
                          </p>

                          <div class="rescheduled__times">
                            <div class="rescheduled__date">
                              <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.ORIGINAL_DATE' |
                                translate}}</p>
                              <p class="rescheduled__date-d">{{lesson.old_reschedule_date}}</p>
                              <p class="rescheduled__date-t">{{lesson.old_reschedule_time}}</p>
                            </div>

                            <div class="rescheduled__date">
                              <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.TEACHER_PROPOSAL' |
                                translate}}</p>
                              <p class="rescheduled__date-d">{{lesson.lesson_date}}</p>
                              <p class="rescheduled__date-t">{{lesson.lesson_time}}</p>
                            </div>

                            <div class="rescheduled__date">
                              <fa-icon [icon]="faArrowRight"></fa-icon>
                            </div>

                            <div class="rescheduled__date">
                              <p class="rescheduled__date-p">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.NEW_PORPOSAL' |
                                translate}}</p>
                              <p class="rescheduled__date-d">{{lesson.resch_date}}</p>
                              <p class="rescheduled__date-t">{{lesson.time}}</p>
                            </div>
                          </div>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                      <!-- <div class="m-tutor" *ngIf="lesson.status == 'new_appoint'">
                        <div class="m-tutor-info">
                          <p class="table-title">Reschedule sent</p>
                        </div>
                      </div> -->

                      <div class="m-tutor" *ngIf="lesson.status == 'canceled'">
                        <div class="m-tutor-info">
                          <p class="table-title">{{'OVERVIEW.PROPOSALS.EXTENDED_BOX.RESCHEDULE_CANCELED' | translate}}
                          </p>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary m-btn-book"
                    *ngIf="lesson.status != 'confirmed' && lesson.status != 'canceled' && lesson.status == 'pending' && lesson.last_updated == 'teacher'"
                    [disabled]="rescheduleForm.invalid"
                    (click)="saveRescheduleLesson()">{{'OVERVIEW.MODALS.RESCHEDULE_PROPOSALS.RESCHEDULE' |
                    translate}}</button>

                  <button class="btn btn-primary m-btn-book" (click)="modal.dismiss()"
                    *ngIf="lesson.status == 'pending' && lesson.last_updated == 'student'">OK</button>
                </div>
              </ng-template>


            </div>
          </mat-tab>

          <mat-tab label="{{'OVERVIEW.PROPOSALS.EXTENDED' | translate}}">
            <div class="spinner-border" role="status" *ngIf="myTeachersLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="extendLessons?.length == 0">
              <p class="general-paragraph">Empty</p>
            </div>
            <div class="inner-box borders" (click)="extendLessonPriceModal(extendModal, ext_lesson)"
              [ngClass]="ext_lesson.status" *ngFor="let ext_lesson of extendLessons">
              <div class="teacher-info">
                <img *ngIf="ext_lesson.teacher_image_path != ''" [src]="ext_lesson.teacher_image_path" alt="">
                <img *ngIf="ext_lesson.teacher_image_path == ''"
                  src="../../../assets/images/{{ext_lesson.teacher.gender}}.jpg" alt="">
                <div class="teacher-name">
                  <h1 class="general-heading">{{ext_lesson.teacher.name }} {{ext_lesson.teacher.surname }}</h1>
                  <p class="inner-box-p">{{ext_lesson.status}}</p>
                </div>
              </div>

              <div class="inner-schedule">
                <p class="inner-box-p">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.REQUEST.I' | translate}}
                  {{ext_lesson.lessons_nr}} {{'OVERVIEW.MODALS.EXTENDED_LESSONS.REQUEST.II' | translate}}</p>
              </div>

              <div class="inner-schedule" *ngIf="ext_lesson.status == 'confirmed' && ext_lesson.payment == 'pending'">
                <p class="inner-box-p">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.PROCEED' | translate}}</p>
              </div>

              <div class="inner-schedule" *ngIf="ext_lesson.status == 'pending'">
                <p class="inner-box-p">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.RESPONSE' | translate}}</p>
              </div>

              <div class="inner-schedule" *ngIf="ext_lesson.status == 'canceled'">
                <p class="inner-box-p">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.DECLINED_FROM_TEACHER' | translate}}</p>
              </div>

              <div class="inner-schedule" *ngIf="ext_lesson.status == 'confirmed' && ext_lesson.payment == 'confirmed'">
                <p class="inner-box-p">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.COMPLETED_LESSON' | translate}}</p>
              </div>

              <ng-template #extendModal let-modal>
                <div class="modal-header">
                  <h1 class="main-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.EXTENDED' | translate}}</h1>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">

                    <!--m-tutor-profile-->
                    <div class="m-tutor-body">

                      <div class="m-tutor" *ngIf="ext_lesson.status == 'pending'">
                        <div class="m-tutor-info">
                          <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.WAIT_TEACHER' | translate}}</p>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                      <div class="m-tutor" *ngIf="ext_lesson.status == 'canceled'">
                        <div class="m-tutor-info">
                          <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.DECLIEND_LESSON_STUDENT' |
                            translate}}</p>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                      <div class="m-tutor" *ngIf="ext_lesson.status == 'confirmed' && ext_lesson.payment == 'pending'">
                        <div class="m-tutor-info">
                          <form [formGroup]="stripeFormGroup" (ngSubmit)="createToken(ext_lesson.id)">

                            <img alt="Credit Card Logos" title="Credit Card Logos" class="supported-cards"
                              src="../../../assets/images/cards.png" />

                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.LESSONS_PAY' |
                                    translate}}: <b>{{ext_lesson.lessons_nr}}</b></p>
                                  <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.WAGE_PAY' |
                                    translate}}: <b>{{ext_lesson.price_per_lesson}}</b></p>
                                  <p class="table-title">TOTAL: <b>{{ext_lesson.price_per_lesson *
                                      ext_lesson.lessons_nr}}</b></p>
                                </div><!-- form-group -->
                              </div>
                              <!--col-md-6-->
                              <div class="col-md-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Prename</mat-label>
                                    <input matInput class="form-input" formControlName='name'>
                                  </mat-form-field>
                                  <!-- <label class="form-label">Prename</label>
                                  <input required type="text" class="form-input" formControlName="firstName"
                                    placeholder="Enter prename here"> -->
                                </div><!-- form-group -->
                              </div>

                              <div class="col-md-6">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Surname</mat-label>
                                    <input matInput class="form-input" formControlName='surname'>
                                  </mat-form-field>
                                </div><!-- form-group -->
                              </div>
                              <!--col-md-6-->
                            </div>
                            <!--row-->
                            <div class="row">

                              <!--col-md-6-->
                              <div class="col-md-12">
                                <div class="form-group">
                                  <mat-form-field class="example-full-width">
                                    <mat-label>Address</mat-label>
                                    <input matInput class="form-input" formControlName='address'>
                                  </mat-form-field>
                                </div><!-- form-group -->
                              </div>
                              <!--col-md-6-->
                            </div>
                            <!--row-->

                            <!-- <div id="dashboard-card"></div> -->

                            <fieldset>

                              <div class="row">
                                <div id="dashboard-card"></div>
                              </div>
                            </fieldset>

                            <button class="btn-pay" [ngClass]="{'btn-disable': this.fullPageLoader}" type="submit"
                              [disabled]="stripeFormGroup.invalid || this.fullPageLoader">
                              {{'OVERVIEW.MODALS.EXTENDED_LESSONS.PAY' | translate}} {{ext_lesson.price_per_lesson *
                              ext_lesson.lessons_nr}} CHF
                            </button>
                          </form>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                      <div class="m-tutor"
                        *ngIf="ext_lesson.status == 'confirmed' && ext_lesson.payment == 'confirmed'">
                        <div class="m-tutor-info">
                          <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.PAID' |
                            translate}}{{ext_lesson.price_per_lesson * ext_lesson.lessons_nr}}
                            CHF</p>
                        </div>
                        <!--m-tutor-info-->
                      </div>

                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <!-- <div class="modal-footer">
                  <button class="btn btn-primary m-btn-book">Done</button>
                </div> -->
              </ng-template>

            </div>
            <!--inner-box-->
          </mat-tab>
        </mat-tab-group>

      </div>
      <!--box-template-->


      <div class="box-template borders fifth-grid">
        <h1 class="general-heading">{{'OVERVIEW.STUDENT.MAIN_TITLE' | translate}}</h1>
        <p class="general-paragraph">{{'OVERVIEW.STUDENT.PARAGRAPH' | translate}}</p>
        <mat-tab-group>

          <mat-tab label="{{'OVERVIEW.STUDENT.MAIN_TITLE' | translate}}">
            <div class="spinner-border" role="status" *ngIf="myTeachersLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="myTeachers?.length == 0">
              <p class="general-paragraph">Empty</p>
            </div>
            <div class="inner-box borders" *ngFor="let teacher of myTeachers">
              <div class="teacher-info">
                <img *ngIf="teacher.teacher_image != ''" [src]="teacher.teacher_image" alt="">
                <div class="teacher-name">
                  <h1 class="general-heading">{{teacher.full_name}}</h1>
                  <!-- <p class="inner-box-p">{{teacher.subjects[0].name}}</p> -->
                </div>
              </div>
              <!--teacher-info-->

              <p class="inner-box-p reschedule" (click)="giveFeedbackModal(giveFeedback, teacher)">
                {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.GIVE_FEEDBACK' | translate}}</p>

              <ng-template #giveFeedback let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">
                    {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.GIVE_FEEDBACK' | translate}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body extend-lesson-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">

                    <div class="m-tutor-body">
                      <form [formGroup]="feedbackForm">



                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.GIVE_FEEDBACK' |
                              translate}}?</p>
                            <ngb-rating [rate]="feedbackRating" [max]="5" [readonly]="false" class="stars-color">rating
                            </ngb-rating>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title">
                              {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.SUBJECT_RATING' | translate}}
                            </p>
                            <mat-form-field appearance="fill">
                              <mat-label>{{'OVERVIEW.MODALS.EXTENDED_LESSONS.SUBJECT' | translate}}</mat-label>
                              <mat-select formControlName="subject">
                                <mat-option *ngFor="let subject of teacher.rating" value="{{subject.id}}"
                                  [disabled]="subject.rating_status">
                                  {{subject.name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor remove-borders">
                          <div class="m-tutor-info">
                            <p class="table-title">
                              {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.TEXT_DESC' | translate}}
                            </p>
                            <mat-form-field class="example-full-width">
                              <mat-label>{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.DESCRIBE' | translate}}</mat-label>
                              <textarea matInput formControlName="raport" class="form-input text-area-style"></textarea>
                            </mat-form-field>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                      </form>
                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button (click)="sendFeedback()" [disabled]="feedbackForm.invalid"
                    class="btn btn-primary m-btn-book">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.SEND_FEEDBACK' |
                    translate}}</button>
                </div>
              </ng-template>
            </div>
            <!--inner-box-->
          </mat-tab>


          <mat-tab label="{{'OVERVIEW.PROPOSALS.EXTENDED' | translate}}">
            <div class="spinner-border" role="status" *ngIf="myTeachersLoader">
              <span class="sr-only">Loading...</span>
            </div>
            <div *ngIf="myTeachers?.length == 0">
              <p class="general-paragraph">Empty</p>
            </div>
            <div class="inner-box inner-box-extend borders" *ngFor="let teacher of myTeachers">
              <div class="teacher-info">
                <img *ngIf="teacher.teacher_image == ''" src="../../../assets/images/{{teacher.gender}}.jpg" alt="">
                <img *ngIf="teacher.teacher_image != ''" [src]="teacher.teacher_image" alt="">

                <div class="teacher-name">
                  <h1 class="general-heading">{{teacher.full_name}}</h1>
                  <p class="inner-box-p">{{teacher.subject}}</p>
                </div>
              </div>
              <!--teacher-info-->
              <!-- <div class="teacher-rating">
                <p class="inner-box-p" style="padding: 0 15px;">{{teacher.subject}}</p>
              </div> -->
              <!--teacher-rating-->

              <p class="inner-box-p reschedule" *ngIf="teacher.can_extend" style="text-align: right;"
                (click)="extendLessonModal(extendLesson, teacher)">
                {{'OVERVIEW.MODALS.EXTENDED_LESSONS.EXTENDED' | translate}}</p>

              <p class="inner-box-p reschedule" *ngIf="!teacher.can_extend && teacher.can_send_proposal" style="text-align: right;"
                (click)="sendToProposal(teacher.id, teacher.subject_id)">
                {{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}</p>

              <ng-template #extendLesson let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.EXTENDED' |
                    translate}}</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body extend-lesson-body" (clickOutside)="onClickedOutside()">
                  <div class="m-tutor-content">

                    <div class="m-tutor-body">
                      <form>

                        <div class="m-tutor">
                          <div class="m-tutor-info">
                            <p class="table-title">
                              {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.INTRO_TEXT.I' | translate}}
                              <b>{{teacher.full_name}}</b> {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.INTRO_TEXT.II' |
                              translate}} <b>{{teacher.subject}}</b>
                              {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.INTRO_TEXT.III' | translate}}
                            </p>


                            <p class="m-tutor-paragraph">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.SUBJECT_SETTINGS' |
                              translate}}:</p>
                            <table class="table">
                              <tbody>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.TEACHER' |
                                    translate}}:</th>
                                  <th scope="col">{{teacher.full_name}}</th>
                                </tr>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.SUBJECT' |
                                    translate}}:</th>
                                  <th scope="col">{{teacher.subject}}</th>
                                </tr>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.PLACE' |
                                    translate}}:</th>
                                  <th scope="col">{{teacher.place}}</th>
                                </tr>
                                <tr class="m-tutor-paragraph">
                                  <th scope="col" class="paragraph-line">
                                    {{'OVERVIEW.MODALS.EXTENDED_LESSONS.EARNING_PER_HOUR' | translate}}:</th>
                                  <th scope="col">{{teacher.lesson_price}} CHF</th>
                                </tr>

                              </tbody>
                            </table>
                            <!-- <p class="m-tutor-paragraph"><span class="paragraph-line">Teacher:</span>
                              {{teacher.full_name}}
                            </p>

                            <p class="m-tutor-paragraph"><span class="paragraph-line">Subject:
                              </span>
                              {{teacher.subject}}
                            </p>


                            <p class="m-tutor-paragraph"><span class="paragraph-line">Place:
                              </span>
                              {{teacher.place}}
                            </p>

                            <p class="m-tutor-paragraph"><span class="paragraph-line">Earnings per hour:
                              </span>
                              {{teacher.lesson_price}}
                            </p> -->

                          </div>
                          <!--m-tutor-info-->

                        </div>


                        <p class="table-title">
                          {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.LESSONS_NR' | translate}}?
                        </p>
                        <div class="m-tutor">

                          <div class="m-tutor-info lessons-counter">
                            <span class="input-number-decrement" (click)="decLessons()">–</span>
                            <input class="input-number" type="text" value="{{lessonsCounter}}" min="0" max="10">
                            <span class="input-number-increment" (click)="incLessons()">+</span>
                          </div>
                          <!--m-tutor-info-->
                        </div>

                        <div class="m-tutor ">
                          <div class="m-tutor-info">

                            <div class="important-info">
                              <h1 class="m-tutor-title">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.IMPORTANT_INFO' |
                                translate}}</h1>

                              <p class="m-tutor-paragraph">
                                {{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.INFO_TEXT' | translate}}
                              </p>
                            </div>
                          </div>
                          <!--m-tutor-info-->
                        </div>
                        <!--m-tutor-->
                      </form>
                    </div>
                    <!--m-tutor-body-->
                  </div>
                  <!--m-tutor-content-->
                </div>
                <div class="modal-footer">
                  <button (click)="sendExtendLesson(teacher)" [disabled]="lessonsCounter <= 0"
                    class="btn btn-primary m-btn-book">{{'OVERVIEW.MODALS.EXTENDED_LESSONS.MODAL.REQUEST' |
                    translate}}</button>
                </div>
              </ng-template>

            </div>
            <!--inner-box-->
          </mat-tab>


        </mat-tab-group>


      </div>
      <!--box-template-->

      <!-- <div class="box-template borders sixth-grid">
        <h1 class="general-heading">My Goals</h1>
        <p class="general-paragraph">My historical spendings on Educagrow</p>
        <div class="inner-box borders" *ngFor="let goal of myGoals">
          <div class="inner-info">
            <h1 class="general-heading">{{goal.title}}</h1>
          </div>
          <div class="inner-schedule">
            <p class="inner-box-p hour">{{goal.start.slice(-5)}} — {{goal.end.slice(-5)}}<br>{{goal.start.slice(0, 10)}} </p>
          </div>
          <fa-icon class="remove-goal" (click)="deleteGoal(goal)" [icon]="goalDelete"></fa-icon>
        </div>
      </div> -->

      <!-- <div class="box-template borders seventh-grid">
        <h1 class="general-heading">Reschedule history </h1>
        <p class="general-paragraph">My historical reschedule history</p>

        <div class="inner-box borders">
          <div class="teacher-info">
            <img src="../../assets/images/teacher.png" alt="">
            <div class="teacher-name">
              <h1 class="general-heading">Skofiar Mustafa</h1>
              <p class="inner-box-p">Geometry</p>
            </div>
          </div>

          <div class="teacher-rating">
            <p class="inner-box-p">6/8</p>
          </div>

          <p>8:00</p>
          <p>9:00</p>
          <p class="inner-box-p reschedule">Extend Lessons</p>
        </div>

      </div> -->

    </div>
    <!--dashboard-->
  </div>
  <!--container-fluid-->
</section>
<!--wrap-->
<ng-template #intro let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Steps</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-horizontal-stepper #stepperAlert>
      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <img src="../../../assets/images/dashboard_one.jpg" alt="">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_I.TITLE' | translate}}
          </p>
          <ul>
            <li>- {{'ALERT.DASHBOARD.STAGE_I.I' | translate}}</li>
            <li>- {{'ALERT.DASHBOARD.STAGE_I.II' | translate}}</li>
          </ul>
        </div>

        <div class="form-buttons first-btn">

          <button mat-button matStepperNext class="right-btn-form">
            <img class="left-rotate" src="../../assets/images/left-arrow-form.png" alt="">
          </button>
        </div>
      </mat-step>
      <mat-step>

        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_II.I' | translate}}
          </p>
          <img src="../../../assets/images/dashboard_two.jpg" alt="">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_II.II' | translate}}
          </p>
        </div>
        <div class="form-buttons">
          <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png" alt="">
          </button>
          <button mat-button matStepperNext class="right-btn-form">
            <img class="left-rotate" src="../../assets/images/left-arrow-form.png" alt="">
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <img src="../../../assets/images/dashboard_three.jpg" alt="">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_III.I' | translate}}
          </p>
        </div>
        <div class="form-buttons">
          <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png" alt="">
          </button>
          <button mat-button matStepperNext class="right-btn-form">
            <img class="left-rotate" src="../../assets/images/left-arrow-form.png" alt="">
          </button>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_IV.TITLE' | translate}}
          </p>
          <img src="../../../assets/images/dashboard_four.jpg" alt="">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_IV.LIST.TITLE' | translate}}
          </p>
          <ul>
            <li>- {{'ALERT.DASHBOARD.STAGE_IV.LIST.I' | translate}}</li>
            <li>- {{'ALERT.DASHBOARD.STAGE_IV.LIST.II' | translate}}</li>
            <li>- {{'ALERT.DASHBOARD.STAGE_IV.LIST.III' | translate}}</li>
            <li>- {{'ALERT.DASHBOARD.STAGE_IV.LIST.IV' | translate}}</li>
          </ul>
        </div>
        <div class="form-buttons">
          <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png" alt="">
          </button>
          <button mat-button matStepperNext class="right-btn-form">
            <img class="left-rotate" src="../../assets/images/left-arrow-form.png" alt="">
          </button>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <p class="m-tutor-paragraph">
            {{'ALERT.DASHBOARD.STAGE_IV.TITLE' | translate}}
          </p>
          <img src="../../../assets/images/dashboard_five.jpg" alt="">

        </div>
        <div class="form-buttons-alert">
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert remove-alert"
            (click)="removeIntroModal()">Don't show it again</button>
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert"
            (click)="modal.dismiss('Cross click')">Done</button>
        </div>

      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <!-- <div class="modal-footer footer-alert">

    <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert" (click)="removeIntroModal()">Don't show it again</button>
    <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert" (click)="modal.dismiss('Cross click')">Done</button>

  </div> -->
</ng-template>
