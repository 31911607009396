<header class="header-01 sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mobile-wrapper">
          <nav class="navbar navbar-expand-lg">
            <!-- logo Start-->
            <a class="navbar-brand" href="index.html">
              <img src="../assets/images/logo-educa.png" alt="">
              <img class="sticky-logo" src="../assets/images/logo-educa.png" alt="">
            </a>
            <!-- logo End-->

            <!-- Moblie Btn Start -->
            <button class="navbar-toggler" type="button">
              <!-- <i class="fal fa-bars"></i> -->
              <fa-icon [icon]="faBars"></fa-icon>
            </button>
            <!-- Moblie Btn End -->

            <!-- Nav Menu Start -->
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="menu-item-has-children">
                  <a routerLink="/">Home</a>

                </li>

                <li class="menu-item-has-children">
                  <a (click)="becomeStudent()" routerLink="/">{{'LABELS.BECOME_STUDENT' | translate}}</a>
                </li>
                <li class="menu-item-has-children">
                  <a (click)="becomeTutor()" routerLink="/tutor">{{'HOMEPAGE.NAV.BECOME_TUTOR' | translate}}</a>
                </li>
                <!-- <li class="menu-item-has-children">
                  <a>{{'HOMEPAGE.NAV.GROUP_TUTORING' | translate}}</a>
                </li> -->
                <li>
                  <a routerLink="/about">{{'HOMEPAGE.NAV.ABOUT_US' | translate}}</a>
                </li>
                <li class="nav-item flag-item">

                  <div class="dropdown d-inline-block" ngbDropdown>
                    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                      <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16"> <span
                        class="ml-1">{{countryName}}</span>
                      <img *ngIf="flagvalue === undefined" src="{{valueset}}" height="16">
                      <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right flag-menu" ngbDropdownMenu>

                      <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                        (click)="setLanguage(item.text, item.lang, item.flag)"
                        [ngClass]="{'active': cookieValue === item.lang}">
                        <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
                          class="align-middle">{{item.text}}</span>
                      </a>

                    </div>
                  </div>
                </li>

                <!-- Join Btn -->
                <a routerLink="/login" class="join-btn">{{'LOGIN_PAGE.SIGN_IN' | translate}}</a>
                <!-- Join Btn -->
              </ul>
            </div>
            <!-- Nav Menu End -->


          </nav>
        </div>

      </div>
    </div>
  </div>
</header>

<section class="page-banner" style="background-image: url('../../../assets/images/4.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="banner-title">Group Tutoring</h2>

      </div>
    </div>
  </div>
</section>


<section class="coursepage-section-2">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="toolbar-wrapper-2">
          <ul class="toolbar-btn nav nav-tabs">
            <li><a class="active" href="#grid" data-toggle="tab"><i class="icon_grid-2x2"></i>Grid</a></li>
            <li><a href="#list" data-toggle="tab"><i class="icon_menu"></i>List</a></li>
          </ul>

        </div>
        <!-- Tab Content -->
        <div class="tab-content">
          <!-- Grid Tab -->
          <div class="tab-pane fade show in active" id="grid" role="tabpanel">

            <div class="row">
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Computer Science</a>
                    <h4><a href="single-course.html">Using Creative Problem Solving</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Art & Design</a>
                    <h4><a href="single-course.html">The Art of Black and White Photography</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Business Study</a>
                    <h4><a href="single-course.html">Learning jQuery mobile for Beginners</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Data Science</a>
                    <h4><a href="single-course.html">Buddhism and modern Psychology</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>UI/UXDesign</a>
                    <h4><a href="single-course.html">Fundamentals of<br> UI Design</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Web Development</a>
                    <h4><a href="single-course.html">Making music with Other people</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>It Solution</a>
                    <h4><a href="single-course.html">How to Make Beautiful Landscape Photos?</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>App Design</a>
                    <h4><a href="single-course.html">Adobe XD: Prototyping Tips and Tricks</a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 course-bottom">
                <div class="feature-course-item-3 fci-3">
                  <div class="fcf-thumb">
                    <img src="../../../assets/images/course-1.jpg" alt="">
                    <a class="enroll" href="#">Enroll Now</a>
                  </div>
                  <div class="fci-details">
                    <a href="#" class="c-cate"><i class="icon_tag_alt"></i>Learning</a>
                    <h4><a href="single-course.html">Fundamental basic of learning english </a></h4>
                    <div class="author">
                      <img src="../../../assets/images/a1.png" alt="">
                      <a href="#">Teacher's name</a>
                    </div>
                    <div class="price-rate">
                      <div class="course-price">
                        25$

                      </div>
                      <div class="ratings">
                        <i class="icon_star"></i>
                        <span>23.05.2022</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 text-center">
                <a href="#" class="bisylms-btn load-btn">Load More<i class="icon_loading"></i></a>
              </div>
            </div>
            <!-- Pagination -->
          </div>
          <!-- Grid Tab -->

          <!-- List Tab -->
          <div class="tab-pane fade in" id="list" role="tabpanel">

            <div class="course-item-3 ci-3-color">
              <div class="ci-thumb">
                <img src="../../../assets/images/p1.jpg" alt="">
                <a href="#" class="c-cate">Software</a>
              </div>
              <div class="course-details">
                <img class="line-bg" src="assets/images/home3/line.jpg" alt="">
                <div class="fcf-bottom">
                  <a href="#"><i class="icon_book_alt"></i>14 Lessons</a>
                  <a href="#"><i class="icon_profile"></i>203</a>
                </div>
                <h4><a href="single-course.html">The Art of Black and White Photography</a></h4>
                <p>
                  Discover how to become a successful Project Manager with this free online introductory course.
                </p>
                <div class="author">
                  <img src="../../../assets/images/author.png" alt="">
                  <a href="#">Anthony</a>
                </div>
                <div class="price-rate">
                  <div class="course-price">
                    $42.76
                    <span>$250.85</span>
                  </div>
                  <div class="ratings">
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <span>4.5 (2 Reviews)</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-item-3 ci-3-color">
              <div class="ci-thumb">
                <img src="../../../assets/images/p1.jpg" alt="">
                <a href="#" class="c-cate">Data Science</a>
              </div>
              <div class="course-details">
                <img class="line-bg" src="assets/images/home3/line.jpg" alt="">
                <div class="fcf-bottom">
                  <a href="#"><i class="icon_book_alt"></i>18 Lessons</a>
                  <a href="#"><i class="icon_profile"></i>228</a>
                </div>
                <h4><a href="single-course.html">Buddhism and Modern Psychology</a></h4>
                <p>
                  Discover how to become a successful Project Manager with this free online introductory course.
                </p>
                <div class="author">
                  <img src="../../../assets/images/author.png" alt="">
                  <a href="#">Anthony</a>
                </div>
                <div class="price-rate">
                  <div class="course-price">
                    $65.50
                    <span>$220.65</span>
                  </div>
                  <div class="ratings">
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <span>4.5 (2 Reviews)</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-item-3 ci-3-color">
              <div class="ci-thumb">
                <img src="../../../assets/images/p1.jpg" alt="">
                <a href="#" class="c-cate">Software</a>
              </div>
              <div class="course-details">
                <img class="line-bg" src="assets/images/home3/line.jpg" alt="">
                <div class="fcf-bottom">
                  <a href="#"><i class="icon_book_alt"></i>10 Lessons</a>
                  <a href="#"><i class="icon_profile"></i>199</a>
                </div>
                <h4><a href="single-course.html">Learning jQuery Mobile for Beginners</a></h4>
                <p>
                  Discover how to become a successful Project Manager with this free online introductory course.
                </p>
                <div class="author">
                  <img src="../../../assets/images/author.png" alt="">
                  <a href="#">Anthony</a>
                </div>
                <div class="price-rate">
                  <div class="course-price">
                    $42.76
                    <span>$250.85</span>
                  </div>
                  <div class="ratings">
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <i class="icon_star"></i>
                    <span>4.5 (2 Reviews)</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col-lg-12 text-center">
                <a href="#" class="bisylms-btn load-btn">Load More<i class="icon_loading"></i></a>
              </div>
            </div>
            <!-- Pagination -->
          </div>
          <!-- Grid Tab -->
        </div>
        <!-- Tab Content -->
      </div>
    </div>
  </div>
</section>
<footer class="footer">
  <div class="container">
    <div class="footer__content row">
      <div class="col-md-4 footer-flex">
        <div class="footer__content_logo">
          <a href=""><img src="../assets/images/logo-educa.png"></a>

        </div>
        <p class="copy">EducaGrow &copy; {{currentYear}}</p>
      </div>
      <!--col-md-6-->
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" routerLink='/impressum'>Impressum</li>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" (click)="contactModal(contactInfo)">Kontakt</li>
            <ng-template #contactInfo let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Contact Info</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="general-heading">E-mail: <a href="mailto:info@educagrow.com">info@educagrow.com</a></p>
                <p class="general-heading contact-margin">Phone: <a href="tel:0763327653">0763327653</a></p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Done</button>
              </div>
            </ng-template>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>

      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title">Verbesserungsvorschläge</li>

          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
    </div>
    <!--footer__content-->
  </div>
  <!--container-->
</footer>
