import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactComponent } from './contact/contact.component';
import { DashboardProposalComponent } from './dashboard/dashboard-proposal/dashboard-proposal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EngagementsComponent } from './engagements/engagements.component';
import { MessengerComponent } from './messenger/messenger.component';
import { PaymentsComponent } from './payments/payments.component';
import { ProfileComponent } from './profile/profile.component';
import { BookingComponent } from './tutors/booking/booking.component';
import { ProposalComponent } from './tutors/proposal/proposal.component';
import { TutorsComponent } from './tutors/tutors.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'dashboard/proposal/:id',
    component: DashboardProposalComponent
  },

  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'engagements',
    component: EngagementsComponent
  },
  {
    path: 'tutors',
    component: TutorsComponent
  },
  {
    path: 'tutors/proposal/:id',
    component: ProposalComponent
  },
  {
    path: 'tutors/booking/:id',
    component: BookingComponent
  },
  {
    path: 'calendar',
    component: CalendarComponent
  },
  {
    path: 'messenger',
    component: MessengerComponent
  },
  {
    path: 'payments',
    component: PaymentsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  // {
  //   path: '**', redirectTo: '/student/dashboard', pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }
