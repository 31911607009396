<div class="calendar">
  <div class="container-fluid">
    <div class="calendar__header">
      <!-- <h1 class="main-title">Current Engagements</h1>
      <div class="filters">
        <button (click)="onTest()" class="form-input btn-filter">Filtering <img src="../../assets/images/Filter.png" alt=""> </button>
        <form class="form-filter">

        </form>
      </div> -->
      <!--filters-->
    </div>
    <!--calendar__header-->
    <div class="calendar__body">
      <div class="calendar-buttons">
        <button (click)="today()" class="btn btn-today" >{{'CALENDAR.TODAY_BTN' | translate}}</button>
        <mat-form-field class="go-to-date" appearance="fill">
          <mat-label>{{'CALENDAR.DATE_BTN' | translate}}</mat-label>
          <input (dateChange)="goToDate($event)" matInput [matDatepicker]="picker">
          <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <!-- <input class="fc-date-button" type="date"> -->

        <button class="fc-appointment-button" (click)="createAppointmentModal(appointment)">{{'CALENDAR.APPOINTMENT_BTN' | translate}}</button>
      </div>
      <!--calendar-buttons-->
      <div *ngIf="showCalendar" class="parent-spinner">
        <div class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <full-calendar [ngClass]="{'show-calendar': showCalendar}" deepChangeDetection="true" id="calendar" #calendar [options]="calendarOptions"></full-calendar>
    </div>
    <!--calendar__body-->
  </div>
  <!--container-fluid-->
</div>
<!--calendar-->

<div class="goals">
  <h1>this is a goal</h1>
</div>

<ng-template #appointment let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'CALENDAR.EDIT_AVAILABILITY' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" (clickOutside)="onClickedOutside()">
    <div class="m-tutor-content">


      <div class="m-tutor-body">
        <form [formGroup]="calendarAppointment">
          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">{{'CALENDAR.TEACHER' | translate}}</mat-label>
                <mat-select formControlName="app_teacher" (selectionChange)="selectTeacher($event)">
                  <mat-option value="{{teacher.id}}" *ngFor="let teacher of teacherAppointment">
                    {{teacher.name}} {{teacher.surname}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">{{'CALENDAR.SUBJECT' | translate}}</mat-label>
                <mat-select formControlName="app_subject" (selectionChange)="selectSubject()">
                  <mat-option value="{{subject.id}}" *ngFor="let subject of subjectAppointment">
                    {{subject.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label>{{'CALENDAR.APPOINT_DATE' | translate}}</mat-label>
                <input (dateChange)="selectDate($event)" formControlName="app_date" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker>w</mat-datepicker>
                <mat-error *ngIf="calendarAppointment.invalid">please selct a date from tomorrow</mat-error>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <p class="m-tutor-paragraph">{{'CALENDAR.AVAILABLE_DAY' | translate}}<b>{{weekDay}}</b></p>
              <div class="desired-time">
                <div class="time testTime" *ngFor="let time of timesAppointment">
                  <input type="checkbox" value="{{time.id}}" (change)="selectTime($event, time)">
                  {{time.time_start}} - {{time.time_end}}
                </div>
              </div>
              <!-- <div class="desired-time">
                <div class="time testTime" [ngClass]="{'disable-time': time.selected == 'true'}"
                  *ngFor="let time of appointmentObject">
                  <input type="checkbox" [disabled]="time.selected == 'true'" value="{{time.id}}"
                    (change)="selectTime($event)">
                  {{time.time}}
                </div>
                <p *ngIf="reschTimes[0]?.length == 0" class="m-tutor-paragraph">This teacher has no available timeslot at this date</p>
              </div> -->

            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">Tutoring Place</mat-label>
                <mat-select formControlName="app_tutoring_place">
                  <mat-option value="{{place.id}}" *ngFor="let place of tutoringPlaceAppointment">
                    {{place.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>



        </form>

      </div>
      <!--m-tutor-body-->
    </div>
    <!--m-tutor-content-->
  </div>
  <div class="modal-footer">
    <button _ngcontent-cvl-c248="" [disabled]='calendarAppointment.invalid' (click)="calendarAppointmentCalendar()" class="btn btn-primary m-btn-book">{{'CALENDAR.CREATE_APPOINTMENT' | translate}}</button>
  </div>
</ng-template>

<ng-template #modalGoal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'CALENDAR.EDIT_AVAILABILITY' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" (clickOutside)="onClickedOutside()">
    <div class="m-tutor-content">

      <div class="m-tutor-profile">

        <p class="inner-box-p"><b>Time of the goal: {{ timeModal | date:'short'}}</b></p>
      </div>
      <!--m-tutor-profile-->
      <!--m-tutor-profile-->
      <div class="m-tutor-body">
        <form [formGroup]="goalForm">
          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field class="example-full-width">
                <mat-label>Title of the goal</mat-label>
                <input class="form-input" formControlName="title" matInput placeholder="Enter goal here">
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field class="example-full-width">
                <mat-label>Description of the goal</mat-label>
                <input class="form-input" formControlName="description" matInput placeholder="Enter description here">
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">Tutoring place</mat-label>
                <mat-select formControlName="tutoring_place">
                  <mat-option value="{{place.id}}" *ngFor="let place of tutoring_place">
                    {{place.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">Teachers</mat-label>
                <mat-select formControlName="teacher">
                  <mat-option value="{{teacher.id}}" *ngFor="let teacher of teachers">
                    {{teacher.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>

          <div class="m-tutor">
            <div class="m-tutor-info">
              <mat-form-field appearance="fill">
                <mat-label class="label-custom">Subjects</mat-label>
                <mat-select formControlName="subject">
                  <mat-option value="{{subject.id}}" *ngFor="let subject of subjects">
                    {{subject.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!--m-tutor-info-->
          </div>
        </form>

      </div>
      <!--m-tutor-body-->
    </div>
    <!--m-tutor-content-->
  </div>
  <div class="modal-footer">
    <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-book" (click)="saveGoal()">Save</button>
  </div>
</ng-template>


