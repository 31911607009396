import { ChangeDetectorRef, Component, DoCheck, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StudentService } from 'src/app/core/_services/student.service';
import { AuthService } from 'src/app/core/_services/auth.service';
const FilterWords = require('bad-words');

import {
  faInfoCircle,
  faClock,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faHome,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faEnvelopeOpenText
} from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss']
})
export class ProposalComponent implements OnInit {

  tutor;
  times = [
    {
      id: 1,
      status: "active",
      time: "08:00-09:00"
    },
    {
      id: 2,
      status: "active",
      time: "09:00-10:00"
    },
    {
      id: 3,
      status: "active",
      time: "10:00-11:00"
    },
    {
      id: 4,
      status: "active",
      time: "11:00-12:00"
    },
    {
      id: 5,
      status: "active",
      time: "12:00-13:00"
    },
    {
      id: 6,
      status: "active",
      time: "13:00-14:00"
    },
    {
      id: 7,
      status: "active",
      time: "14:00-15:00"
    },
    {
      id: 8,
      status: "active",
      time: "15:00-16:00"
    },
    {
      id: 9,
      status: "active",
      time: "16:00-17:00"
    },
    {
      id: 10,
      status: "active",
      time: "17:00-18:00"
    },
    {
      id: 11,
      status: "active",
      time: "18:00-19:00"
    },
    {
      id: 12,
      status: "active",
      time: "19:00-20:00"
    },
    {
      id: 13,
      status: "active",
      time: "20:00-21:00"
    },
    {
      id: 14,
      status: "active",
      time: "21:00-22:00"
    }
  ]

  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign
  percentRate = faPercent
  overview = faEye;
  calendarIcon = faCalendarAlt;
  faHome = faHome;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faEnvelopeOpenText = faEnvelopeOpenText;
  studentId;
  timeArray;
  teacherId;

  subjectId;

  fullPageLoader = false;
  allTutors = [];

  previous = false;
  next = false;
  lastElement;
  firstElement;

  showQuestion = false;
  filter;

  questionForm;

  subjectDescription;

  constructor(private studentService: StudentService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private ref: ChangeDetectorRef,
    private authService: AuthService) {

    //this.subscribeRouteChange();


  }

  ngOnInit(): void {
    this.getIds();
    this.retrieveMatchedTutors();
    this.retrieveProposal();

    this.route.paramMap.subscribe((params: ParamMap) => {

      this.getIds();
      this.retrieveMatchedTutors();
      this.retrieveProposal();

    })

    this.questionForm = this.formBuilder.group({
      message: ['', Validators.required],
    })

  }




  getIds() {
    this.route.queryParamMap.subscribe(data => {
      this.subjectId = data['params'].subject_id;
    })

    this.teacherId = this.route.snapshot.paramMap.get('id');


  }

  retrieveMatchedTutors() {
    console.log('teacherIDPAram', this.teacherId, this.subjectId)
    this.studentService.getMatchedTutors().subscribe(data => {
      console.log('mtutor', data)
      this.allTutors = [];
      data.data.subjects.forEach(element => {
        element.teachers.forEach(nestedEl => {
          this.allTutors.push({
            subject_id: element.id,
            proposal_id: nestedEl.id
          })
        })
      });
      console.log('loop', this.allTutors)
      this.blockNext();
    })
  }


  retrieveProposal() {
    const id = this.route.snapshot.paramMap.get('id');

    console.log('ids', this.teacherId, this.subjectId)

    this.studentService.getMatchedTutorStatus(id, this.subjectId).subscribe(data => {
      console.log('teacher', data)
      this.tutor = data.teacher;
      //this.teacherId = data.teacher['user']['id'];

      this.authService.getUser().subscribe(dataUser => {
        console.log('user', dataUser)
        //console.log('teacherId', this.teacherId)
        this.studentId = dataUser.data.user.id;

        dataUser.data.user.subjects.forEach(subject => {
          if (this.subjectId == subject.id) {
            this.subjectDescription = subject.pivot_student.describe;
          }
        });

        this.studentService.getMatchedTimes(this.studentId, this.teacherId).subscribe(data => {
          console.log('times', data)
          this.timeArray = data;
          this.timeArray.forEach(element => {
            if (element.day == 'monday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#monday${nesetedElement.id}`).prop('checked', true);
                  $(`#monday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#monday${nesetedElement.id}`).prop('checked', true);
                  $(`#monday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#monday${nesetedElement.id}`).prop('checked', true);
                //   $(`#monday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'tuesday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#tuesday${nesetedElement.id}`).prop('checked', true);
                  $(`#tuesday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#tuesday${nesetedElement.id}`).prop('checked', true);
                  $(`#tuesday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#tuesday${nesetedElement.id}`).prop('checked', true);
                //   $(`#tuesday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'wednesday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#wednesday${nesetedElement.id}`).prop('checked', true);
                  $(`#wednesday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#wednesday${nesetedElement.id}`).prop('checked', true);
                  $(`#wednesday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#wednesday${nesetedElement.id}`).prop('checked', true);
                //   $(`#wednesday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'thursday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#thursday${nesetedElement.id}`).prop('checked', true);
                  $(`#thursday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#thursday${nesetedElement.id}`).prop('checked', true);
                  $(`#thursday${nesetedElement.id}`).parent().addClass('blue-time')
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#thursday${nesetedElement.id}`).prop('checked', true);
                //   $(`#thursday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'friday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#friday${nesetedElement.id}`).prop('checked', true);
                  $(`#friday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#friday${nesetedElement.id}`).prop('checked', true);
                  $(`#friday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#friday${nesetedElement.id}`).prop('checked', true);
                //   $(`#friday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'saturday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#saturday${nesetedElement.id}`).prop('checked', true);
                  $(`#saturday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#saturday${nesetedElement.id}`).prop('checked', true);
                  $(`#saturday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#saturday${nesetedElement.id}`).prop('checked', true);
                //   $(`#saturday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }
            else if (element.day == 'sunday') {
              element.times.forEach(nesetedElement => {
                if (nesetedElement.belongs_to == 'both') {
                  $(`#sunday${nesetedElement.id}`).prop('checked', true);
                  $(`#sunday${nesetedElement.id}`).parent().addClass('green');
                } else if (nesetedElement.belongs_to == 'student') {
                  $(`#sunday${nesetedElement.id}`).prop('checked', true);
                  $(`#sunday${nesetedElement.id}`).parent().addClass('blue-time');
                }
                // else if (nesetedElement.belongs_to == 'teacher') {
                //   $(`#sunday${nesetedElement.id}`).prop('checked', true);
                //   $(`#sunday${nesetedElement.id}`).parent().addClass('colorTime');
                // }
              });
            }

          });

        })
      })

    })
  }


  blockNext() {
    let index = this.allTutors.findIndex(el => (el.proposal_id == this.teacherId && el.subject_id == this.subjectId));

    this.lastElement = this.allTutors[this.allTutors.length - 1];
    let lastIndex = this.allTutors.lastIndexOf(this.lastElement);

    let firstElement = this.allTutors[0];
    let firstIndex = this.allTutors.lastIndexOf(firstElement);

    if (lastIndex == index) {
      console.log('test')
      this.next = true;
    } else if (firstIndex == index) {
      this.previous = true;
    }
  }


  sendPropsals() {

    let replaceWords = '';
    let message = '';



    // filterWord = this.questionForm.value.message.replace(/(^|\W)+41(\w+)/g,'***');
    replaceWords = this.questionForm.value.message
      .replaceAll('+41', '###')
      .replaceAll('+ 4 1', '###')
      .replaceAll('+ 41', '###')
      .replaceAll('+4 1', '###')
      .replaceAll('0041/', '###')
      .replaceAll('079', '###')
      .replaceAll('0 7 9', '###')
      .replaceAll('07 9', '###')
      .replaceAll('0 79', '###')
      .replaceAll('076', '###')
      .replaceAll('0 7 6', '###')
      .replaceAll('07 6', '###')
      .replaceAll('0 76', '###')
      .replaceAll('@', '###');

    message = replaceWords.replace(/(^|\W)#(\w+)/g, '#####')


    console.log(message)

    this.fullPageLoader = true;
    console.log(this.teacherId, this.subjectId)
    this.studentService.proposals(this.teacherId, this.subjectId, message).subscribe(res => {
      console.log(res)
      this.retrieveProposal();
      this.fullPageLoader = false;
    }, (err) => {
      console.log(err)
      this.retrieveProposal();
      this.fullPageLoader = false;
    })

  }



  nextPage() {
    let nextPage;

    let index = this.allTutors.findIndex(el => (el.proposal_id == this.teacherId && el.subject_id == this.subjectId));


    nextPage = this.allTutors[index + 1];


    this.router.navigate([`student/tutors/proposal/`, nextPage.proposal_id],
      {
        queryParams: { subject_id: nextPage.subject_id },

        queryParamsHandling: 'merge',
      }).then(res => {
        window.location.reload();
      })
  }

  backPage() {
    let nextPage;
    let firstElement;

    let index = this.allTutors.findIndex(el => (el.proposal_id == this.teacherId && el.subject_id == this.subjectId));



    nextPage = this.allTutors[index - 1];

    this.router.navigate([`student/tutors/proposal/`, nextPage.proposal_id],
      {
        queryParams: { subject_id: nextPage.subject_id },

        queryParamsHandling: 'merge',
      }).then(res => {
        window.location.reload();
      })
  }

  question(e) {
    console.log(e)
    if (e.value == 'yes') {
      this.showQuestion = true;
    }
    else if (e.value == 'no') {
      this.showQuestion = false;
    }
  }

  getBadWords(str) {
    let badwords = ['spider', 'monkey', 'pig']
    let words = []
    badwords.forEach(word =>
      str.replace(/ /g, '').toLowerCase()
        .includes(word.replace(/ /g, '').toLowerCase()) ? words.push(word) : null)
    return words
  }

  message = '';
  passWord = [];
  checkMessage(e) {
    let filterMeesage;
    console.log(e)

    let value = '';
    let wordArray = [];
    this.message += e.key;

    wordArray = this.message.split(' ');

    value = this.questionForm.value.message;

    // if (value.includes('+41')) {

    //   Swal.fire({
    //     title: "You can't use this word" + '+14',
    //     icon: 'info',
    //     confirmButtonText: 'OK'
    //   })
    //   this.questionForm.get('message').setValue('');
    // }

    // wordArray.forEach(sentence => {

    //   this.passWord.push(sentence)
    //   if (sentence.includes('+41') || sentence.includes('+42')) {
    //     let exludedWord = this.passWord.splice(this.passWord.length - 1).join(" ");
    //     // console.log('pass', this.passWord.splice(this.passWord.length - 1))
    //     this.passWord = [];
    //     Swal.fire({
    //       title: "You can't user this " + exludedWord,
    //       icon: 'info',
    //       confirmButtonText: 'OK'
    //     })
    //     this.questionForm.get('message').setValue('');
    //   }

    // })


    // if(this.message.includes("+41")){

    //   console.log('wrong')
    //   this.message.replace('')
    // }

  }

}
