<div class="contact">
  <div *ngIf="fullPageLoader" class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="container">
    <div class="student__title">
      <h1 class="main-title">{{'CONTACT.TITLE' | translate}}</h1>
    </div>

    <div class="contact-boxes" *ngIf="!sendMessage">
      <div class="box mail-box button-inverted" (click)="startMessage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="currentColor">
					<path d="M19.21 5.77H4.8a1.18 1.18 0 00-1.14 1.2v10.05a1.18 1.18 0 001.14 1.21H19.2a1.18 1.18 0 001.14-1.2V6.97a1.18 1.18 0 00-1.14-1.21zM4.6 6.69a.27.27 0 01.2-.1H19.2a.27.27 0 01.2.1l.09.1-7.5 5.4-.07-.04L4.5 6.78zm14.93 10.33a.36.36 0 01-.3.38H4.78a.36.36 0 01-.31-.38V7.8l7.36 5.29a.35.35 0 00.32 0l.03-.02a.42.42 0 01.05-.02l7.28-5.25z"></path>
				</svg>
        <p>Write us a message</p>
      </div>
      <a href="https://tawk.to/chat/61adf95a80b2296cfdd03c17/1fm7pc2vc" class="box chat-box button-inverted">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" fill="currentColor">
					<path d="M6.86 19.74a.42.42 0 00.22.22.42.42 0 00.32 0 .43.43 0 00.13-.09l3.4-3.4h5.83a3.6 3.6 0 003.59-3.59V7.6A3.6 3.6 0 0016.76 4H7.24a3.6 3.6 0 00-3.59 3.6v5.28a3.6 3.6 0 003.08 3.54l.1.01v3.15a.43.43 0 00.03.16zm-2.38-6.86V7.6a2.76 2.76 0 012.76-2.76h9.52a2.76 2.76 0 012.76 2.76v5.3a2.76 2.76 0 01-2.76 2.75h-6a.43.43 0 00-.15.03.4.4 0 00-.14.1l-2.82 2.81v-2.53a.42.42 0 00-.41-.41 2.76 2.76 0 01-2.76-2.76z"></path>
					<circle cx="8.45" cy="10.24" r=".7"></circle>
					<circle cx="12" cy="10.24" r=".7"></circle>
					<circle cx="15.55" cy="10.24" r=".7"></circle>
				</svg>
        <p>Chat with us</p>
      </a>
    </div>

    <div class="info" *ngIf="sendMessage">
      <form [formGroup]="contactForm">
        <div class="row">
          <div class="col-md-5">
            <div class="form-group">
              <h3 class="general-heading">{{'CONTACT.NAME' | translate}}: <b>{{user?.name}}</b></h3>
              <h3 class="general-heading">{{'CONTACT.DATE' | translate}}: <b>{{currentDate | date:'dd/mm/yyyy'}}</b></h3>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <mat-form-field class="date-custom">
                <mat-label>{{'CONTACT.TOPIC' | translate}}:</mat-label>
                <input matInput class="form-input" formControlName="topic">
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <mat-form-field class="date-custom">
                <mat-label>{{'CONTACT.SMS' | translate}}:</mat-label>
                <textarea matInput class="form-input text-area-style" formControlName="description"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <button
              class="btn btn-primary m-btn-book button"
              (click)="sendQuestion()"
              [disabled]="this.contactForm.invalid">{{'CONTACT.BTN' | translate}}</button>
            </div>
          </div>
        </div>
      </form>
      <hr>
      <p class="general-heading footer-text ">{{'CONTACT.FOOTER_SMS' | translate}}: <br><span>+41 76 332 76 53</span></p>
      <div class="logo">
        <a class="navbar-brand" href="#"><img src="../../assets/images/logo-educa.png" alt=""></a>
      </div>
    </div>


  </div>
</div>
