import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CalendarOptions, Calendar, NowIndicatorRoot } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { StudentService } from 'src/app/core/_services/student.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { dateValidatorTimeslot } from '../../core/_shared/validator';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [DatePipe]
})
export class CalendarComponent implements OnInit, AfterViewInit {
  events;

  timeModal;

  calendarLang;

  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  @ViewChild('modalGoal', { read: TemplateRef }) mGoal: TemplateRef<any>;
  @ViewChild('intro', { read: TemplateRef }) private introModal: TemplateRef<any>;

  closeResult = '';
  appointmentForm: FormGroup;
  calendarOptions: CalendarOptions = {
    nowIndicator: true,
  };
  goalForm: FormGroup;
  calendarAppointment: FormGroup;

  //createAppointmentModal variables
  teacherAppointment;
  subjectAppointment;
  tutoringPlaceAppointment;
  timesAppointment;
  weekDay;
  appointmentObject = {
    times: []
  };

  appointmentData = {};

  times;
  getValue = [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    },
  ];

  tutoring_place;
  teachers;
  subjects

  showCalendar = true;

  temporaryEvents;

  lang;

  constructor(private formBuilder: FormBuilder,
    private studentService: StudentService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public modalService: NgbModal,
    private dateAdapter: DateAdapter<Date>,
    private cdr: ChangeDetectorRef) {

      this.lang = localStorage.getItem('lang') || 'de';
      this.dateAdapter.setLocale(this.lang);

    // this.studentService.geTutoringPlaces().subscribe(data => {

    //   this.tutoring_place = data.data;
    // })
    // this.studentService.getTutoringStyle().subscribe(data => {
    //   this.tutoring_style = data.data;
    // })

    this.calendarLang = localStorage.getItem('lang');

    this.studentService.getCalendarData().subscribe(data => {
      this.tutoring_place = data.tutoring_place;
      this.teachers = data.teachers;
      this.subjects = data.subjects;
    })

    // this.studentService.getTimes().subscribe(data => {
    //   console.log('times', data)
    //   this.times = data.data;
    // })

    this.studentService.getTemporaryCalendar().subscribe(data => {
      console.log('temp', data)
      this.temporaryEvents = data.data;
    })

  }

  ngOnInit(): void {

    this.renderEvents();

    this.goalForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      tutoring_place: ['', [Validators.required]],
      teacher: ['', [Validators.required]],
      subject: ['', [Validators.required]],
    })

    this.calendarAppointment = this.formBuilder.group({
      app_teacher: ['', [Validators.required]],
      app_subject: ['', [Validators.required]],
      app_date: [{ value: '', disabled: true }, [Validators.required, dateValidatorTimeslot]],
      app_tutoring_place: ['', [Validators.required]],
    })


    $(window).on('load', () => {
      this.calendarViewMobile()
    })

    $(window).on('resize', () => {
      //this.calendarViewMobile()
    });

  }

  ngAfterViewInit() {
    this.calendarComponent.getApi().changeView('timeGridWeek');
    if ($(window).innerWidth() <= 760) {
      this.calendarComponent.getApi().changeView('listMonth');
    }
  }







  saveAvailability() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, edit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentService.saveTimeAvailability(this.getValue).subscribe(data => {
          Swal.fire(
            'Changed!',
            'Your time availability has been changed.',
            'success'
          )
        })
        this.modalService.dismissAll();
      }
    })
  }

  renderEvents() {
    this.studentService.getEvents().subscribe(data => {
      console.log('calendar', data)
      this.events = data.data;
      const rescheduled = [];

      this.events.forEach(element => {
        if (element.reschedule != null) {
          rescheduled.push(element.reschedule[0])
        }
      });
      console.log('res', rescheduled)
      this.calendarOptions = {
        nowIndicator: true,
        slotEventOverlap: false,
        headerToolbar: {
          start: 'title prev,next', // will normally be on the left. if RTL, will be on the right
          center: '',
          end: '' // will normally be on the right. if RTL, will be on the left
        },


        loading: (isLoading) => {
          if (isLoading) {
            this.showCalendar = false;
            this.cdr.detectChanges();
          }
        },
        titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
        initialView: 'timeGridWeek',
        dayMaxEventRows: 1,
        selectable: true,
        allDaySlot: false,
        slotDuration: '00:60:00',
        slotMinTime: '08:00:00',
        //dateClick: this.openModal.bind(this),
        eventSources: [
          this.events,
          rescheduled,
          this.temporaryEvents
        ],
        //events: this.events,
        eventTimeFormat: { // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          meridiem: true
        },
        dayHeaderFormat: {
          day: "numeric",
          weekday: "long",
        },
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: true,
          hour12: false
        },
        firstDay: 1,
        // eventDidMount: function (info) {
        //   console.log('elemt',info)
        //   let p = document.createElement("p")
        //   info.el.children.appendChild(info.event.extendedProps.teacher);
        // },
        eventDidMount: function (info) {

          info.el.insertAdjacentHTML('afterbegin', `<div>${info.event.extendedProps.teacher} - ${info.event.extendedProps.subject}</div>`);
        },
        locale: this.calendarLang,

      };
    })
  }

  fetchEvents() {
    this.studentService.getEvents().subscribe(data => {

      this.events = data.data;
    })
  }


  position() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Goal has been saved',
      showConfirmButton: false,
      timer: 2000
    });
  }

  today() {
    this.calendarComponent.getApi().today();
  }

  timeOptions = { hour12: false };
  openModal(e) {

    this.timeModal = this.datePipe.transform(e.date.toLocaleString('en-US', this.timeOptions), 'yyyy-MM-dd HH:mm')
    this.modalService.open(this.mGoal).dismissed.subscribe(data => {
      this.timeModal = '';
      this.goalForm.reset();
      // this.goalForm = this.formBuilder.group({
      //   title: '',
      //   description: '',
      //   tutoring_place: '',
      //   teacher: '',
      //   subject: '',
      // })
    })
  }


  calendarViewMobile() {
    if ($(window).innerWidth() <= 760) {
      this.calendarComponent.getApi().changeView('listMonth');
    } else {
      this.calendarComponent.getApi().changeView('timeGridWeek');
    }
  }



  // breakWord() {
  //   setTimeout(() => {
  //     var arrClass = document.querySelectorAll(".fc-col-header-cell-cushion ");
  //     for (let i = 0; i < arrClass.length; i++) {

  //       var innerArr = arrClass[i].innerHTML;
  //       var test = innerArr.split(' ');
  //       //console.log(test)
  //       arrClass[i].innerHTML = `${test[1]} <br> ${test[0]}`;
  //     }
  //   }, 50)
  // }



  goToDate(e) {
    this.calendarComponent.getApi().gotoDate(e.value)
  }

  // addEvent() {
  //   this.calendarComponent.getApi().addEvent({
  //     title: 'event', // a property!
  //     start: '2021-03-24T10:00:00', // a property!
  //   })
  // }

  saveGoal() {
    let goalObject = {};
    goalObject = this.goalForm.value;
    goalObject['time'] = this.timeModal;

    this.studentService.sendGoal(goalObject).subscribe(data => {
      this.renderEvents();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Goal has been saved',
        showConfirmButton: false,
        timer: 2000
      });
    }, (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    })


    this.modalService.dismissAll();

  }


  selectTime(e, time) {
    console.log(e, time)
    if (e.target.checked === true) {
      e.target.parentNode.classList.add('colorTime');
      this.appointmentObject['times'].push({
        time_id: e.target.defaultValue,
        day_id: time.pivot.time_id,
        day: time.pivot.day
      })

    } else {
      e.target.parentNode.classList.remove('colorTime');
      var index = this.appointmentObject['times'].findIndex(el => el.time_id === e.target.defaultValue)
      this.appointmentObject['times'].splice(index, 1);

    }

  }


  createAppointmentModal(appointment) {

    this.studentService.calendarAppointment().subscribe(data => {
      console.log('open', data)
      this.teacherAppointment = data.teachers;
      this.tutoringPlaceAppointment = data.tutoring_place;
    })
    this.modalService.open(appointment).dismissed.subscribe(data => {
      this.calendarAppointment.reset()
      this.calendarAppointment.controls.app_date.disable();
      this.timesAppointment = [];
      this.appointmentObject = {
        times: []
      };
    })
  }

  selectTeacher(e) {

    this.timesAppointment = []
    this.calendarAppointment.controls.app_date.reset();
    if (e.value != '') {
      this.calendarAppointment.controls.app_date.enable();
    }
    this.appointmentData['teacher_id'] = e.value;
    this.appointmentData['teacher_name'] = e.source.triggerValue;
    this.studentService.calendarAppointmentT(this.appointmentData).subscribe(data => {
      console.log('subject', data)
      this.subjectAppointment = data.subjects;

    })
  }


  selectSubject(){
    this.timesAppointment = []
    this.calendarAppointment.controls.app_date.reset();
  }

  selectDate(e) {
    let appointmentDate = this.datePipe.transform(e.value.toLocaleString('en-US', this.timeOptions), 'yyyy-MM-dd')
    this.appointmentData['date'] = appointmentDate;
    this.studentService.calendarAppointmentD(this.appointmentData).subscribe(data => {

      console.log('times', data)
      this.timesAppointment = data.times;


    })
    this.weekDay = e.value.toLocaleString('en-US', { weekday: 'long' });
  }

  calendarAppointmentCalendar() {

    this.appointmentData['tutoring_place'] = this.calendarAppointment.value.app_tutoring_place;
    this.appointmentData['subject_id'] = this.calendarAppointment.value.app_subject;
    this.appointmentData['times'] = this.appointmentObject.times;

    this.teacherAppointment.forEach(element => {
      if (this.appointmentData['teacher_id'] == element.id) {
        this.appointmentData['email'] = element.user.email;
      }
    });


    console.log('appoint send obj', this.appointmentData)


    this.studentService.sendAppointmentCalendar(this.appointmentData).subscribe(data => {
      console.log(data)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: data.message,
        showConfirmButton: false,
        timer: 2000
      });
    }, (err) => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
    })


    this.modalService.dismissAll();
  }




  // onSubmit() {
  //   //console.log(this.appointmentForm)
  //   this.addEvent();
  // }

  removeIntroModal(){
    this.studentService.updateIntro('calendar', false).subscribe(res =>{
      console.log(res)
      this.modalService.dismissAll();
    })
  }



}
