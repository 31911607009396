
<header class="header-01 sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mobile-wrapper">
          <nav class="navbar navbar-expand-lg">
            <!-- logo Start-->
            <a class="navbar-brand" href="index.html">
              <img src="../assets/images/logo-educa.png" alt="">
              <img class="sticky-logo" src="../assets/images/logo-educa.png" alt="">
            </a>
            <!-- logo End-->

            <!-- Moblie Btn Start -->
            <button class="navbar-toggler" type="button">
              <!-- <i class="fal fa-bars"></i> -->
              <fa-icon [icon]="faBars"></fa-icon>
            </button>
            <!-- Moblie Btn End -->

            <!-- Nav Menu Start -->
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="menu-item-has-children">
                  <a routerLink="/">Home</a>

                </li>
                <li class="menu-item-has-children">
                  <a routerLink="/group-tutoring">Group Tutoring</a>
                </li>
                <li class="menu-item-has-children">
                  <a (click)="becomeStudent()" routerLink="/">{{'LABELS.BECOME_STUDENT' | translate}}</a>
                </li>
                <li class="menu-item-has-children" style="margin-right: 46px;">
                  <a (click)="becomeTutor()" routerLink="/tutor">{{'HOMEPAGE.NAV.BECOME_TUTOR' | translate}}</a>
                </li>
                <!-- <li class="menu-item-has-children">
                  <a>{{'HOMEPAGE.NAV.GROUP_TUTORING' | translate}}</a>
                </li> -->
                <li style="margin-right: 0;">
                  <a routerLink="/about">{{'HOMEPAGE.NAV.ABOUT_US' | translate}}</a>
                </li>
                <li class="nav-item flag-item">

                  <div class="dropdown d-inline-block" ngbDropdown>
                    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                      <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16"> <span
                        class="ml-1">{{countryName}}</span>
                      <img *ngIf="flagvalue === undefined" src="{{valueset}}" height="16">
                      <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right flag-menu" ngbDropdownMenu>

                      <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                        (click)="setLanguage(item.text, item.lang, item.flag)"
                        [ngClass]="{'active': cookieValue === item.lang}">
                        <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
                          class="align-middle">{{item.text}}</span>
                      </a>

                    </div>
                  </div>
                </li>

                <!-- Join Btn -->
                <a routerLink="/login" class="join-btn">{{'LOGIN_PAGE.SIGN_IN' | translate}}</a>
                <!-- Join Btn -->
              </ul>
            </div>
            <!-- Nav Menu End -->


          </nav>
        </div>

      </div>
    </div>
  </div>
</header>

<section class="page-banner" style="background-image: url('../../../assets/images/about-us.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="banner-title">{{"HOMEPAGE.NAV.ABOUT_US" | translate}}</h2>

      </div>
    </div>
  </div>
</section>
<section class="testimonial-section" >
  <div class="container">
    <div class="row">
      <!-- <div class="col-md-12 text-center">
        <h2 class="sec-title">{{"HOMEPAGE.NAV.ABOUT_US" | translate}}</h2>
      </div> -->
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="testimonial-slider text-center">

          <p>{{"ABOUT.US.I" | translate}}</p>

          <p>{{"ABOUT.US.II" | translate}}.</p>

          <p> {{"ABOUT.US.III" | translate}}</p>


        </div>
      </div>

    </div>
  </div>
</section>
<section class="instructor-section" style="background-image: url(../assets/images/testimonial-bg.png);">
  <div class="container">
    <div class="row">
      <div class="col-md-12 text-center">
        <h2 class="sec-title mb-15"><span>{{"ABOUT.TEAM.I" | translate}}</span>- {{"ABOUT.TEAM.II" | translate}}</h2>
        <!-- <p class="sec-desc">

        </p> -->
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="teacher-item">
          <div class="teacher-thumb">
            <img src="../../../assets/images/nektar.jpeg" alt="">
            <div class="teacher-social">
              <a href="#"><i class="social_youtube"></i></a>
              <a href="#"><i class="social_pinterest"></i></a>
            </div>
          </div>
          <div class="teacher-meta">
            <h5><a href="#">Nektar Mustafa</a></h5>
            <p>CMO</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="teacher-item">
          <div class="teacher-thumb">
            <img src="../../../assets/images/ylli.jpeg" alt="">
            <div class="teacher-social">
              <a href="#"><i class="social_facebook"></i></a>
              <a href="#"><i class="social_twitter"></i></a>
            </div>
          </div>
          <div class="teacher-meta">
            <h5><a href="#">Ylli Latifi</a></h5>
            <p>Back-End Developer</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="teacher-item">
          <div class="teacher-thumb">
            <img src="../../../assets/images/avatar.jpg" alt="">
            <div class="teacher-social">
              <a href="#"><i class="social_vimeo"></i></a>
              <a href="#"><i class="social_tumblr"></i></a>
            </div>
          </div>
          <div class="teacher-meta">
            <h5><a href="#">Krist Domgjoni</a></h5>
            <p>Front-End Developer</p>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
<footer class="footer">
  <div class="container">
    <div class="footer__content row">
      <div class="col-md-4 footer-flex">
        <div class="footer__content_logo">
          <a href=""><img src="../assets/images/logo-educa.png"></a>

        </div>
        <p class="copy">EducaGrow &copy; {{currentYear}}</p>
      </div>
      <!--col-md-6-->
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" routerLink='/impressum'>Impressum</li>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" (click)="contactModal(contactInfo)">Kontakt</li>
            <ng-template #contactInfo let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Contact Info</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="general-heading">E-mail: <a href="mailto:info@educagrow.com">info@educagrow.com</a></p>
                <p class="general-heading contact-margin">Phone: <a href="tel:0763327653">0763327653</a></p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Done</button>
              </div>
            </ng-template>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>

      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title">Verbesserungsvorschläge</li>

          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
    </div>
    <!--footer__content-->
  </div>
  <!--container-->
</footer>
