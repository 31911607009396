import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';


export function passValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {
    const cnfpassValue = control.value;

    const passControl = control.root.get('password'); // magic is this
    if (passControl) {
      const passValue = passControl.value;
      if (passValue !== cnfpassValue || passValue === '') {
        return {
          isError: true
        };
      }
    }
  }

  return null;
}

export function phoneValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {
    const phoneValue = control.value;

    const phoneControl = control.root.get('phone'); // magic is this
    console.log('phoneControl', phoneControl, 'phoneval', phoneValue)
    if (phoneControl) {
      const passValue = phoneControl.value;
      if (passValue === '' || (!phoneValue.startsWith("+41") && !phoneValue.startsWith("+49"))) {
        return {
          isError: true
        };
      }
    }
  }

  return null;
}

//register form
export function dateValidator(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {


    if (new Date().getFullYear() - (new Date(control.value)).getFullYear() < 7) {
      return {
        isError: true
      }
    }

    return null;
  }
}


//modal timeslot available
export function dateValidatorTimeslot(control: AbstractControl) {
  if (control && (control.value !== null || control.value !== undefined)) {

    console.log(new Date() > new Date(control.value));

    if (new Date() > new Date(control.value)) {
      return {
        isError: true
      }
    }

    return null;
  }
}

export function requiredFileType(type: string[]) {
  return function (control: FormControl) {
    // return (control: AbstractControl): { [key: string]: any } | null => {
    console.log('control', control)
    const file = control.value;
    const valid = null;
    if (file) {
      // console.log(file);
      var path = file.name.replace(/^.*[\\\/]/, "");
      //   var el_down = path
      //     .split("\\")
      //     .pop()
      //     .split("/")
      //     .pop();

      const extension = path.split(".")[1].toUpperCase();

      var existValue: boolean = false;
      for (var i = 0; i < type.length; i++) {
        let typeFile = type[i].toUpperCase();
        if (typeFile === extension.toUpperCase()) {
          console.log("type" + typeFile);
          existValue = true;
        }
      }
      if (existValue == true) {
        return null;
      } else {
        return {
          requiredFileType: true
        };
      }
      return null;
    }
    return null;
  };
}

export function checkFileType(control: AbstractControl): { [key: string]: any } | null {
  const files: File[] = control.value;
  let errors: string[] = [];

  if (files.length >= 1) {
    for (let index = 0; index < files.length; index++) {
      //Use a type list here to check for your types for example "image/jpeg"
      if (files[index].type === '') {
        errors.push(`${files[index].name} has an invalid type of unknown\n`);
      }
    }

    return errors.length >= 1 ? { invalid_type: errors } : null;
  }
  return null;  // no file, can be capture by "Required" validation
}


export function noWhitespaceValidator(control: FormControl) {
  const isSpace = (control.value || '').match(/^\S*$/);
  return isSpace ? { isError: true } : null;
}
