<div class="messenger">
  <!-- <div class="side-menu">

    <div class="navbar-brand">
      <a class="nav-link" [routerLinkActive]="['active']">
        <span class="logo">
          <img src="../../../assets/images/msg-logo.svg" alt="">
        </span>
      </a>
    </div>
    <div class="navbar-menu">
      <ul class="menu-list">
        <li>
          <a class="nav-link" (click)="navMenu('users')">
            <fa-icon [icon]="faUserAlt"></fa-icon>
          </a>
        </li>
        <li>
          <a class="nav-link" (click)="navMenu('chat')">
            <fa-icon [icon]="faCommentDots"></fa-icon>
          </a>
        </li>

      </ul>
    </div>
    <div class="navbar-settings">
      <ul class="settings-list">
        <li>
          <a class="nav-link">
            <fa-icon [icon]="faGlobe"></fa-icon>
          </a>
        </li>

      </ul>
    </div>
  </div> -->

  <div class="chat-leftsidebar">
    <div class="tab-content" *ngIf="tabVal == 'users'">
      <div class="title-head">
        <h4>Chat</h4>
      </div>
      <div class="carousel-list">
        <owl-carousel-o [options]="howItWorksOptions">
          <ng-template carouselSlide *ngFor="let chatUser of this.chatData">
            <div class="user-box" (click)="displayChat(chatUser.id)">
              <div class="user-img mx-auto avatar-xs">
                <img *ngIf="chatUser.teacher_image == ''" src="../../../assets/images/{{chatUser.teacher.gender}}.jpg"
                  alt="">
                <img *ngIf="chatUser.teacher_image != ''" [src]="chatUser.teacher_image" alt="">
              </div>

              <h5>{{chatUser?.teacher.name}}</h5>
            </div>
          </ng-template>

        </owl-carousel-o>
      </div>
      <div class="recent-list">
        <h5>{{"CHAT.RECENT" | translate}}</h5>
        <perfect-scrollbar class="chat-message-list">
          <div class="list-wrapper">
            <ul>
              <li (click)="displayChat(chatUser.id)" *ngFor="let chatUser of this.chatData"
                [ngClass]="{'active-recent': chatUser.id == idSelected}" [hidden]="chatUser?.messages.length == 0">

                <img *ngIf="chatUser.teacher_image == ''" src="../../../assets/images/{{chatUser.teacher.gender}}.jpg"
                  alt="">
                <img *ngIf="chatUser.teacher_image != ''" [src]="chatUser.teacher_image" alt="">
                <div>
                  <h5>{{chatUser?.teacher.name}}</h5>
                  <p>{{chatUser.messages[chatUser?.messages.length-1]?.body}}</p>
                </div>

              </li>

            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

    <div class="tab-content" *ngIf="tabVal == 'chat'">
      <div class="my-profile">
        <div class="title-head">
          <h4>My Profile</h4>
        </div>
        <div class="profile-status">
          <img *ngIf="this.user.user.image_path == ''" src="../../../assets/images/{{this.user.user.gender}}.jpg" alt=""
            width="100px">
          <img *ngIf="this.user.user.image_path != ''" [src]="this.user.user.image_path" alt="" width="100px">
          <h5>{{this.user.name}}</h5>
        </div>
        <perfect-scrollbar>
          <div class="account-info">

            <ngb-accordion #acc="ngbAccordion">
              <ngb-panel title="Info">
                <ng-template ngbPanelContent>
                  <div class="body-text">
                    <p>Email</p>
                    <h5>{{this.user.email}}</h5>

                  </div>
                  <div class="body-text">
                    <p>Address</p>
                    <h5>{{this.user.user.city}}, {{this.user.user.address}}</h5>
                  </div>
                  <div class="body-text">
                    <p>Tutoring Place</p>
                    <h5>{{this.user.user.preferences.tutoring_place}}</h5>
                  </div>
                  <div class="body-text">
                    <p>Tutoring Style</p>
                    <h5>{{this.user.user.tutoring_style.name}}</h5>
                  </div>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </div>
        </perfect-scrollbar>
      </div>

    </div>

  </div>
  <div class="chat-room" [ngClass]="{'show-chat': showMessage}">
    <div class="room-wrapper">
      <div class="room-header">
        <div class="row">
          <div class="col-sm-4 col-8">

            <div class="active-chat">
              <fa-icon (click)="hideChat()" [icon]="faChevronLeft"></fa-icon>
              <img *ngIf="chatMessagesData?.teacher_image == ''"
                src="../../../assets/images/{{chatMessagesData?.teacher.gender}}.jpg" alt="">
              <img *ngIf="chatMessagesData?.teacher_image != ''" [src]="chatMessagesData?.teacher_image" alt="">
              <h5>{{chatMessagesData?.teacher.name}} {{chatMessagesData?.teacher.surname}}</h5>
            </div>
          </div>
          <div class="col-sm-8 col-4 text-end">
            <div class="user-icon">
              <fa-icon (click)="closeSettings()" [icon]="faUser"></fa-icon>
            </div>

          </div>
        </div>
      </div>
      <perfect-scrollbar class="chat-scrollbar" #chatPS>
        <div class="intro-sms" *ngIf="chatMessagesData?.messages.length == 0">
          Messages are end-to-end encrypted. No one outside of this chat, not even WhatsApp,<br> can read or listen to
          them. Click to learn more.
        </div>
        <div class="room-content" *ngIf="chatMessagesData?.messages.length != 0">
          <ul class="room-list">
            <li [ngClass]="{'right': chat.sender == 'student'}" *ngFor="let chat of chatMessagesData?.messages">
              <div class="message">

                <img *ngIf="chat?.file" [src]="chat?.file" alt="">
                <p *ngIf="chat?.body">
                  {{chat?.body}}
                </p>
              </div>
            </li>

          </ul>
        </div>
      </perfect-scrollbar>
      <div class="room-footer">
        <div class="row">
          <div class="col-auto" style="padding: 0;">
            <div class="input-attach">
              <input type="file" id="file-input" class="file-input__input" (change)="uploadImage($event)">
              <label class="file-input__label" for="file-input">
                <fa-icon [icon]="faImages">

                </fa-icon>
              </label>

            </div>
          </div>
          <div class="col">
            <form (ngSubmit)="sendMessage(chatMessagesData.id)" [formGroup]="formData">
              <input type="text" formControlName='message' class="input-field-text" placeholder="Enter Message..">
            </form>

          </div>

          <div class="col-auto" style="padding: 0;">
            <div class="send-button">
              <button (click)="sendMessage(chatMessagesData.id)">
                <!-- <fa-icon [icon]="faPaperPlane"></fa-icon> -->
                <img src="../../../assets/images/flyPaper.png" width="30" alt="">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="account-settings" [ngClass]="{'display-none': accountSettings}">
      <div class="close-account">
        <button (click)="closeSettings()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
      <div class="account-status">
        <img *ngIf="chatMessagesData?.teacher_image == ''"
          src="../../../assets/images/{{chatMessagesData?.teacher.gender}}.jpg" width="100px" alt="">
        <img *ngIf="chatMessagesData?.teacher_image != ''" [src]="chatMessagesData?.teacher_image" alt="" width="100px">

        <h5>{{chatMessagesData?.teacher.name}}</h5>
      </div>
      <perfect-scrollbar>
        <div class="account-info">
          <p> If several languages coalesce, the grammar of the resulting language is more simple and regular than that
            of the individual.</p>
          <ngb-accordion #acc="ngbAccordion" activeIds="toggle-0" (click)="acc.toggle('toggle-0')">
            <ngb-panel title="Info" id="toggle-0">
              <ng-template ngbPanelContent>
                <!-- <div class="body-text">
                  <p>Name</p>
                  <h5>{{chatMessagesData?.teacher.name}} {{chatMessagesData?.teacher.surname}}</h5>
                </div> -->
                <div class="body-text">
                  <p>{{"CHAT.SUBJECT" | translate}}</p>
                  <h5>{{chatMessagesData?.about.subject}}</h5>
                </div>
                <div class="body-text">
                  <p>{{"CHAT.NEXT_LESSON" | translate}}</p>
                  <h5>{{chatMessagesData?.about.next_lesson.day}}, {{chatMessagesData?.about.next_lesson.date}}</h5>
                </div>
                <div class="body-text">
                  <p>{{"CHAT.TIME" | translate}}</p>
                  <h5>{{chatMessagesData?.about.next_lesson.time.time_start}} -
                    {{chatMessagesData?.about.next_lesson.time.time_end}}</h5>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
