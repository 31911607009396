<div class="forms">
  <div class="forms__content">
    <div class="forms__content_image"></div>
    <div class="forms__content_form">
      <div class="container">
        <div class="login">
          <h1 class="form-heading">Verify Email</h1>



            <!-- <div class="form-group login-form-margin">
              <mat-form-field class="example-full-width">
                <mat-label>{{'LOGIN_PAGE.EMAIL_ADDRESS' | translate}}</mat-label>
                <input matInput class="form-input" required formControlName="email">
                <mat-error *ngIf="verifyForm.controls.email.invalid">Enter valid email</mat-error>
              </mat-form-field>
            </div> -->


            <!-- <div class="loading-wrapper" *ngIf="isLogin">
              <div class="spinner-border">
                <span class="sr-only">Loading...</span>
              </div>
            </div> -->


            <button class="btn btn-primary" (click)="resendEmail()">
             Resend Email <img src="../assets/images/right-arrow.png" alt="">
            </button>

          <p class="bottom-login">{{'LOGIN_PAGE.ACCOUNT' | translate}}</p>
        </div>
        <!--login-->


        <div class="register-redirect">
          <div class="teacher-register">
            <img routerLink="/tutor/register" src="../assets/images/teacher-register.png" alt="">
            <p>Lehrer werden</p>
          </div>

          <div class="student-register">
            <img routerLink="/student/register" src="../assets/images/student-register.png" alt="">
            <p>Schüler werden</p>
          </div>
        </div>
      </div><!-- container -->
    </div>
    <!--forms__content_form"-->
  </div><!-- forms__content -->
</div><!-- forms -->
