import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { map, startWith, windowWhen } from 'rxjs/operators';
import { HomeService } from '../core/_services/home.service';
import { Options } from '@angular-slider/ngx-slider'
import * as $ from 'jquery';
import { StudentService } from '../core/_services/student.service';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { RegisterFormsService } from '../core/_services/register-forms.service';
import {
  faShoePrints,
  faUsers,
  faBriefcase,
  faClock,
  faInfoCircle,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faChalkboardTeacher,
  faEdit,
  faHandshake,
  faBoxOpen,
  faCreditCard,
  faCalendarAlt,
  faCalendarTimes,
  faUserShield,
  faHome,
  faPhoneAlt,
  faEnvelope,
  faDotCircle,
  faBars,
  faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('contentIntro', { read: TemplateRef }) private contentIntro: TemplateRef<any>;

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;


  listLang = [
    { text: '', flag: '../assets/images/flags/us.jpg', lang: 'en' },
    { text: '', flag: '../assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: '../assets/images/flags/italy.jpg', lang: 'it' },
  ];

  stepIndicators = faShoePrints;
  faUsers = faUsers;
  faBriefcase = faBriefcase;
  faClock = faClock;
  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign;
  percentRate = faPercent;
  overview = faEye;
  faEdit = faEdit;
  faHandshake = faHandshake;
  faBoxOpen = faBoxOpen;
  faChalkboardTeacher = faChalkboardTeacher;
  faCreditCard = faCreditCard;
  faCircle = faDotCircle;
  faCalendarAlt = faCalendarAlt;
  faCalendarTimes = faCalendarTimes;
  faUserShield = faUserShield;
  faHome = faHome;
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faBars = faBars;
  faHandHoldingUsd = faHandHoldingUsd;


  lang;
  times;
  zip;

  currentYear;

  teacherPrices;
  selectedSubject;





  panelOpenState = false;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;	'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1,
      }
    },
    nav: true
  }

  howItWorksOptions: OwlOptions = {
    autoHeight: true,
    loop: false,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1,
      }
    },
    nav: true
  }

  aboutUs: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;	'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1,
      }
    },
    nav: true
  }

  myControl = new FormControl();
  desiredFormGroup: FormGroup;
  QAFormGroup: FormGroup;
  feedbackForm: FormGroup;

  region_list = [];
  region_list_table = [];
  filteredOptions: Observable<string[]>;

  subjects;
  tutoringStyle;
  tutoringPlace;
  week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

  filterData = {
    subject: []
  };
  tutors = [];

  day = [];


  showDemoBoxes = false;
  hasQuestion = true;
  sendQA = false;

  setIntervalTime;

  feedbackArray;



  constructor(private homeService: HomeService,
    private studentService: StudentService,
    private registerFormService: RegisterFormsService,
    config: NgbRatingConfig,
    private router: Router,

    private formBuilder: FormBuilder,
    public modalService: NgbModal) {


    //window['tidioChatApi'].hide();

    let routeUser = window.localStorage.getItem('route');

    if (routeUser == 'tutor') {
      router.navigate(['/tutor'])
    } else {
      router.navigate(['/'])
    }

    this.homeService.getFeedback().subscribe(data => {
      console.log('feedback', data)
      this.feedbackArray = data['data'].filter((el, i) => {
        return el['type'] == 'student';
      })
    })

    this.homeService.getRegions().subscribe(data => {
      console.log('regions', data)
      this.region_list = data.data;
    })

    this.studentService.getTimes().subscribe(data => {
      this.times = data.data;
    })

    this.registerFormService.getSubjects().subscribe(data => {
      console.log('subjects', data)
      this.subjects = data.data
    })

    this.registerFormService.getTutoringStyle().subscribe(data => {
      this.tutoringStyle = data.data;
    })

    this.registerFormService.geTutoringPlaces().subscribe(data => {
      this.tutoringPlace = data.data;
    })

    config.max = 5;
    config.readonly = true;


  }

  ngOnInit(): void {

    this.hasQuestion = !Boolean(localStorage.getItem('question'))

    //this.lang = localStorage.getItem('lang') || 'de';
    this.desiredFormGroup = this.formBuilder.group({
      desiredDay: ['', Validators.required],
      desiredDate: ['', Validators.required],
    });

    this.QAFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      phone: ['']
    });

    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      city: ['', Validators.required],
      description: ['', Validators.required]
    })

    this.cookieValue = localStorage.getItem('lang') || 'de';
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.currentYear = new Date().getFullYear();

    // if (this.hasQuestion) {
    //   this.setIntervalTime = setTimeout(() => {
    //     this.openIntroModal(this.contentIntro)
    //   }, 3000)
    // }


    this.homeService.getClientIp().subscribe(data => {
      console.log('ip',data)
    })

  }


  ngAfterViewInit() {

    $(window).on('scroll', function () {

      if ($(window).scrollTop() > 40) {
        $(".sticky").addClass('fix-header animated fadeInDown');
        if ($(window).width() > 991) {
          $(".navbar-nav li a").css('color', '#000')
        }
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')

      } else {
        $(".sticky").removeClass('fix-header animated fadeInDown');
        $(".navbar-nav li a").css('color', '#000')
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')
      }

    });

    if ($(window).width() < 991) {
      console.log('called')
      $('.navbar-toggler').on('click', function (e) {

        e.stopPropagation();
        $('.navbar-collapse').stop(true, true).slideToggle();
        e.preventDefault();
      });

      $('.navbar-nav li.menu-item-has-children').each(function () {
        var $this = $(this);
        $this.append('<span class="submenu-toggler"><i class="fal fa-plus"></i></span>');
      });

      $('.navbar-nav li.menu-item-has-children span.submenu-toggler').on('click', function () {
        var $this = $(this);

        if ($(this).hasClass('active-span')) {
          $('i', $this).removeClass('fa-minus').addClass('fa-plus');
        } else {
          $('i', $this).addClass('fa-minus').removeClass('fa-plus');
        }

        //$(this).prev('ul.sub-menu, .mega-menu').stop(true, true).slideToggle();
        $(this).toggleClass('active-span');
      });
    };

  }

  ngOnDestroy(): void {
    console.log('pass')
    clearTimeout(this.setIntervalTime);
  }


  becomeTutor() {
    localStorage.setItem('route', 'tutor')
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    localStorage.setItem('lang', lang)
    window.location.reload();
  }



  changeLang(lang) {
    console.log(lang)
    localStorage.setItem('lang', lang)
    window.location.reload();
  }

  // selectRegion(e) {
  //   this.homeService.getTableRegions(e.value).subscribe(data => {
  //     this.zip = e.value;
  //     this.region_list_table = data;
  //     console.log(this.region_list_table)
  //   })
  //   $('.results__table').css("display", "flex")
  //   $('.periodicity').css("visibility", "visible")
  // }

  // selectSubject(e) {
  //   this.homeService.getTableSubjects(this.zip, e.value).subscribe(data => {
  //     this.region_list_table = data;
  //     console.log('data', data)
  //   })
  // }

  openDemoTime(modalTime) {
    this.modalService.open(modalTime)
  }

  searchByday(e) {
    this.filterData['day'] = e.value;
    this.day = e.value;

    console.log(this.day)
  }






  searchBySubject(e) {
    console.log(e)

    let subjectId = e.value;

    this.filterData['subject'] = e.value;
    this.tutorFilter();
    //this.selectedSubject = e;
    //console.log(this.filterData)
    // if (e.target.checked === true) {
    //   //e.target.parentNode.classList.add('selected');
    //   this.filterData['subject'].push(e.target.defaultValue);

    //   console.log('index',this.filterData)
    //   this.tutorFilter();
    // }else{
    //   //e.target.parentNode.classList.remove('selected');
    //   let index = this.filterData['subject'].findIndex(e => e == subjectId)

    //   this.filterData['subject'].splice(index, 1)
    //   this.tutorFilter();
    //   console.log('index',this.filterData)
    // }

  }


  // searchByStyle(e) {
  //   this.filterData['style'] = e.value;
  // }

  // searchByPrice(e) {
  //   this.filterData['price'] = e.value;
  // }


  tutorFilter() {
    this.showDemoBoxes = true;
    this.homeService.searchTutorSubject(this.filterData).subscribe(data => {
      console.log('tutors',data)
      this.tutors = data.data;
      this.showDemoBoxes = false;

    })
  }

  sendToDemo(tutor) {
    //this.homeService.saveData(tutor)

    this.router.navigate([`demo/`, tutor.id], { queryParams: { subject_id: this.filterData['subject'] } })

  }




  contactModal(contactInfo) {
    this.modalService.open(contactInfo, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

    }, (reason) => {

    });
  }

  showLandingPage(e) {

    this.hasQuestion = true;
    window.localStorage.setItem('question', 'true');
    window.localStorage.setItem('route', e.value)
    this.modalService.dismissAll();
    this.router.navigate([`/${e.value}`])

  }

  openIntroModal(contentIntro) {
    this.modalService.open(contentIntro);
  }

  sendEmail() {
    this.sendQA = true;
    console.log(this.QAFormGroup)
    this.studentService.sendQA(this.QAFormGroup).subscribe(res => {
      this.sendQA = false;
      console.log(res)
      this.QAFormGroup.reset();
      Swal.fire(
        'Done!',
        'E-mail Sent!',
        'success'
      )
    }, (err) => {
      this.sendQA = false;
      Swal.fire(
        {
          icon: 'error',
          title: 'Oops...',
          text: 'Try Again!',
        })
    })
  }

  feedbackModal(feedback) {
    this.modalService.open(feedback);
  }

  sendFeedback(){
    this.homeService.feedback(this.feedbackForm).subscribe(res => {
      console.log(res)
      this.modalService.dismissAll()
      Swal.fire(
        'Done!',
        'Feedback Sent!',
        'success'
      )
    }, (err) => {
      this.sendQA = false;
      this.modalService.dismissAll()
      Swal.fire(
        {
          icon: 'error',
          title: 'Oops...',
          text: 'Try Again!',
        })
    })
  }





}


