<div *ngIf="fullPageLoader" class="loading">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="forms" *ngIf="submitRegister; else elseBlock">
  <div class="forms__content">
    <div class="forms__content_image">

    </div>
    <div class="forms__content_form">
      <div class="container">
        <div class="student-register">
          <mat-horizontal-stepper [linear]="isLinear" #stepper>

            <mat-step [stepControl]="firstFormGroup">
              <h1 class="form-heading">{{'LABELS.BECOME_STUDENT' | translate}}</h1>
              <form [formGroup]="firstFormGroup">
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.PERSONAL_INFORMATION' | translate}}</ng-template>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label class="label-custom">{{'LABELS.GENDER' | translate}}</mat-label>
                        <mat-select formControlName="gender">

                          <mat-option selected value="male">
                            {{'LABELS.MALE' | translate}}
                          </mat-option>
                          <mat-option value="female">
                            {{'LABELS.FEMALE' | translate}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <!--form-group-->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.PRENAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="firstname" (keydown)="testForm()">

                      </mat-form-field>
                      <!-- <label class="form-label">Prename</label>
                      <input required type="text" class="form-input" formControlName="firstName"
                        placeholder="Enter prename here"> -->
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.SURNAME' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="surname">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field class="date-custom" appearance="fill">
                        <mat-label>{{'LABELS.DATE' | translate}}</mat-label>
                        <input class="input-date" (click)="picker.open()" matInput formControlName="date"
                          [matDatepicker]="picker" placeholder="dd/mm/yyyy" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-4">
                    <!-- <div class="form-group">
                      <mat-form-field class="date-custom">
                        <mat-label>IBAN</mat-label>
                        <input matInput class="form-input" formControlName="ibanReactive" (change)="returnIban()">
                        <div class="edited-error" *ngIf="verifyIban != ''">
                          {{verifyIban}}
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="ibanCheck">
                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>Checking IBAN</p>
                        </div>
                      </mat-form-field>
                    </div> -->
                  </div>
                  <!--col-md-4-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.ADDRESS' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="address">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-2">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.ZIP_CODE' | translate}}</mat-label>
                        <input matInput maxlength="5" class="form-input" formControlName="zipCode"
                          (keyup)="returnZip()">
                        <div class="edited-error" *ngIf="verifyZip">
                          please add valid code
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="zipCheck">
                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>Checking ZIP</p>
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->

                  </div>
                  <!--col-md-2-->
                  <div class="col-md-4">
                    <div class="form-group form-group-margin-m">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.CITY' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="city">
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-4-->
                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.EMAIL' | translate}}</mat-label>
                        <input matInput type="email" class="form-input" (keyup)="returnEmail()" formControlName="email">
                        <div class="edited-error" *ngIf="verifyEmail != ''">
                          {{verifyEmail}}
                        </div>
                        <div class="loading-wrapper custom-loader" *ngIf="emailExists">

                          <div class="spinner-border">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <p>{{'LABELS.CHECKING_EMIAL' | translate}}</p>
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width country-code">
                        <mat-label>{{'LABELS.PHONE' | translate}}</mat-label>

                        <input ng2TelInput [ng2TelInputOptions]="{initialCountry: 'ch', 'onlyCountries': ['ch', 'de']}"
                          (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)" matInput class="form-input"
                          formControlName="phone">
                        <div class="edited-error" *ngIf="!numberError">
                          {{'VALIDATIONS.PHONE' | translate}}
                        </div>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.PASSWORD' | translate}}</mat-label>
                        <input matInput minlength="8" #x type="password" class="form-input" formControlName="password">
                        <div class="show-password" (click)="x.type=x.type=='password'?'text':'password'">
                          {{'LOGIN_PAGE.SHOW' | translate}}</div>
                        <mat-error *ngIf="firstFormGroup.controls.password.invalid">{{'VALIDATIONS.PASSWORD' |
                          translate}}</mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                  <div class="col-md-6">
                    <div class="form-group form-group-margin">
                      <mat-form-field class="example-full-width">
                        <mat-label>{{'LABELS.RE_PASSWORD' | translate}}</mat-label>
                        <input matInput minlength="8" #y type="password" class="form-input"
                          formControlName="verifyPassword">
                        <div class="show-password" (click)="y.type=y.type=='password'?'text':'password'">
                          {{'LOGIN_PAGE.SHOW' | translate}}</div>
                        <mat-error *ngIf="firstFormGroup.controls.verifyPassword.invalid">{{'VALIDATIONS.RE_PASSWORD' |
                          translate}}
                        </mat-error>
                      </mat-form-field>
                    </div><!-- form-group -->
                  </div>
                  <!--col-md-6-->
                </div>
                <!--row-->

                <div class="form-buttons first-btn">
                  <button [disabled]="firstFormGroup.invalid || verifyEmail != ''  || !numberError || verifyZip "
                    mat-button matStepperNext class="right-btn-form">
                    <img class="left-rotate"
                      [ngClass]="{'disable-cursor' : firstFormGroup.invalid || verifyEmail != '' || !numberError || verifyZip}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="secondFormGroup">
              <h1 class="form-heading">{{'LABELS.BECOME_STUDENT' | translate}}</h1>
              <form [formGroup]="secondFormGroup">
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.EDUCATION' | translate}}</ng-template>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.DEGREE' | translate}}</mat-label>

                        <mat-select formControlName="lastDegre" (selectionChange)="lastDegreOnChange($event)">
                          <mat-option *ngFor="let edu of educations; let i = index" value="{{edu.id}}">
                            {{edu.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group" *ngIf="showDirection">
                      <mat-form-field [ngClass]='{"disabled-filed": secondFormGroup.controls.direction.disabled}'>
                        <mat-label>{{'LABELS.DIRECTION' | translate}}</mat-label>
                        <input matInput class="form-input" formControlName="direction">
                      </mat-form-field>
                    </div>
                    <!-- <div class="form-group" *ngIf="!showDirection">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.DIRECTION' | translate}}</mat-label>
                        <mat-select formControlName="direction_id">
                          <mat-option *ngFor="let nestedDegree of degreDepth" value="{{nestedDegree.id}}">
                            {{nestedDegree.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div> -->
                  </div>


                  <div class="col-md-6">

                  </div>


                </div>
                <!--row-->

                <div class="row">
                  <div class="col-md-12">
                    <p class="form-label">
                      {{'LABELS.EDUCATION_STUDENT_INFO' | translate}}
                    </p>
                  </div>
                </div>
                <div class="break-form" formArrayName="education"
                  *ngFor="let education of educationFormArray; let i = index">
                  <div [formGroupName]="i">
                    <div class="row">
                      <div class="col-md-6">

                      </div>
                      <!--col-md-6-->
                      <div class="col-md-6">
                        <div class="education-form remove-form" *ngIf="educationFormArray.length > 1"
                          (click)="removeEducationForm(i, selectSubject.value)">{{'LABELS.REMOVE_EDUCATION' |
                          translate}}
                          level <img src="../../assets/images/deleteform.png" alt=""> </div>
                      </div>
                    </div>
                    <!--row-->



                    <div class="row">

                      <div class="col-md-6">
                        <!-- <h4 class="form-label">For which school subjects would you like tutoring?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.SUBJECT'| translate}}</mat-label>
                            <mat-select #selectSubject formControlName="id"
                              (selectionChange)="updateValidation($event)">
                              <ng-container *ngFor="let sub of subjects">
                                <mat-option value="{{sub.id}}{{sub.name}}"
                                  *ngIf="selectSubject.value === sub.id + sub.name || !isSelectedSubject(sub.id + sub.name)">
                                  {{sub.name}}
                                </mat-option>
                              </ng-container>

                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->



                      <div class="col-md-6">
                        <!-- <h4 class="form-label">What level of education or school are you at?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.LEVEL' | translate}}</mat-label>
                            <mat-select formControlName="education_id" (selectionChange)="updateValidation($event)">
                              <mat-option *ngFor="let edu of educations; let i = index" class="test" value="{{edu.id}}">
                                {{edu.name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                      <div class="col-md-12">
                        <!-- <h4 class="form-label">What level of education or school are you at?</h4> -->
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>{{'LABELS.PROBLEM' | translate}}</mat-label>
                            <textarea matInput class="form-input" formControlName="describe"
                              (input)="updateValidation($event)" style="height: 160px; padding: 10px;"></textarea>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                      <div class="col-md-6">
                        <!-- <h4 class="form-label">What level of education or school are you at?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.PLANNED_LESSONS' | translate}}</mat-label>
                            <mat-select formControlName="nr_of_lessons" (selectionChange)="updateValidation($event)">
                              <mat-option *ngFor="let less of lessons" value="{{less.id}}">
                                {{less.nr_lessons.split(' ')[0]}} {{'LABELS.LESSONS_NR' | translate}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                      <div class="col-md-6">
                        <!-- <h4 class="form-label">What level of education or school are you at?</h4> -->
                        <div class="form-group">
                          <mat-form-field appearance="fill">
                            <mat-label>{{'LABELS.PERIODICITY' | translate}}</mat-label>
                            <mat-select formControlName="periodicity" (selectionChange)="updateValidation($event)">
                              <mat-option value="weekly">
                                {{'LABELS.WEEKLY' | translate}}
                              </mat-option>
                              <mat-option value="biweekly">
                                {{'LABELS.BIWEEKLY' | translate}}
                              </mat-option>
                              <mat-option value="monthly">
                                {{'LABELS.MONTHLY' | translate}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <!--col-md-6-->

                    </div>
                    <!--row-->

                  </div>

                  <div class="row">
                    <div class="col-md-6">
                    </div>

                  </div>

                </div>
                <div class="education-form add-form" (click)="addEducaitonForm()">{{'LABELS.ADD_EDUCATION' |
                  translate}}<img src="../../assets/images/add-from.png" alt=""></div>

                <div class="form-buttons">
                  <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png" alt="">
                  </button>

                  <button [disabled]="secondFormGroup.invalid" mat-button matStepperNext>
                    <img class="left-rotate" [ngClass]="{'disable-cursor' : secondFormGroup.invalid}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button>
                </div>
              </form>
            </mat-step>

            <mat-step [stepControl]="thirdFormGroup">
              <form [formGroup]="thirdFormGroup">
                <h1 class="form-heading">{{'LABELS.BECOME_STUDENT' | translate}}</h1>
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.LESSON_DETAILS' | translate}}</ng-template>
                <h4 class="form-label">{{'LABELS.TEXT_FORM' | translate}}</h4>
                <div class="row">
                  <div class="col-md-6">
                    <!-- <h4 class="form-label">What kind of tutoring style do you prefer?</h4> -->
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.PLACE' | translate}}</mat-label>
                        <mat-select (selectionChange)="sliderValidation($event)" formControlName="tutoringPlaces">
                          <mat-option *ngFor="let place of tutoringPlaces" value="{{place.id}}">
                            {{place.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--col-md-12-->
                  <!-- <div class="col-md-12">
                    <div class="form-group">
                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.LESSONS' | translate}}</mat-label>
                        <mat-select formControlName="lessons">
                          <mat-option *ngFor="let less of lessons" value="{{less.id}}">
                            {{less.nr_lessons}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div> -->
                  <!--col-md-12-->
                  <div class="col-md-6">
                    <!-- <h4 class="form-label">Where would you prefer to be tutored?</h4> -->
                    <div class="form-group">

                      <mat-form-field appearance="fill">
                        <mat-label>{{'LABELS.STYLE' | translate}}</mat-label>
                        <mat-select formControlName="tutoringStyle">
                          <mat-option *ngFor="let tuto of tutoringStyle" value="{{tuto.id}}" [disabled]='tuto.id == 2'>
                            {{tuto.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <!--col-md-12-->

                  <div class="col-md-12">
                    <h4 class="form-label">{{'LABELS.TIMESLOT_INFO_STUDENT' | translate}}</h4>
                    <button type="button" class="btn btn-primary times" (click)='onceClick()' data-toggle="modal"
                      data-target="#exampleModal">
                      {{'LABELS.SELECT_TIMESLOT' | translate}}
                    </button>
                  </div>
                  <!--col-md-12-->
                </div>
                <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                  aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{'LABELS.TIME_INTERVAL' | translate}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="grid-container">
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="monday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.MONDAY' | translate}}</div>
                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="monday{{t.id}}" name="monday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="tuesday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.TUESDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="tuesday{{t.id}}" name="tuesday" value="{{t.id}}">{{t.time}}
                            </div>

                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="wednesday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.WEDNESDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="wednesday{{t.id}}" name="wednesday" value="{{t.id}}">{{t.time}}
                            </div>

                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="thursday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.THURSDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="thursday{{t.id}}" name="thursday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="friday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.FRIDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="friday{{t.id}}" name="friday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="saturday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.SATURDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="saturday{{t.id}}" name="saturday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                          <div class="week">
                            <div class="time testTime"><input (click)="selectAll($event)" value="sunday"
                                type="checkbox">{{'WEEK_DAYS.SELECT_ALL' | translate}}
                            </div>
                            <div class="day">{{'WEEK_DAYS.SUNDAY' | translate}}</div>

                            <div *ngFor="let t of times" class="time testTime"><input (change)="changeSelection($event)"
                                type="checkbox" id="sunday{{t.id}}" name="sunday" value="{{t.id}}">{{t.time}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary btn-time" data-dismiss="modal">{{'LABELS.DONE' |
                          translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>

                <table class="timeslot-table">
                  <tr [hidden]='wrapTime[0].monday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.MON' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].monday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].tuesday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.TUE' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].tuesday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].wednesday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.WED' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].wednesday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].thursday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.THU' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].thursday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].friday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.FRI' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].friday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].saturday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.SAT' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].saturday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr [hidden]='wrapTime[0].sunday.length == 0'>
                    <td class="day-name">{{'WEEK_DAYS.SHORT.SUN' | translate}}</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime time-style" *ngFor="let time of wrapTime[0].sunday">
                          <div *ngFor="let t of time;let last = last; let first = first" class="time-style">
                            <div *ngIf="first">
                              {{t.time | slice:0:5}} -
                            </div>
                            <div *ngIf="last">
                              {{ t.time | slice:6:11}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="day-name">Sun</td>
                    <td>
                      <div class="show-weeks">
                        <div class="show-time time colorTime" *ngFor="let timeSlot of getValue[0].sunday">
                          {{timeSlot.time}}
                          <img id="sunday{{timeSlot.id}}" (click)="deleteTime($event)"
                            src="../../assets/images/close.png" alt="">
                        </div>
                      </div>
                    </td>
                  </tr> -->
                </table>


                <div class="form-buttons">
                  <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png"
                      alt=""></button>

                  <!-- <button [disabled]="thirdFormGroup.invalid" mat-button matStepperNext>
                    <img class="left-rotate" [ngClass]="{'disable-cursor' : thirdFormGroup.invalid}"
                      src="../../assets/images/left-arrow-form.png" alt="">
                  </button> -->

                  <button [disabled]="
                  thirdFormGroup.invalid ||
                    !(wrapTime[0].monday.length != 0 ||
                    wrapTime[0].tuesday.length != 0 ||
                    wrapTime[0].wednesday.length != 0 ||
                    wrapTime[0].thursday.length != 0 ||
                    wrapTime[0].friday.length != 0 ||
                    wrapTime[0].saturday.length != 0 ||
                    wrapTime[0].sunday.length != 0)
                  " [ngClass]="{
                    'disable-cursor' :
                    fourthFormGroup.invalid ||
                    !(wrapTime[0].monday.length != 0 ||
                    wrapTime[0].tuesday.length != 0 ||
                    wrapTime[0].wednesday.length != 0 ||
                    wrapTime[0].thursday.length != 0 ||
                    wrapTime[0].friday.length != 0 ||
                    wrapTime[0].saturday.length != 0 ||
                    wrapTime[0].sunday.length != 0)
                  }" class="btn btn-primary btn-submit" mat-button (click)="submit()">
                    {{'LABELS.SUBMIT' | translate}}
                  </button>
                </div>
              </form>
            </mat-step>

            <!-- <mat-step [stepControl]="fourthFormGroup">
              <form [formGroup]="fourthFormGroup">
                <h1 class="form-heading">{{'LABELS.BECOME_STUDENT' | translate}}</h1>
                <ng-template matStepLabel>{{'REGISTER_TUTOR_PAGE.PREFERENCES' | translate}}</ng-template>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-additional-margin">
                      <h4 class="form-label">{{'LABELS.RADIO_BTN.AGE' | translate}}</h4>
                      <mat-radio-group (change)="sliderValidation($event)" formControlName="tutorAge"
                        aria-label="Select an option">
                        <mat-radio-button value="yes">{{'LABELS.YES_DOES' | translate}}</mat-radio-button>
                        <mat-radio-button value="no">{{'LABELS.NO_DOESNT' | translate}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <h4 class="form-label">{{'LABELS.RADIO_BTN.RANGE' | translate}}</h4>
                    <ngx-slider formControlName="tutorRange" [(value)]="value" [(highValue)]="highValue"
                      [options]="optionsRange"></ngx-slider>
                  </div>

                </div>


                <div class="row">
                  <div class="col-md-12">
                    <div class="form-additional-margin">
                      <h4 class="form-label">{{'LABELS.RADIO_BTN.GENDER' | translate}}</h4>
                      <mat-radio-group aria-label="Select an option" formControlName="preferedGender">
                        <mat-radio-button value="female">{{'LABELS.FEMALE' | translate}}</mat-radio-button>
                        <mat-radio-button value="male">{{'LABELS.FEMALE' | translate}}</mat-radio-button>
                        <mat-radio-button value="none">{{'LABELS.DONT_MIND' | translate}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>

                </div>







                <div class="row">
                  <div class="col-md-6 additional-margin" *ngIf="isVirtualMeeting">
                    <h4 class="form-label">Does the location make a difference to you?</h4>
                    <mat-radio-group (change)="sliderValidation($event)" formControlName="askLocation"
                      aria-label="Select an option">
                      <mat-radio-button value="yes">Yes ti does</mat-radio-button>
                      <mat-radio-button value="no">No it doesn’t</mat-radio-button>
                    </mat-radio-group>
                  </div>


                  <div class="col-md-6" *ngIf="isVirtualMeeting; else platforms">
                    <div class="form-group">
                      <label class="form-label">How many kilometers</label>
                      <mat-slider formControlName="askedLocation" [displayWith]="formatLabel" thumbLabel min="0"
                        max="20" step="5"></mat-slider>
                    </div>

                  </div>

                  <ng-template #platforms>
                    <div class="col-md-6">

                      <div class="form-group">
                        <mat-form-field appearance="fill" class="platforme-margin">
                          <mat-label>{{'LABELS.PLATFORM' | translate}}</mat-label>
                          <mat-select formControlName="platform">
                            <mat-option value="zoom">
                              Zoom
                            </mat-option>
                            <mat-option value="skype">
                              Skype
                            </mat-option>
                            <mat-option value="google">
                              Google Meet
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </ng-template>





                </div>

              </form>

              <div class="form-buttons">
                <button mat-button matStepperPrevious><img src="../../assets/images/left-arrow-form.png"
                    alt=""></button>

                <button [disabled]="fourthFormGroup.invalid" [ngClass]="{'disable-cursor' : fourthFormGroup.invalid}"
                  class="btn btn-primary btn-submit" mat-button (click)="submit()">
                  {{'LABELS.SUBMIT' | translate}}
                </button>
              </div>
            </mat-step> -->
          </mat-horizontal-stepper>

        </div>
        <!--login-->
      </div><!-- container -->
    </div>
  </div><!-- forms__content -->
</div><!-- forms -->
<ng-template #elseBlock>
  <div class="container">
    <div class="after-register">
      <img src="../assets/images/save.png" alt="">
      <h1>{{'REGISTRATION.TITLE' | translate}}</h1>
      <p>{{'REGISTRATION.PARAGRAPH_STUDENT' | translate}}</p>
      <a href="#">{{'REGISTRATION.LINK' | translate}}</a>
    </div>
  </div>
  <!-- <div class="bottom-text">
    <p>Didn’t receive an email? Click <a href="#">here</a> to re-send the email.</p>
  </div> -->
</ng-template>
