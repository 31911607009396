<header class="header" [ngClass]="status ? 'header-show' : 'danger'">
  <div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#"><img src="../../assets/images/Logo.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" (click)="headerToggle()">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">

          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/dashboard">
              <img src="../../assets/images/conversation.png" alt="">
              Overview
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/images/calendar.png" alt="">
              All Conections</a>

              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <a routerLink="/admin/appointments" class="dropdown-item">All Appointments</a>
                <div class="dropdown-divider"></div>
                <a routerLink="/admin/matches" class="dropdown-item">All Matches</a>
                <div class="dropdown-divider"></div>
                <a  routerLink="/admin/proposals" class="dropdown-item">All Proposals</a>
              </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/images/overview-dashboard.png" alt="">
              Users
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a routerLink="/admin/tutors" class="dropdown-item">Tutors</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/students" class="dropdown-item">Students</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/roles" *ngIf="isAdmin" class="dropdown-item">Admin Roles</a>
            </div>
          </li>

          <li class="nav-item" *ngIf="isAdmin">
            <a class="nav-link" routerLink="/admin/invoice">
              <img src="../../assets/images/engagements.png" alt="">
              Invoices
            </a>
          </li>
          <li class="nav-item" *ngIf="isAdmin || isManager">
            <a class="nav-link" routerLink="/admin/report">
              <img src="../../assets/images/engagements.png" alt="">
              Reports
            </a>

          </li>
          <li class="nav-item dropdown" *ngIf="isAdmin || isManager">
            <a  class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/images/engagements.png" alt="">
              Mails
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a routerLink="/admin/mails" class="dropdown-item">Send E-mail</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/faq" class="dropdown-item">Landing Page Mails</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/feedback" class="dropdown-item">Landing Page Feedback</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/user-question" class="dropdown-item">User Questions</a>
            </div>

          </li>

          <li class="nav-item dropdown" *ngIf="isAdmin">
            <a  class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="../../assets/images/engagements.png" alt="">
              Employee
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a routerLink="/admin/employee" class="dropdown-item">Add new employee</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/admin/payout" class="dropdown-item">Payout</a>
            </div>

          </li>

        </ul>
        <div class="btn-sign dropdown">
          <a class="nav-link dropdown-toggle profile-name" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="initial">AD</div>
            {{user?.name}}
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <!-- <a class="dropdown-item" routerLink="/admin/profile"><img src="../../assets/images/account-settings.png" alt="">Account Settings</a>
            <div class="dropdown-divider"></div> -->
            <a class="dropdown-item" (click)="sendToPayoutes()" ><img src="../../assets/images/wallet.png" alt="">Payment</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" (click)="logOut()"><img src="../../assets/images/sign-out.png" alt="">Sign Out</a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</header>
<router-outlet></router-outlet>
