import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// setTimeout(() => {
//   window['Tawk_API'] = window['Tawk_API'] || {};

//   window['Tawk_API'].onLoad = function () {
//     //place your code here
//     console.log('window.Tawk_API.isChatHidden()', window['Tawk_API'].isChatHidden())
//     window['Tawk_API'].hideWidget();
//   };
// }, 3000)


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
