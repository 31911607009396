<header class="header-01 sticky">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="mobile-wrapper">
          <nav class="navbar navbar-expand-lg">
            <!-- logo Start-->
            <a class="navbar-brand" href="index.html">
              <img src="../assets/images/logo-educa.png" alt="">
              <img class="sticky-logo" src="../assets/images/logo-educa.png" alt="">
            </a>
            <!-- logo End-->

            <!-- Moblie Btn Start -->
            <button class="navbar-toggler" type="button">
              <!-- <i class="fal fa-bars"></i> -->
              <fa-icon [icon]="faBars"></fa-icon>
            </button>
            <!-- Moblie Btn End -->

            <!-- Nav Menu Start -->
            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="menu-item-has-children">
                  <a routerLink="/">Home</a>

                </li>

                <li class="menu-item-has-children">
                  <a (click)="becomeStudent()" routerLink="/">{{'LABELS.BECOME_STUDENT' | translate}}</a>
                </li>
                <li class="menu-item-has-children">
                  <a (click)="becomeTutor()" routerLink="/tutor">{{'HOMEPAGE.NAV.BECOME_TUTOR' | translate}}</a>
                </li>
                <!-- <li class="menu-item-has-children">
                  <a>{{'HOMEPAGE.NAV.GROUP_TUTORING' | translate}}</a>
                </li> -->
                <li>
                  <a routerLink="/about">{{'HOMEPAGE.NAV.ABOUT_US' | translate}}</a>
                </li>
                <li class="nav-item flag-item">

                  <div class="dropdown d-inline-block" ngbDropdown>
                    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                      <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="16"> <span
                        class="ml-1">{{countryName}}</span>
                      <img *ngIf="flagvalue === undefined" src="{{valueset}}" height="16">
                      <span *ngIf="flagvalue === undefined" class="ml-1">English</span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right flag-menu" ngbDropdownMenu>

                      <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
                        (click)="setLanguage(item.text, item.lang, item.flag)"
                        [ngClass]="{'active': cookieValue === item.lang}">
                        <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12"> <span
                          class="align-middle">{{item.text}}</span>
                      </a>

                    </div>
                  </div>
                </li>

                <!-- Join Btn -->
                <a routerLink="/login" class="join-btn">{{'LOGIN_PAGE.SIGN_IN' | translate}}</a>
                <!-- Join Btn -->
              </ul>
            </div>
            <!-- Nav Menu End -->


          </nav>
        </div>

      </div>
    </div>
  </div>
</header>

<section class="page-banner" style="background-image: url('../../../assets/images/4.jpg');">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2 class="banner-title">Details Group Tutoring</h2>

      </div>
    </div>
  </div>
</section>

<section class="course-details-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-9">
        <div class="single-course-area">
          <div class="course-top">
            <h4>Using Creative Problem Solving</h4>
            <div class="course-meta">
              <div class="author">
                <img src="../../../assets/images/a1.png" alt="">
                <span>Teacher</span>
                <a href="#">Anthony</a>
              </div>
              <div class="categories">
                <span>Categories:</span>
                <a href="#">Art & Design</a>
              </div>
              <div class="ratings">
                <span>4.5 (9 Reviews)</span>
                <i class="icon_star"></i>
                <i class="icon_star"></i>
                <i class="icon_star"></i>
                <i class="icon_star"></i>
                <i class="icon_star"></i>
              </div>
            </div>
            <div class="course-price">
              $75.00
              <span>$92.00</span>
            </div>
          </div>
          <div class="sc-thumb">
            <img src="../../../assets/images/singlepage.jpg" alt="">
          </div>
          <div class="course-tab-wrapper">
            <ul class="course-tab-btn nav nav-tabs">
              <li><a href="#overview" data-toggle="tab"><i class="icon_ribbon_alt"></i>Overview</a></li>
              <li><a href="#curriculum" data-toggle="tab"><i class="icon_book_alt"></i>Curriculum</a></li>
              <li><a class="active" href="#instructors" data-toggle="tab"><i class="icon_profile"></i>Instructors</a>
              </li>
              <li><a href="#reviews" data-toggle="tab"><i class="icon_star"></i>Reviews</a></li>
            </ul>
            <!-- Tab Content -->
            <div class="tab-content">
              <!-- Overview Tab -->
              <div class="tab-pane fade in" id="overview" role="tabpanel">
                <div class="overview-content">
                  <h4>Course Description</h4>
                  <p>
                    You mug tickety-boo bite your arm off David bamboozled easy peasy in my flat bugger argy-bargy,
                    Oxford starkers barney bender haggle barmy A bit of how's your father, pear shaped arse over tit
                    dropped a clanger cup of char bloke mufty pukka. He legged it some dodgy chav pardon you vagabond
                    old bubble and squeak posh young delinquent mufty Charles quaint, bloke spend a penny sloshed Harry
                    car boot bugger all mate bamboozled blatant cack, skive off hanky panky cheeky chap hotpot burke
                    such a fibber cheeky bugger blag. Chinwag Eaton owt to do with me Charles excuse my French cor
                    blimey guvnor bender give us a bell gosh bamboozled, wind up in my flat David bog lurgy the full
                    monty cup of char cockup, brolly bevvy super bubble and squeak well starkers cobblers smashing. Only
                    a quid amongst well argy-bargy hotpot bugger all mate chimney pot cup of char do one barmy
                    fantastic, Eaton bloke barney blower haggle cheesed off Elizabeth daft.
                  </p>
                  <p>
                    Chimney pot barmy easy peasy he lost his bottle nancy boy old cor blimey guvnor bog tickety-boo
                    geeza, Richard on your bike mate down the pub are you taking the piss cack super hunky-dory haggle I
                    spend a penny, hanky panky zonked cobblers spiffing good time cup of tea in my flat faff about the
                    full monty.
                  </p>
                  <h4>Course Description</h4>
                  <ul>
                    <li><i class="icon_check_alt2"></i>Learn The Latest Skills
                      <span>
                        He lost his bottle starkers up the duff wind up easy peasy cracking goal cheers I butty only a
                        quid he legged it, cuppa the little rotter bevvy bugger all mate spiffing good time.
                      </span>
                    </li>
                    <li><i class="icon_check_alt2"></i>Earn a Certificate or Degree
                      <span>
                        Chimney pot barmy easy peasy he lost his bottle nancy boy old cor blimey guvnor bog tickety-boo
                        geeza, Richard on your bike mate down the pub are you taking.
                      </span>
                    </li>
                    <li><i class="icon_check_alt2"></i>Get Ready for a Career
                      <span>
                        Lost the plot plastered he lost his bottle blatant barney butty are you taking the piss porkies
                        me old mucker young delinquent smashing so I said pear shaped cheeky say.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Overview Tab -->
              <!-- Curriculum Tab -->
              <div class="tab-pane fade in" id="curriculum" role="tabpanel">
                <div class="curriculum-item" id="id_1">
                  <div class="card-header" id="cc_1">
                    <h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#acc_1" aria-expanded="true"
                        aria-controls="acc_1">
                        Understanding Customer Service
                      </button>
                    </h5>
                  </div>
                  <div id="acc_1" class="collapse show" aria-labelledby="cc_1" data-parent="#id_1">
                    <div class="card-body">
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Identifying Customer Expectations</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">02 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Customer Service And The Telephone</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">04 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Handling Complaints</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">03 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Customer Service Skills - Course Assessment</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">03 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Focusing On The Customer</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">01 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="curriculum-item" id="id_2">
                  <div class="card-header" id="cc_2">
                    <h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#acc_2" aria-expanded="true"
                        aria-controls="acc_2">
                        Handling Complaints
                      </button>
                    </h5>
                  </div>
                  <div id="acc_2" class="collapse show" aria-labelledby="cc_2" data-parent="#id_2">
                    <div class="card-body">
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Handling Complaints</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">02 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Customer Service And The Telephone</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">04 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">WooCommerce Payments</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="questions">4 questions</a>
                          <a href="#" class="time">03 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="curriculum-item" id="id_3">
                  <div class="card-header" id="cc_3">
                    <h5 class="mb-0">
                      <button class="btn btn-link" data-toggle="collapse" data-target="#acc_3" aria-expanded="true"
                        aria-controls="acc_3">
                        Pellentesque Pretium
                      </button>
                    </h5>
                  </div>
                  <div id="acc_3" class="collapse show" aria-labelledby="cc_3" data-parent="#id_3">
                    <div class="card-body">
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">How to Use WordPress</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">02 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Certificate On Theme Development</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">04 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item with-bg">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Focusing On The Customer</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="questions">4 questions</a>
                          <a href="#" class="time">03 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                      <div class="ci-item">
                        <h5>
                          <i class="icon_menu-square_alt2"></i>
                          <a href="#">Identifying Customer Expectations</a>
                        </h5>
                        <div class="ci-tools">
                          <a href="#" class="time">03 hour</a>
                          <a href="#" class="lock"><i class="icon_lock_alt"></i></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Curriculum Tab -->
              <!-- Instructors Tab -->
              <div class="tab-pane fade in show active" id="instructors" role="tabpanel">
                <div class="teacher-item-3">
                  <div class="teacher-thumb">
                    <img src="../../../assets/images/i1.jpg" alt="">
                  </div>
                  <div class="teacher-meta">
                    <h5><a href="#">Dianne Ameter</a></h5>
                    <span>Illustrator</span>
                    <p>
                      I don't want no agro car boot lavatory wind up twit haggle spiffing show off show off pick your
                      nose and blow off spend a penny David zonked what a plonker are you taking.
                    </p>
                    <div class="teacher-social">
                      <a href="#"><i class="social_facebook"></i></a>
                      <a href="#"><i class="social_twitter"></i></a>
                      <a href="#"><i class="social_linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="teacher-item-3">
                  <div class="teacher-thumb">
                    <img src="../../../assets/images/i1.jpg" alt="">
                  </div>
                  <div class="teacher-meta">
                    <h5><a href="#">Hugh Saturation</a></h5>
                    <span>Photographer</span>
                    <p>
                      I don't want no agro car boot lavatory wind up twit haggle spiffing show off show off pick your
                      nose and blow off spend a penny David zonked what a plonker are you taking.
                    </p>
                    <div class="teacher-social">
                      <a href="#"><i class="social_facebook"></i></a>
                      <a href="#"><i class="social_twitter"></i></a>
                      <a href="#"><i class="social_linkedin"></i></a>
                    </div>
                  </div>
                </div>
                <div class="teacher-item-3">
                  <div class="teacher-thumb">
                    <img src="../../../assets/images/i1.jpg" alt="">
                  </div>
                  <div class="teacher-meta">
                    <h5><a href="#">Jim Séchen</a></h5>
                    <span>Stylist & Author</span>
                    <p>
                      I don't want no agro car boot lavatory wind up twit haggle spiffing show off show off pick your
                      nose and blow off spend a penny David zonked what a plonker are you taking.
                    </p>
                    <div class="teacher-social">
                      <a href="#"><i class="social_facebook"></i></a>
                      <a href="#"><i class="social_twitter"></i></a>
                      <a href="#"><i class="social_linkedin"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Instructors Tab -->
              <!-- Reviews Tab -->
              <div class="tab-pane fade in" id="reviews" role="tabpanel">
                <div class="reviw-area">
                  <h4>Reviews</h4>
                  <div class="reating-details">
                    <div class="average-rate">
                      <p>Average Rating</p>
                      <div class="rate-box">
                        <h2>4.8</h2>
                        <div class="ratings">
                          <i class="icon_star"></i>
                          <i class="icon_star"></i>
                          <i class="icon_star"></i>
                          <i class="icon_star"></i>
                          <i class="icon_star"></i>
                        </div>
                        <span>4 Reviews</span>
                      </div>
                    </div>
                    <div class="details-rate">
                      <p>Detailed Rating</p>
                      <div class="detail-rate-box">
                        <div class="rate-item">
                          <p>5</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="100"
                              aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <span>100%</span>
                        </div>
                        <div class="rate-item">
                          <p>4</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 30%;" aria-valuenow="30"
                              aria-valuemin="0" aria-valuemax="30"></div>
                          </div>
                          <span>30%</span>
                        </div>
                        <div class="rate-item">
                          <p>3</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 20%;" aria-valuenow="20"
                              aria-valuemin="0" aria-valuemax="20"></div>
                          </div>
                          <span>20%</span>
                        </div>
                        <div class="rate-item">
                          <p>2</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 10%;" aria-valuenow="10"
                              aria-valuemin="0" aria-valuemax="10"></div>
                          </div>
                          <span>10%</span>
                        </div>
                        <div class="rate-item">
                          <p>1</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width: 0%;" aria-valuenow="0"
                              aria-valuemin="0" aria-valuemax="0"></div>
                          </div>
                          <span>0%</span>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
              <!-- Reviews Tab -->
            </div>
            <!-- Tab Content -->
          </div>


        </div>
      </div>
      <div class="col-lg-3">
        <div class="course-sidebar">
          <aside class="widget">
            <div class="info-course">
              <ul>
                <li><i class="icon_house_alt"></i><span>Instructor: </span> Justin Case</li>
                <li><i class="icon_document_alt"></i><span>Lectures: </span> 14</li>
                <li><i class="icon_clock_alt"></i><span>Duration: </span> 10 weeks</li>
                <li><i class="icon_profile"></i><span>Enrolled: </span> 75 students</li>
                <li><i class="icon_cog"></i><span>Language: </span> English</li>
                <li><i class="icon_calendar"></i><span>Deadline: </span> 16 April 2020</li>
              </ul>
              <a class="bisylms-btn" href="#">Enroll Course</a>
            </div>
          </aside>

        </div>
      </div>
    </div>
  </div>
</section>
<footer class="footer">
  <div class="container">
    <div class="footer__content row">
      <div class="col-md-4 footer-flex">
        <div class="footer__content_logo">
          <a href=""><img src="../assets/images/logo-educa.png"></a>

        </div>
        <p class="copy">EducaGrow &copy; {{currentYear}}</p>
      </div>
      <!--col-md-6-->
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" routerLink='/impressum'>Impressum</li>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title" (click)="contactModal(contactInfo)">Kontakt</li>
            <ng-template #contactInfo let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Contact Info</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="general-heading">E-mail: <a href="mailto:info@educagrow.com">info@educagrow.com</a></p>
                <p class="general-heading contact-margin">Phone: <a href="tel:0763327653">0763327653</a></p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Done</button>
              </div>
            </ng-template>
          </ul>
        </div>
        <!--footer__content_menu-->
      </div>

      <div class="col-md-2">
        <div class="footer__content_menu">
          <ul>
            <li class="list-title">Verbesserungsvorschläge</li>

          </ul>
        </div>
        <!--footer__content_menu-->
      </div>
    </div>
    <!--footer__content-->
  </div>
  <!--container-->
</footer>
