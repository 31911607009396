import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  faShoePrints,
  faUsers,
  faBriefcase,
  faClock,
  faInfoCircle,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faChalkboardTeacher,
  faEdit,
  faHandshake,
  faBoxOpen,
  faCreditCard,
  faCalendarAlt,
  faCalendarTimes,
  faUserShield,
  faHome,
  faPhoneAlt,
  faEnvelope,
  faDotCircle,
  faBars,
  faHandHoldingUsd
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';
import { StudentService } from 'src/app/core/_services/student.service';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { HomeService } from 'src/app/core/_services/home.service';

@Component({
  selector: 'app-tutor',
  templateUrl: './tutor.component.html',
  styleUrls: ['./tutor.component.scss']
})
export class TutorComponent implements OnInit {


  stepIndicators = faShoePrints;
  faUsers = faUsers;
  faBriefcase = faBriefcase;
  faClock = faClock;
  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign;
  percentRate = faPercent;
  overview = faEye;
  faEdit = faEdit;
  faHandshake = faHandshake;
  faBoxOpen = faBoxOpen;
  faChalkboardTeacher = faChalkboardTeacher;
  faCreditCard = faCreditCard;
  faCircle = faDotCircle;
  faCalendarAlt = faCalendarAlt;
  faCalendarTimes = faCalendarTimes;
  faUserShield = faUserShield;
  faHome = faHome;
  faPhoneAlt = faPhoneAlt;
  faEnvelope = faEnvelope;
  faBars = faBars;
  faHandHoldingUsd = faHandHoldingUsd;

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;	'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1,
      }
    },
    nav: true
  }

  howItWorksOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    autoplayTimeout: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['&larr;', '&rarr;	'],
    margin: 20,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1,
      }
    },
    nav: true
  }

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;
  currentYear;


  desiredFormGroup: FormGroup;
  QAFormGroup: FormGroup;
  feedbackForm: FormGroup;

  feedbackArray;

  panelOpenState = false;
  sendQA = false;

  listLang = [
    { text: '', flag: '../assets/images/flags/us.jpg', lang: 'en' },
    { text: '', flag: '../assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: '../assets/images/flags/italy.jpg', lang: 'it' },
  ];

  constructor(private studentService: StudentService,
    private registerFormService: RegisterFormsService,
    config: NgbRatingConfig,
    private router: Router,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    public modalService: NgbModal) {

      // this.studentService.getQA().subscribe(data => {
      //   console.log('QA',data)
      // })

      this.homeService.getFeedback().subscribe(data => {
        this.feedbackArray = data['data'].filter((el, i) => {
          return el['type'] == 'teacher';
        })
      })

     }

  ngOnInit(): void {

    this.desiredFormGroup = this.formBuilder.group({
      desiredDay: ['', Validators.required],
      desiredDate: ['', Validators.required],
    });

    this.QAFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      description: ['', Validators.required],
      phone: ['']
    });

    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', Validators.required],
      city: ['', Validators.required],
      description: ['', Validators.required]
    })

    this.cookieValue = localStorage.getItem('lang') || 'de';

    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.currentYear = new Date().getFullYear();
  }

  ngAfterViewInit(){

    $(window).on('scroll', function () {

      if ($(window).scrollTop() > 40) {
        $(".sticky").addClass('fix-header animated fadeInDown');
        if ($(window).width() > 991) {
          $(".navbar-nav li a").css('color', '#000')
        }
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')

      } else {
        $(".sticky").removeClass('fix-header animated fadeInDown');
        $(".navbar-nav li a").css('color', '#000')
        $(".mobile-wrapper .navbar.navbar-expand-lg .navbar-toggler").css('color', '#000')
      }

    });

    if ($(window).width() < 991) {

      $('.navbar-toggler').on('click', function (e) {

        e.stopPropagation();
        $('.navbar-collapse').stop(true, true).slideToggle();
        e.preventDefault();
      });

      $('.navbar-nav li.menu-item-has-children').each(function () {
        var $this = $(this);
        $this.append('<span class="submenu-toggler"><i class="fal fa-plus"></i></span>');
      });

      $('.navbar-nav li.menu-item-has-children span.submenu-toggler').on('click', function () {
        var $this = $(this);

        if ($(this).hasClass('active-span')) {
          $('i', $this).removeClass('fa-minus').addClass('fa-plus');
        } else {
          $('i', $this).addClass('fa-minus').removeClass('fa-plus');
        }

        $(this).prev('ul.sub-menu, .mega-menu').stop(true, true).slideToggle();
        $(this).toggleClass('active-span');
      });
    };

  }

  becomeStudent(){
    window.localStorage.setItem('route', '/')
  }


  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    localStorage.setItem('lang', lang)
    window.location.reload();
  }



  changeLang(lang) {
    console.log(lang)
    localStorage.setItem('lang', lang)
    window.location.reload();
  }

  contactModal(contactInfo) {
    this.modalService.open(contactInfo, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {

    }, (reason) => {

    });
  }

  sendEmail() {
    this.sendQA = true;
    console.log(this.QAFormGroup)
    this.studentService.sendQA(this.QAFormGroup).subscribe(res => {
      this.sendQA = false;
      console.log(res)
      this.QAFormGroup.reset();
      Swal.fire(
        'Done!',
        'E-mail Sent!',
        'success'
      )
    }, (err) => {
      this.sendQA = false;
      Swal.fire(
        {
          icon: 'error',
          title: 'Oops...',
          text: 'Try Again!',
        })
    })
  }

  feedbackModal(feedback) {
    this.modalService.open(feedback);
  }

  sendFeedback(){
    this.homeService.feedback(this.feedbackForm).subscribe(res => {
      console.log(res)
      this.modalService.dismissAll()
      Swal.fire(
        'Done!',
        'Feedback Sent!',
        'success'
      )
    }, (err) => {
      this.sendQA = false;
      this.modalService.dismissAll()
      Swal.fire(
        {
          icon: 'error',
          title: 'Oops...',
          text: 'Try Again!',
        })
    })
  }


}
