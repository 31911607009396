import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/_services/auth.service';
import { StudentService } from 'src/app/core/_services/student.service';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { DatePipe } from '@angular/common';
import {
  faInfoCircle,
  faClock,
  faUserTie,
  faDollarSign,
  faPercent,
  faEye,
  faHome,
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faEnvelopeOpenText,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

import { StripeService } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';



@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss'],
  providers: [DatePipe]

})
export class BookingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('#card-element') cardElement: ElementRef;

  elements: StripeElements;
  card: StripeCardElement;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;

  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign
  percentRate = faPercent
  overview = faEye;
  calendarIcon = faCalendarAlt;
  faHome = faHome;
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faQuestionCircle = faQuestionCircle;

  bookingFormGroup: FormGroup;
  secondFormGroup;
  isEditable = false;

  fullPageLoader = false;

  _totalAmount: number;
  //card: any;
  //cardHandler = this.onChange.bind(this);
  cardError: string;

  subjectId;
  tutor;
  proposalDetails;
  teacherId;
  studentId;

  tutorData;

  value: number = 5;
  tutorPriceLessonSlider;
  percentage = 0;
  perArr = [0, 0, 0, 0, 0];
  nrOfLessonsId;
  totalPriceLesson = 0;
  fixedPriceLesson = [];
  tutorPriceLesson;
  nr_of_lessons_id;


  periodicityData = {
    periodicityTime: [],
    sortedByDay: []
  };


  paymentStatus;

  startingDay = [];
  startingDate;

  startingLessons;

  subjectDescription;

  options: Options = {
    showTicks: true,
    showSelectionBar: true,
    floor: 5,
    ceil: 35,
    getLegend: (value: number): string => {
      if (value == 9) {
        return `1-9
        <div class="percentage-price">
        CHF ${this.fixedPriceLesson[0]}
        </div>

        <div class="percentage-color">
        ${this.changeColor(this.perArr[0].toFixed(2))}%
        </div>
        `
      } else if (value == 14) {
        return `10-14
        <div class="percentage-price">
        CHF ${this.fixedPriceLesson[1]}
        </div>
        <div class="percentage-color">
          ${this.changeColor(this.perArr[1].toFixed(2))}%
        </div>`
      }
      else if (value == 19) {
        return `15-19
        <div class="percentage-price">
        CHF ${this.fixedPriceLesson[2]}
        </div>
        <div class="percentage-color">
        ${this.changeColor(this.perArr[2].toFixed(2))}%
        </div>`
      } else if (value == 24) {
        return `20-24
        <div class="percentage-price">
        CHF ${this.fixedPriceLesson[3]}
        </div>
        <div class="percentage-color">
         ${this.changeColor(this.perArr[3].toFixed(2))}%
        </div>`
      } else if (value == 29) {
        return `25 - 29+
        <div class="percentage-price">
        CHF ${this.fixedPriceLesson[4]}
        </div>
        <div class="percentage-color">
         ${this.changeColor(this.perArr[4].toFixed(2))}%
        </div>`
      }
    }
  };

  lang;
  websiteFee;

  get periodicityFormArray() {
    return this.bookingFormGroup.controls.periodicity as FormArray;
  }

  constructor(private studentService: StudentService,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private authService: AuthService,
    private stripeService: StripeService) {

    this.lang = localStorage.getItem('lang') || 'de';

    this.route.queryParamMap.subscribe(data => {
      this.subjectId = data['params'].subject_id;
    })

    this.studentService.getWebsiteFee().subscribe(data => {
      console.log('fees',data)
      this.websiteFee = data;
    })


    this.authService.getUser().subscribe(dataUser => {
      console.log('user', dataUser)
      //console.log('teacherId', this.teacherId)
      //this.studentId = dataUser.data.user.id;

      dataUser.data.user.subjects.forEach(subject => {
        if (this.subjectId == subject.id) {
          this.subjectDescription = subject.pivot_student.describe;
        }
      });

      if (dataUser.data.user.preferences.nr_of_lessons_id != null) {
        this.nr_of_lessons_id = dataUser.data.user.preferences.nr_of_lessons_id;
      } else {
        dataUser.data.user.subjects.forEach(subject => {
          if (this.subjectId == subject.id) {
            this.nr_of_lessons_id = subject.pivot_student.nr_of_lessons_id;
          }
        });
      }

    })

  }

  ngOnInit(): void {

    this.stripeTest = this.formBuilder.group({
      name: ['', Validators.required]
    });



    this.bookingFormGroup = this.formBuilder.group({
      starting_date: ['', Validators.required],
      starting_day: ['', Validators.required]
    });

    this.secondFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required]
    });

    this.retrieveProposal();

    console.log('booking Form', this.secondFormGroup)


  }

  ngAfterViewInit() {

    //this.initiateCardElement();

    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', this.cardOptions);
          //this.card.mount('#card-element')
          this.card.mount(document.querySelector('#card-element') as HTMLCanvasElement);
        }
      });
  }

  ngOnDestroy() {
    if (this.card) {
      // We remove event listener here to keep memory clean
      // this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    }
  }




  retrieveProposal() {
    const id = this.route.snapshot.paramMap.get('id');
    this.studentService.sendSubjectIdBooked(id, this.subjectId).subscribe(data => {
      console.log('status', data)
      this.tutorData = data;
      this.tutor = data.teacher;
      this.proposalDetails = data.proposal;
      //this.teacherId = data.teacher['user']['id'];
      this.paymentStatus = data.proposal.payment;
      this.startingLessons = data.proposal.starting_lessons;

      for (var i = 0; i < data.prices.length; i++) {
        this.fixedPriceLesson[i] = data.prices[i].price;
      }



      setTimeout(() => {
        //this.value = this.proposalDetails.starting_lessons;
        this.value = 6;
        this.percCalculate(data);
        this.sliderEvent()
        //this.initiateCardElement();
      }, 100)

    })
  }



  // initiateCardElement() {
  //   // Giving a base style here, but most of the style is in scss file

  //   const cardStyle = {
  //     labels: 'floating',
  //     hidePostalCode: true,
  //     base: {
  //       padding: '100px',
  //       color: '#32325d',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '14px',
  //       '::placeholder': {
  //         color: '#aab7c4',
  //       },

  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a',
  //     },
  //   };


  //   this.card = elements.create('card', { hidePostalCode: true, cardStyle });
  //   elements.update({ locale: this.lang });
  //   // this.card.mount(document.querySelector('#example1-card'));
  //   // this.card.addEventListener('change', this.cardHandler);

  // }
  // onChange({ error }) {
  //   if (error) {
  //     this.cardError = error.message;
  //   } else {
  //     this.cardError = null;
  //   }
  //   //this.cd.detectChanges();
  // }



  // async createStripeToken(tutor) {
  //   this.fullPageLoader = true;
  //   this.isPaid = true;
  //   const { token, error } = await stripe.createToken(this.card);
  //   if (token) {
  //     this.onSuccess(token, tutor, this.subjectId);

  //   } else {
  //     this.onError('Something went wrong');
  //   }

  // }

  // onSuccess(token, tutor, subjectId) {

  //   this.dataPost = {
  //     value: this.value,
  //     nrOfLessons: this.nrOfLessonsId,
  //     price_lesson: this.tutorPriceLesson,
  //     // total_price: (this.totalPriceLesson * (1 + 3.5 / 100)).toFixed(2),
  //     total_price: this.totalPriceLesson,
  //     ...tutor,
  //     ...token,
  //     ...this.periodicityData,
  //   }
  //   // this.dataPost['date'] = this.proposedFormGroup.value.date;
  //   // this.dataPost['teacher_id'] = tutor.id;
  //   this.dataPost['subject_id'] = subjectId;
  //   this.dataPost['starting_date'] = this.startingDate;
  //   console.log('tutorStripe', this.dataPost)

  //   this.isPaid = false;
  //   console.log(this.dataPost)
  //   this.studentService.bookTeacher(this.dataPost).subscribe(data => {


  //     // Swal.fire({
  //     //   title: 'Success!',
  //     //   text: data.message,
  //     //   icon: 'success',
  //     //   confirmButtonText: 'OK'
  //     // })
  //     console.log(data)

  //     if (data) {
  //       this.fullPageLoader = false;
  //       this.router.navigate(['student/tutors'])
  //     }

  //   }, (err) => {
  //     this.fullPageLoader = false;
  //     console.log(err)
  //     // return Swal.fire({
  //     //   icon: 'error',
  //     //   title: 'Please try again',
  //     //   text: this.arrLang[this.lang].wrong,
  //     // })

  //   })


  // }

  isPaid = false;
  dataPost;
  createToken(tutor) {
    //const name = this.stripeTest.get('name').value;

    this.fullPageLoader = true;
    this.stripeService
      .createToken(this.card)
      .subscribe((result) => {
        if (result.token) {
          // Use the token


          this.dataPost = {
            value: this.value,
            nrOfLessons: this.nrOfLessonsId,
            price_lesson: this.tutorPriceLesson,
            // total_price: (this.totalPriceLesson * (1 + 3.5 / 100)).toFixed(2),
            total_price: this.totalPriceLesson,
            ...tutor,
            ...result.token,
            ...this.periodicityData,
          }
          // this.dataPost['date'] = this.proposedFormGroup.value.date;
          // this.dataPost['teacher_id'] = tutor.id;
          this.dataPost['subject_id'] = this.subjectId;
          this.dataPost['starting_date'] = this.startingDate;

          console.log('post', this.dataPost)
          this.studentService.bookTeacher(this.dataPost).subscribe(data => {

            if (data) {
              console.log(data)
              this.fullPageLoader = false;
              this.router.navigate(['student/tutors'])
            }

          }, (err) => {
            this.fullPageLoader = false;
            console.log(err)
            // return Swal.fire({
            //   icon: 'error',
            //   title: 'Please try again',
            //   text: this.arrLang[this.lang].wrong,
            // })

          })

        } else if (result.error) {
          // Error creating the token
          this.fullPageLoader = false;
          console.log(result.error.message);
        }
      });
  }

  // onError(error) {
  //   if (error.message) {
  //     this.cardError = error.message;
  //     // Swal.fire({
  //     //   icon: 'error',
  //     //   title: error.message,
  //     //   text: this.arrLang[this.lang].wrong,
  //     // })
  //     // this.modalService.dismissAll();
  //     // this.retrieveTutors();
  //     // this.retrieveMatchedTutorsSeen();
  //   }
  //   this.fullPageLoader = false;
  //   this.isPaid = false;

  // }






  changeColor(val) {
    if (val < 0) {
      return val.fontcolor('red')
    }
    else if (val == 0) {
      return val.fontcolor('#89909e')
    }
    else if (val > 0) {
      return val.fontcolor('#4bb34b')
    }
  }


  checkTime(e) {
    this.bookingFormGroup.updateValueAndValidity();
    console.log(e, $('#periodicity_' + e.target.name).val())



    if (e.target.checked === true) {
      e.target.parentNode.classList.add('periodicity-time-selected');
      e.target.nextSibling.classList.add('sign-checked')
      this.periodicityData.periodicityTime.push({
        time_id: e.target.time,//time_id
        day: e.target.id,
        periodicity: $('#periodicity_' + e.target.name).val()
      })
      $('#periodicity_' + e.target.name).removeAttr('disabled')



      let daysIndex = this.startingDay.findIndex(el => el.day == e.target.id)


      daysIndex === -1 ? this.startingDay.push({
        day: e.target.id,
        time: e.target.time
      }) : console.log('exists')

    }
    else {
      //console.log('here')


      let index = this.periodicityData.periodicityTime.findIndex(el => el.time_id == e.target.time && el.day == e.target.id)
      this.periodicityData.periodicityTime.splice(index, 1)

      e.target.parentNode.classList.remove('periodicity-time-selected');
      e.target.nextSibling.classList.remove('sign-checked')
      $('#periodicity_' + e.target.name).attr('disabled', 'disabled')

      console.log('array', this.periodicityData.periodicityTime)

      this.periodicityData.periodicityTime.forEach(elObject => {
        console.log('show', elObject)
        if (elObject.day != e.target.id) {
          let daysIndex = this.startingDay.findIndex(el => el.day == e.target.id)
          this.startingDay.splice(daysIndex, 1)

        }
      })


    }



    // this.startingDay = this.periodicityData.periodicityTime.filter((v,i,a)=>a.findIndex(t=>(t.day === v.day))===i)

    this.startingDay = this.periodicityData.periodicityTime.filter((value, index, arr) => arr.findIndex(t => (t.day == value.day)) === index)


    console.log('starting day', this.startingDay)
    this.sortArray()
  }

  sortArray() {
    let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    let sortedArray = [];

    this.periodicityData.periodicityTime.forEach(periodicity => {

      sortedArray.push({
        ...periodicity,
        dayId: days.indexOf(periodicity.day)
      })

      this.periodicityData.sortedByDay = sortedArray.sort((a, b) => Number(a.dayId) - Number(b.dayId))

      this.periodicityData.sortedByDay.sort(
        (a, b) => {
          if (a.dayId === b.dayId) {
            // Price is only important when cities are the same
            return a.time_id - b.time_id;
          }
          return a.dayId > b.dayId ? 1 : -1;
        });

      //sortedByDay.sort((a, b) => Number(a.time_id) - Number(b.time_id))

    })

    console.log('sorted', this.periodicityData.sortedByDay)
  }

  changePeriodicity(e, time, day) {

    let dayEvent = e.target.offsetParent.childNodes[0].time;
    let timeEvent = e.target.offsetParent.childNodes[0].id;


    let indexPerdiodicity = this.periodicityData.periodicityTime.findIndex(el => el.day == day && el.time_id == time)

    console.log('index', indexPerdiodicity)

    this.periodicityData.periodicityTime[indexPerdiodicity].periodicity = e.target.value;

    console.log(this.periodicityData)
  }


  percCalculate(teacher) {

    console.log('percentage', teacher)

    const numberArr = [9, 10, 15, 20, 25]

    for (var i = 0; i < numberArr.length; i++) {
      for (var j = 0; j < teacher.prices.length; j++) {

        if (i == j) {

          this.tutorPriceLesson = teacher.prices[j].price;
          if (this.nr_of_lessons_id == teacher.prices[j].id) {

            this.perArr[i] = ((numberArr[i] * (this.tutorPriceLesson)) / (numberArr[i] * this.tutorPriceLesson) * 100) - 100;

          } else {
            this.perArr[i] = 100 - ((numberArr[i] * this.tutorPriceLesson) / (numberArr[i] * (this.tutorPriceLesson - (teacher.prices[j].price - teacher.prices[this.nr_of_lessons_id - 1].price))) * 100);
          }
        }

      }
    }
  }


  sliderEvent() {
    if (this.value <= 9) {
      this.tutorPriceLessonSlider = this.tutorData.prices[0].price;
      //this.percentage_9 = 100 - ((this.value * this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[0].pivot.price - this.teacher.prices_per_lesson[1].pivot.price))) * 100);
      this.percentage = this.perArr[0];
      this.nrOfLessonsId = 1;

    } else if (this.value >= 10 && this.value <= 14) {
      this.tutorPriceLessonSlider = this.tutorData.prices[1].price;
      //this.percentage_14 = ((this.value * (this.tutorPriceLesson)) / (this.value * this.tutorPriceLesson) * 100) - 100;
      this.percentage = this.perArr[1];
      this.nrOfLessonsId = 2;
    } else if (this.value >= 15 && this.value <= 19) {
      this.tutorPriceLessonSlider = this.tutorData.prices[2].price;
      //this.percentage_19 = 100 - ((this.value * (this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[2].price - this.teacher.prices[1].price)))) * 100);
      this.percentage = this.perArr[2];
      this.nrOfLessonsId = 3;
    } else if (this.value >= 20 && this.value <= 24) {
      this.tutorPriceLessonSlider = this.tutorData.prices[3].price;
      //this.percentage_20 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[3].price - this.teacher.prices[1].price))) * 100);
      this.percentage = this.perArr[3];
      this.nrOfLessonsId = 4;
    } else if (this.value >= 25) {
      this.tutorPriceLessonSlider = this.tutorData.prices[4].price;
      //this.percentage_25 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[4].price - this.teacher.prices[1].price))) * 100);
      this.percentage = this.perArr[4];
      this.nrOfLessonsId = 5;
    }
    this.totalPriceLesson = this.tutorPriceLessonSlider * this.value;
    console.log('totali', this.totalPriceLesson)

  }


  dayIndex;

  getNextDay(dayName) {


    // The current day
    let date = new Date();
    let now = date.getDay();
    let optionsFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

    // Days of the week
    let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    // The index for the day you want
    let day = days.indexOf(dayName.toLowerCase());
    this.dayIndex = day;

    // Find the difference between the current day and the one you want
    // If it's the same day as today (or a negative number), jump to the next week
    let diff = day - now;
    diff = diff < 2 ? 7 + diff : diff;

    // Get the timestamp for the desired day
    let nextDayTimestamp = date.getTime() + (1000 * 60 * 60 * 24 * diff);


    // Get the next day
    return new Date(nextDayTimestamp).toLocaleDateString("en-US");




  }

  nextDates = [];
  getNextDates(e) {
    console.log(e)
    let choosedDate = new Date(this.getNextDay(e.value));
    let daySum = 0;
    for (let i = 0; i < 7; i++) {
      this.nextDates[i] = (new Date(choosedDate.valueOf() + daySum * 24 * 60 * 60 * 1000))
      daySum += 7;
    }

    console.log(this.nextDates)
  }


  getStartingDate(e) {
    this.startingDate = this.datePipe.transform(e.value, 'yyyy/MM/dd')
    console.log(this.startingDate)
  }

}
