import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import { StudentRoutingModule } from './student-routing.module';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { NavbarComponent } from './navbar/navbar.component';
import { EngagementsComponent } from './engagements/engagements.component';


// import { TableModule } from 'primeng/table';
// import { ToastModule } from 'primeng/toast';
// import { SliderModule } from 'primeng/slider';
// import { MultiSelectModule } from 'primeng/multiselect';
// import { ContextMenuModule } from 'primeng/contextmenu';
// import { DialogModule } from 'primeng/dialog';
// import { ButtonModule } from 'primeng/button';
// import { DropdownModule } from 'primeng/dropdown';
// import { ProgressBarModule } from 'primeng/progressbar';
// import { InputTextModule } from 'primeng/inputtext';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';

import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TutorsComponent } from './tutors/tutors.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MessengerComponent } from './messenger/messenger.component';


import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgApexchartsModule } from 'ng-apexcharts';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';
import {NgxPhotoEditorModule} from "ngx-photo-editor";
import { PaymentsComponent } from './payments/payments.component';
import { ContactComponent } from './contact/contact.component';
import { ProposalComponent } from './tutors/proposal/proposal.component';
import { DashboardProposalComponent } from './dashboard/dashboard-proposal/dashboard-proposal.component';
import { BookingComponent } from './tutors/booking/booking.component';
import { MatBadgeModule } from '@angular/material/badge';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { CarouselModule } from 'ngx-owl-carousel-o';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  bootstrapPlugin
]);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    DashboardComponent,
    ProfileComponent,
    NavbarComponent,
    EngagementsComponent,
    TutorsComponent,
    CalendarComponent,
    MessengerComponent,
    PaymentsComponent,
    ContactComponent,
    ProposalComponent,
    DashboardProposalComponent,
    BookingComponent
  ],
  imports: [
    FormsModule,
    NgbModule,
    CommonModule,
    FullCalendarModule,
    StudentRoutingModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    MatExpansionModule,
    MatBadgeModule,
    // TableModule,
    // SliderModule,
    // DialogModule,
    // MultiSelectModule,
    // ContextMenuModule,
    // DropdownModule,
    // ButtonModule,
    // ToastModule,
    // InputTextModule,
    // ProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PerfectScrollbarModule,
    NgxSliderModule,
    CarouselModule,
    MatCheckboxModule,
    MatSidenavModule,
    NgApexchartsModule,
    MatPaginatorModule,
    MatIconModule,
    FontAwesomeModule,
    TranslateModule,
    CrystalLightboxModule,
    NgxPhotoEditorModule,
    Ng2TelInputModule,
  ],
  providers: [
    MatDatepickerModule,
    MatStepperModule,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class StudentModule { }
