<div class="proposal">
  <div *ngIf="fullPageLoader" class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="container">
    <div class="main-body">

      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card" style="height: 100%;">
            <div class="card-body image-box">

              <div class="d-flex flex-column align-items-center text-center">
                <img *ngIf="this.tutor?.image_path != ''" [src]="this.tutor?.image_path" alt="" class="rounded-circle"
                  width="200" height="200">
                <img *ngIf="this.tutor?.image_path == ''" width="200" height="200"
                  src="../../../assets/images/{{this.tutor?.gender}}.jpg" alt="">
                <div class="mt-3">
                  <h4> {{this.tutor?.name}}</h4>
                  <ngb-rating [rate]="this.tutor?.rating" [max]="5" [readonly]="true" class="stars-color">rating
                  </ngb-rating>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="personalInfo"></fa-icon>
            <h1 class="main-title">
              {{'TUTORS.MODALS.MATCHED_TUTOR.PERSONAL_INFORMATION' | translate}}
            </h1>
          </div>

          <div class="card mb-3">

            <div class="card-body">

              <div class="row">

                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.age}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.edu_finished}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  <div *ngFor="let subject of this.tutor?.subjects">
                    <div *ngIf="subject.id == this.subjectId">
                      {{subject.name}}
                    </div>
                  </div>
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  <div *ngFor="let price of this.tutor?.subjects">
                    <div *ngIf="price.id == this.subjectId">
                      {{price.pivot_teacher.prices[0].price}} CHF
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <!-- <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Subject</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.education.current_work}}
                </div>
              </div> -->


              <!-- <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.tutoring.experience}}
                </div>
              </div>
              <hr> -->
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.all_students}}
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  <b>{{this.tutor?.user.last_login_at | slice:0:11 | date:'dd/MM/yyyy'}}</b>
                </div>
              </div>


            </div>
          </div>



        </div>
        <!-- <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="bio"></fa-icon>
            <h1 class="main-title">
              {{'DASHBOARD_PROPOSAL.REASON' | translate}}
            </h1>
          </div>
          <div class="card mb-3">
            <div class="card-body card-body-edit">
              <div class="col-sm-9 text-secondary">
                {{this.subjectDescription}}
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-md-12 question"
          *ngIf="proposalDetails?.messages[0].message != null || proposalDetails?.messages[1].message != null">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="faEnvelopeOpenText"></fa-icon>
            <h1 class="main-title">
              {{'BOOKING.MESSAGE' | translate}}
            </h1>
          </div>
          <div class="card mb-3">
            <div class="card-body forms text-secondary">
              <div *ngFor="let sms of proposalDetails?.messages">
                <div class="question-part">
                  <h1 *ngIf="sms.sender == 'student'">{{'BOOKING.YOUR_MESSAGE' | translate}}</h1>
                  <p class="text-secondary" *ngIf="sms.sender == 'student'">{{sms.message}}</p>
                  <!-- <ng-template #elseStudent>
                    <p class="text-secondary">{{'DASHBOARD_PROPOSAL.NO_MESSAGE_STUDENT' | translate}}</p>
                  </ng-template> -->
                </div>

                <div class="question-part">
                  <h1 *ngIf="sms.sender == 'teacher'">{{'BOOKING.TEACHER_MESSAGE' | translate}}</h1>
                  <p class="text-secondary" *ngIf="sms.sender == 'teacher'">{{sms.message}}</p>
                  <!-- <ng-template  #elseTeacher>
                    <p class="text-secondary">{{'DASHBOARD_PROPOSAL.NO_MESSAGE_TEACHER' | translate}}</p>
                  </ng-template> -->
                </div>
              </div>
            </div>


          </div>
        </div>




        <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="calendarIcon"></fa-icon>
            <h1 class="main-title">
              {{'BOOKING.MATCH_TIME' | translate}}
            </h1>
          </div>

          <div class="card mb-3">
            <div class="card-body steps">
              <mat-horizontal-stepper [linear]="true" #stepper>
                <mat-step
                  [completed]="!(this.periodicityData['periodicityTime'].length <= 0 || bookingFormGroup.invalid || value < startingLessons)">


                  <form [formGroup]="bookingFormGroup">
                    <ng-template matStepLabel>{{'BOOKING.BOOKING_TIMES' | translate}}</ng-template>
                    <div class="book-form-group">
                      <div class="wrap-time">
                        <div *ngFor="let periodicity of this.proposalDetails?.details; let i = index">
                          <div class="periodicity-time" *ngIf="periodicity.availability">

                            <input type="checkbox" name="{{periodicity.id}}" time="{{periodicity.time_id}}"
                              id="{{periodicity.day}}" (change)="checkTime($event)">
                            <div class="sign"></div>

                            <div>

                              <p><b>{{'WEEK_DAYS.'+ periodicity.day | uppercase | translate}}</b></p>
                            </div>

                            <div>

                              <p class="choosed-time"><b>{{periodicity.time}}</b></p>
                            </div>

                            <div>

                              <div class="select-periodicity">
                                <select [disabled]="true" id="periodicity_{{periodicity.id}}"
                                  (change)="changePeriodicity($event, periodicity.time_id, periodicity.day)"
                                  class="form-input custom-input">
                                  <option value="1">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                                  </option>
                                  <option value="2">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                                  </option>
                                  <option value="3">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                                  </option>
                                </select>

                              </div>
                            </div>


                          </div>
                        </div>

                      </div>


                      <div class="m-tutor custom-margin">

                        <div class="m-tutor-info margin-bottom">
                          <h1 class="form-label">{{'BOOKING.DESIRED_DATE' | translate}}:
                          </h1>
                        </div>

                        <div class="starting-time">
                          <mat-form-field class="periodicity" appearance="fill">
                            <mat-label class="label-custom">{{'BOOKING.DAY' |
                              translate}}</mat-label>
                            <mat-select (selectionChange)="getNextDates($event)" formControlName="starting_day">
                              <mat-option value="{{day.day}}" *ngFor="let day of this.startingDay">
                                {{'WEEK_DAYS.'+ day.day | uppercase | translate}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="periodicity" appearance="fill">
                            <mat-label class="label-custom">{{'BOOKING.DATE' |
                              translate}}</mat-label>
                            <mat-select formControlName="starting_date" (selectionChange)="getStartingDate($event)">
                              <mat-option value="{{date}}" *ngFor="let date of this.nextDates">
                                {{date | date:'dd/MM/yyyy'}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>


                      </div>

                      <div class="m-tutor margin-bottom">

                        <div class="m-tutor-info">
                          <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICING_RATE' | translate}}
                          </h1>
                          <div class="m-slider-calc">
                            <div class="m-slider-value">
                              <input [(ngModel)]='value' [ngModelOptions]="{standalone: true}" type="text">
                              <span> x
                                CHF {{tutorPriceLessonSlider}} </span>
                            </div>
                            <div class="m-slider-res">
                              <p class="m-slider-price">CHF{{totalPriceLesson}}</p>
                              <p class="m-slider-save">{{'TUTORS.MODALS.MATCHED_TUTOR.YOU_SAVE' | translate}}
                                {{percentage.toFixed(2)}}%</p>
                            </div>
                          </div>
                          <!--m-slider-calc-->
                          <ngx-slider [(value)]="value" [options]="options" (userChange)="sliderEvent()">
                          </ngx-slider>
                        </div>
                        <!--m-tutor-info-->
                      </div>


                      <p class="m-tutor-paragraph" *ngIf="value < startingLessons">
                        {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.I' | translate}}
                        <b>{{startingLessons}}</b>
                        {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.II' | translate}}
                      </p>
                    </div>


                    <div class="modal-footer">

                      <button class="btn btn-primary m-btn-book" *ngIf="paymentStatus == 'pending'"
                        [disabled]="this.periodicityData['periodicityTime'].length <= 0 || bookingFormGroup.invalid || value < startingLessons"
                        mat-button matStepperNext>{{'TUTORS.MODALS.MATCHED_TUTOR.NEXT' | translate}}</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step [stepControl]="secondFormGroup">
                  <form [formGroup]="secondFormGroup" (ngSubmit)="createToken(tutor)">
                    <!-- (ngSubmit)="createStripeToken(tutor) -->
                    <ng-template matStepLabel>{{'BOOKING.BOOKING_FINAL' | translate}}</ng-template>

                    <div class="m-tutor text-center">

                      <div class="m-tutor-info">
                        <h1 class="m-tutor-title booking-title">{{'BOOKING.ALL_PAYMENTS' | translate}}
                        </h1>
                      </div>

                      <img alt="Credit Card Logos" title="Credit Card Logos" class="supported-cards"
                        src="../../../assets/images/cards.png" />

                      <hr>
                    </div>

                    <div class="m-tutor overview-prices">


                      <main class="page payment-page">
                        <section class="payment-form dark">
                          <div class="container">


                            <div class="products">
                              <h3 class="title">{{'BOOKING.BOOKING_OVERVIEW' | translate}}</h3>
                              <div class="item">
                                <span class="price">{{'TUTORS.MODALS.MATCHED_TUTOR.NR_LESSONS' | translate}}</span>
                                <p class="item-name"> {{value}} {{'BOOKING.LESSONS' | translate}}</p>

                              </div>
                              <div class="item">
                                <span class="price">{{'TUTORS.MODALS.MATCHED_TUTOR.HOURLY_CHARGES' | translate}}</span>
                                <p class="item-name">{{tutorPriceLessonSlider}} CHF</p>
                              </div>

                              <div class="item total">
                                <div class="item">
                                  <span class="price">{{'TUTORS.MODALS.MATCHED_TUTOR.TUTOR_COSTS' | translate}}</span>
                                  <p class="item-name total-value">{{tutorPriceLessonSlider * value}} CHF</p>
                                </div>

                                <div class="item flex-item">
                                  <span class="price">{{'TUTORS.MODALS.MATCHED_TUTOR.SEREVICE_FEE' | translate}}
                                    <fa-icon placement="top" ngbTooltip="{{'TUTORS.MODALS.MATCHED_TUTOR.INFO_FEE' | translate}}" [icon]="faQuestionCircle"></fa-icon>
                                  </span>

                                  <p class="item-name total-value fees-val">{{websiteFee}}% x {{tutorPriceLessonSlider * value}} CHF</p>

                                  <p class="item-name total-value">{{totalPriceLesson * (websiteFee / 100)}} CHF</p>
                                </div>
                              </div>

                              <div class="total">{{totalPriceLesson + (totalPriceLesson * (websiteFee / 100))}} CHF<span class="price">TOTAL</span></div>
                            </div>

                          </div>
                        </section>
                      </main>

                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>{{'LABELS.PRENAME' | translate}}</mat-label>
                            <input matInput class="form-input" formControlName="name">
                          </mat-form-field>
                          <!-- <label class="form-label">Prename</label>
                          <input required type="text" class="form-input" formControlName="firstName"
                            placeholder="Enter prename here"> -->
                        </div><!-- form-group -->
                      </div>
                      <!--col-md-6-->
                      <div class="col-md-6">
                        <div class="form-group">
                          <mat-form-field class="example-full-width">
                            <mat-label>{{'LABELS.SURNAME' | translate}}</mat-label>
                            <input matInput class="form-input" formControlName="surname">
                          </mat-form-field>
                        </div><!-- form-group -->
                      </div>
                      <!--col-md-6-->
                    </div>
                    <!--row-->

                    <fieldset>
                      <div class="row">
                        <div id="card-element"></div>
                      </div>
                    </fieldset>

                    <!-- <div id="card-element"></div> -->



                    <button type="submit" class="btn-pay" [ngClass]="{'btn-disable': this.fullPageLoader}"
                      [disabled]="secondFormGroup.invalid || this.fullPageLoader">
                      {{'TUTORS.MODALS.MATCHED_TUTOR.PURCHASE' | translate}}
                    </button>

                    <!-- <button class="btn-pay" type="submit">
                      {{'TUTORS.MODALS.MATCHED_TUTOR.PURCHASE' | translate}}
                    </button> -->
                  </form>

                  <!-- <form [formGroup]="stripeTest">
                    <input type="text" formControlName="name" placeholder="Jane Doe">
                    <div id="card-element" class="field"></div>
                    <button type="submit">
                      CREATE TOKEN
                    </button>
                  </form> -->

                </mat-step>

              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
