<div *ngIf="fullPageLoader" class="loading">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="proposal-nav">
  <div class="home">
    <fa-icon class="remove-goal" [icon]="faHome" routerLink="../../"></fa-icon>
  </div>
  <div class="move">
    <button (click)="backPage()" [disabled]="previous">
      <fa-icon class="remove-goal" [icon]="faChevronLeft"></fa-icon>
      {{"BOOKING.BACK" | translate}}
    </button>
    <button (click)="nextPage()" [disabled]="next">
      {{"BOOKING.NEXT" | translate}}
      <fa-icon class="remove-goal" [icon]="faChevronRight"></fa-icon>
    </button>
  </div>
</div>

<div class="proposal">
  <div class="container">
    <div class="main-body">
      <div *ngIf="this.tutor?.poke" class="poked-message">
        <p>{{'BOOKING.POKED_TEXT' | translate}}</p>
      </div>
      <div class="row gutters-sm">
        <div class="col-md-4 mb-3">
          <div class="card" style="height: 100%;">
            <div class="card-body image-box">

              <div class="d-flex flex-column align-items-center text-center">
                <img *ngIf="this.tutor?.image_path != ''" [src]="this.tutor?.image_path" alt="" class="rounded-circle"
                  width="200" height="200">
                <img *ngIf="this.tutor?.image_path == ''" width="200" height="200"
                  src="../../../assets/images/{{this.tutor?.gender}}.jpg" alt="">
                <div class="mt-3">
                  <h4> {{this.tutor?.name}}</h4>
                  <ngb-rating [rate]="this.tutor?.rating" [max]="5" [readonly]="true" class="stars-color">rating
                  </ngb-rating>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="personalInfo"></fa-icon>
            <h1 class="main-title">
              {{'TUTORS.MODALS.MATCHED_TUTOR.PERSONAL_INFORMATION' | translate}}
            </h1>
          </div>

          <div class="card mb-3">

            <div class="card-body">

              <div class="row">

                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.age}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.edu_finished}}
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  <div *ngFor="let subject of this.tutor?.subjects">
                    <div *ngIf="subject.id == this.subjectId">
                      {{subject.name}}
                    </div>
                  </div>
                </div>
              </div>
              <hr>

              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  <div *ngFor="let price of this.tutor?.subjects">
                    <div *ngIf="price.id == this.subjectId">
                      {{price.pivot_teacher.prices[0].price}} CHF
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <!-- <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">Subject</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.education.current_work}}
                </div>
              </div> -->


              <!-- <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.tutoring.experience}}
                </div>
              </div>
              <hr> -->
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                  {{this.tutor?.all_students}}
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-sm-4">
                  <h6 class="mb-0">{{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' | translate}}</h6>
                </div>
                <div class="col-sm-8 text-secondary">
                   <b>{{this.tutor?.user.last_login_at | slice:0:11 | date:'dd/MM/yyyy'}}</b>
                </div>
              </div>


            </div>
          </div>



        </div>
        <!-- <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="faEnvelopeOpenText"></fa-icon>
            <h1 class="main-title">
              {{'DASHBOARD_PROPOSAL.REASON' | translate}}
            </h1>
          </div>
          <div class="card mb-3">
            <div class="card-body card-body-edit">
              <div class="col-sm-9 text-secondary">
                {{this.subjectDescription}}
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-12">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="calendarIcon"></fa-icon>
            <h1 class="main-title">
              {{'BOOKING.MATCH_TIME' | translate}}
            </h1>
          </div>
          <div class="color-info">
            <p> <span class="colors green"></span>{{'BOOKING.MATCHED_TIMESLOT' | translate}}</p>
            <p> <span class="colors blue"></span>{{'BOOKING.STUDENT_TIMESLOT' | translate}}</p>
          </div>
          <div class="card mb-3">
            <div class="card-body">
              <div class="grid-container">
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.MONDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="monday{{t.id}}" name="monday"
                      value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.TUESDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="tuesday{{t.id}}" name="tuesday"
                      value="{{t.id}}">{{t.time}}
                  </div>

                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.WEDNESDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="wednesday{{t.id}}"
                      name="wednesday" value="{{t.id}}">{{t.time}}
                  </div>

                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.THURSDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="thursday{{t.id}}" name="thursday"
                      value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.FRIDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="friday{{t.id}}" name="friday"
                      value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.SATURDAY' | translate}}</div>
                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="saturday{{t.id}}" name="saturday"
                      value="{{t.id}}">{{t.time}}
                  </div>
                </div>
                <div class="week">
                  <div class="day">{{'WEEK_DAYS.SUNDAY' | translate}}</div>

                  <div *ngFor="let t of times" class="time"><input type="checkbox" id="sunday{{t.id}}" name="sunday"
                      value="{{t.id}}">{{t.time}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 question" *ngIf="!this.tutor?.proposal_sent">
          <div class="header">
            <fa-icon class="remove-goal" [icon]="bio"></fa-icon>
            <h1 class="main-title">
              {{'BOOKING.QUESTION' | translate}}?
            </h1>
          </div>
          <div class="card mb-3">
            <div class="card-body card-body-edit forms text-secondary">
              <form [formGroup]="questionForm">


                <p>{{'BOOKING.TUTOR_QUESTION' | translate}}?</p>
                <div class="btn-radio">
                  <mat-radio-group aria-label="Select an option" (change)="question($event)">
                    <mat-radio-button value="yes">{{'BOOKING.YES' | translate}}</mat-radio-button>
                    <mat-radio-button [checked]="true" value="no">{{'BOOKING.NO' | translate}}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="text-area" *ngIf="showQuestion">
                  <div class="form-group form-group-margin">
                    <mat-form-field class="example-full-width">
                      <mat-label>{{'BOOKING.TYPE_QUESTION' | translate}}</mat-label>
                      <textarea matInput class="form-input text-area-style" formControlName="message"
                        (keyup)="checkMessage($event)"></textarea>
                    </mat-form-field>

                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button">
      <button *ngIf="!this.tutor?.proposal_sent" class="btn btn-primary"
        (click)="sendPropsals()">{{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}</button>
      <p class="tutor-book" *ngIf="this.tutor?.proposal_status == 'pending'">
        {{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSAL_PENDING' | translate}}</p>
      <p class="tutor-book" *ngIf="this.tutor?.proposal_confirmed">{{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSAL_PENDING' |
        translate}}</p>
    </div>
  </div>

</div>
