import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../core/_services/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {


  verifyForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private authService: AuthService) { }

  ngOnInit(): void {

    this.verifyForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }



  resendEmail(){
    this.authService.resendEmail().subscribe(res =>{
      console.log(res)
    })
  }

}
