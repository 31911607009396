<section class="tutors">
  <div *ngIf="fullPageLoader" class="loading">
    <span></span>
    <span></span>
    <span></span>
  </div>

  <div class="show-filters show-transition" [ngClass]="{'hide-filters': showFilters}">
    <!-- <div class="close-wrapper">
      <mat-icon (click)='openFilter()' class="close-icon">close</mat-icon>
    </div> -->
    <div class="group-filter">
      <button class="btn btn-primary" (click)="clearFilters()">{{'TUTORS.CLEAR_FILTERS' | translate}}</button>
    </div>
    <!--group-filter-->



    <div class="group-filter group-days">
      <h4 class="form-label">{{'TUTORS.TUTORING_DAYS' | translate}}:</h4>
      <div *ngFor="let d of days; let i = index">
        <mat-checkbox (change)="daysChange($event)" value='{{d}}' [checked]="day.includes(d)">{{'WEEK_DAYS.'+ d |
          uppercase | translate}}
        </mat-checkbox>
      </div>
    </div>

    <!-- <div class="group-filter">
      <mat-form-field appearance="fill">
        <mat-label>Days</mat-label>
        <mat-select multiple (change)="daysChange($event)">
          <mat-option *ngFor="let d of days; let i = index" [checked]="day.includes(d)">{{d | titlecase }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div class="group-filter">
      <h4 class="form-label">{{'TUTORS.SORTING_OPTIONS' | translate}}:</h4>
      <!-- <div class="rating" [ngClass]="{'disable-input-filter': ratingProperty}">
        <input id="rating" [ngClass]="{'disable-cursor-filter': ratingProperty}" (change)="ratingChange($event)"
          [disabled]="ratingProperty" type="checkbox">Rating
      </div>

      <div class="rating" [ngClass]="{'disable-input-filter': pricePoperty}">
        <input id="price" [ngClass]="{'disable-cursor-filter': pricePoperty}" (change)="priceChange($event)"
          [disabled]='pricePoperty' type="checkbox">Price
      </div>

      <div class="rating" [ngClass]="{'disable-input-filter': priceRatingProperty}">
        <input id="price-rating" [ngClass]="{'disable-cursor-filter': priceRatingProperty}"
          (change)="ratingChange($event)" [disabled]="priceRatingProperty" type="checkbox">Price per Rating
      </div> -->

      <mat-radio-group (change)="radioFilters($event)" class="radio-group" aria-label="Select an option">
        <mat-radio-button value="rating">{{'TUTORS.RATING' | translate}}</mat-radio-button>
        <mat-radio-button value="price">{{'TUTORS.PRICE' | translate}}</mat-radio-button>
        <mat-radio-button value="price-rating">{{'TUTORS.PRICE_RATING' | translate}}</mat-radio-button>
      </mat-radio-group>

    </div>

    <div class="group-filter">
      <h4 class="form-label">{{'TUTORS.TUTOR_PREFERENCES' | translate}}:</h4>
      <mat-form-field appearance="fill">
        <mat-label>{{'TUTORS.GENDER' | translate}}</mat-label>
        <mat-select id="gender" (selectionChange)="selectChange($event)">
          <mat-option value='male'>
            {{'LABELS.MALE' | translate}}
          </mat-option>
          <mat-option value='female'>
            {{'LABELS.FEMALE' | translate}}
          </mat-option>
          <mat-option value='none'>
            {{'LABELS.NO_MATTER' | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--group-filter-->



    <div class="group-filter">
      <mat-form-field appearance="fill">
        <mat-label>{{'TUTORS.TUTORING_STYLE' | translate}}</mat-label>
        <mat-select id="tutoring_style" [(ngModel)]="tutoring_style" (selectionChange)="selectChange($event)">
          <mat-option *ngFor="let tuto of tutoringStyle" value="{{tuto.id}}">
            {{tuto.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--group-filter-->
    <div class="group-filter">
      <mat-form-field appearance="fill">
        <mat-label>{{'TUTORS.TUTORING_PLACE' | translate}}</mat-label>
        <mat-select id="tutoring_place" [(ngModel)]="tutoring_place" (selectionChange)="selectChange($event)">
          <mat-option *ngFor="let place of tutoringPlaces" value="{{place.id}}">
            {{place.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--group-filter-->


    <div class="group-filter">
      <h4 class="form-label">{{'TUTORS.AGE_RANGE' | translate}}</h4>
      <ngx-slider (mouseup)="onChangeSlider($event)" (userChange)="onChangeRangeIn($event)" [(value)]="minValueAge"
        [(highValue)]="highValueAge" [options]="optionsRangeAge">
      </ngx-slider>
    </div>
    <!--group-filter-->

    <div class="group-filter">
      <h4 class="form-label">{{'TUTORS.DISTANCE' | translate}}</h4>
      <ngx-slider [(value)]="valueKm" [options]="optionsKm" (mouseup)="onChangeSliderkm($event)"
        (userChange)="sliderKm($event)"></ngx-slider>
    </div>



    <div class="group-filter">
      <button class="btn btn-primary btn-mobile justify-content-center" (click)='openFilter()'>Search</button>
    </div>


    <!-- <div class="group-filter desired-time">
      <mat-form-field class="filter-distance">
        <mat-label>Start Time</mat-label>
        <input matInput id="start-time" type='time' class="form-input">
      </mat-form-field>
      <mat-form-field class="filter-distance">
        <mat-label>End Time</mat-label>
        <input matInput id="end-time" type='time' class="form-input">
      </mat-form-field>
    </div> -->
    <!--group-filter-->



    <!--group-filter-->

    <!-- <div class="group-filter">
      <h4 class="form-label">What is your preferred Gender?</h4>
      <mat-radio-group id="gender" (change)="radioChange($event)" aria-label="Select an option">
        <mat-radio-button value="female">Female</mat-radio-button>
        <mat-radio-button value="male">Male</mat-radio-button>
      </mat-radio-group>
    </div> -->

    <!-- <div class="group-filter">
      <h4 class="form-label">What is the price range?</h4>
      <ngx-slider [(value)]="minValuePrice" [(highValue)]="highValuePrice" [options]="optionsRangePrice"></ngx-slider>
    </div>group-filter -->


  </div>
  <div class="container-fluid">

    <div class="filters">
      <mat-icon (click)='openFilter()' class="filter-icon">filter_alt</mat-icon>

      <!-- <form style="width: 60%;" (ngSubmit)="createStripeToken()" class="checkout">
        <label for="card-info">Card Info</label>
        <div id="form-field">
          <div id="card-info" #cardInfo></div>
          <button id="submit-button" type="submit">
            Pay ${{_totalAmount}}
          </button>
          <mat-error id="card-errors" role="alert" *ngIf="cardError">
            <mat-icon style="color: #f44336">cancel</mat-icon>
            &nbsp;{{ cardError }}
          </mat-error>
        </div>
      </form> -->

      <!-- <div class="quick-filters">
        <button (click)="openFilter()" [ngClass]="{'btn-filter-bg': showFilters}" class="form-input btn-filter">Filtering <img src="../../assets/images/Filter.png" alt=""> </button>
        <form class="form-filter">
          <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date">
              <input matEndDate placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
      </div> -->

      <!-- <div *ngFor="let teacher of this.getTeachers">
        {{teacher.email}}
      </div> -->
    </div>

    <div *ngIf="isFetching; else filtersShow">
      <!-- <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div> -->

      <div class="tweets-skeleton">
        <div class="tweet-skeleton" *ngFor="let n of loaderCards">
          <div class="img"></div>
          <div class="content-1">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div class="content-2">
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>


      </div>

    </div>

    <ng-template #filtersShow>
      <div class="information-panel-column">
        <!-- <p>{{'TUTORS.PARAGRAPH.I' | translate}} {{tutors.subject}} {{'TUTORS.PARAGRAPH.II' | translate}} </p>
        <p>{{'TUTORS.PARAGRAPH.III' | translate}}</p> -->
        <div class="row">
          <div class="col-md-6 text-center">
            <fa-icon [icon]="faUsers"></fa-icon>
            <h5>{{'TUTORS.FILTER_TUTORS.TITLE' | translate}}</h5>
            <p>{{'TUTORS.FILTER_TUTORS.DESC' | translate}}</p>
          </div>
          <div class="col-md-6 text-center">
            <fa-icon [icon]="faUserEdit"></fa-icon>
            <h5>{{'TUTORS.ENGAGED_TUTORS.TITLE' | translate}}</h5>
            <p>{{'TUTORS.ENGAGED_TUTORS.DESC' | translate}}</p>
          </div>
        </div>
      </div>
      <mat-tab-group class="label-container">
        <mat-tab label="{{'TUTORS.FILTERED' | translate}}">
          <div class="tutors__table" *ngFor="let tutors of matchedTutors" #filtersShow>
            <div class="tutors__table-header">
              <h1 class="main-title">{{'TUTORS.TUTOR_INFO' | translate}} {{tutors.subject}}</h1>

            </div>
            <!--tutors__table--header-->

            <div class="boxes">

              <div class="tutor-box" [ngClass]="tutor.proposal_status"
                *ngFor="let tutor of tutors.teachers; let index = index;">
                <!-- <img *ngIf="tutor.poke" class="poked-image" src="../../../assets/images/poked.png" alt=""> -->

                <div class="box-info">
                  <!-- <div class="box-img" style="background-image: url('../../assets/images/avatar-1.jpg');"></div> -->

                  <div class="box-img">
                    <img *ngIf="tutor.image_path!= ''" [src]="tutor.image_path" alt="">
                    <img *ngIf="tutor.image_path.length == 0" src="../../../assets/images/{{tutor.gender}}.jpg" alt="">
                  </div>
                  <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                  <h1 class="general-heading">{{tutor.name}}</h1>
                  <p class="inner-box-p text-center">
                    {{tutor.edu_finished}}</p>
                </div>
                <!--box-info-->
                <!-- <div class="box-price">
                  <p class="table-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_LESSON' | translate}}</p>
                  <p class="table-title">{{subjectPrice[index]}} CHF</p>
                </div> -->
                <!--box-price-->


                <div class="box-price" *ngFor="let price of tutor.subjects">
                  <p class="table-title" *ngIf="tutors.subject == price.name">
                    {{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_LESSON' | translate}}</p>
                  <p class="table-title" *ngIf="tutors.subject == price.name">{{price.pivot_teacher.prices[0].price}}
                    CHF</p>
                </div>




                <!-- <button class="tutor-book" [disabled]="modalOpen" *ngIf="tutor.proposal_confirmed"
                  (click)="openWindowCustomClass(content, tutor, tutors.id)">{{'TUTORS.BOOK_TEACHER' |
                  translate}}</button> -->

                <p class="tutor-book" *ngIf="tutor.proposal_confirmed" (click)="sendToBooking(tutor.id, tutors.id)">
                  {{'TUTORS.BOOK_TEACHER' |
                  translate}}
                </p>

                <!-- <p class="tutor-book" *ngIf="!tutor.proposal_sent" (click)="proposalModal(proposal, tutor, tutors.id)">
                  {{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}
                </p> -->

                <!-- <div *ngIf="!tutor.proposal_sent" class="proposal-btn">
                  <p routerLink="proposal/{{tutor.id}}">Proposal</p>
                  <p>
                    {{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}
                  </p>

                </div> -->

                <p class="tutor-book" *ngIf="!tutor.proposal_sent" (click)="sendToProposal(tutor.id, tutors.id)">
                  {{'TUTORS.MODALS.MATCHED_TUTOR.MORE_INFO' | translate}}
                </p>

                <p class="tutor-book" *ngIf="tutor.proposal_status == 'pending'"
                  (click)="sendToProposal(tutor.id, tutors.id)">
                  {{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSAL_PENDING' | translate}}
                  <!-- <img src="../../../assets/images/right-arrow-black.png"> -->
                </p>

                <p class="tutor-book not-allowed" *ngIf="tutor.proposal_canceled">Proposal declined</p>



                <!-- <ng-template #proposal let-modal>

                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" (clickOutside)="onClickedOutside()">

                    <div *ngIf="tutor.poke" class="poked-message">
                      <p>{{'ALERT.POKED' | translate}}</p>
                    </div>

                    <div class="m-tutor-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="m-tutor-profile">
                            <img *ngIf="tutor.image_path != ''" [src]="tutor.image_path">
                            <h1 *ngIf="tutor.image_path == ''">{{tutor.name}}</h1>

                          </div>

                        </div>
                        <div class="col-md-7">
                          <div class="m-tutor-body">
                            <div class="m-tutor">
                              <fa-icon [icon]="personalInfo"></fa-icon>
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PERSONAL_INFORMATION' |
                                  translate}}</h1>
                                <table class="table">
                                  <tbody>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.NAME' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.name}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.age}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.edu_finished}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' |
                                        translate}}:</th>
                                      <th scope="col"><span
                                          *ngFor="let subject of tutor.subjects; last as isLast">{{subject.name}}<span
                                            *ngIf="!isLast"> , </span>
                                        </span></th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.RATING' |
                                        translate}}:</th>
                                      <th scope="col">
                                        <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                                      </th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE'
                                        | translate}}:</th>
                                      <th scope="col">{{tutor.tutoring.experience}} {{'HOMEPAGE.DEMO.YEARS' |
                                        translate}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR'
                                        | translate}}:</th>
                                      <th scope="col"><b>{{tutor.all_students}}</b> {{'HOMEPAGE.DEMO.STUDENT' |
                                        translate}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' |
                                        translate}}:</th>
                                      <th scope="col"><b>{{tutor.user.last_login_at}}</b></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>


                      <div class="m-tutor-body">
                        <form [formGroup]="desiredFormGroup">
                          <div class="m-tutor">
                            <fa-icon [icon]="bio"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.SKILLS' | translate}}</h1>
                              <p class="m-tutor-paragraph">{{tutor.skills}}</p>
                            </div>

                          </div>


                          <div class="m-tutor">
                            <fa-icon [icon]="proposalTime"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.AVAILABLE_TIME' | translate}}
                              </h1>
                              <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.TIME_DESCRIPTION' |
                                translate}}
                              </p>
                              <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.TIMESLOT_DESCRIPTION' |
                                translate}}</p>
                              <div class="counter-time" *ngFor='let d of day; let id = index'>


                                <p class="m-tutor-title"><b>{{'WEEK_DAYS.'+ d | uppercase | translate}}</b></p>


                                <div class="group-filter desired-time">
                                  <div *ngIf="proposalTimes[0][d].length == 0">
                                    <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.NO_FREE_TIME' |
                                      translate}}</p>
                                  </div>

                                  <div class="time testTime" *ngFor="let time of proposalTimes[0][d]">
                                    <input type="checkbox" (change)="selectTime($event)" id="{{d}}{{time.id}}"
                                      name="{{d}}" value="{{time.id}}">
                                    {{time.time}}
                                  </div>

                                </div>

                                <div *ngIf="timeDays[0][d].length > 0">{{'TUTORS.MODALS.MATCHED_TUTOR.SELECTED_SLOT' |
                                  translate}}
                                  {{timeDays[0][d].length}} {{'TUTORS.MODALS.MATCHED_TUTOR.TIMESLOT' | translate}}
                                </div>

                                <div class="time-period" *ngIf="timeDays[0][d].length > 0">

                                  <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.PLAN_INTERVAL' |
                                    translate}} </p>
                                  <mat-form-field class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.PERIODICITY' |
                                      translate}}</mat-label>
                                    <mat-select formControlName="{{d}}" (selectionChange)="periodDropdown(d)">
                                      <mat-option selected value="1">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                                      </mat-option>
                                      <mat-option value="2">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                                      </mat-option>
                                      <mat-option value="4">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                </div>


                              </div>

                            </div>

                          </div>

                          <div class="m-tutor">
                            <fa-icon [icon]="priceIcon"></fa-icon>
                            <div class="row">
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_TABLE' | translate}}</h1>
                                <p class="m-tutor-paragraph">
                                  {{'TUTORS.MODALS.MATCHED_TUTOR.TABLE_DESCRIPTION' | translate}}
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="m-tutor-info">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th class="table-title" scope="col">
                                          {{'TUTORS.MODALS.MATCHED_TUTOR.AMOUNT_LESSON' | translate}}</th>
                                        <th class="table-title" scope="col">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_LESSON'
                                          | translate}}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr class="border-table" [ngClass]="{'in-row-price': prices.id == priceId}"
                                        *ngFor="let prices of proposalPrices; let i = index">
                                        <td>{{ prices.nr_lessons }}</td>
                                        <td>{{ prices.price }} CHF</td>
                                      </tr>
                                    </tbody>
                                  </table>

                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="m-tutor-info">
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_DESCRIPTION' | translate}}
                                  </p>
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.I' | translate}} <input
                                      class="lessons-number" formControlName="numberLessonsForm" min="4" type="number"
                                      #currentVal (input)="teacherPriceCalc(currentVal.value)">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.II' | translate}}
                                  </p>

                                </div>


                                <div class="m-tutor-info wrap-results">
                                  <div class="m-tutor-paragraph text-results" *ngIf="currentVal.value">
                                    <div class="lessons-res">
                                      <b>L</b> {{currentVal.value}}
                                    </div>
                                    <div class="lessons-price">
                                      <b style="margin-right: 20px;">X</b> <b>CHF</b> {{pricePerLessonVal}}
                                    </div>
                                    <div class="lessons-total">
                                      <b>CHF</b> {{teacherPropRes}}
                                    </div>

                                  </div>
                                </div>

                              </div>

                              <div class="m-tutor-info">
                                <div class="form-group">
                                  <div class="time-period">

                                    <p class="m-tutor-paragraph">
                                      {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.III' | translate}}
                                      <b>{{currenLessonNr}}</b>
                                      {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.IV' | translate}}
                                    </p>

                                  </div>
                                </div>
                              </div>

                            </div>


                          </div>



                          <div class="m-tutor">
                            <fa-icon [icon]="calendarIcon"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_TIME' | translate}}</h1>
                              <p class="m-tutor-paragraph">
                                {{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_DESCRIPTION' | translate}}
                              </p>

                              <div class="form-group form-group-margin">
                                <div class="time-period">
                                  <mat-form-field class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DAY' | translate}}
                                    </mat-label>
                                    <mat-select formControlName="desiredDay"
                                      (selectionChange)="selectDesiredTime($event)">
                                      <mat-option value="{{index}}" *ngFor="let day of desiredDays; let index = index;">
                                        {{day}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <mat-form-field *ngIf="desiredDayIndex" class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DATE' | translate}}
                                    </mat-label>
                                    <mat-select formControlName="desiredDate">
                                      <mat-option value="{{date.date}}"
                                        *ngFor="let date of desiredDayArray[desiredDayIndex]?.dates">
                                        {{date?.date | date:"dd/MM/yyyy"}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>

                          </div>




                          <div class="m-tutor">
                            <div class="m-tutor-info">

                              <div class="important-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.IMPORTANT_INFORMATION' |
                                  translate}}</h1>
                                <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.INFORMATION_DESCRIPTION' |
                                  translate}}
                                </p>
                              </div>
                            </div>

                          </div>




                        </form>
                      </div>

                    </div>

                  </div>
                  <div class="modal-footer">
                    <button [disabled]="desiredFormGroup.invalid || currenLessonNr < 5"
                      class="btn btn-primary m-btn-book"
                      (click)="sendPropsals(tutor)">{{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}</button>
                  </div>
                </ng-template> -->

                <!-- <ng-template #content let-modal>
                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body payment-modal" (clickOutside)="onClickedOutside()">
                    <mat-horizontal-stepper [linear]="isLinear" #stepper>
                      <mat-step [stepControl]="proposedFormGroup">
                        <form [formGroup]="proposedFormGroup">
                          <ng-template matStepLabel>{{'TUTORS.MODALS.MATCHED_TUTOR.LESSON_PLAN' | translate}}
                          </ng-template>
                          <div class="m-tutor-content">
                            <div class="row">
                              <div class="col-md-5">
                                <div class="m-tutor-profile">
                                  <img *ngIf="tutor.image_path != ''" [src]="tutor.image_path">
                                  <h1 *ngIf="tutor.image_path == ''">{{tutor.name}}</h1>


                                </div>

                              </div>
                              <div class="col-md-7">
                                <div class="m-tutor-body">

                                  <div class="m-tutor">
                                    <fa-icon [icon]="personalInfo"></fa-icon>
                                    <div class="m-tutor-info">
                                      <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PERSONAL_INFORMATION' |
                                        translate}}</h1>
                                      <table class="table">
                                        <tbody>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.NAME'
                                              | translate}}:</th>
                                            <th scope="col">{{tutor.name}}
                                            </th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' |
                                              translate}}:</th>
                                            <th scope="col">{{tutor.age}}</th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' | translate}}:</th>
                                            <th scope="col">{{tutor.edu_finished}}</th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' | translate}}:</th>
                                            <th scope="col"><span
                                                *ngFor="let subject of tutor.subjects; last as isLast">{{subject.name}}<span
                                                  *ngIf="!isLast"> , </span>
                                              </span></th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.RATING' | translate}}:</th>
                                            <th scope="col">
                                              <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                                            </th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE' | translate}}:</th>
                                            <th scope="col">{{tutor.tutoring.experience}} {{'HOMEPAGE.DEMO.YEARS' |
                                              translate}}</th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR' | translate}}:</th>
                                            <th scope="col"><b>{{tutor.all_students}}</b> {{'HOMEPAGE.DEMO.STUDENT' |
                                              translate}}</th>
                                          </tr>
                                          <tr class="m-tutor-paragraph">
                                            <th scope="col" class="paragraph-line">
                                              {{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' | translate}}:</th>
                                            <th scope="col"><b>{{tutor.user.last_login_at}}</b></th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>


                                </div>

                              </div>

                            </div>


                            <div class="m-tutor-body">
                              <div class="m-tutor">
                                <fa-icon [icon]="bio"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.SKILLS' | translate}}</h1>
                                  <p class="m-tutor-paragraph">{{tutor.skills}}.</p>
                                </div>

                              </div>


                              <div class="m-tutor">
                                <fa-icon [icon]="proposalTime"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSED_TIME' | translate}}
                                  </h1>
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_DESCRIPTION_BOOKING' | translate}}
                                  </p>
                                  <div class="form-group form-group-margin" *ngFor="let detail of details">

                                    <p class="m-tutor-title"><b>{{detail.day | titlecase }}</b></p>
                                    <div class="time-period form-group-margin"
                                      *ngFor="let time of detail.time; let i = index">
                                      <div class="selected-time">{{time.time}}</div>
                                      <p style="margin-bottom: 0; margin-left: 10px;">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.EVERY' | translate}}</p>
                                      <mat-form-field class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.PERIODICITY' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="{{detail.day + i}}">
                                          <mat-option selected value="1">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                                          </mat-option>
                                          <mat-option value="2">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                                          </mat-option>
                                          <mat-option value="4">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                    <hr>
                                  </div>


                                  <div class="form-group">
                                    <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_STARTING_TIME' |
                                      translate}}:</p>
                                    <div class="time-period form-group-margin">
                                      <mat-form-field class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DAY' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="day" (selectionChange)="daySelect($event)">
                                          <mat-option value="{{index}}"
                                            *ngFor="let day of proposedDays; let index = index;">
                                            {{day.day}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>

                                      <mat-form-field *ngIf="proposedIndexDay" class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DATE' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="date" [(value)]="preDate">
                                          <mat-option value="{{date.date}}"
                                            *ngFor="let date of proposedDays[proposedIndexDay]?.dates">
                                            {{date?.date | date:"dd/MM/yyyy"}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                </div>

                              </div>



                              <div class="m-tutor">
                                <fa-icon [icon]="percentRate"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICING_RATE' | translate}}
                                  </h1>
                                  <div class="m-slider-calc">
                                    <div class="m-slider-value">
                                      <input [(ngModel)]='value' [ngModelOptions]="{standalone: true}" type="text">
                                      <span> x
                                        CHF {{tutorPriceLessonSlider}} </span>
                                    </div>
                                    <div class="m-slider-res">
                                      <p class="m-slider-price">CHF{{totalPriceLesson}}</p>
                                      <p class="m-slider-save">{{'TUTORS.MODALS.MATCHED_TUTOR.YOU_SAVE' | translate}}
                                        {{percentage.toFixed(2)}}%</p>
                                    </div>
                                  </div>

                                  <ngx-slider [(value)]="value" [options]="options" (userChange)="sliderEvent($event)">
                                  </ngx-slider>
                                </div>

                              </div>

                            </div>


                          </div>

                          <div class="modal-footer ">
                            <p class="m-tutor-paragraph" *ngIf="value < startingLessons">
                              {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.I' | translate}}
                              <b>{{startingLessons}}</b>
                              {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.II' | translate}}
                            </p>
                            <button class="btn btn-primary m-btn-book" mat-button matStepperNext (click)="nextPay()"
                              [disabled]="proposedFormGroup.invalid || value < startingLessons">{{'TUTORS.MODALS.MATCHED_TUTOR.NEXT'
                              | translate}}</button>
                          </div>
                        </form>
                      </mat-step>
                      <mat-step [stepControl]="secondFormGroup"
                        label="{{'TUTORS.MODALS.MATCHED_TUTOR.PAYMENT' | translate}}">
                        <div class="m-tutor-content">
                          <div class="m-tutor-body">
                            <div class="m-tutor">
                              <fa-icon [icon]="overview"></fa-icon>
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.BOOKING_VIEW' | translate}}
                                </h1>



                                <p class="m-tutor-paragraph">
                                  <span class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.NR_LESSONS' | translate}}:
                                  </span>{{value}}
                                </p>
                                <p class="m-tutor-paragraph">
                                  <span class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.HOURLY_CHARGES' |
                                    translate}}:
                                  </span>{{tutorPriceLessonSlider}} CHF
                                </p>
                                <p class="m-tutor-paragraph">
                                  <span class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.TOTAL' | translate}}:
                                  </span>{{totalPriceLesson}}
                                </p>
                              </div>

                            </div>

                          </div>
                        </div>

                        <form [formGroup]="secondFormGroup" (ngSubmit)="createStripeToken(tutor, tutors.id)">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Prename</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>

                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Surname</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Email</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>

                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Address</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>
                              </div>
                            </div>

                          </div>

                          <fieldset>
                            <div class="row">
                              <div id="example1-card"></div>
                            </div>
                          </fieldset>

                          <button class="btn-pay" type="submit" [disabled]="isPaid">
                            {{'TUTORS.MODALS.MATCHED_TUTOR.PURCHASE' | translate}} {{totalPriceLesson}} CHF
                          </button>
                        </form>




                      </mat-step>

                    </mat-horizontal-stepper>

                  </div>

                </ng-template> -->
              </div>
              <!--tutor-box-->

            </div>
            <!--boxes-->
          </div>
          <!--tutors__table-->
        </mat-tab>
        <mat-tab label="{{'TUTORS.ENGAGED' | translate}}">
          <div class="wrapped__filters">
            <p class="filter-btn" [ngClass]="{'active': activeFilter == 'all'}" (click)="filterTutors('all')">
              {{'TUTORS.ALL' | translate}}</p>
            <p class="filter-btn" [ngClass]="{'active': activeFilter == 'confirmed'}"
              (click)="filterTutors('confirmed')">{{'TUTORS.CONFIRMED' | translate}}</p>
            <p class="filter-btn" [ngClass]="{'active': activeFilter == 'pending'}" (click)="filterTutors('pending')">
              {{'TUTORS.PENDING' | translate}}</p>
            <p class="filter-btn" [ngClass]="{'active': activeFilter == 'canceled'}" (click)="filterTutors('canceled')">
              {{'TUTORS.CANCELED' | translate}}</p>
          </div>
          <div class="tutors__table" *ngFor="let tutors of matchedTutorsSeen">
            <div class="tutors__table-header">
              <h1 class="main-title">{{'TUTORS.TUTOR_INFO' | translate}} {{tutors.subject}}</h1>
              <p class="inner-box-p" *ngIf='tutors.teachers.length == 0'>{{'TUTORS.NO_TUTOR' | translate}}
                {{tutors.subject}} </p>
            </div>

            <div class="boxes">

              <div class="tutor-box" [ngClass]="tutor.proposal_status"
                *ngFor="let tutor of tutors.teachers; let index = index;">

                <div class="box-info">
                  <!-- <div class="box-img" style="background-image: url('../../assets/images/avatar-1.jpg');"></div> -->
                  <div class="box-img">
                    <img *ngIf="tutor.image_path!= ''" [src]="tutor.image_path" alt="">
                    <img *ngIf="tutor.image_path.length == 0" src="../../../assets/images/{{gender}}.jpg" alt="">
                  </div>
                  <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                  <h1 class="general-heading">{{tutor.name}}</h1>
                  <p class="inner-box-p">{{tutor.edu_finished}}</p>
                </div>
                <!--box-info-->
                <div class="box-price">
                  <p class="table-title">Price per lesson</p>
                  <p class="table-title">{{subjectPrice[index]}} CHF</p>
                </div>
                <!--box-price-->

                <!-- <button class="tutor-book" [disabled]="modalOpen" *ngIf="tutor.proposal_confirmed"
                  (click)="openWindowCustomClass(content, tutor, tutors.id)">{{'TUTORS.BOOK_TEACHER' |
                  translate}}</button> -->

                <button class="tutor-book" *ngIf="tutor.proposal_confirmed"
                  (click)="sendToBooking(tutor.id, tutors.id)">{{'TUTORS.BOOK_TEACHER' |
                  translate}}
                </button>

                <!-- <p class="tutor-book" *ngIf="!tutor.proposal_sent" (click)="proposalModal(proposal, tutor, tutors.id)">
                    {{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}
                  </p> -->

                <!-- <div *ngIf="!tutor.proposal_sent" class="proposal-btn">
                    <p routerLink="proposal/{{tutor.id}}">Proposal</p>
                    <p>
                      {{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}
                    </p>

                  </div> -->

                <p class="tutor-book" *ngIf="!tutor.proposal_sent" (click)="sendToProposal(tutor.id, tutors.id)">
                  {{'TUTORS.MODALS.MATCHED_TUTOR.MORE_INFO' | translate}}
                </p>

                <p class="tutor-book" *ngIf="tutor.proposal_status == 'pending'"
                  (click)="sendToProposal(tutor.id, tutors.id)">
                  {{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSAL_PENDING' | translate}}
                  <!-- <img src="../../../assets/images/right-arrow-black.png"> -->
                </p>

                <p class="tutor-book not-allowed" *ngIf="tutor.proposal_canceled">Proposal declined</p>



                <!-- <ng-template #proposal let-modal>

                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" (clickOutside)="onClickedOutside()">

                    <div class="m-tutor-content">
                      <div class="row">
                        <div class="col-md-5">
                          <div class="m-tutor-profile">
                            <img *ngIf="tutor.image_path != ''" [src]="tutor.image_path">
                            <h1 *ngIf="tutor.image_path == ''">{{tutor.name}}</h1>

                          </div>

                        </div>
                        <div class="col-md-7">
                          <div class="m-tutor-body">
                            <div class="m-tutor">
                              <fa-icon [icon]="personalInfo"></fa-icon>
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PERSONAL_INFORMATION' |
                                  translate}}</h1>
                                <table class="table">
                                  <tbody>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.NAME' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.name}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.age}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' |
                                        translate}}:</th>
                                      <th scope="col">{{tutor.edu_finished}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' |
                                        translate}}:</th>
                                      <th scope="col"><span
                                          *ngFor="let subject of tutor.subjects; last as isLast">{{subject.name}}<span
                                            *ngIf="!isLast"> , </span>
                                        </span></th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.RATING' |
                                        translate}}:</th>
                                      <th scope="col">
                                        <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                                      </th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE'
                                        | translate}}:</th>
                                      <th scope="col">{{tutor.tutoring.experience}} {{'HOMEPAGE.DEMO.YEARS' |
                                        translate}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR'
                                        | translate}}:</th>
                                      <th scope="col"><b>{{tutor.all_students}}</b> {{'HOMEPAGE.DEMO.STUDENT' |
                                        translate}}</th>
                                    </tr>
                                    <tr class="m-tutor-paragraph">
                                      <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' |
                                        translate}}:</th>
                                      <th scope="col"><b>{{tutor.user.last_login_at}}</b></th>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>


                      <div class="m-tutor-body">
                        <form [formGroup]="desiredFormGroup">


                          <div class="m-tutor">
                            <fa-icon [icon]="bio"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.SKILLS' | translate}}</h1>
                              <p class="m-tutor-paragraph">{{tutor.skills}}</p>
                            </div>

                          </div>


                          <div class="m-tutor">
                            <fa-icon [icon]="proposalTime"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.AVAILABLE_TIME' | translate}}
                              </h1>
                              <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.TIME_DESCRIPTION' |
                                translate}}
                              </p>

                              <p class="m-tutor-paragraph" *ngIf="proposalTimes[0][d].length > 0">
                                {{'TUTORS.MODALS.MATCHED_TUTOR.TIMESLOT_DESCRIPTION' | translate}}</p>
                              <div class="counter-time" *ngFor='let d of day; let id = index'>


                                <p class="m-tutor-title"><b>{{d | titlecase }}</b></p>


                                <div class="group-filter desired-time">
                                  <div *ngIf="proposalTimes[0][d].length == 0">
                                    <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.NO_FREE_TIME' |
                                      translate}}</p>
                                  </div>

                                  <div class="time testTime" *ngFor="let time of proposalTimes[0][d]">
                                    <input type="checkbox" (change)="selectTime($event)" id="{{d}}{{time.id}}"
                                      name="{{d}}" value="{{time.id}}">
                                    {{time.time}}
                                  </div>

                                </div>

                                <div *ngIf="timeDays[0][d].length > 0">{{'TUTORS.MODALS.MATCHED_TUTOR.SELECTED_SLOT' |
                                  translate}}
                                  {{timeDays[0][d].length}} {{'TUTORS.MODALS.MATCHED_TUTOR.TIMESLOT' | translate}}
                                </div>

                                <div class="time-period" *ngIf="timeDays[0][d].length > 0">

                                  <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.PLAN_INTERVAL' |
                                    translate}} </p>
                                  <mat-form-field class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.PERIODICITY' |
                                      translate}}</mat-label>
                                    <mat-select formControlName="{{d}}" (selectionChange)="periodDropdown(d)">
                                      <mat-option selected value="1">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                                      </mat-option>
                                      <mat-option value="2">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                                      </mat-option>
                                      <mat-option value="4">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                </div>


                              </div>

                            </div>

                          </div>

                          <div class="m-tutor">
                            <fa-icon [icon]="priceIcon"></fa-icon>
                            <div class="row">
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_TABLE' | translate}}</h1>
                                <p class="m-tutor-paragraph">
                                  {{'TUTORS.MODALS.MATCHED_TUTOR.TABLE_DESCRIPTION' | translate}}
                                </p>
                              </div>
                              <div class="col-md-6">
                                <div class="m-tutor-info">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th class="table-title" scope="col">
                                          {{'TUTORS.MODALS.MATCHED_TUTOR.AMOUNT_LESSON' | translate}}</th>
                                        <th class="table-title" scope="col">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_LESSON'
                                          | translate}}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr class="border-table" [ngClass]="{'in-row-price': prices.id == priceId}"
                                        *ngFor="let prices of proposalPrices; let i = index">
                                        <td>{{ prices.nr_lessons }}</td>
                                        <td>{{ prices.price }} CHF</td>
                                      </tr>
                                    </tbody>
                                  </table>

                                </div>
                              </div>

                              <div class="col-md-6">
                                <div class="m-tutor-info">
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.PRICE_DESCRIPTION' | translate}}
                                  </p>
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.I' | translate}} <input
                                      class="lessons-number" formControlName="numberLessonsForm" min="4" type="number"
                                      #currentVal (input)="teacherPriceCalc(currentVal.value)">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.II' | translate}}
                                  </p>

                                </div>


                                <div class="m-tutor-info wrap-results">
                                  <div class="m-tutor-paragraph text-results" *ngIf="currentVal.value">
                                    <div class="lessons-res">
                                      <b>L</b> {{currentVal.value}}
                                    </div>
                                    <div class="lessons-price">
                                      <b style="margin-right: 20px;">X</b> <b>CHF</b> {{pricePerLessonVal}}
                                    </div>
                                    <div class="lessons-total">
                                      <b>CHF</b> {{teacherPropRes}}
                                    </div>

                                  </div>
                                </div>

                              </div>

                              <div class="m-tutor-info">
                                <div class="form-group">
                                  <div class="time-period">

                                    <p class="m-tutor-paragraph">
                                      {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.III' | translate}}
                                      <b>{{currenLessonNr}}</b>
                                      {{'TUTORS.MODALS.MATCHED_TUTOR.DEMO_PRICE.IV' | translate}}
                                    </p>

                                  </div>
                                </div>
                              </div>

                            </div>


                          </div>


                          <div class="m-tutor">
                            <fa-icon [icon]="bio"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.SKILLS' | translate}}</h1>
                              <p class="m-tutor-paragraph">{{tutor.skills}}</p>
                            </div>

                          </div>


                          <div class="m-tutor">
                            <fa-icon [icon]="calendarIcon"></fa-icon>
                            <div class="m-tutor-info">
                              <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_TIME' | translate}}</h1>
                              <p class="m-tutor-paragraph">
                                {{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_DESCRIPTION' | translate}}
                              </p>

                              <div class="form-group form-group-margin">
                                <div class="time-period">
                                  <mat-form-field class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DAY' | translate}}
                                    </mat-label>
                                    <mat-select formControlName="desiredDay"
                                      (selectionChange)="selectDesiredTime($event)">
                                      <mat-option value="{{index}}" *ngFor="let day of desiredDays; let index = index;">
                                        {{day}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>

                                  <mat-form-field *ngIf="desiredDayIndex" class="periodicity" appearance="fill">
                                    <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DATE' | translate}}
                                    </mat-label>
                                    <mat-select formControlName="desiredDate">
                                      <mat-option value="{{date.date}}"
                                        *ngFor="let date of desiredDayArray[desiredDayIndex]?.dates">
                                        {{date?.date | date:"dd/MM/yyyy"}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>

                          </div>




                          <div class="m-tutor">
                            <div class="m-tutor-info">

                              <div class="important-info">
                                <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.IMPORTANT_INFORMATION' |
                                  translate}}</h1>
                                <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.INFORMATION_DESCRIPTION' |
                                  translate}}
                                </p>
                              </div>
                            </div>

                          </div>




                        </form>
                      </div>

                    </div>

                  </div>
                  <div class="modal-footer">
                    <button [disabled]="desiredFormGroup.invalid || currenLessonNr < 5"
                      class="btn btn-primary m-btn-book"
                      (click)="sendPropsals(tutor)">{{'TUTORS.MODALS.MATCHED_TUTOR.SEND_PROPOSAL' | translate}}</button>
                  </div>
                </ng-template> -->

                <!-- <ng-template #content let-modal>
                  <div class="modal-header">
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body payment-modal" (clickOutside)="onClickedOutside()">
                    <mat-horizontal-stepper [linear]="isLinear" #stepper>
                      <mat-step [stepControl]="proposedFormGroup">
                        <form [formGroup]="proposedFormGroup">
                          <ng-template matStepLabel>{{'TUTORS.MODALS.MATCHED_TUTOR.LESSON_PLAN' | translate}}
                          </ng-template>
                          <div class="m-tutor-content">
                            <div class="row">
                              <div class="col-md-5">
                                <div class="m-tutor-profile">
                                  <img *ngIf="tutor.image_path != ''" [src]="tutor.image_path">
                                  <h1 *ngIf="tutor.image_path == ''">{{tutor.name }}</h1>


                                </div>

                              </div>
                              <div class="col-md-7">
                                <tbody>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.NAME' |
                                      translate}}:</th>
                                    <th scope="col">{{tutor.name}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.AGE' |
                                      translate}}:</th>
                                    <th scope="col">{{tutor.age}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EDUCATION' |
                                      translate}}:</th>
                                    <th scope="col">{{tutor.edu_finished}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.SUBJECT' |
                                      translate}}:</th>
                                    <th scope="col"><span
                                        *ngFor="let subject of tutor.subjects; last as isLast">{{subject.name}}<span
                                          *ngIf="!isLast"> , </span>
                                      </span></th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.RATING' |
                                      translate}}:</th>
                                    <th scope="col">
                                      <ngb-rating [rate]="tutor.rating">rating</ngb-rating>
                                    </th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.EXPERIENCE' |
                                      translate}}:</th>
                                    <th scope="col">{{tutor.tutoring.experience}} {{'HOMEPAGE.DEMO.YEARS' | translate}}
                                    </th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.STUDENTS_NR' |
                                      translate}}:</th>
                                    <th scope="col"><b>{{tutor.all_students}}</b> {{'HOMEPAGE.DEMO.STUDENT' |
                                      translate}}</th>
                                  </tr>
                                  <tr class="m-tutor-paragraph">
                                    <th scope="col" class="paragraph-line">{{'TUTORS.MODALS.MATCHED_TUTOR.ONLINE' |
                                      translate}}:</th>
                                    <th scope="col"><b>{{tutor.user.last_login_at}}</b></th>
                                  </tr>
                                </tbody>

                              </div>

                            </div>


                            <div class="m-tutor-body">
                              <div class="m-tutor">
                                <fa-icon [icon]="bio"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.SKILLS' | translate}}</h1>
                                  <p class="m-tutor-paragraph">{{tutor.skills}}.</p>
                                </div>

                              </div>


                              <div class="m-tutor">
                                <fa-icon [icon]="proposalTime"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PROPOSED_TIME' | translate}}
                                  </h1>
                                  <p class="m-tutor-paragraph">
                                    {{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_DESCRIPTION_BOOKING' | translate}}
                                  </p>
                                  <div class="form-group form-group-margin" *ngFor="let detail of details">

                                    <p class="m-tutor-title"><b>{{detail.day | titlecase }}</b></p>
                                    <div class="time-period form-group-margin"
                                      *ngFor="let time of detail.time; let i = index">
                                      <div class="selected-time">{{time.time}}</div>
                                      <p style="margin-bottom: 0; margin-left: 10px;">
                                        {{'TUTORS.MODALS.MATCHED_TUTOR.EVERY' | translate}}</p>
                                      <mat-form-field class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.PERIODICITY' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="{{detail.day + i}}">
                                          <mat-option selected value="1">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.WEEKLY' | translate}}
                                          </mat-option>
                                          <mat-option value="2">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.BIWEEKLY' | translate}}
                                          </mat-option>
                                          <mat-option value="4">
                                            {{'TUTORS.MODALS.MATCHED_TUTOR.MONTHLY' | translate}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                    <hr>
                                  </div>


                                  <div class="form-group">
                                    <p class="m-tutor-paragraph">{{'TUTORS.MODALS.MATCHED_TUTOR.DESIRED_STARTING_TIME' |
                                      translate}}:</p>
                                    <div class="time-period form-group-margin">
                                      <mat-form-field class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DAY' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="day" (selectionChange)="daySelect($event)">
                                          <mat-option value="{{index}}"
                                            *ngFor="let day of proposedDays; let index = index;">
                                            {{day.day}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>

                                      <mat-form-field *ngIf="proposedIndexDay" class="periodicity" appearance="fill">
                                        <mat-label class="label-custom">{{'TUTORS.MODALS.MATCHED_TUTOR.DATE' |
                                          translate}}</mat-label>
                                        <mat-select formControlName="date" [(value)]="preDate">
                                          <mat-option value="{{date.date}}"
                                            *ngFor="let date of proposedDays[proposedIndexDay]?.dates">
                                            {{date?.date | date:"dd/MM/yyyy"}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </div>

                                </div>

                              </div>



                              <div class="m-tutor">
                                <fa-icon [icon]="percentRate"></fa-icon>
                                <div class="m-tutor-info">
                                  <h1 class="m-tutor-title">{{'TUTORS.MODALS.MATCHED_TUTOR.PRICING_RATE' | translate}}
                                  </h1>
                                  <div class="m-slider-calc">
                                    <div class="m-slider-value">
                                      <input [(ngModel)]='value' [ngModelOptions]="{standalone: true}" type="text">
                                      <span> x
                                        CHF {{tutorPriceLessonSlider}} </span>
                                    </div>
                                    <div class="m-slider-res">
                                      <p class="m-slider-price">CHF{{totalPriceLesson}}</p>
                                      <p class="m-slider-save">{{'TUTORS.MODALS.MATCHED_TUTOR.YOU_SAVE' | translate}}
                                        {{percentage.toFixed(2)}}%</p>
                                    </div>
                                  </div>

                                  <ngx-slider [(value)]="value" [options]="options" (userChange)="sliderEvent($event)">
                                  </ngx-slider>
                                </div>

                              </div>

                            </div>


                          </div>

                          <div class="modal-footer">
                            <p class="m-tutor-paragraph" *ngIf="value < startingLessons">
                              {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.I' | translate}}
                              <b>{{startingLessons}}</b>
                              {{'TUTORS.MODALS.MATCHED_TUTOR.SMALLEST_LESSON.II' | translate}}
                            </p>
                            <button class="btn btn-primary m-btn-book" mat-button matStepperNext (click)="nextPay()"
                              [disabled]="proposedFormGroup.invalid || value < startingLessons">{{'TUTORS.MODALS.MATCHED_TUTOR.NEXT'
                              | translate}}</button>
                          </div>
                        </form>
                      </mat-step>
                      <mat-step [stepControl]="secondFormGroup"
                        label="{{'TUTORS.MODALS.MATCHED_TUTOR.PAYMENT' | translate}}">
                        <div class="m-tutor-content">
                          <div class="m-tutor-body">
                            <div class="m-tutor">
                              <fa-icon [icon]="overview"></fa-icon>
                              <div class="m-tutor-info">
                                <h1 class="m-tutor-title">Booking Overview</h1>
                                <p class="m-tutor-paragraph"><span class="paragraph-line">Lessons Fee:
                                  </span>{{value}} x {{tutorPriceLessonSlider}} CHF</p>

                                <p class="m-tutor-paragraph"><span class="paragraph-line">Total:
                                  </span>{{totalPriceLesson}}</p>
                              </div>

                            </div>

                          </div>
                        </div>

                        <form [formGroup]="secondFormGroup" (ngSubmit)="createStripeToken(tutor, tutors.id)">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Prename</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>

                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Surname</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>
                              </div>
                            </div>

                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Email</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>

                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <mat-form-field class="example-full-width">
                                  <mat-label>Address</mat-label>
                                  <input matInput class="form-input">
                                </mat-form-field>
                              </div>
                            </div>

                          </div>

                          <fieldset>
                            <div class="row">
                              <div id="example1-card"></div>
                            </div>
                          </fieldset>

                          <button class="btn-pay" type="submit" [disabled]="isPaid">
                            {{'TUTORS.MODALS.MATCHED_TUTOR.PURCHASE' | translate}} {{totalPriceLesson}} CHF
                          </button>
                        </form>




                      </mat-step>

                    </mat-horizontal-stepper>

                  </div>

                </ng-template> -->
              </div>
              <!--tutor-box-->

            </div>
            <!--boxes-->
          </div>
          <!--tutors__table-->
        </mat-tab>
      </mat-tab-group>
    </ng-template>



  </div>
  <!--container-fluid-->
</section>
<ng-template #intro let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Steps</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-horizontal-stepper #stepperAlert>
      <mat-step>
        <ng-template matStepLabel></ng-template>
        <div class="wrap-alert">
          <p class="m-tutor-paragraph">
            {{'ALERT.TUTORS.STAGE_I.TITLE' | translate}}
          </p>
          <p class="m-tutor-paragraph">
            {{'ALERT.TUTORS.STAGE_I.LIST.TITLE' | translate}}
          </p>
          <ul>
            <li>- {{'ALERT.TUTORS.STAGE_I.LIST.I' | translate}}</li>
            <li>- {{'ALERT.TUTORS.STAGE_I.LIST.II' | translate}}</li>
            <li>- {{'ALERT.TUTORS.STAGE_I.LIST.III' | translate}}</li>
          </ul>
        </div>
        <div class="form-buttons-alert">
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert remove-alert"
            (click)="removeIntroModal()">Don't show it again</button>
          <button _ngcontent-cvl-c248="" class="btn btn-primary m-btn-alert"
            (click)="modal.dismiss('Cross click')">Done</button>
        </div>
      </mat-step>

    </mat-horizontal-stepper>
  </div>

</ng-template>
