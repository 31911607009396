import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { StudentService } from 'src/app/core/_services/student.service';
import { NgbModal, NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import Swal from 'sweetalert2'
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';


import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AuthService } from 'src/app/core/_services/auth.service';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';

import { faInfoCircle, faClock, faUserTie,faEnvelopeOpenText, faDollarSign, faPercent, faEye, faCalendarAlt, faUsers, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { element } from 'protractor';

@Component({
  selector: 'app-tutors',
  templateUrl: './tutors.component.html',
  styleUrls: ['./tutors.component.scss'],
  providers: [NgbRatingConfig]
})
export class TutorsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cardInfo', { read: ElementRef }) cardInfo: ElementRef;
  @ViewChild('intro', { read: TemplateRef }) private introModal: TemplateRef<any>;

  poked = true;

  arrLang = {
    en: {
      accept: "Yes, accept it!",
      cancel: "Cancel",
      done: "Done",
      wrong: "Something went wrong!",
      feedback: "Thank you for your feedback",
      matched_tutors: {
        proposal: "The teacher will response to your proposal within one day.The answer will be sent to you by E-Mail and can also be seen on the 'Overview'-Tab.Keep looking around to find the ideal tutor for you"
      }
    },
    de: {
      accept: "Akzeptieren!",
      cancel: "Ablehnen",
      done: "Erledigt",
      wrong: "Etwas ist schief gegangen!",
      feedback: "Danke für dein Feedback",
      matched_tutors: {
        proposal: "Der Tutor antwortet innerhalb eines Tages auf deinen Vorschlag. Die Antwort wird dir per E-Mail zugeschickt und kann auch auf dem 'Übersicht'-Tab eingesehen werden. Stöbere weiter, um weitere potentiele Nachhilfelerer für dich zu finden."
      }
    }
  }


  _totalAmount: number;
  card: any;
  //cardHandler = this.onChange.bind(this);
  cardError: string;


  loaderCards = [];
  personalInfo = faInfoCircle;
  proposalTime = faClock;
  bio = faUserTie;
  priceIcon = faDollarSign
  percentRate = faPercent
  overview = faEye;
  calendarIcon = faCalendarAlt;
  faEnvelopeOpenText = faEnvelopeOpenText;
  faUsers = faUsers;
  faUserEdit = faUserEdit;


  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  proposedFormGroup: FormGroup;
  desiredFormGroup: FormGroup;

  pageEvent: PageEvent;
  Page: number = 0;
  Size: number = 5;
  recordCount: number = 100;
  pageSizeOptions: number[] = [1, 2, 3, 4, 5];

  matchedTutors;
  filters;

  teacher;

  showFilters: boolean = true;
  tutoringStyle;
  tutoringPlaces;
  isCollapsed = true;
  times;

  tutoring_style = '';
  tutoring_place = '';

  tutorPriceLesson;
  tutorPriceLessonSlider;
  fixedPriceLesson = [];
  // fixedPriceLesson_9 = 0;
  // fixedPriceLesson_14 = 0;
  // fixedPriceLesson_19 = 0;
  // fixedPriceLesson_20 = 0;
  // fixedPriceLesson_25 = 0;
  percentage = 0;
  percentage_9 = 0;
  percentage_14 = 0;
  percentage_19 = 0;
  percentage_20 = 0;
  percentage_25 = 0;
  totalPriceLesson = 0;

  ratingProperty
  pricePoperty
  priceRatingProperty

  nr_of_lessons_id;
  isFetching: boolean = true;

  // highValuePrice: number = 25;
  // minValuePrice: number = 23;

  // max_price;
  // min_price;

  // optionsRangePrice: Options = {
  //   floor: 0,
  //   ceil: 100
  // };

  max_age;
  min_age;
  highValueAge: number = 70;
  minValueAge: number = 15;
  optionsRangeAge: Options = {
    floor: 0,
    ceil: 100
  }

  days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  day = [];

  perArr = [0, 0, 0, 0, 0];



  value: number = 5;

  params = {}
  filtersObject = {}


  dataPost;

  details;
  proposedInformations = {
    times: [],
  };

  //desiredTime varialbles booking modal
  proposedDays;
  proposedIndexDay;

  //desiredTime varialbles propsal modal
  desiredDayIndex;
  desiredDays = [];
  desiredDayArray = [];

  //Proposal modal table prices
  proposalPrices

  //Preselected day&date on booking view modal
  preDay;
  preDate;

  newTimeArr = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  };

  quarterHours = ["00", "15", "30", "45"];
  timeoptions = []

  selectedOption = 'week';

  fullPageLoader = false;


  // options: Options = {
  //   showTicks: true,
  //   showSelectionBar: true,
  //   floor: 5,
  //   ceil: 35,
  //   getLegend: (value: number): string => {
  //     if (value == 9) {
  //       return `1-9 CHF${this.fixedPriceLesson[0]} ${this.changeColor(this.perArr[0].toFixed(2))}%`
  //     } else if (value == 14) {
  //       return `10-14 CHF${this.fixedPriceLesson[1]} ${this.changeColor(this.perArr[1].toFixed(2))}%`
  //     }
  //     else if (value == 19) {
  //       return `15-19 CHF${this.fixedPriceLesson[2]} ${this.changeColor(this.perArr[2].toFixed(2))}%`
  //     } else if (value == 24) {
  //       return `20-24 CHF${this.fixedPriceLesson[3]} ${this.changeColor(this.perArr[3].toFixed(2))}%`
  //     } else if (value == 29) {
  //       return `25 - 29+ CHF${this.fixedPriceLesson[4]} ${this.changeColor(this.perArr[4].toFixed(2))}%`
  //     }
  //   }
  // };

  valueKm = 150;
  optionsKm: Options = {
    showSelectionBar: false,
    floor: 0,
    ceil: 150,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return value + "<b>km</b>";
        default:
          return value + "<b> </b>";
      }
    }
  }

  allProposals;

  proposalTimes = [{
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }]


  proposalObject = {
    proposals: []
  }
  Math: any;
  proposalForm;

  userSubject;
  proposalTeacherPrice;

  //subject price in card view
  subjectPrice = [];

  matchedTutorsSeen;
  activeFilter = 'all';

  lessonsNumber;
  startingLessons;

  gender;

  lang;

  constructor(
    private cd: ChangeDetectorRef,
    private studentService: StudentService,
    private modalService: NgbModal,
    config: NgbRatingConfig,
    private router: Router,
    private route: ActivatedRoute,
    private registerFormService: RegisterFormsService,
    private formBuilder: FormBuilder,
    private authService: AuthService) {


    this.lang = localStorage.getItem('lang') || 'de';

    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }

    // this.studentService.getIntro('tutors').subscribe(data => {
    //   console.log('intro', data)
    //   if (data == '1') {
    //     this.modalService.open(this.introModal)
    //   } else {
    //     return
    //   }

    // })

    this.Math = Math;
    this.loaderCards.length = 12;

    this.studentService.getProposals().subscribe(data => {
      console.log('match', data)
      this.allProposals = data.data;
    })

    this.studentService.matchedTutorsSeen().subscribe(data => {
      console.log('seen', data)
      this.matchedTutorsSeen = data;
    })

    // this.studentService.getMatchedTutors().subscribe(data => {

    //   this.matchedTutors = data.data.subjects;

    //   this.matchedTutors.forEach(element => {

    //     element.teachers.forEach(teacher => {

    //       for(let i = 0; i < teacher.subjects.length; i++){
    //         if(element.id == teacher.subjects[i].id){
    //           this.subjectPrice.push(teacher.subjects[i].pivot_teacher.prices[0].price);

    //         }

    //       }
    //     });
    //   });


    //   this.filters = data.data.filters

    //   // this.max_price = this.filters[0].max_price;
    //   // this.min_price = this.filters[0].min_price;
    //   this.max_age = this.filters[1].max_age;
    //   this.min_age = this.filters[1].min_age;



    //   // this.highValuePrice = this.filters[0].max_price;
    //   // this.minValuePrice = this.filters[0].min_price;
    //   this.highValueAge = this.filters[1].max_age;
    //   this.minValueAge = this.filters[1].min_age;



    //   // this.optionsRangePrice = Object.assign({}, this.optionsRangePrice, { ceil: this.max_price, floor: this.min_price });
    //   this.optionsRangeAge = Object.assign({}, this.optionsRangeAge, { ceil: this.max_age, floor: this.min_age });


    // }, (error) => {
    //   return error
    // })

    this.retrieveTutors();

    config.max = 5;
    config.readonly = true;



    this.authService.getUser().subscribe(user => {
      //this.userSubject = user.data.user.subjects[0].name;


      this.gender = user.data.user.gender;

      this.nr_of_lessons_id = user.data.user.preferences.nr_of_lessons_id;
      this.tutoring_place = user.data.user.preferences.tutoring_place_id.toString()
      this.tutoring_style = user.data.user.tutoring_style.id.toString()

      this.day = [];
      user.data.user.times.forEach(element => {
        if (this.day.indexOf(element.pivot_student.day) === -1) {
          this.day.push(element.pivot_student.day);

        }
      });


      // setTimeout(() => {
      //   this.search()
      // }, 1000);


    })

    this.registerFormService.getTutoringStyle().subscribe(res => {
      this.tutoringStyle = res.data;

    });
    this.registerFormService.geTutoringPlaces().subscribe(res => {
      this.tutoringPlaces = res.data;
    });

    this.registerFormService.getTimes().subscribe(res => {
      this.times = res.data;
    });

    this.registerFormService.getLessons().subscribe(data => {

      this.lessonsNumber = data.data;
    })


  }



  ngOnInit(): void {


    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });



    this.proposedFormGroup = this.formBuilder.group({
      day: ['', Validators.required],
      date: ['', Validators.required],

    });

    this.desiredFormGroup = this.formBuilder.group({

      desiredDay: ['', Validators.required],
      desiredDate: ['', Validators.required],
      numberLessonsForm: ['', Validators.required],
      // proposalPeriodicity: this.formBuilder.array([
      //   // this.formBuilder.group({
      //   //   periodicity_proposal: ['', Validators.required],
      //   // })
      // ])
    });


  }

  ngAfterViewInit() {

    // setTimeout(() => {
    //   this.search();
    // }, 1000);

    //this.initiateCardElement();

  }

  // ngAfterContentChecked(){
  //   this.search();
  // }

  ngOnDestroy() {
    // if (this.card) {
    //   // We remove event listener here to keep memory clean
    //   this.card.removeEventListener('change', this.cardHandler);
    //   //this.card.destroy();
    // }
  }

  onTidioChatApiReady() {
    // Code after chat loaded
    if(this.router.url == '/student/tutors'){
      //window['tidioChatApi'].hide();
    }
  }


  // initiateCardElement() {
  //   // Giving a base style here, but most of the style is in scss file
  //   const cardStyle = {
  //     base: {
  //       padding: '100px',
  //       color: '#32325d',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '14px',
  //       '::placeholder': {
  //         color: '#aab7c4',
  //       },

  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a',
  //     },
  //   };
  //   this.card = elements.create('card', { cardStyle });
  //   this.card.mount(document.querySelector('#example1-card'));
  //   this.card.addEventListener('change', this.cardHandler);
  // }
  // onChange({ error }) {
  //   if (error) {
  //     this.cardError = error.message;
  //   } else {
  //     this.cardError = null;
  //   }
  //   this.cd.detectChanges();
  // }


  // isPaid = false;
  // async createStripeToken(tutor, subjectId) {
  //   this.isPaid = true;
  //   const { token, error } = await stripe.createToken(this.card);
  //   if (token) {
  //     this.retrieveAllTutors();
  //     this.onSuccess(token, tutor, subjectId);

  //   } else {
  //     this.onError('Something went wrong');
  //   }

  // }

  // onSuccess(token, tutor, subjectId) {

  //   this.fullPageLoader = true;
  //   this.dataPost = {
  //     value: this.value,
  //     nrOfLessons: this.nrOfLessonsId,
  //     price_lesson: this.tutorPriceLesson,
  //     // total_price: (this.totalPriceLesson * (1 + 3.5 / 100)).toFixed(2),
  //     total_price: this.totalPriceLesson,
  //     ...tutor,
  //     ...token,
  //     ...this.proposedInformations,
  //   }
  //   this.dataPost['date'] = this.proposedFormGroup.value.date;
  //   this.dataPost['teacher_id'] = tutor.id;
  //   this.dataPost['subject_id'] = subjectId;

  //   this.isPaid = false;
  //   this.studentService.bookTeacher(this.dataPost).subscribe(data => {


  //     Swal.fire({
  //       title: 'Success!',
  //       text: data.message,
  //       icon: 'success',
  //       confirmButtonText: 'OK'
  //     })
  //     this.retrieveTutors();
  //     this.retrieveMatchedTutorsSeen();
  //     this.fullPageLoader = false;
  //   }, (err) => {
  //     this.fullPageLoader = false;
  //     console.log(err)
  //     return Swal.fire({
  //       icon: 'error',
  //       title: 'Please try again',
  //       text: this.arrLang[this.lang].wrong,
  //     })

  //   })
  //   this.modalService.dismissAll();

  // }
  // onError(error) {
  //   if (error.message) {
  //     this.cardError = error.message;
  //     Swal.fire({
  //       icon: 'error',
  //       title: error.message,
  //       text: this.arrLang[this.lang].wrong,
  //     })
  //     this.modalService.dismissAll();
  //     this.retrieveTutors();
  //     this.retrieveMatchedTutorsSeen();
  //   }
  //   this.fullPageLoader = false;
  //   this.isPaid = false;

  // }

  // checkout() {
  //   const dialogRef = this.dialog.open(TutorsComponent, {
  //     // opening dialog here which will give us back stripeToken
  //     data: { totalAmount: 500 },
  //   });
  //   dialogRef.afterClosed()
  //     // waiting for stripe token that will be given back
  //     .subscribe((result: any) => {

  //       if (result) {
  //         stripe.createOrder(result.token.id);
  //       }
  //     });
  //   }






  teacherPropRes;
  pricePerLessonVal;
  priceId;
  currenLessonNr;
  splitPrices;
  // teacherPriceCalc(e) {

  //   this.currenLessonNr = e;
  //   this.proposalPrices.forEach(element => {

  //     if (e >= 5 && e <= 9) {
  //       if (element.nr_lessons == "1-9 Lessons") {
  //         this.teacherPropRes = e * element.price;
  //         this.pricePerLessonVal = element.price;
  //         this.priceId = 1;
  //         this.splitPrices = 1;
  //       }


  //     } else if (e >= 10 && e <= 14) {
  //       if (element.nr_lessons == "10-14 Lessons") {
  //         this.teacherPropRes = e * element.price;
  //         this.pricePerLessonVal = element.price;
  //         this.priceId = 2;
  //         this.splitPrices = 10;
  //       }
  //     } else if (e >= 15 && e <= 19) {
  //       if (element.nr_lessons == "15-19 Lessons") {
  //         this.teacherPropRes = e * element.price;
  //         this.pricePerLessonVal = element.price;
  //         this.priceId = 3;
  //         this.splitPrices = 15;
  //       }
  //     } else if (e >= 20 && e <= 24) {
  //       if (element.nr_lessons == "20-24 Lessons") {
  //         this.teacherPropRes = e * element.price;
  //         this.pricePerLessonVal = element.price;
  //         this.priceId = 4;
  //         this.splitPrices = 20;
  //       }
  //     } else if (e >= 25) {
  //       if (element.nr_lessons == "25+ Lessons") {
  //         this.teacherPropRes = e * element.price;
  //         this.pricePerLessonVal = element.price;
  //         this.priceId = 5;
  //         this.splitPrices = 25;
  //       }
  //       // this.teacherPropRes = e * 24
  //       // this.pricePerLessonVal = 24;
  //     }
  //   });
  //   this.desiredFormGroup.get('numberLessonsForm').updateValueAndValidity();

  // }

  pageNavigations(event?: PageEvent) {

    this.Page = event.pageIndex;
    this.Size = event.pageSize;

  }

  retrieveTutors() {
    this.isFetching = true;
    this.studentService.getMatchedTutors().subscribe(data => {
      this.matchedTutors = data.data.subjects;

      console.log('mtutor', data)


      this.filters = data.data.filters

      // this.max_price = this.filters[0].max_price;
      // this.min_price = this.filters[0].min_price;
      this.max_age = this.filters[1].max_age;
      this.min_age = this.filters[1].min_age;



      // this.highValuePrice = this.filters[0].max_price;
      // this.minValuePrice = this.filters[0].min_price;




      // this.optionsRangePrice = Object.assign({}, this.optionsRangePrice, { ceil: this.max_price, floor: this.min_price });
      this.optionsRangeAge = Object.assign({}, this.optionsRangeAge, { ceil: this.max_age, floor: this.min_age });


      this.matchedTutors.forEach(element => {

        element.teachers.forEach(teacher => {

          for (let i = 0; i < teacher.subjects.length; i++) {
            if (element.id == teacher.subjects[i].id) {
              this.subjectPrice.push(teacher.subjects[i].pivot_teacher.prices[0].price);

            }

          }
        });
      });

      this.search();

    })




  }

  retrieveAllTutors() {
    this.studentService.getMatchedTutors().subscribe(data => {
      this.matchedTutors = data.data.subjects;
    })
  }

  retrieveMatchedTutorsSeen() {
    this.studentService.matchedTutorsSeen().subscribe(data => {
      this.matchedTutorsSeen = data;
    })
  }


  daysChange(e) {

    if (e.checked == false) {

      let dayValue = e.source.value;
      let index = this.day.indexOf(dayValue)

      this.day.splice(index, 1)
    } else if (e.checked == true) {

      let dayValue = e.source.value;
      this.day.push(dayValue);



    }

    //this.search()

    this.router.navigate([], {
      queryParams: {
        day: this.day.toString(),
      }
    });

    this.isFetching = true;
    setTimeout(() => {
      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {
        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });

    }, 500)

  }

  // duplicateArray(data) {
  //   return data.filter((value, index) => data.indexOf(value) === index)
  // }

  // end_time_value;
  // start_time_value = [];

  // dropdownTime(e) {


  //   this.end_time_value = parseFloat(e.target.value.replace(':', '.')) + 1;

  //   let monday_start = document.querySelectorAll('.monday_start option');
  //   let monday_end = document.querySelectorAll('.monday_end option');

  //   let lastArr = [];


  //   this.proposalTimes[0]['monday'].forEach((element, index) => {
  //     let arrTime = element.toString().split('-')

  //     lastArr.push(parseFloat(arrTime[0]), parseFloat(arrTime[1]))

  //     let arr = this.duplicateArray(lastArr);


  //     const cluster = (arr, tmp = [], result = []) =>
  //     (result = arr.reduce((acc, c, i) =>
  //     (!tmp.length || c === (arr[i - 1] + 1)
  //       ? (tmp.push(c), acc)
  //       : (acc.push(tmp), tmp = [c], acc))
  //       , []), tmp.length ? (result.push(tmp), result) : result)

  //     let groupArr = cluster(arr)




  //     var end_array = Array.from(groupArr, x => x[x.length - 1]);
  //     var first_array = Array.from(groupArr, x => x[0]);

  //     var subtract_array = end_array.map(function (item, index) {
  //       // In this case item correspond to currentValue of array a,
  //       // using index to get value from array b
  //       return item - first_array[index];
  //     })




  //     for (let i = 0; i < monday_start.length; i++) {
  //       //monday_start[i].innerHTML.replace(':', '.') == arrTime[0].replace(':', '.')
  //       //parseFloat(monday_start[i].innerHTML) == parseFloat(arrTime[0])
  //       if (monday_start[i].innerHTML.replace(':', '.') == arrTime[0].replace(':', '.')) {
  //         monday_start[i].setAttribute('class', 'exists')

  //       }
  //       var indexArray = $.map(subtract_array, function (elementOfArray, indexInArray) {
  //         return elementOfArray > 1 ? indexInArray : null;
  //       });

  //       indexArray.forEach((el, ind) => {
  //         if (parseFloat(monday_start[i].innerHTML) == first_array[el]) {
  //           monday_start[i].setAttribute('class', 'exists')
  //         }
  //       })





  //     }


  //     for (let i = 0; i < monday_end.length; i++) {
  //       //monday_start[i].innerHTML.replace(':', '.') == arrTime[0].replace(':', '.')
  //       //parseFloat(monday_start[i].innerHTML) == parseFloat(arrTime[0])

  //       if (monday_end[i].innerHTML.replace(':', '.') == arrTime[1].replace(':', '.')) {
  //         monday_end[i].setAttribute('class', 'exists')
  //       }
  //       var indexArray = $.map(subtract_array, function (elementOfArray, indexInArray) {
  //         return elementOfArray > 1 ? indexInArray : null;
  //       });

  //       indexArray.forEach((el, ind) => {
  //         if (parseFloat(monday_end[i].innerHTML) == 12) {
  //           monday_end[i].setAttribute('class', 'exists')
  //         }
  //       })


  //     }

  //     // for (let i = 0; i < monday_end.length; i++) {
  //     //   //monday_end[i].innerHTML == arrTime[1]
  //     //   if (parseFloat(monday_start[i].innerHTML) == parseFloat(arrTime[0]) + 1) {
  //     //     monday_end[i].setAttribute('class', 'exists')
  //     //   }

  //     // }
  //   });

  //   $('.monday_start option').not(".exists").remove()
  //   $('.monday_end option').not(".exists").remove()


  // }

  // deletePermanent(){
  //   this.studentService.deletePermanentFilter().subscribe(res =>{
  //     this.retrieveTutors();

  //   })
  // }

  // searchFilter(){
  //   this.studentService.permanentFilter().subscribe(res =>{
  //     this.retrieveTutors();
  //   })
  // }
  timer
  onChangeSlider(e) {
    this.isFetching = true;
    this.timer = setTimeout(() => {

      this.filtersObject['min_age'] = this.minValueAge;
      this.filtersObject['max_age'] = this.highValueAge;

      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {
        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });
    }, 1000)
  }


  onChangeSliderkm(e) {

    this.isFetching = true;
    this.timer = setTimeout(() => {

      this.filtersObject['distance'] = this.valueKm;

      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {
        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });
    }, 1000)
  }




  sliderKm(e) {
    clearTimeout(this.timer);
  }

  onChangeRangeIn(e) {
    clearTimeout(this.timer);
  }




  radioFilters(e) {


    delete this.filtersObject['price'];
    delete this.filtersObject['price-rating'];
    delete this.filtersObject['rating'];

    if (e.value == 'rating') {
      this.filtersObject[e.value] = '';
    }
    else if (e.value == 'price') {
      this.filtersObject[e.value] = 'high';
    }
    else if (e.value == 'price-rating') {
      this.filtersObject[e.value] = '';
    }
    //this.search()


    this.isFetching = true;

    setTimeout(() => {
      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {
        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });

    }, 500)
  }
  // ratingChange(e) {
  //   delete this.filtersObject['price'];

  //   if (e.target.checked === true) {
  //     e.target.parentNode.classList.add('rating-checked');
  //     this.filtersObject[e.srcElement.id] = '';

  //     if (e.srcElement.id == 'rating') {
  //       this.ratingProperty = false
  //       this.pricePoperty = true;
  //       this.priceRatingProperty = true;
  //     }
  //     else if (e.srcElement.id == 'price-rating') {
  //       this.ratingProperty = true;
  //       this.pricePoperty = true;
  //       this.priceRatingProperty = false;
  //     }

  //   } else {
  //     e.target.parentNode.classList.remove('rating-checked');
  //     delete this.filtersObject[e.srcElement.id];

  //     this.ratingProperty = false;
  //     this.pricePoperty = false;
  //     this.priceRatingProperty = false;
  //   }
  //   this.search()
  // }

  // priceChange(e) {
  //   if (e.target.checked === true) {
  //     e.target.parentNode.classList.add('rating-checked');
  //     this.filtersObject[e.srcElement.id] = 'high';
  //     if (e.srcElement.id == 'price') {
  //       this.ratingProperty = true
  //       this.pricePoperty = false;
  //       this.priceRatingProperty = true;
  //     }
  //   } else {
  //     e.target.parentNode.classList.remove('rating-checked');
  //     this.filtersObject[e.srcElement.id] = 'low';
  //     this.ratingProperty = false;
  //     this.pricePoperty = false;
  //     this.priceRatingProperty = false;
  //   }
  //   this.search()
  // }

  selectChange(e) {
    if (e.value == 'none') {
      delete this.filtersObject[e.source.id]
    } else {
      this.filtersObject[e.source.id] = e.value;
    }
    //this.search();

    this.isFetching = true;
    setTimeout(() => {
      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {
        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });

    }, 500)

  }

  // valuechange(e) {
  //   if (e.target.value == "") {
  //     delete this.filtersObject[e.target.id]
  //   } else {
  //     this.filtersObject[e.target.id] = e.target.value;
  //   }
  // }


  // radioChange(e) {

  //   this.filtersObject[e.source._elementRef.nativeElement.parentElement.id] = e.value;
  //   this.search();
  // }

  search() {

    this.router.navigate([], {
      queryParams: {
        // max_price: this.highValuePrice,
        // min_price: this.minValuePrice,
        // min_age: this.minValueAge,
        // max_age: this.highValueAge,
        // distance: this.valueKm,
        // tutoring_place: this.tutoring_place,
        // tutoring_style: this.tutoring_style,
        day: this.day.toString(),
      }
    });

    this.filtersObject['min_age'] = this.minValueAge;
    this.filtersObject['max_age'] = this.highValueAge;
    this.filtersObject['distance'] = this.valueKm;
    this.filtersObject['tutoring_place'] = this.tutoring_place;
    this.filtersObject['tutoring_style'] = this.tutoring_style;


    setTimeout(() => {

      this.params = {
        ...this.route.snapshot.queryParamMap['params'],
        ...this.filtersObject,
      }

      this.studentService.generalfilters(this.params).subscribe(data => {

        this.matchedTutors = data.data.subjects;
        this.isFetching = false;
      });

    }, 700)


  }

  clearFilters() {
    this.day = []
    this.tutoring_place = undefined;
    this.tutoring_style = undefined;
    this.params = {};
    this.filtersObject = {};
    this.retrieveAllTutors();
    this.router.navigate([], {
      queryParams: {}
    });
  }



  initialValues() {
    this.tutorPriceLesson = 0;
    // this.fixedPriceLesson_9 = 0;
    // this.fixedPriceLesson_14 = 0;
    // this.fixedPriceLesson_19 = 0;
    // this.fixedPriceLesson_20 = 0;
    // this.fixedPriceLesson_25 = 0;
    this.percentage = 0;
    // this.percentage_9 = 0;
    // this.percentage_14 = 0;
    // this.percentage_19 = 0;
    // this.percentage_20 = 0;
    // this.percentage_25 = 0;
    this.totalPriceLesson = 0;
    this.tutorPriceLessonSlider = 0;
    this.value = 5;
    this.proposedInformations = {
      times: [],
    }
  }

  // changeColor(val) {
  //   if (val < 0) {
  //     return val.fontcolor('red')
  //   }
  //   else if (val == 0) {
  //     return val.fontcolor('#89909e')
  //   }
  //   else if (val > 0) {
  //     return val.fontcolor('#4bb34b')
  //   }
  // }


  sendToProposal(proposalId, subjectId) {
    this.router.navigate([`student/tutors/proposal/`, proposalId], { queryParams: { subject_id: subjectId } });
  }

  sendToBooking(proposalId, subjectId) {
    this.router.navigate([`student/tutors/booking/`, proposalId], { queryParams: { subject_id: subjectId } });
  }


  // open proposal modal
  // proposalModal(proposal, tutor, subjectId) {
  //   console.log(tutor)

  //   this.studentService.sendSubjectId(tutor, subjectId).subscribe(data => {
  //     // this.desiredDays = data.proposal.days;
  //     console.log('nest', data)
  //     this.proposalPrices = data.prices;
  //     this.desiredDayArray = data.days;

  //   })

  //   this.proposalObject.proposals.push({
  //     teacher_name: tutor.name,
  //     teacher_id: tutor.id,
  //     teacher_email: tutor.user.email,
  //     subject_id: subjectId,
  //     days: this.newTimeArr //timeDays[0]
  //   })



  //   //customize array for html template
  //   tutor.times.forEach(element => {
  //     if (element.pivot_teacher.day == 'monday') {
  //       this.proposalTimes[0].monday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'tuesday') {
  //       this.proposalTimes[0].tuesday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'wednesday') {
  //       this.proposalTimes[0].wednesday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'thursday') {
  //       this.proposalTimes[0].thursday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'friday') {
  //       this.proposalTimes[0].friday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'saturday') {
  //       this.proposalTimes[0].saturday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     } else if (element.pivot_teacher.day == 'sunday') {
  //       this.proposalTimes[0].sunday.push({
  //         time: element.time,
  //         id: element.id
  //       })
  //     }
  //   });

  //   this.modalService.open(proposal, { windowClass: 'dark-modal' }).dismissed.subscribe(data => {

  //     this.proposalTimes = [{
  //       monday: [],
  //       tuesday: [],
  //       wednesday: [],
  //       thursday: [],
  //       friday: [],
  //       saturday: [],
  //       sunday: []
  //     }]

  //     this.timeDays = [
  //       {
  //         monday: [],
  //         tuesday: [],
  //         wednesday: [],
  //         thursday: [],
  //         friday: [],
  //         saturday: [],
  //         sunday: []
  //       },
  //     ];
  //     this.proposalObject.proposals = []

  //     this.desiredFormGroup.reset();
  //   })

  // }


  timeDays = [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    },
  ];

  wrapTime = [{
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  }]


  // selectTime(e) {

  //   if (e.target.checked === true && e.target.id == 'monday' + e.target.defaultValue) {

  //     this.timeDays[0].monday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 1,
  //       time: e.target.nextSibling.data,
  //     });
  //     e.target.parentNode.classList.add('colorTime');


  //   } else if (e.target.checked === true && e.target.id === 'tuesday' + e.target.defaultValue) {

  //     this.timeDays[0].tuesday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 2,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');


  //   } else if (e.target.checked === true && e.target.id === 'wednesday' + e.target.defaultValue) {

  //     this.timeDays[0].wednesday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 3,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');

  //   } else if (e.target.checked === true && e.target.id === 'thursday' + e.target.defaultValue) {

  //     this.timeDays[0].thursday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 4,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');

  //   } else if (e.target.checked === true && e.target.id === 'friday' + e.target.defaultValue) {

  //     this.timeDays[0].friday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 5,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');

  //   }
  //   else if (e.target.checked === true && e.target.id === 'saturday' + e.target.defaultValue) {

  //     this.timeDays[0].saturday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 6,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');

  //   }
  //   else if (e.target.checked === true && e.target.id === 'sunday' + e.target.defaultValue) {

  //     this.timeDays[0].sunday.push({
  //       time_id: e.target.defaultValue,
  //       day_id: 7,
  //       time: e.target.nextSibling.data,
  //       periodicity: "weekly"
  //     });
  //     e.target.parentNode.classList.add('colorTime');

  //   } else {

  //     e.target.parentNode.classList.remove('colorTime');

  //     if (e.target.id == 'monday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].monday.findIndex(el => el.time_id === e.target.defaultValue)

  //       this.timeDays[0].monday.splice(index, 1);
  //     } else if (e.target.id == 'tuesday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].tuesday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].tuesday.splice(index, 1);
  //     } else if (e.target.id == 'wednesday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].wednesday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].wednesday.splice(index, 1);
  //     }
  //     else if (e.target.id == 'thursday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].thursday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].thursday.splice(index, 1);
  //     } else if (e.target.id == 'friday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].friday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].friday.splice(index, 1);
  //     } else if (e.target.id == 'saturday' + e.target.defaultValue) {

  //       var index = this.timeDays[0].saturday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].saturday.splice(index, 1);
  //     } else if (e.target.id == 'sunday' + e.target.defaultValue) {
  //       var index = this.timeDays[0].sunday.findIndex(el => el.time_id === e.target.defaultValue)
  //       this.timeDays[0].sunday.splice(index, 1);
  //     }
  //   }
  //   let day = e.target.name;

  //   this.wrapTime[0][day] = this.reduceArray(this.timeDays[0][day].slice().sort((a, b) => a.id - b.id));


  //   this.addProposalPeriodicity(this.timeDays[0], day);

  //   this.desireSelectedDays(this.timeDays[0], day);

  // }


  // reduceArray(arr) {
  //   return arr.reduce((acc, current) => {
  //     const lastSubArray = acc[acc.length - 1];

  //     if (!lastSubArray || parseInt(lastSubArray[lastSubArray.length - 1].time_id) !== parseInt(current.time_id) - 1) {
  //       acc.push([]);
  //     }

  //     acc[acc.length - 1].push(current);

  //     return acc;

  //   }, []);
  // }

  // get proposalPeriodicityArray() {
  //   return (this.desiredFormGroup.get('proposalPeriodicity') as FormArray).controls;
  // }

  //add formGroup proposalPeriodicity
  chosedDays = []
  daysFormCounted;


  // addProposalPeriodicity(daysArray, day) {


  //   let daysForm = {};
  //   let saveForm;

  //   if (this.chosedDays.includes(day) === false) this.chosedDays.push(day);

  //   for (let i = 0; i < this.chosedDays.length; i++) {
  //     if (daysArray[day].length == 0) {
  //       if (this.chosedDays[i] == day) {
  //         this.chosedDays.splice(i, 1)
  //       }
  //     }
  //     daysForm[this.chosedDays[i]] = ['', Validators.required]

  //   }


  //   // for (let i = 0; i < this.chosedDays.length; i++) {
  //   //     daysForm[this.chosedDays[i]] = ['', Validators.required];

  //   // }

  //   daysForm['desiredDay'] = ['', Validators.required]
  //   daysForm['desiredDate'] = ['', Validators.required]
  //   daysForm['numberLessonsForm'] = ['', Validators.required]

  //   this.desiredFormGroup = this.formBuilder.group(
  //     daysForm
  //   )

  //   console.log('daysForm', this.chosedDays)

  // }

  //Desired days porposalModal. Add days to array and reduce duplicated elements
  // desireSelectedDays(daysArray, day) {
  //   if (this.desiredDays.includes(day) === false) this.desiredDays.push(day);

  //   for (let i = 0; i < this.desiredDays.length; i++) {
  //     if (daysArray[day].length == 0) {
  //       if (this.desiredDays[i] == day) {
  //         this.desiredDays.splice(i, 1)
  //       }
  //     }
  //   }

  //   if (this.desiredFormGroup.get('desiredDay')) {
  //     this.desiredFormGroup.get('desiredDay').updateValueAndValidity();
  //   }


  // }

  // periodDropdown(dayName) {

  //   if (this.desiredFormGroup.get(dayName)) {
  //     this.desiredFormGroup.get(dayName).updateValueAndValidity();
  //   }


  //   this.newTimeArr[dayName] = this.timeDays[0][dayName].map(el => ({ ...el, periodicity: this.desiredFormGroup.value[dayName] }));


  // }

  lessonsNumberDrop(e) {
    this.desiredFormGroup.get('numberLessonsForm').updateValueAndValidity();

  }
  //sendPropsals() {

    //this.proposalObject.proposals.push(this.proposalObjectTemp.proposals[0])


    // this.studentService.proposals(
    //   this.proposalObject.proposals[0],
    //   this.desiredFormGroup.value.desiredDate,
    //   this.priceId,
    //   this.desiredFormGroup.value.numberLessonsForm).subscribe(data => {
    //     Swal.fire({
    //       title: this.arrLang[this.lang].done,
    //       text: this.arrLang[this.lang].matched_tutors.proposal,
    //       icon: 'success',
    //       confirmButtonText: 'OK'
    //     })
    //     this.retrieveTutors();
    //     this.retrieveMatchedTutorsSeen();
    //     this.modalService.dismissAll()
    //   }, (error) => {

    //     return Swal.fire({
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: this.arrLang[this.lang].wrong,
    //     })

    //   });


  //}

  // timeValidator(control: AbstractControl) {

  //   if (control && (control.value !== null || control.value !== undefined)) {
  //     const valu = parseInt(control.value);
  //     if (typeof this.proposalTimes[0].monday.length == 'undefined') {

  //     }
  //     for (var i = 0; i < this.proposalTimes[0].monday.length; i++) {
  //       var arr = this.proposalTimes[0].monday[i].split('-');


  //       if (valu !== 8 && valu !== 10) {
  //         return {
  //           isError: true
  //         };
  //       }
  //     }

  //     return null;
  //   }
  // }




  //desired time booking modal
  daySelect(e) {
    this.proposedIndexDay = this.proposedFormGroup.value.day;

  }

  //desired time proposal modal
  selectDesiredTime(e) {

    this.desiredDayIndex = this.desiredFormGroup.value.desiredDay;
  }


  modalOpen = false;
  //booking view modal
  // openWindowCustomClass(content, tutor, subjectId) {


  //   console.log(tutor)
  //   this.modalOpen = true;

  //   let groups = [];
  //   let dynamicGroup = {};
  //   this.studentService.sendSubjectId(tutor, subjectId).subscribe(data => {

  //     console.log('subjectId', data)
  //     this.startingLessons = data.proposal.starting_lessons;

  //     data.proposal.details.forEach(element => {
  //       this.proposedInformations.times.push({
  //         time_id: element.time_id,//time_id
  //         day: element.day,
  //         //day_id: element.day_id,
  //       })
  //     });


  //     //edite array based on day name
  //     this.details = data.proposal.details.reduce((acc, d) => {
  //       let idx = acc.findIndex(a => a.day === d.day);
  //       let val = { time: d.time, time_id: d.time_id };

  //       if (idx > -1) {
  //         acc[idx].time.push(val);
  //       } else {
  //         acc.push({ day: d.day, time: [val] });
  //       }

  //       return acc;
  //     }, []);




  //     for (let i = 0; i < this.details.length; i++) {
  //       this.details[i].time.forEach((element, index) => {

  //         groups.push(this.details[i].day + index)
  //       });
  //     }

  //     for (let i = 0; i < groups.length; i++) {
  //       dynamicGroup[groups[i]] = ['', Validators.required];
  //     }

  //     dynamicGroup['day'] = ['', Validators.required]
  //     dynamicGroup['date'] = ['', Validators.required]
  //     this.proposedFormGroup = this.formBuilder.group(
  //       dynamicGroup,
  //     );

  //     this.proposedDays = data.proposal.days;



  //     //Slider data
  //     this.teacher = data;
  //     console.log('tat', data)
  //     for (var i = 0; i < data.prices.length; i++) {

  //       this.fixedPriceLesson[i] = data.prices[i].price;
  //     }

  //     setTimeout(() => {
  //       this.value = 6;
  //       this.percCalculate(data);
  //       this.sliderEvent()
  //       this.initiateCardElement();

  //       // //Save preselected day&date
  //       // this.preDay = "0"
  //       // this.daySelect('0');
  //       // this.proposedFormGroup.controls.day.setValue('0');
  //       // this.preDate = '2021-06-14';

  //     }, 100)

  //   })



  //   // this.studentService.getTutor(tutor.id).subscribe(data => {
  //   //   this.teacher = data.teacher;



  //   //   for (var i = 0; i < this.teacher.prices_per_lesson.length; i++) {

  //   //     this.fixedPriceLesson[i] = this.teacher.prices_per_lesson[i].pivot.price;
  //   //   }

  //   //   //this.tutorPriceLesson = this.teacher.prices_per_lesson[0].pivot.price;
  //   //   // this.fixedPriceLesson_9 = this.teacher.prices_per_lesson[0].pivot.price;
  //   //   // this.fixedPriceLesson_14 = this.teacher.prices_per_lesson[1].pivot.price;
  //   //   // this.fixedPriceLesson_19 = this.teacher.prices_per_lesson[2].pivot.price;
  //   //   // this.fixedPriceLesson_20 = this.teacher.prices_per_lesson[3].pivot.price;
  //   //   // this.fixedPriceLesson_25 = this.teacher.prices_per_lesson[4].pivot.price;


  //   //   setTimeout(() => {
  //   //     this.value = 6;
  //   //     this.percCalculate(data.teacher);
  //   //     this.sliderEvent()
  //   //     this.initiateCardElement();
  //   //   }, 100)

  //   // })
  //   this.modalService.open(content, { windowClass: 'dark-modal' }).dismissed.subscribe(data => {
  //     this.initialValues();
  //     this.proposedFormGroup.reset()
  //     if (this.card) {
  //       // We remove event listener here to keep memory clean
  //       this.card.removeEventListener('change', this.cardHandler);
  //       this.card.destroy();
  //     }


  //     this.modalOpen = false;

  //   })

  // }


  //Booking view proceed to the final payment step
  // nextPay() {
  //   for (let i = 0; i <= Object.keys(this.proposedFormGroup.value).length - 3; i++) {

  //     this.proposedInformations.times[i]['periodicity'] = this.proposedFormGroup.value[Object.keys(this.proposedFormGroup.value)[i]]

  //   }

  // }

  // bookTutor(tutor) {

  //   const timesArray = {
  //     times: []
  //   };


  //   // tutor.times.forEach(element => {
  //   //   timesArray.times.push({
  //   //     id: element.id,
  //   //     day: element.pivot_teacher.day
  //   //   })
  //   // });

  //   this.dataPost = {
  //     value: this.value,
  //     price_lesson: this.tutorPriceLesson,
  //     total_price: this.totalPriceLesson,
  //     ...tutor,
  //   }

  //   // this.createStripeToken();


  //   // this.studentService.bookTeacher(this.dataPost).subscribe(data => {
  //   //   Swal.fire({
  //   //     title: 'Success!',
  //   //     text: 'Teacher booked successfully',
  //   //     icon: 'success',
  //   //     confirmButtonText: 'OK'
  //   //   });

  //   // }, (error) => {

  //   //   return Swal.fire({
  //   //     icon: 'error',
  //   //     title: 'Oops...',
  //   //     text: 'Something went wrong!',
  //   //   })

  //   // })

  //   this.initialValues();
  //   this.modalService.dismissAll()
  // }



  // percCalculate(teacher) {

  //   const numberArr = [9, 10, 15, 25, 20]

  //   for (var i = 0; i < numberArr.length; i++) {
  //     for (var j = 0; j < teacher.prices.length; j++) {

  //       if (i == j) {

  //         this.tutorPriceLesson = teacher.prices[j].price;
  //         if (this.nr_of_lessons_id == teacher.prices[j].id) {

  //           this.perArr[i] = ((numberArr[i] * (this.tutorPriceLesson)) / (numberArr[i] * this.tutorPriceLesson) * 100) - 100;

  //         } else {
  //           this.perArr[i] = 100 - ((numberArr[i] * this.tutorPriceLesson) / (numberArr[i] * (this.tutorPriceLesson - (teacher.prices[j].price - teacher.prices[this.nr_of_lessons_id - 1].price))) * 100);
  //         }
  //       }

  //     }
  //   }

  //   //old version
  //   // for (var i = 0; i < numberArr.length; i++) {
  //   //   for (var j = 0; j < teacher.prices_per_lesson.length; j++) {

  //   //     if (i == j) {

  //   //       this.tutorPriceLesson = teacher.prices_per_lesson[j].pivot.price;
  //   //       if (this.nr_of_lessons_id == teacher.prices_per_lesson[j].id) {

  //   //         this.perArr[i] = ((numberArr[i] * (this.tutorPriceLesson)) / (numberArr[i] * this.tutorPriceLesson) * 100) - 100;

  //   //       } else {
  //   //         this.perArr[i] = 100 - ((numberArr[i] * this.tutorPriceLesson) / (numberArr[i] * (this.tutorPriceLesson - (teacher.prices_per_lesson[j].pivot.price - teacher.prices_per_lesson[this.nr_of_lessons_id - 1].pivot.price))) * 100);
  //   //       }
  //   //     }

  //   //   }
  //   // }

  // }

  nrOfLessonsId;

  // sliderEvent() {

  //   if (this.value <= 9) {
  //     this.tutorPriceLessonSlider = this.teacher.prices[0].price;
  //     //this.percentage_9 = 100 - ((this.value * this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[0].pivot.price - this.teacher.prices_per_lesson[1].pivot.price))) * 100);
  //     this.percentage = this.perArr[0];
  //     this.nrOfLessonsId = 1;

  //   } else if (this.value >= 10 && this.value <= 14) {
  //     this.tutorPriceLessonSlider = this.teacher.prices[1].price;
  //     //this.percentage_14 = ((this.value * (this.tutorPriceLesson)) / (this.value * this.tutorPriceLesson) * 100) - 100;
  //     this.percentage = this.perArr[1];
  //     this.nrOfLessonsId = 2;
  //   } else if (this.value >= 15 && this.value <= 19) {
  //     this.tutorPriceLessonSlider = this.teacher.prices[2].price;
  //     //this.percentage_19 = 100 - ((this.value * (this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[2].price - this.teacher.prices[1].price)))) * 100);
  //     this.percentage = this.perArr[2];
  //     this.nrOfLessonsId = 3;
  //   } else if (this.value >= 20 && this.value <= 24) {
  //     this.tutorPriceLessonSlider = this.teacher.prices[3].price;
  //     //this.percentage_20 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[3].price - this.teacher.prices[1].price))) * 100);
  //     this.percentage = this.perArr[3];
  //     this.nrOfLessonsId = 4;
  //   } else if (this.value >= 25) {
  //     this.tutorPriceLessonSlider = this.teacher.prices[4].price;
  //     //this.percentage_25 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices[4].price - this.teacher.prices[1].price))) * 100);
  //     this.percentage = this.perArr[4];
  //     this.nrOfLessonsId = 5;
  //   }
  //   this.totalPriceLesson = this.tutorPriceLessonSlider * this.value;

  //   //old version
  //   // if (this.value <= 9) {
  //   //   this.tutorPriceLessonSlider = this.teacher.prices_per_lesson[0].pivot.price;
  //   //   //this.percentage_9 = 100 - ((this.value * this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[0].pivot.price - this.teacher.prices_per_lesson[1].pivot.price))) * 100);
  //   //   this.percentage = this.perArr[0];

  //   // } else if (this.value >= 10 && this.value <= 14) {
  //   //   this.tutorPriceLessonSlider = this.teacher.prices_per_lesson[1].pivot.price;
  //   //   //this.percentage_14 = ((this.value * (this.tutorPriceLesson)) / (this.value * this.tutorPriceLesson) * 100) - 100;
  //   //   this.percentage = this.perArr[1];
  //   // } else if (this.value >= 15 && this.value <= 19) {
  //   //   this.tutorPriceLessonSlider = this.teacher.prices_per_lesson[2].pivot.price;
  //   //   //this.percentage_19 = 100 - ((this.value * (this.tutorPriceLesson) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[2].pivot.price - this.teacher.prices_per_lesson[1].pivot.price)))) * 100);
  //   //   this.percentage = this.perArr[2];
  //   // } else if (this.value >= 20 && this.value <= 24) {
  //   //   this.tutorPriceLessonSlider = this.teacher.prices_per_lesson[3].pivot.price;
  //   //   //this.percentage_20 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[3].pivot.price - this.teacher.prices_per_lesson[1].pivot.price))) * 100);
  //   //   this.percentage = this.perArr[3];
  //   // } else if (this.value >= 25) {
  //   //   this.tutorPriceLessonSlider = this.teacher.prices_per_lesson[4].pivot.price;
  //   //   //this.percentage_25 = 100 - ((this.value * (this.tutorPriceLesson)) / (this.value * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[4].pivot.price - this.teacher.prices_per_lesson[1].pivot.price))) * 100);
  //   //   this.percentage = this.perArr[4];
  //   // }
  // }



  // calculatePercentage(teacher) {
  //   const nine = 9;
  //   const ten = 10;
  //   const fifteen = 15;
  //   const twentyfive = 25;
  //   const twenty = 20;


  //   if (nine) {

  //     this.tutorPriceLesson = this.teacher.prices_per_lesson[0].pivot.price;
  //     if(this.nr_of_lessons_id == this.teacher.prices_per_lesson[0].id){
  //       this.percentage_9 = ((nine * (this.tutorPriceLesson)) / (nine * this.tutorPriceLesson) * 100) - 100;
  //     }else{
  //       this.percentage_9 = 100 - ((nine * this.tutorPriceLesson) / (nine * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[0].pivot.price - this.teacher.prices_per_lesson[this.nr_of_lessons_id -1].pivot.price))) * 100);
  //     }

  //   }
  //   if (ten) {
  //     this.tutorPriceLesson = this.teacher.prices_per_lesson[1].pivot.price;
  //     if(this.nr_of_lessons_id == this.teacher.prices_per_lesson[1].id){
  //       this.percentage_14 = ((ten * (this.tutorPriceLesson)) / (ten * this.tutorPriceLesson) * 100) - 100;
  //     }else{
  //       this.percentage_14 = 100 - ((ten * this.tutorPriceLesson) / (ten * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[1].pivot.price - this.teacher.prices_per_lesson[this.nr_of_lessons_id -1].pivot.price))) * 100);
  //     }

  //   }
  //   if (fifteen) {
  //     this.tutorPriceLesson = this.teacher.prices_per_lesson[2].pivot.price;
  //     if(this.percentage_19 == this.teacher.prices_per_lesson[2].id){
  //       this.percentage_14 = ((fifteen * (this.tutorPriceLesson)) / (fifteen * this.tutorPriceLesson) * 100) - 100;
  //     }else{
  //       this.percentage_19 = 100 - ((fifteen * (this.tutorPriceLesson) / (fifteen * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[2].pivot.price - this.teacher.prices_per_lesson[this.nr_of_lessons_id -1].pivot.price)))) * 100);
  //     }

  //   }
  //   if (twenty) {
  //     this.tutorPriceLesson = this.teacher.prices_per_lesson[3].pivot.price;
  //     if(this.nr_of_lessons_id == this.teacher.prices_per_lesson[3].id){
  //       this.percentage_20 = ((twenty * (this.tutorPriceLesson)) / (twenty * this.tutorPriceLesson) * 100) - 100;
  //     }else{
  //       this.percentage_20 = 100 - ((twenty * (this.tutorPriceLesson)) / (twenty * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[3].pivot.price - this.teacher.prices_per_lesson[this.nr_of_lessons_id -1].pivot.price))) * 100);
  //     }

  //   }
  //   if (twentyfive) {
  //     this.tutorPriceLesson = this.teacher.prices_per_lesson[4].pivot.price;
  //     if(this.nr_of_lessons_id == this.teacher.prices_per_lesson[4].id){
  //       this.percentage_25 = ((twenty * (this.tutorPriceLesson)) / (twenty * this.tutorPriceLesson) * 100) - 100;
  //     }else{
  //       this.percentage_25 = 100 - ((twentyfive * (this.tutorPriceLesson)) / (twentyfive * (this.tutorPriceLesson - (this.teacher.prices_per_lesson[4].pivot.price - this.teacher.prices_per_lesson[this.nr_of_lessons_id -1].pivot.price))) * 100);
  //     }


  //   }
  // }


  openFilter() {
    this.showFilters = !this.showFilters;
  }

  filterTutors(filterValue) {
    this.activeFilter = filterValue;

    if (filterValue == 'all') {
      this.retrieveMatchedTutorsSeen();
    }

    this.studentService.matchedTutorsSeenFilter(filterValue).subscribe(data => {
      this.matchedTutorsSeen = data;
    })
  }


  removeIntroModal() {
    this.studentService.updateIntro('tutors', false).subscribe(res => {
      console.log(res)
      this.modalService.dismissAll();
    })
  }



}
