import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IbanFormatterPipe } from 'angular-iban';
import { Observable } from 'rxjs';

const LANGUAGE = localStorage.getItem('lang') || 'de';
const API = `https://dev.api.educagrow.com/public/api/admin`

const MATCHES_API = `${API}/matches`;
const STUDENTS_API = `${API}/students`;
const TUTORS_API = `${API}/tutors`;
const APPOINTMENTS_API = `${API}/appointments`;
const NEW_STUDENTS_API = `${API}/new-students`;
const NEW_TEACHERS_API = `${API}/new-teachers`;
const NEW_MATCHES_API = `${API}/new-matches`;
const PAST_LESSONS_API = `${API}/past-lessons`;
const FUTURE_LESSONS_API = `${API}/future-lessons`;
const INVOICES = `${API}/invoices`;
const PAY_INVOICE = `${API}/pay-invoices`;
const GET_CODE = `${API}/get-code`;
const REFUNDS = `${API}/refunds`;
const STOP_COLLAB = `${API}/stop-collab`;
const PROPOSALS = `${API}/proposals`;
const MAILS = `${API}/mails`;
const SUBJECTS = `${API}/subjects`;
const UPDATE_USER = `${API}/update-user`;
const USER_AVAILABILITY = `${API}/user-availability`;
const ROLES = `${API}/roles`;
const ADMINS = `${API}/admins`;
const PAY_EMPLOYEE = `${API}/pay-employee`;
const GET_EMPLOYEE_INVOICES = `${API}/get-employee-invoices`;
const DEACTIVATE_ACCOUNT = `${API}/deactivate-account`;
const MATCHED_TUTORS = `${API}/matched-tutors`;
const NR_LESSONS = `${API}/nr-lessons`;
const TUTORS_FILTER = `${API}/tutors-filter`;
const SETTINGS = `${API}/settings`;
const QA_FORUM = `${API}/qa-forum`;
const FEEDBACK = `${API}/feedback`;
const QUESTIONS = `${API}/questions`;
const TUTOR_CHANGE_PRICES = `${API}/tutor-change-prices`;
const PAYMENTS_DATA = `${API}/payments-data`;
const EXPORT_STUDENTS = `${API}/export-students`;
const EXPORT_TUTORS = `${API}/export-tutors`;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const httpOptionsImage = {

  headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) {
  }


  getMatches(page): Observable<any> {
    return this.http.get(`${MATCHES_API}?page=${page}`);
  }

  getAllStudents(): Observable<any> {
    return this.http.get(STUDENTS_API);
  }
  getStudents(page): Observable<any> {
    return this.http.get(`${STUDENTS_API}?page=${page}`);
  }
  getStudent(id): Observable<any> {
    return this.http.get(`${STUDENTS_API}/${id}`)
  }

  getAllTutors(): Observable<any> {
    return this.http.get(`${TUTORS_API}`);
  }
  getTutors(page): Observable<any> {
    return this.http.get(`${TUTORS_API}?page=${page}`);
  }
  getTutor(id): Observable<any> {
    return this.http.get(`${TUTORS_API}/${id}`)
  }

  getAllAppointments(page): Observable<any> {
    return this.http.get(`${APPOINTMENTS_API}?page=${page}`);
  }

  getNewStudents(params): Observable<any> {
    return this.http.get(`${NEW_STUDENTS_API}`, { params: params });
  }
  getNewTeachers(params): Observable<any> {
    return this.http.get(`${NEW_TEACHERS_API}`, { params: params });
  }
  getNewMatches(params): Observable<any> {
    return this.http.get(`${NEW_MATCHES_API}`, { params: params });
  }
  getPastLessons(): Observable<any> {
    return this.http.get(PAST_LESSONS_API);
  }
  getFutureLessons(): Observable<any> {
    return this.http.get(FUTURE_LESSONS_API);
  }

  getInvoices(): Observable<any> {
    return this.http.get(INVOICES);
  }

  payInvoice(invoicesArray, code): Observable<any> {
    console.log('servv', invoicesArray)
    return this.http.post(PAY_INVOICE, {
      invoices: invoicesArray,
      code: code
    })
  }

  getCode(): Observable<any> {
    return this.http.post(`${GET_CODE}`, {
    })
  }

  getRefunds(): Observable<any> {
    return this.http.get(REFUNDS);
  }

  getStopCollab(): Observable<any> {
    return this.http.get(STOP_COLLAB);
  }

  getProposals(page): Observable<any> {
    return this.http.get(`${PROPOSALS}?page=${page}`)
  }

  sendMails(contactForm, mails, fileUpload) {
    // const fd = new FormData();
    // fd.append('file', fileUpload, fileUpload.name)

    // const jsonFormData: any = new FormData();
    // jsonFormData.append('file', fd)
    // jsonFormData.append('topic', contactForm.value.topic)
    // jsonFormData.append('description', contactForm.value.description)
    // jsonFormData.append('emails', mails)


    //console.log(jsonFormData)

    console.log('mails', mails, contactForm.value.topic, contactForm.value.description);

    return this.http.post(MAILS, {
      emails: mails,
      topic: contactForm.value.topic,
      description: contactForm.value.description,
    })
  }

  addNewSubject(subjectVal) {
    return this.http.post(SUBJECTS, {
      status: subjectVal.value.status,
      de: {
        name: subjectVal.value.subjectDe
      },
      en: {
        name: subjectVal.value.subjectDe
      }
    })
  }

  getSubjects(page) {
    return this.http.get(`${SUBJECTS}?page=${page}`)
  }

  updateSubject(id, status) {
    return this.http.put(`${SUBJECTS}/${id}`, {
      status: status
    })
  }

  updateUser(userForm, userType, userId) {
    return this.http.put(UPDATE_USER, {
      name: userForm.value.name,
      surname: userForm.value.surname,
      phone: userForm.value.phone,
      email: userForm.value.email,
      user_type: userType,
      user_type_id: userId
    })
  }

  updateTime(userType, userId, time) {
    return this.http.post(USER_AVAILABILITY, {
      user_type: userType,
      id: userId,
      days: time
    })
  }

  getRoles() {
    return this.http.get(ROLES)
  }

  getAdmins() {
    return this.http.get(ADMINS)
  }

  getPayouts(id){
    return this.http.get(`${ADMINS}/${id}`)
  }

  setAdmin(registerForm) {
    return this.http.post(ADMINS, {
      name: registerForm.value.name,
      email: registerForm.value.email,
      password: registerForm.value.password,
      roles: registerForm.value.roles
    })
  }
  updateRole(updateForm, id) {
    return this.http.put(`${ADMINS}/${id}`, {
      name: updateForm.value.name,
      email: updateForm.value.email,
      password: updateForm.value.password,
      roles: updateForm.value.roles
    })
  }

  removeAdmin(id) {
    return this.http.delete(`${ADMINS}/${id}`)
  }

  newEmployee(employeeForm) {
    return this.http.post(`${ADMINS}`, {
      name: employeeForm.value.fullname,
      email: employeeForm.value.email,
      password: employeeForm.value.password,
      roles: employeeForm.value.roles,
      iban_number: employeeForm.value.iban_number,
      card_holder: employeeForm.value.card_holder,
      address: employeeForm.value.address,
      zip: employeeForm.value.zip,
      city: employeeForm.value.city,
      birthdate: employeeForm.value.birthdate,
      phone: employeeForm.value.phone
    })
  }

  updateEmployee(employeeForm, id){
    return this.http.put(`${ADMINS}/${id}`, {
      name: employeeForm.value.fullname,
      email: employeeForm.value.email,
      password: employeeForm.value.password,
      roles: employeeForm.value.roles,
      iban_number: employeeForm.value.iban_number,
      card_holder: employeeForm.value.card_holder,
      address: employeeForm.value.address,
      zip: employeeForm.value.zip,
      city: employeeForm.value.city,
      birthdate: employeeForm.value.birthdate,
      phone: employeeForm.value.phone
    })
  }

  payEmployee(payForm, code){
    return this.http.post(`${PAY_EMPLOYEE}`, {
      user_id: payForm.value.user_id,
      amount: payForm.value.amount,
      description: payForm.value.description,
      code: code
    })
  }

  getEmployeeInvoices(){
    return this.http.get(`${GET_EMPLOYEE_INVOICES}`)
  }

  getMatchTutors(id) {
    return this.http.get(`${MATCHED_TUTORS}/${id}`)
  }

  deactiveAccount(userType, userId) {
    return this.http.post(DEACTIVATE_ACCOUNT, {
      user_type: userType,
      user_type_id: userId
    })
  }

  getNrLessons() {
    return this.http.get(`${NR_LESSONS}`)
  }

  updatePercentage(percentageForm) {
    return this.http.put(`${NR_LESSONS}`, {
      nr_of_lessons: [{
        id: 1,
        percentage: percentageForm.value.lessons_1_9
      },
      {
        id: 2,
        percentage: percentageForm.value.lessons_10_14
      },
      {
        id: 3,
        percentage: percentageForm.value.lessons_15_19
      },
      {
        id: 4,
        percentage: percentageForm.value.lessons_20_24
      },
      {
        id: 5,
        percentage: percentageForm.value.lessons_25
      }]
    })
  }


  getFilterTutors(val) {
    return this.http.get(`${TUTORS_API}`, { params: val })
  }

  getFilterStudents(val) {
    return this.http.get(`${STUDENTS_API}`, { params: val })
  }

  getFilterMatches(val) {
    return this.http.get(MATCHES_API, { params: val });
  }

  getFilterApointments(val) {
    return this.http.get(APPOINTMENTS_API, { params: val });
  }

  getFilterProposals(val) {
    return this.http.get(PROPOSALS, { params: val });
  }

  getSettings() {
    return this.http.get(SETTINGS)
  }

  setSettings(fee) {
    return this.http.post(SETTINGS, {
      website_fee: fee
    })
  }

  getQA() {
    return this.http.get(QA_FORUM)
  }

  getFeedback() {
    return this.http.get(FEEDBACK)
  }

  updateFeedback(id, status, type) {
    return this.http.put(`${FEEDBACK}/${id}`, {
      status: status,
      type: type
    })
  }

  getQuestions() {
    return this.http.get(QUESTIONS)
  }

  tutorChangePrices(editSubjectPricesForm, subjectId, teacherId) {
    return this.http.put(TUTOR_CHANGE_PRICES, {
      teacher_id: teacherId,
      subject: subjectId,
      prices: [
        {
          id: 1,
          price: editSubjectPricesForm.value.price_one
        },
        {
          id: 2,
          price: editSubjectPricesForm.value.price_two
        },
        {
          id: 3,
          price: editSubjectPricesForm.value.price_three
        },
        {
          id: 4,
          price: editSubjectPricesForm.value.price_four
        },
        {
          id: 5,
          price: editSubjectPricesForm.value.price_five
        }
      ]
    })
  }

  getPaymentsData() {
    return this.http.get(PAYMENTS_DATA)
  }

  updatePaymentsData() {
    return this.http.post(PAYMENTS_DATA, {})
  }

  filterPayments(subjectId) {
    return this.http.get(`${PAYMENTS_DATA}?subject=${subjectId}`)
  }

  studentsExcel(){
    return this.http.get(`${EXPORT_STUDENTS}`,{ responseType: 'blob'})
  }

  tutorsExcel(){
    return this.http.get(`${EXPORT_TUTORS}`,{ responseType: 'blob'})
  }

}
