import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {
  faUserTie,
  faCalendarAlt,
  faInfoCircle,
  faClock,
  faTrashAlt,
  faGraduationCap,
  faPlus,
  faFolder,
  faCheck,
  faTimes,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Options } from '@angular-slider/ngx-slider';
import {
  ApexChart,
  ApexAxisChartSeries,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexGrid,
  ApexNonAxisChartSeries,
  ApexFill,
  ApexStroke,
} from "ng-apexcharts";
import { StudentService } from 'src/app/core/_services/student.service';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';
import { AuthService } from 'src/app/core/_services/auth.service';
import { dateValidatorTimeslot } from '../../core/_shared/validator';
import { DateAdapter } from '@angular/material/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';

import { StripeService } from "ngx-stripe";
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeCardElementChangeEvent
} from '@stripe/stripe-js';

type ApexXAxis = {
  type?: "category" | "datetime" | "numeric";
  categories?: any;
  labels?: {
    style?: {
      colors?: string | string[];
      fontSize?: string;
    };
  };
};


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  legend: ApexLegend;
};


export type chartCircleOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  stroke: ApexStroke;
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("chart") chart: ChartComponent;
  @ViewChild('intro', { read: TemplateRef }) private introModal: TemplateRef<any>;
  public chartOptions: Partial<ChartOptions>;
  public chartCircleOptions: Partial<chartCircleOptions>;
  @ViewChild('#dashboard-card') cardElement: ElementRef;

  elements: StripeElements;
  card: StripeCardElement;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }

  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  arrLang = {
    en: {
      percent: "Percent",
      accept: "Yes, accept it!",
      cancel: "Cancel",
      done: "Done",
      wrong: "Something went wrong!",
      feedback: "Thank you for your feedback",
      matched_tutors: {
        title: "Are you sure?",
        desc: {
          I: "That you want to accept the offer of ",
          II: "- tutoring for ",
          III: "lectures with student ",
          IV: 'from'
        },
        proposal: "Your proposal has been sent"
      },
      reschedule: {
        info: "By clicking on 'Reschedule', you propose the selected appointment to the teacher. The teacher then has the option of accepting the rescheduling or suggesting a new appointment.   In case of a new proposal, you will be informed about the new proposal in the 'Dashboard'."
      },

      extend_lessons: {
        title: 'Are you sure?',
        delete: "Yes, delete it!",
        ask_accept: "Do you want to accept this extension",
        accept: "Yes, accept it!",
        accept_desc: {
          I: "By accepting this extension, you commit, in case of a definitive booking from the student's side, for additional number of ",
          II: " lessons with this student"
        },
        cancel: "Cancel",
        deleted: "Deleted"
      },

    },
    de: {
      percent: "Prozent",
      accept: "Akzeptieren!",
      cancel: "Ablehnen",
      done: "Erledigt",
      wrong: "Etwas ist schief gegangen!",
      feedback: "Danke für dein Feedback",
      matched_tutors: {
        title: "Bist du dir sicher?",
        desc: {
          I: "Du bist im Stande die Offerte einer ",
          II: "- von ",
          III: "mit dem Schüler ",
          IV: 'vom '
        },
        proposal: "Wir senden dem Schüler deine Antwort zu."
      },
      reschedule: {
        info: "Wenn du auf 'Lektion verschieben' klickst, schlägst du dem Lehrer den ausgewählten Termin vor. Der Lehrer hat dann die Möglichkeit, die Terminverschiebung zu akzeptieren oder einen neuen Termin vorzuschlagen.   Im Falle eines neuen Vorschlags wirst du im 'Übersichts'-Tab über den neuen Vorschlag informiert."
      },
      extend_lessons: {
        title: 'Bist Du dir sicher?',
        delete: "Ja, bitte löschen",
        cancel: "Abbrechen",
        deleted: "Gelöscht!",
        accept: "Ja, akzeptiere es!",
        accept_desc: {
          I: "Indem du diese Verlängerung akzeptierst, verpflichtest du dich, im Falle einer endgültigen Buchung von Seiten des Schülers, für eine zusätzliche Anzahl von ",
          II: " Lektionen"
        }
      }
    }
  }

  //@ViewChild('cardInfo', { read: ElementRef }) cardInfo: ElementRef;
  _totalAmount: number;
  //card: any;
  //cardHandler = this.onChange.bind(this);
  cardError: string;


  subjects;
  tutoringStyle;
  educations = [];
  tutoringPlaces;
  fieldsView = true;
  hideDestionationFiled = true;
  showAge = false;
  valueKm = 150;
  optionsKm: Options = {
    showSelectionBar: true,
    floor: 0,
    ceil: 150,
  }

  personalInfo = faInfoCircle;
  proposalTime = faClock;
  goalDelete = faTrashAlt;
  faCalendarAlt = faCalendarAlt;
  faUserTie = faUserTie;
  lessonsInfo = faGraduationCap;
  otherInfo = faFolder;
  plus = faPlus;
  proposalAccept = faCheck;
  porposalDecline = faTimes;
  faArrowRight = faArrowRight;



  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  stripeFormGroup: FormGroup;
  addSubjectFormGroup: FormGroup;
  addSubjectFinishFormGroup: FormGroup;
  isSubjctLinear = true;
  rescheduleForm: FormGroup;
  feedbackForm: FormGroup;

  allProposals;
  calendarProposals;
  status;

  isFetchingProposals = true;
  current_engagements;

  myTeachers;
  myGoals;

  // subjectObtject = {
  //   subject: {},
  //   preferences: {}
  // }
  newDataSubjects;

  lessonsCounter = 0;

  rescheduledLessons;
  rescheduledObject = {
    teacherReschId: '',
    teacherReschDate: '',
    proposedId: '',
    created_by: '',
    comment: '',
  }

  //modal stored times
  reschTimes = []; //get
  reschTimesObject = {
    time: []
  }; //send

  extendLessons;

  //loader for when we fetch free timeslot (modal)
  loadTimeslot = false;

  //dashboard loaders
  upcomingLessonsLoader = true;
  goalsLoader = true;
  subjectLoader = true;
  calendarProposalsLoader = true;
  rescheduleProposalsLoader = true;
  myTeachersLoader = true;
  extendedLessonsLoader = true;


  availableTimeslot = false;

  userId;
  userLastDegree;
  feedbackRating = 1;

  //Feedback object request
  feedbackObject = {}


  //Overview wallet
  studentSubjects;
  walletData;
  walletPercentage = [];


  fullPageLoader = false;

  subscriber;

  lang;
  nr_of_lessons;

  userTutoringPlace;
  userTutoringStyle;

  constructor(private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private registerFormService: RegisterFormsService,
    private authService: AuthService,
    private studentService: StudentService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    private router: Router,
    private stripeService: StripeService,
    private dateAdapter: DateAdapter<Date>) {

    this.lang = localStorage.getItem('lang') || 'de';

    this.dateAdapter.setLocale(this.lang); //dd/MM/yyyy

    // if (window['tidioChatApi']) {
    //   window['tidioChatApi'].on("ready", this.onTidioChatApiReady.bind(this));
    // } else {
    //   document.addEventListener("tidioChat-ready", this.onTidioChatApiReady.bind(this));
    // }



    this.subscriber = this.authService.getUser().subscribe(user => {
      this.userId = user.data.user.id;
      this.userLastDegree = user.data.user.education.id;
      this.userTutoringPlace = user.data.user.preferences.tutoring_place_id;
      this.userTutoringStyle = user.data.user.tutoring_style.id;
      console.log('user logedIn', user)

      this.registerFormService.getEducation().subscribe(res => {
        console.log('education', res)
        for (let i = this.userLastDegree; i <= res.data.length; i++) {
          this.educations.push(res.data[i - 1])
        }
        console.log('education loop', this.educations)
      });
    })

    // this.studentService.getIntro('overview').subscribe(data => {
    //   console.log('intro', data)
    //   if(data == '1'){
    //     this.modalService.open(this.introModal)
    //   }else {
    //     return
    //   }

    // })

    console.log('subscriber', this.subscriber);

    this.studentService.showExtendLessons().subscribe(data => {
      console.log('extends', data)
      this.extendLessons = data['data'];
      this.extendedLessonsLoader = false;
    })

    this.studentService.getAuthSubjects().subscribe(res => {
      console.log('auth', res)
      this.subjects = res['data'];
    });

    this.authService.getUserTest()

    this.registerFormService.getTutoringStyle().subscribe(res => {
      this.tutoringStyle = res.data;
    });


    this.registerFormService.geTutoringPlaces().subscribe(res => {
      this.tutoringPlaces = res.data;
    });

    this.registerFormService.getLessons().subscribe(res => {
      this.nr_of_lessons = res.data;
    });


    this.studentService.getGoals().subscribe(data => {
      console.log('goals', data)
      this.myGoals = data.data;
      this.goalsLoader = false;
    })

    this.studentService.getTeachers().subscribe(data => {
      console.log('my teachers', data)
      this.myTeachers = data;
      this.myTeachersLoader = false;
    })

    this.studentService.getNewSubjects().subscribe(data => {
      console.log('subjects', data)
      this.newDataSubjects = data['data'];
      this.subjectLoader = false;
    })

    // this.studentService.getLessonsByCategory().subscribe(data => {
    // })

    this.studentService.getRescheduledLessons().subscribe(data => {
      console.log('resch', data)
      this.rescheduledLessons = data.data;
      this.rescheduleProposalsLoader = false;
    })

    this.studentService.getProposals().subscribe(data => {
      console.log('proposals', data)
      this.allProposals = data.booking;
      this.calendarProposals = data.appointment;
      this.status = data.booking.staus;
      this.isFetchingProposals = false;
      this.calendarProposalsLoader = false;
    })

    this.studentService.getCurrentEngagements().subscribe(data => {
      this.current_engagements = data.data;
      this.upcomingLessonsLoader = false;
    })

    this.studentService.studentSubject().subscribe(data => {
      console.log('sub', data)
      this.studentSubjects = data;
    })

    this.studentService.wallet('all').subscribe(data => {
      console.log('wallet', data)
      this.walletData = data;
      this.walletPercentage.push(data['percentage']);
      this.chartCircleOptions.series = this.walletPercentage;
    })






    this.chartCircleOptions = {
      series: [],
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },


          dataLabels: {
            show: true,
            name: {
              show: true,
              color: "#888",
              fontSize: "17px"
            },
            value: {
              formatter: function (val) {
                return parseInt(val.toString(), 10).toString();
              },
              color: "#111",
              fontSize: "36px",
              show: true
            }
          }
        }
      },

      stroke: {
        lineCap: "round"
      },
      labels: [this.arrLang[this.lang].percent]
    };



    this.chartOptions = {
      series: [
        {
          name: "distibuted",
          data: [1, 5, 3, 10]
        }
      ],

      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          }
        }
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
      ],
      plotOptions: {
        bar: {
          columnWidth: "70%",
          distributed: true
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      grid: {
        show: false
      },

      xaxis: {
        categories: [
          "Geo",
          "Math",
          "Eng",
          "Pia"
        ],
        labels: {
          style: {
            colors: [
              "#008FFB",
              "#00E396",
              "#FEB019",
              "#FF4560",
              "#775DD0",
              "#546E7A",
              "#26a69a",
              "#D10CE8"
            ],
            fontSize: "12px"
          }
        }
      }
    };

  }

  // initiateCardElement() {
  //   // Giving a base style here, but most of the style is in scss file
  //   const cardStyle = {
  //     base: {
  //       padding: '100px',
  //       color: '#32325d',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSmoothing: 'antialiased',
  //       fontSize: '14px',
  //       '::placeholder': {
  //         color: '#aab7c4',
  //       },

  //     },
  //     invalid: {
  //       color: '#fa755a',
  //       iconColor: '#fa755a',
  //     },
  //   };
  //   this.card = elements.create('card', { cardStyle });
  //   if (this.card) {
  //     this.card.mount(document.querySelector('#dashboard-card'));
  //   }

  //   this.card.addEventListener('change', this.cardHandler);
  //   console.log('card', this.card)
  // }

  // onChange({ error }) {
  //   if (error) {
  //     this.cardError = error.message;
  //   } else {
  //     this.cardError = null;
  //   }
  //   this.cd.detectChanges();
  // }

  // async createStripeToken(id) {
  //   this.fullPageLoader = true;
  //   const { token, error } = await stripe.createToken(this.card);
  //   if (token) {
  //     this.onSuccess(token, id);

  //   } else {
  //     this.onError('Something went wrong');
  //   }

  // }

  // onSuccess(token, id) {
  //   console.log(token)
  //   this.modalService.dismissAll();
  //   this.studentService.bookExtendLesson(this.extendedPrice, id, token).subscribe(data => {
  //     console.log('payment', data)
  //     this.fullPageLoader = false;
  //     this.retrieveExtendLessons();
  //     Swal.fire({
  //       title: this.arrLang[this.lang].extend_lessons.done,
  //       icon: 'success',
  //       confirmButtonText: 'OK'
  //     })

  //   }, (err) => {
  //     console.log(err)
  //     this.fullPageLoader = false;
  //     return Swal.fire({
  //       icon: 'error',
  //       title: 'Ups!',
  //       text: 'Something went wrong!',
  //     })
  //   })

  // }
  // onError(error) {
  //   this.fullPageLoader = false;
  //   this.modalService.dismissAll();
  //   if (error.message) {
  //     console.log(error)
  //     this.cardError = error.message;
  //     Swal.fire({
  //       icon: 'error',
  //       title: error.message,
  //       text: 'Something went wrong!',
  //     })
  //   }

  // }


  createToken(id) {

    this.fullPageLoader = true;

    this.stripeService
      .createToken(this.card)
      .subscribe((result) => {
        if (result.token) {

          this.studentService.bookExtendLesson(this.extendedPrice, id, result.token).subscribe(data => {
            console.log('payment', data)
            this.fullPageLoader = false;
            this.modalService.dismissAll();
            this.retrieveExtendLessons();
            Swal.fire({
              title: this.arrLang[this.lang].extend_lessons.done,
              icon: 'success',
              confirmButtonText: 'OK'
            })

          }, (err) => {
            console.log(err)
            this.fullPageLoader = false;
            return Swal.fire({
              icon: 'error',
              title: 'Ups!',
              text: 'Something went wrong!',
            })
          })

        } else if (result.error) {
          // Error creating the token
          this.fullPageLoader = false;
          console.log(result.error.message);
        }
      });
  }

  ngOnDestroy() {
    // if (this.card) {
    //   // We remove event listener here to keep memory clean
    //   this.card.removeEventListener('change', this.cardHandler);
    //   this.card.destroy();
    // }

    this.subscriber.unsubscribe();

    console.log(this.subscriber)

  }

  ngOnInit(): void {



    this.firstFormGroup = this.formBuilder.group({

    });
    this.secondFormGroup = this.formBuilder.group({

    });

    this.stripeFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      address: ['', Validators.required],

    });



    this.addSubjectFormGroup = this.formBuilder.group({
      subject: ['', Validators.required],
      degree: ['', Validators.required],
      describe: ['', Validators.required],
      nr_lessons: ['', Validators.required],
      periodicity: ['', Validators.required],
      tutoring_style_id: ['', Validators.required],
      tutoring_place_id: ['', Validators.required],
    })

    // this.addSubjectFinishFormGroup = this.formBuilder.group({
    //   tutoring_style_id: ['', Validators.required],
    //   tutoring_place_id: ['', Validators.required],
    //   distance: [''],
    //   online_platform: [''],
    //   gender: ['', Validators.required],
    //   age: ['', Validators.required],
    //   age_range: ['']
    // });

    this.rescheduleForm = this.formBuilder.group({
      date: ['', [Validators.required, dateValidatorTimeslot]],
      comment: ['', Validators.required]
    })

    this.feedbackForm = this.formBuilder.group({
      raport: ['', Validators.required],
      subject: ['', Validators.required]
    })

    // setTimeout(() => {
    //   console.log(window['tidioChatApi'])
    //   window['tidioChatApi'].display(true);
    // }, 1000)
  }



  ngAfterViewInit() {
    // this.stripeService.elements(this.elementsOptions)
    // .subscribe(elements => {
    //   this.elements = elements;
    //   // Only mount the element the first time
    //   if (!this.card) {
    //     this.card = this.elements.create('card', this.cardOptions);
    //     //this.card.mount('#card-element')
    //     this.card.mount(document.querySelector('#dashboard-card') as HTMLCanvasElement);
    //   }
    // });
  }

  // onTidioChatApiReady() {
  //   // Code after chat loaded
  //   if(this.router.url == '/student/dashboard'){
  //     window['tidioChatApi'].hide();
  //   }
  // }

  retriveGoals() {
    this.studentService.getGoals().subscribe(data => {
      this.myGoals = data.data;
    })
  }
  retriveSubjects() {
    this.studentService.getNewSubjects().subscribe(data => {
      this.newDataSubjects = data['data'];
    })
    this.studentService.getAuthSubjects().subscribe(res => {
      console.log('auth', res)
      this.subjects = res['data'];
    });
  }

  retrieveReschLessons() {
    this.studentService.getRescheduledLessons().subscribe(data => {
      this.rescheduledLessons = data.data;
    })
  }

  retrieveMyTeachers() {
    this.studentService.getTeachers().subscribe(data => {
      this.myTeachers = data;
    })
  }

  proposalGetModal(modalProposal, proposal) {
    console.log('proposal', proposal)
    this.modalService.open(modalProposal);
  }

  retrieveUpcomingLessons() {
    this.studentService.getCurrentEngagements().subscribe(data => {
      this.current_engagements = data.data;
      this.upcomingLessonsLoader = false;
    })
  }

  retrieveExtendLessons() {
    this.studentService.showExtendLessons().subscribe(data => {
      this.extendLessons = data['data'];
      this.extendedLessonsLoader = false;
    })

  }

  ratingValue;
  ratingChange(e) {

    this.ratingValue = e.value;
    // if (e.target.checked === true) {
    //   e.target.parentNode.classList.add('rating-checked');

    // } else {
    //   e.target.parentNode.classList.remove('rating-checked');
    // }
  }

  deleteGoal(goal) {
    Swal.fire({
      title: this.arrLang[this.lang].extend_lessons.title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.arrLang[this.lang].extend_lessons.delete
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentService.removeGoal(goal.id).subscribe(data => {
          Swal.fire(
            this.arrLang[this.lang].extend_lessons.deleted,
            'success'
          )
          this.retriveGoals();
        })
      }
    })
  }


  lessonsModal(e) {
    this.hideDestionationFiled = false;
    if (e.value === '4') {
      this.fieldsView = true;
    } else {
      this.fieldsView = false;
    }
  }
  ageModal(e) {
    console.log(e)
    if (e.value == 'yes') {
      this.showAge = true;
    } else {
      this.showAge = false;
    }
  }

  newSubject(subjectModal) {
    this.addSubjectFormGroup.reset();
    // this.addSubjectFinishFormGroup.reset();
    // this.hideDestionationFiled = true;
    this.modalService.open(subjectModal).dismissed.subscribe(data => {
      // this.subjectObtject = {
      //   subject: {},
      //   preferences: {}
      // }
      //this.addSubjectFormGroup.reset();
      // this.addSubjectFinishFormGroup.reset();
    });
  }

  deleteSubject(subjectId) {
    Swal.fire({
      title: this.arrLang[this.lang].extend_lessons.title,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.arrLang[this.lang].extend_lessons.delete
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentService.removeSubject(subjectId).subscribe(data => {
          Swal.fire(
            this.arrLang[this.lang].extend_lessons.deleted,
            'success'
          )
          this.retriveSubjects();
        })
      }
    }, (err) => {

    })

  }

  // addNewSubject() {

  //   this.subjectObtject['subject']['subject_id'] = this.addSubjectFormGroup.value.subject
  //   this.subjectObtject['subject']['education_id'] = this.addSubjectFormGroup.value.degree
  //   this.subjectObtject['subject']['describe'] = this.addSubjectFormGroup.value.degree

  //   console.log(this.subjectObtject)
  //   this.studentService.addNewSubject(this.subjectObtject).subscribe(data => {
  //     this.retriveSubjects();
  //   })
  //   this.modalService.dismissAll();
  //   //this.router.navigate(['student/tutors']);
  // }

  addFullSubject() {
    // this.subjectObtject['subject']['subject_id'] = this.addSubjectFormGroup.value.subject
    // this.subjectObtject['subject']['education_id'] = this.addSubjectFormGroup.value.degree
    // this.subjectObtject['subject']['describe'] = this.addSubjectFormGroup.value.degree

    // this.subjectObtject['preferences']['tutoring_style_id'] = this.addSubjectFinishFormGroup.value.tutoring_style_id
    // this.subjectObtject['preferences']['tutoring_place_id'] = this.addSubjectFinishFormGroup.value.tutoring_place_id
    // if(this.addSubjectFinishFormGroup.value.distance){
    //   this.subjectObtject['preferences']['distance'] = this.addSubjectFinishFormGroup.value.distance
    // }
    // if(this.addSubjectFinishFormGroup.value.online_platform){
    //   this.subjectObtject['preferences']['distance'] = this.addSubjectFinishFormGroup.value.online_platform
    // }

    // this.subjectObtject['preferences']['age_range'] = this.addSubjectFinishFormGroup.value.age_range;


    console.log('full subject', this.addSubjectFormGroup)

    this.studentService.addNewSubjectFinished(this.addSubjectFormGroup.value).subscribe(data => {
      //this.retriveSubjects();
      this.addSubjectFormGroup.reset();
      this.router.navigate(['student/tutors'])
    })


    this.modalService.dismissAll();
    //this.router.navigate(['student/tutors']);
  }


  decLessons() {
    if (this.lessonsCounter == 0) {
      return false;
    }
    this.lessonsCounter--
  }

  incLessons() {
    this.lessonsCounter++
  }


  acceptReschLesson(lesson, status = "confirmed") {
    Swal.fire({
      title: this.arrLang[this.lang].extend_lessons.title,
      icon: 'warning',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      denyButtonText: `I refuse`,
      confirmButtonText: 'Yes, I accept it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.studentService.updateReschLesson(lesson, status).subscribe(data => {
          Swal.fire(
            'OK',
            'success'
          )
          this.retrieveReschLessons();
        })
      }
    })
  }


  //rescheduled modal
  rescheduledModal(reschModal, lesson) {
    console.log('reschedule', lesson)
    this.rescheduledObject.teacherReschId = lesson.teacher.id;
    this.rescheduledObject.proposedId = lesson.id;
    this.rescheduledObject.created_by = lesson.created_by;
    this.modalService.open(reschModal).dismissed.subscribe(data => {
      this.retrieveReschLessons();
      this.availableTimeslot = false;
    });;
  }

  //search by date for free times
  reschDate(e) {
    console.log('object', this.rescheduledObject)
    this.availableTimeslot = true;
    this.loadTimeslot = true;
    this.rescheduledObject.teacherReschDate = '';
    this.reschTimes = [];
    let date = this.datePipe.transform(e.value, 'yyyy-MM-dd')
    this.rescheduledObject.teacherReschDate = date;
    this.studentService.getReschTime(date, this.rescheduledObject.teacherReschId).subscribe(data => {
      this.loadTimeslot = false;
      this.reschTimes.push(data['data'])
      console.log('times', this.reschTimes)
    })

  }

  selectTime(e) {
    if (e.target.checked === true) {
      e.target.parentNode.classList.add('colorTime');
      this.reschTimesObject['time'].push({
        time_id: e.target.defaultValue,
        selected: true,
      })
      this.reschTimes[0].forEach((el, getIndex) => {
        if (parseInt(e.target.defaultValue) != el.id) {
          el.selected = "true";
        }
      })

    } else {
      e.target.parentNode.classList.remove('colorTime');
      var index = this.reschTimesObject['time'].findIndex(el => el.time_id === e.target.defaultValue)
      this.reschTimesObject['time'].splice(index, 1);
      this.reschTimes[0].forEach((el, getIndex) => {
        if (parseInt(e.target.defaultValue) != el.id) {
          el.selected = "false";
        }
      })
    }
  }


  // reschedule lesson from reschedule proposals
  saveRescheduleLesson() {
    this.rescheduledObject.comment = this.rescheduleForm.value.comment;
    this.studentService.newReschAppoint(this.rescheduledObject, this.reschTimesObject).subscribe(res => {
      console.log(res)
      this.retrieveReschLessons();
    })
    this.modalService.dismissAll();
  }

  // reschedule lesson from reschedule proposals
  saveRescheduleUpcomingLesson(id) {
    this.studentService.rescheduleLesson(id, this.reschTimesObject, this.rescheduledObject.teacherReschDate, this.rescheduleForm).subscribe(res => {
      console.log('res')
      Swal.fire({
        title: this.arrLang[this.lang].done,
        text: this.arrLang[this.lang].reschedule.text,
        icon: 'success',
        confirmButtonText: 'OK'
      })
      this.retrieveUpcomingLessons();
      this.modalService.dismissAll()
    }, (error) => {

      return Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: this.arrLang[this.lang].wrong,
      })
    })

    this.modalService.dismissAll();
  }



  // extend lessons modal
  extendedPrice;
  extendLessonModal(extendLesson, teacher) {
    console.log('modal', teacher)
    this.extendedPrice = teacher.price_per_lesson * teacher.lessons_nr;
    this.modalService.open(extendLesson)
  }


  extendLessonPriceModal(extendModal, ext_lesson) {
    console.log('modal', ext_lesson)
    this.extendedPrice = ext_lesson.price_per_lesson * ext_lesson.lessons_nr;
    this.modalService.open(extendModal).dismissed.subscribe(res => {
      if (ext_lesson.status == 'confirmed' && ext_lesson.payment == 'pending') {

        // We remove event listener here to keep memory clean
        //this.card.removeEventListener('change', this.cardHandler);
        this.card.unmount()

        this.card.destroy();


      }

    })
    setTimeout(() => {
      if (ext_lesson.status == 'confirmed' && ext_lesson.payment == 'pending') {
        //this.initiateCardElement();


          this.stripeService.elements(this.elementsOptions)
          .subscribe(elements => {
            this.elements = elements;
            // Only mount the element the first time

              this.card = this.elements.create('card', this.cardOptions);
              //this.card.mount('#card-element')
              this.card.mount(document.querySelector('#dashboard-card') as HTMLCanvasElement);

          });



      }

    }, 100)

  }

  //send extend lesson request
  sendExtendLesson(teacher) {
    this.studentService.extendLesson(teacher, this.lessonsCounter).subscribe(res => {
      console.log(res)
      this.retrieveMyTeachers();
      this.retrieveExtendLessons();
    })
    this.modalService.dismissAll()
  }

  giveFeedbackModal(giveFeedback, teacher) {
    console.log(teacher)

    this.feedbackObject = {
      'student_id': this.userId,
      'teacher_id': teacher.teacher_id,
    }
    this.modalService.open(giveFeedback).dismissed.subscribe(res => {
      this.feedbackObject = {}
      this.feedbackForm.reset();
    });
  }

  sendFeedback() {
    this.feedbackObject['comment'] = this.feedbackForm.value.raport;
    this.feedbackObject['subject_id'] = this.feedbackForm.value.subject;
    this.feedbackObject['rating'] = this.feedbackRating;
    console.log(this.feedbackObject)
    this.studentService.giveFeedback(this.feedbackObject).subscribe(res => {
      Swal.fire({
        title: this.arrLang[this.lang].done,
        text: this.arrLang[this.lang].feedback,
        icon: 'success',
        confirmButtonText: 'OK'
      })
      this.retrieveMyTeachers();
    }, (err) => {
      return Swal.fire({
        icon: 'error',
        title: err.message,
        text: this.arrLang[this.lang].wrong
      })
    })
    this.modalService.dismissAll();
  }

  sendToProposal(proposalId, subjectId) {
    this.router.navigate([`student/tutors/proposal/`, proposalId], { queryParams: { subject_id: subjectId } });
  }


  //get wallet data by subject
  getWalletSubject(event) {
    console.log(event.value)
    this.studentService.wallet(event.value).subscribe(data => {
      this.walletData = data;
      this.walletPercentage.push(data['percentage']);
      this.chartCircleOptions.series = this.walletPercentage;
      console.log(data)
    })
  }


  ///rescheduled lesson modal (current engagements)
  rescheduleCurrentModal(currentModal, tutor) {
    console.log(tutor)
    this.rescheduledObject.teacherReschId = tutor.user_engagements_id;
    this.modalService.open(currentModal, { windowClass: 'dark-modal' }).dismissed.subscribe(res => {
      this.rescheduleForm.reset();
      this.reschTimes = [];
      this.reschTimesObject = {
        time: []
      };
      this.availableTimeslot = false;
    })
  }


  removeIntroModal() {
    this.studentService.updateIntro('overview', false).subscribe(res => {
      console.log(res)
      this.modalService.dismissAll();
    })
  }

}
