import { AfterContentChecked, AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/_services/auth.service';
import { DatePipe } from '@angular/common';
import { StudentService } from 'src/app/core/_services/student.service';
import { TokenStorageService } from 'src/app/core/_services/token-storage.service';
import { Router } from '@angular/router';
import { passValidator, requiredFileType } from 'src/app/core/_shared/validator';
import { CroppedEvent } from 'ngx-photo-editor';
import Swal from 'sweetalert2';
import { RegisterFormsService } from 'src/app/core/_services/register-forms.service';
import { DateAdapter } from '@angular/material/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [DatePipe]
})
export class ProfileComponent implements OnInit{

  user;

  tutoringPlace;
  tutoringStyle;

  firstFormGroup: FormGroup;
  passFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  preferencesFormGroup: FormGroup;


  imageChangedEvent: any;
  base64: any;
  file: File = null;

  passError = '';
  gender;

  imageLoader = false;
  lang;

  times
  getValue = [
    {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: []
    },
  ];
  appointmentObject = {
    times: []
  };

  weekDays = [
    {
      day: 'monday',
      day_id: 1

    },
    {
      day: 'tuesday',
      day_id: 2
    },
    {
      day: 'wednesday',
      day_id: 3
    },
    {
      day: 'thursday',
      day_id: 4
    },
    {
      day: 'friday',
      day_id: 5
    },
    {
      day: 'saturday',
      day_id: 6
    },
    {
      day: 'sunday',
      day_id: 7
    },
  ]

  fullPageLoader = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private datePipe: DatePipe,
    public modalService: NgbModal,
    private studentService: StudentService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private registerService: RegisterFormsService,
    private dateAdapter: DateAdapter<Date>
  ) {


    this.lang = localStorage.getItem('lang') || 'de';
    this.dateAdapter.setLocale(this.lang);

    this.studentService.getTimes().subscribe(data => {
      console.log('times', data)
      this.times = data.data;
    })

    this.registerService.geTutoringPlaces().subscribe(data => {
      console.log(data)
      this.tutoringPlace = data.data
    })


    this.registerService.getTutoringStyle().subscribe(data => {
      console.log(data)
      this.tutoringStyle = data.data
    })

    this.authService.getUser().subscribe(data => {
      console.log(data)
      this.user = data.data;
      this.gender = this.user.user.gender;

      this.firstFormGroup.setValue({
        gender: this.user.user.gender,
        firstname: this.user.user.name,
        surname: this.user.user.surname,
        email: this.user.email,
        phone: this.user.user.phone,
        date: this.user.user.birthdate,
        //date: this.datePipe.transform(this.user.user.birthdate, 'yyyy-MM-dd')
      });

      this.addressFormGroup.setValue({
        streetName: this.user.user.address,
        location: this.user.user.city,
        zipCode: this.user.user.zip,
      })


      // this.preferencesFormGroup.setValue({
      //   tutoringPlace: this.user.user.address,
      //   tutoringStyle: this.user.user.tutoring_style.name
      // })
    })


  }


  ngOnInit(): void {

    this.firstFormGroup = this.formBuilder.group({
      gender: ["", Validators.required],
      firstname: ["", Validators.required],
      surname: ["", Validators.required],
      date: [],
      email: ["", Validators.required],
      phone: ["", Validators.required],

    });

    this.passFormGroup = this.formBuilder.group({
      oldPass: ["", Validators.required],
      password: ["", Validators.required],
      confPass: ["", [Validators.required, passValidator]],
    })

    this.thirdFormGroup = this.formBuilder.group({
      image: ['']
    })

    this.addressFormGroup = this.formBuilder.group({
      streetName: ["", Validators.required],
      zipCode: ["", Validators.required],
      location: ["", Validators.required],
    })

    this.preferencesFormGroup = this.formBuilder.group({
      tutoringPlace: ["", Validators.required],
      tutoringStyle: ["", Validators.required]

    })


    setTimeout(() => {
      this.timeAvailability();
    }, 1000)

  }

  getNumber(e) {
    //console.log('number', e)
    this.firstFormGroup.get('phone').setValue(e)
  }

  numberError = true;
  hasError(e) {
    //console.log(e)
    this.numberError = e;
  }

  telInputObject(obj) {
    //console.log('obj', obj)
  }




  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  imageCropped(event: CroppedEvent) {
    this.base64 = event.base64;
    console.log(event)
    this.file = <File>event.file;
    this.thirdFormGroup.get('image').setValue(this.file);
    this.submitPhoto();
  }


  deleteImage() {
    this.studentService.deleteMedia().subscribe(res => {
      this.thirdFormGroup.get('image').setValue('');
      this.base64 = null
      window.location.reload();
    })
  }

  submitProfile() {
    //this.submitPhoto();
    this.studentService.updateProfile(this.firstFormGroup).subscribe(res => {
      console.log(res)
      window.location.reload();
    })
  }

  submitPhoto() {
    this.imageLoader = true;
    const fd = new FormData();

    fd.append('image', this.file, this.file.name)

    this.studentService.updateMedia(fd).subscribe(res => {
      window.location.reload();
      this.imageLoader = true;
    }, (err) => {
      this.imageLoader = true;
    })
  }

  changePass() {
    this.passError = '';
    this.studentService.updatePassword(this.passFormGroup).subscribe(res => {
      this.tokenStorage.signOut();
      this.router.navigate(['/login'])
      Swal.fire(
        'Your password has been successfully changed!',
        '',
        'success'
      )
    }, (err) => {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.error.message,
      })
    })
  }


  submitAddress() {
    this.studentService.updateProfile(this.addressFormGroup).subscribe(res => {
      console.log(res)
      window.location.reload();
    })
  }

  submitPreferences() {
    this.studentService.updateProfile(this.preferencesFormGroup).subscribe(res => {
      window.location.reload();
    }, err => {
      console.log(err)
    })
  }

  timeLoader = true;
  timeAvailability() {
    this.studentService.getTimeAvailability().subscribe(data => {
      console.log('avail', data.data)


      data.data.forEach(element => {

        for (let i = 0; i < this.weekDays.length; i++){
          if (element.day == this.weekDays[i].day){
            if (element.availability == 'inactive') {
              $(`#${this.weekDays[i].day}${element.time_id}`).prop('disabled', true);
              $(`#${this.weekDays[i].day}${element.time_id}`).parent().addClass('inactive');
              this.getValue[0][this.weekDays[i].day].push({
                day: element.day,
                time_id: element.time_id
              });
              $(`#${this.weekDays[i].day}${element.time_id}`).remove();

            } else if (element.availability == 'active') {
              $(`#${this.weekDays[i].day}${element.time_id}`).prop('checked', true);
              $(`#${this.weekDays[i].day}${element.time_id}`).parent().addClass('colorTime');
              this.getValue[0][this.weekDays[i].day].push({
                day: element.day,
                time_id: element.time_id
              });
            }
          }
        }

        // if (element.day == 'monday') {
        //   if (element.availability == 'inactive') {
        //     $(`#monday${element.time_id}`).prop('disabled', true);
        //     $(`#monday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].monday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#monday${element.time_id}`).prop('checked', true);
        //     $(`#monday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].monday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });
        //   }
        // } else if (element.day == 'tuesday') {
        //   if (element.availability == 'inactive') {
        //     $(`#tuesday${element.time_id}`).prop('disabled', true);
        //     $(`#tuesday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].tuesday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#tuesday${element.time_id}`).prop('checked', true);
        //     $(`#tuesday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].tuesday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });
        //   }

        // } else if (element.day == 'wednesday') {

        //   if (element.availability == 'inactive') {
        //     $(`#wednesday${element.time_id}`).prop('disabled', true);
        //     $(`#wednesday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].wednesday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });
        //     $(`#wednesday${element.time_id}`).remove();

        //   } else if (element.availability == 'active') {
        //     $(`#wednesday${element.time_id}`).prop('checked', true);
        //     $(`#wednesday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].wednesday.push({

        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   }
        // } else if (element.day == 'thursday') {
        //   if (element.availability == 'inactive') {
        //     $(`#thursday${element.time_id}`).prop('disabled', true);
        //     $(`#thursday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].thursday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#thursday${element.time_id}`).prop('checked', true);
        //     $(`#thursday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].thursday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   }


        // } else if (element.day == 'friday') {
        //   if (element.availability == 'inactive') {
        //     $(`#friday${element.time_id}`).prop('disabled', true);
        //     $(`#friday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].friday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#friday${element.time_id}`).prop('checked', true);
        //     $(`#friday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].friday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   }
        // } else if (element.day == 'saturday') {
        //   if (element.availability == 'inactive') {
        //     $(`#saturday${element.time_id}`).prop('disabled', true);
        //     $(`#saturday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].saturday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#saturday${element.time_id}`).prop('checked', true);
        //     $(`#saturday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].saturday.push({

        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   }
        // } else if (element.day == 'sunday') {
        //   if (element.availability == 'inactive') {
        //     $(`#sunday${element.time_id}`).prop('disabled', true);
        //     $(`#sunday${element.time_id}`).parent().addClass('inactive');
        //     this.getValue[0].saturday.push({
        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   } else if (element.availability == 'active') {
        //     $(`#sunday${element.time_id}`).prop('checked', true);
        //     $(`#sunday${element.time_id}`).parent().addClass('colorTime');
        //     this.getValue[0].sunday.push({

        //       day: element.day,
        //       time_id: element.time_id
        //     });

        //   }
        //}
      });
      this.timeLoader = false;
      console.log('getvalue', this.getValue)
    }, (err) =>  {
      this.timeLoader = false;
    })
  }

  saveAvailability() {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, edit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.fullPageLoader = true;
        this.studentService.saveTimeAvailability(this.getValue).subscribe(data => {
          Swal.fire(
            'Changed!',
            'Your time availability has been changed.',
            'success'
          )
          this.fullPageLoader = false;
          window.location.reload();
        })
        //this.timeAvailability();
      }
    })
  }


  changeSelection(e) {

    if (e.target.checked === true && e.target.id == 'monday' + e.target.defaultValue) {

      this.getValue[0].monday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');


    } else if (e.target.checked === true && e.target.id === 'tuesday' + e.target.defaultValue) {

      this.getValue[0].tuesday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'wednesday' + e.target.defaultValue) {

      this.getValue[0].wednesday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'thursday' + e.target.defaultValue) {

      this.getValue[0].thursday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    } else if (e.target.checked === true && e.target.id === 'friday' + e.target.defaultValue) {

      this.getValue[0].friday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'saturday' + e.target.defaultValue) {

      this.getValue[0].saturday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else if (e.target.checked === true && e.target.id === 'sunday' + e.target.defaultValue) {

      this.getValue[0].sunday.push({
        time_id: e.target.defaultValue,
        day: e.target.id.replace(/[0-9]/g, ''),
        // time: e.target.nextSibling.data
      });
      e.target.parentNode.classList.add('colorTime');
    }
    else {
      e.target.parentNode.classList.remove('colorTime');

      if (e.target.id == 'monday' + e.target.defaultValue) {
        var index = this.getValue[0].monday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].monday.splice(index, 1);
      } else if (e.target.id == 'tuesday' + e.target.defaultValue) {
        var index = this.getValue[0].tuesday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].tuesday.splice(index, 1);
      } else if (e.target.id == 'wednesday' + e.target.defaultValue) {
        var index = this.getValue[0].wednesday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].wednesday.splice(index, 1);
      }
      else if (e.target.id == 'thursday' + e.target.defaultValue) {
        var index = this.getValue[0].thursday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].thursday.splice(index, 1);
      } else if (e.target.id == 'friday' + e.target.defaultValue) {
        var index = this.getValue[0].friday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].friday.splice(index, 1);
      } else if (e.target.id == 'saturday' + e.target.defaultValue) {
        console.log(e.target.id)
        var index = this.getValue[0].saturday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].saturday.splice(index, 1);
      } else if (e.target.id == 'sunday' + e.target.defaultValue) {
        var index = this.getValue[0].sunday.findIndex(el => parseInt(el.time_id) === parseInt(e.target.defaultValue))
        this.getValue[0].sunday.splice(index, 1);
      }
    }
    console.log(this.getValue)
  }

  logOut() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login'])
    //window['tidioChatApi'].display(false);
  }


  deactivateAccount(){
    Swal.fire({
      title: 'Do you want to save the continue?',
      text: "if you try to login into your account within a month, your account will be activated automatically",
      showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: `No`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.studentService.deactivateAccount().subscribe(res => {
          this.logOut();
        })
      }
    })
  }

  // openAvailabilityModal(editTime) {
  //   this.timeAvailability();
  //   this.modalService.open(editTime).dismissed.subscribe(data => {
  //     this.getValue = [
  //       {
  //         monday: [],
  //         tuesday: [],
  //         wednesday: [],
  //         thursday: [],
  //         friday: [],
  //         saturday: [],
  //         sunday: []
  //       },
  //     ];
  //   })
  // }






}
