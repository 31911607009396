import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/_services/auth.service';
import { TokenStorageService } from 'src/app/core/_services/token-storage.service';
import {
  faBell,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import { TeacherService } from 'src/app/core/_services/teacher.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponentTutor implements OnInit {

  faBell = faBell;
  faClock = faClock;

  element: any;
  cookieValue;
  flagvalue;
  countryName;
  valueset: string;


  listLang = [
    { text: 'English', flag: '../assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Deutsch', flag: '../assets/images/flags/germany.jpg', lang: 'de' }
  ];

  status: boolean = false;
  user

  lang;

  notifications;

  constructor(private tokenStorage: TokenStorageService,
    private router: Router,
    private teacherService: TeacherService,
    private authService: AuthService) {
    this.authService.getUser().subscribe(data => {
      this.user = data.data;
      console.log('nav', data)
    })

    this.teacherService.getNotifications().subscribe(data => {
      console.log('noti', data)
      this.notifications = data;
    })
  }


  ngOnInit(): void {
    //this.lang = localStorage.getItem('lang') || 'en';

    this.cookieValue = localStorage.getItem('lang') || 'de';
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }


  }

  timeElapsed(date) {
    var seconds = Math.floor((new Date().valueOf() - new Date(date.split('.')[0]).valueOf()) / 1000);


    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.round(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.round(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.round(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.round(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.round(interval) + " minutes";
    }
    return Math.round(seconds) + " seconds";
  }

  headerToggle() {
    this.status = !this.status;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    localStorage.setItem('lang', this.cookieValue)
    window.location.reload();
  }

  // changeLang(lang){
  //   console.log(lang)
  //   localStorage.setItem('lang', lang)
  //   window.location.reload();
  // }

  logOut() {
    this.tokenStorage.signOut();
    this.router.navigate(['/login'])
  }

  // seenNotification(){
  //   this.teacherService.getSeenNotification(notificationId).subscribe(res => {
  //     console.log('senn')
  //   })
  // }

  seenAllNotifications(){
    this.teacherService.getMarkAllSeen().subscribe(res => {
      console.log('done')
    })
  }

}
