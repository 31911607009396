import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorService implements ErrorHandler {

  constructor() {

  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      console.log('Backend error occured', error)
    } else {
      console.log('Frontend error occured', error);
    }
    //this.loggingService.logError(error);
   // console.error(error);
  }
}
